import React from 'react'
import { ErrorOutline } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Box } from '@ui/structure'

interface Props {
  error?: string
  center?: boolean
  className?: string
  action?: {
    onClick: () => void
    label: string
  }
}

const ErrorState = ({
  error = 'Ein Fehler ist aufgetreten',
  center = true,
  className = '',
  action,
}: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        color: ({ palette }) => palette.text.secondary,
        fontWeight: 500,
        ...(center
          ? {
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate3d(-50%, -50%, 0)',
            }
          : {}),
      }}
      className={className}
    >
      <Box mb={1}>
        <ErrorOutline fontSize="medium" />
      </Box>
      {error}
      {action && (
        <Box mt={2}>
          <LoadingButton size="small" onClick={action.onClick} color="inherit" variant="outlined">
            {action.label}
          </LoadingButton>
        </Box>
      )}
    </Box>
  )
}

export default ErrorState

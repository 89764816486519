export const tabConfig = {
  'base-params': {
    value: 'base-params',
    label: 'Parameter',
  },
  'outer-walls': {
    value: 'outer-walls',
    label: 'Wände',
  },
  roofs: {
    value: 'roofs',
    label: 'Dach',
  },
  results: {
    value: 'results',
    label: 'Resultate',
  },
}

import { postRequest, retryUntilSuccessOrFailure } from 'src/state/utils'

export const alignWalls: RequestTypeVariable = {
  getKey: (projectId: string) => ['alignWalls', projectId],
  request: async (id: string): Promise<void> => {
    const { data } = await postRequest<{ task_id: string }>({
      url: `/projects/${id}/planar-model/walls/alignment`,
    })

    await retryUntilSuccessOrFailure({
      url: `/task-status/${data?.task_id}`,
    })
  },
}

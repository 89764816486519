export type LocalTab = 'loads' | 'settings'

export const tabConfig: Record<LocalTab, { value: LocalTab; label: string }> = {
  settings: {
    value: 'settings',
    label: 'Einstellungen',
  },
  loads: {
    value: 'loads',
    label: 'Lasten',
  },
}

export const elementTypeLabelPrefixLookup: Record<ElementTypes, string> = {
  outer_walls: 'Wand',
  inner_walls: 'Wand',
  slabs: 'Decke',
  roof_slabs: 'Dach',
  vertical_slabs: 'Deckenposition',
  vertical_roof_slabs: 'Dachposition',
  beams: 'Unterzug',
  columns: 'Stütze',
  purlins: 'Pfette',
  rips: 'Rippe in Wand',
  lintels: 'Sturz',
  slab_beams: 'Deckenbalken',
  roof_slab_beams: 'Sparren',
  standard_rips: 'Rippe in Wand',
  foundation: 'Fundament',
}

import { RectangleXYZ } from '@modugen/scene/lib/types'

const extrudeMember = (
  member: Member,
  memberHeight = 0.1,
  memberWidth = memberHeight,
): RectangleXYZ => {
  const heightDirection = member.coordinate_system.z_direction
  const widthDirection = member.coordinate_system.y_direction

  const lowerMiddleStartPoint = member.start.addScaledVector(heightDirection, -memberHeight / 2)

  const p1 = lowerMiddleStartPoint.addScaledVector(widthDirection, -memberWidth / 2)
  const p2 = lowerMiddleStartPoint.addScaledVector(widthDirection, memberWidth / 2)

  const lowerMiddleEndPoint = member.end.addScaledVector(heightDirection, -memberHeight / 2)

  const p3 = lowerMiddleEndPoint.addScaledVector(widthDirection, memberWidth / 2)
  const p4 = lowerMiddleEndPoint.addScaledVector(widthDirection, -memberWidth / 2)

  // points need to be in counter clockwise orientation to define extrusion
  // direction
  return [p4, p3, p2, p1]
}

const extrudeBeam = (beam: SlabBeam, beamHeight = 0.1, beamWidth = beamHeight): RectangleXYZ => {
  const heightDirection = beam.coordinate_system.z_direction
  const widthDirection = beam.coordinate_system.y_direction

  const lowerMiddleStartPoint = beam.shape.start.addScaledVector(heightDirection, -beamHeight / 2)

  const p1 = lowerMiddleStartPoint.addScaledVector(widthDirection, -beamWidth / 2)
  const p2 = lowerMiddleStartPoint.addScaledVector(widthDirection, beamWidth / 2)

  const lowerMiddleEndPoint = beam.shape.end.addScaledVector(heightDirection, -beamHeight / 2)

  const p3 = lowerMiddleEndPoint.addScaledVector(widthDirection, beamWidth / 2)
  const p4 = lowerMiddleEndPoint.addScaledVector(widthDirection, -beamWidth / 2)

  // points need to be in counter clockwise orientation to define extrusion
  // direction
  return [p4, p3, p2, p1]
}

export { extrudeMember, extrudeBeam }

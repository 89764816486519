import React, { ReactElement } from 'react'
import { Form } from '@ui/forms'
import TimberFrameFormFields from './TimberFrameFormFields'
import { timberFrameLayerSchema } from './schema'
import { formStateToLayer, layerToFormState } from './utils'

const TimberFrameLayerForm = ({ id, onSubmit, defaultValues }: LayerFormProps): ReactElement => {
  const defaults = defaultValues
    ? layerToFormState(defaultValues as TimberFrameLayer)
    : timberFrameLayerSchema.getDefault()

  return (
    <Form
      id={id}
      validationSchema={timberFrameLayerSchema}
      defaultValues={defaults}
      onSubmit={values => onSubmit(formStateToLayer(values))}
    >
      <TimberFrameFormFields />
    </Form>
  )
}

export default TimberFrameLayerForm

import { array, object, string } from 'yup'

const prioritizationCategorySchema = (category: string) =>
  array()
    .of(string())
    .default([])
    .test({
      name: 'min-prios',
      test: function (value: string[]) {
        if (value.length === 0) {
          return this.createError({
            message: `Bitte wählen Sie für ${category} mindestens eine Zugverbindung`,
            path: category,
          })
        }

        return true
      },
    })

export const schema = object({
  inner_inner: prioritizationCategorySchema('Innen-Innen'),
  inner_foundation: prioritizationCategorySchema('Innen-Fundament'),
  outer_outer: prioritizationCategorySchema('Außen-Außen'),
  outer_foundation: prioritizationCategorySchema('Außen-Fundament'),
})

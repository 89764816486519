import { patchRequest, postRequest } from '../utils'

export const saveSnowLoads: RequestTypeVariable = {
  getKey: (projectId: string) => ['saveSnowLoads', projectId],
  request: async (id: string, data: SnowLoadConfiguration[]): Promise<SnowLoadConfiguration[]> => {
    const { data: result } = await postRequest<SnowLoadConfiguration[]>({
      url: `/projects/${id}/snow-load/load-assignment`,
      data: data,
    })

    return result as SnowLoadConfiguration[]
  },
}

export const saveLiveLoads: RequestTypeVariable = {
  getKey: (projectId: string) => ['saveLiveLoads', projectId],
  request: async (id: string, data: LiveLoadConfiguration[]): Promise<LiveLoadConfiguration[]> => {
    const { data: result } = await postRequest<LiveLoadConfiguration[]>({
      url: `/projects/${id}/live-load/load-assignment`,
      data: data,
    })

    return result as LiveLoadConfiguration[]
  },
}

export const smoothLoads: RequestTypeVariable = {
  getKey: (projectId: string) => ['smoothLoads', projectId],
  request: async (id: string, data: SmoothingSettings): Promise<ElementLoad[]> => {
    const { data: result } = await postRequest<ElementLoad[]>({
      url: `/smooth-loads`,
      data,
    })

    return result as ElementLoad[]
  },
}

export const saveElementLoads: RequestTypeVariable = {
  getKey: (projectId: string) => ['saveElementLoads', projectId],
  request: async (id: string, data: ElementLoad[]): Promise<ElementLoad[]> => {
    const { data: result } = await postRequest<ElementLoad[]>({
      url: `/projects/${id}/manual-loads-per-element`,
      data,
    })

    return result as ElementLoad[]
  },
}

export const saveLoadsOnElement: RequestTypeVariable = {
  getKey: (projectId: string, elementId: string) => ['saveElementLoads', projectId, elementId],
  request: async (
    projectId: string,
    elementId: string,
    data: ElementLoad[],
  ): Promise<ElementLoad[]> => {
    const { data: result } = await postRequest<ElementLoad[]>({
      url: `/projects/${projectId}/manual-loads-per-element/${elementId}`,
      data,
    })

    return result as ElementLoad[]
  },
}

export const saveSeismicSettings: RequestTypeVariable = {
  getKey: (projectId: string) => ['saveSeismicSettings', projectId],
  request: async (id: string, data: SeismicSettings): Promise<SeismicSettings> => {
    const { data: result } = await postRequest<SeismicSettings>({
      url: `/projects/${id}/seismic-settings`,
      data: data,
    })

    return result as SeismicSettings
  },
}

export const uploadSeismicResultsFile: RequestTypeVariable = {
  getKey: (projectId: string) => ['uploadSeismicResultsFile', projectId],
  request: async (id: string, data: File): Promise<void> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.addEventListener('load', async event => {
        if (!event?.target) return

        const str = event.target.result || ''
        const json = JSON.parse(str as string)

        try {
          await postRequest<SeismicSettings>({
            url: `/projects/${id}/seismic-forces-per-segment`,
            data: json,
          })

          resolve()
        } catch (error) {
          reject(error)
        }
      })

      reader.readAsText(data)
    })
  },
}

export const postSimplifiedSeismicConfig: RequestTypeVariable = {
  getKey: (projectId: string) => ['postSimplifiedSeismicConfig', projectId],
  request: async (
    id: string,
    data: SimplifiedSeismicModelConfig,
  ): Promise<SimplifiedSeismicResults> => {
    const { data: result } = await postRequest<SimplifiedSeismicResults>({
      url: `/projects/${id}/simplified-seismic-config`,
      data: data,
    })

    return result as SimplifiedSeismicResults
  },
}

export const postSimplifiedSeismicModelInputs: RequestTypeVariable = {
  getKey: (projectId: string) => ['postSimplifiedSeismicInputs', projectId],
  request: async (id: string, data: SeismicInputsInStorey): Promise<SeismicInputsInStorey> => {
    const { data: result } = await postRequest<SeismicInputsInStorey>({
      url: `/projects/${id}/estimated-model-inputs`,
      data: data,
    })

    return result as SeismicInputsInStorey
  },
}

export const postSimplifiedSeismicResults: RequestTypeVariable = {
  getKey: (projectId: string) => ['postSimplifiedSeismicResults', projectId],
  request: async (
    id: string,
    data: SimplifiedSeismicResults,
  ): Promise<SimplifiedSeismicResults> => {
    const { data: result } = await patchRequest<SimplifiedSeismicResults>({
      url: `/projects/${id}/simplified-seismic-results`,
      data: data,
    })

    return result as SimplifiedSeismicResults
  },
}

export type Tab = 'walls' | 'beams' | 'columns' | 'purlins' | 'slab-beams' | 'roof-slab-beams'

export const tabConfig: Record<Tab, { value: Tab; label: string }> = {
  walls: {
    value: 'walls',
    label: 'Wand',
  },
  'slab-beams': {
    value: 'slab-beams',
    label: 'Deckenbalken',
  },
  'roof-slab-beams': {
    value: 'roof-slab-beams',
    label: 'Dachbalken',
  },
  beams: {
    value: 'beams',
    label: 'Unterzüge',
  },
  purlins: {
    value: 'purlins',
    label: 'Pfetten',
  },
  columns: {
    value: 'columns',
    label: 'Stützen',
  },
}

export const positionGroupingTypeToFilename: { [k in PositionGroupingType]?: string } = {
  beams: 'Unterzüge',
  columns: 'Stützen',
  purlins: 'Pfetten',
  'slab-beams': 'Deckenunterzüge',
  'roof-slab-beams': 'Dachunterzüge',
}

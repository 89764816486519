import React, { ReactNode, ReactElement } from 'react'
import { createPortal } from 'react-dom'
import { useUIStore } from '@stores'

interface Props {
  children: ReactNode
}

export const NavbarPortalLeft = ({ children }: Props): ReactElement => {
  const portalRootLeft = useUIStore(state => state.portalRootLeft)

  return <>{portalRootLeft && createPortal(<>{children}</>, portalRootLeft)}</>
}

export const NavbarPortalCenter = ({ children }: Props): ReactElement => {
  const portalRootCenter = useUIStore(state => state.portalRootCenter)

  return <>{portalRootCenter && createPortal(<>{children}</>, portalRootCenter)}</>
}

export const NavbarPortalRight = ({ children }: Props): ReactElement => {
  const portalRootRight = useUIStore(state => state.portalRootRight)

  return <>{portalRootRight && createPortal(<>{children}</>, portalRootRight)}</>
}

import { useEffect, useRef } from 'react'
import axios from 'axios'
import { OAuthError, useAuth0 } from '@auth0/auth0-react'
import { useConfigStore } from '@stores'

const useAuthToken = () => {
  const { isAuthenticated, getAccessTokenSilently, logout } = useAuth0()
  const authInterceptorRef = useRef<number | undefined>()
  const { auth0Audience, auth0Scopes } = useConfigStore()

  useEffect(() => {
    if (isAuthenticated) {
      authInterceptorRef.current = axios.interceptors.request.use(async axiosConfig => {
        let token: string | undefined

        try {
          token = await getAccessTokenSilently({
            authorizationParams: {
              audience: auth0Audience,
              scopes: auth0Scopes,
            },
          })
        } catch (error) {
          // this is needed when the user has not yet given consent to the usage
          // of his data. note: this will only be happening when running the
          // frontend on localhost as all our applications are registered as
          // first level applications and therefore do not need user consent
          // (only needed for third party applications). localhost however is
          // handled as third party application, hence we have this check here
          if ((error as OAuthError).error === 'consent_required') {
            // we will logout user, so he/she needs to relogin and consent to
            // required scopes. We could use getAccessTokenWithPopup here, but
            // as this requires some fundamental changes to how useAuthToken is
            // being used and this is only relevant for localhost we just logout
            logout({ logoutParams: { returnTo: window.location.origin + '/authorize' } })
          } else {
            throw error
          }
        }

        axiosConfig.headers = {
          ...axiosConfig.headers,
          Authorization: `Bearer ${token}`,
        }

        return axiosConfig
      })
    } else if (authInterceptorRef.current) {
      axios.interceptors.request.eject(authInterceptorRef.current)
      authInterceptorRef.current = undefined
    }

    return () => {
      if (authInterceptorRef.current) {
        axios.interceptors.request.eject(authInterceptorRef.current)
        authInterceptorRef.current = undefined
      }
    }
  }, [isAuthenticated])
}

export default useAuthToken

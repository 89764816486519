import { some, find } from 'lodash-es'
import { Line3 } from 'three'
import { number, object, string, boolean, ref } from 'yup'
import { isPointOnLineSegment } from '@modugen/scene/lib/utils'

export const columnSchema = object({
  guid: string(),
  x: number(),
  y: number(),
  zStart: number(),
  zEnd: number().moreThan(ref('zStart'), 'z Anfang muss kleiner sein als z Ende'),
  isNotOnPurlin: boolean().test({
    name: 'is-not-on-purlin',
    message: 'Die Stütze steht nicht unter der gewählten Pfette',
    test: function () {
      const { purlin, columns } = this.options.context as {
        purlin: ShapeObjectLine
        columns: ShapeObjectLine[]
      }

      if (!purlin) return true

      const currentColumn = find(columns, ['guid', this.parent.guid]) as ShapeObjectLine

      if (!currentColumn) return true

      const purlinStart = purlin.shape.start
      const purlinEnd = purlin.shape.end

      const columnEnd = currentColumn.shape.end

      const line = new Line3(purlinStart.v, purlinEnd.v)

      return isPointOnLineSegment(line, columnEnd.v, 0.01)
    },
  }),
  isIntersecting: boolean().test({
    name: 'is-intersecting-column',
    message: 'Es kann nur eine Stütze an dieser Position geben',
    test: function () {
      const { columns } = this.options.context || {}
      const currentColumn = find(columns, ['guid', this.parent.guid])

      if (!currentColumn) return true

      const currentEnd = currentColumn.shape.end

      return !some(columns, column => {
        if (column.guid === currentColumn.guid) return false

        return column.shape.end.equals(currentEnd)
      })
    },
  }),
})

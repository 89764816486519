import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'

const projectWall2D = (wall: ShapeObject) => {
  const [start, end] = wall.shape.points

  return {
    guid: wall.guid,
    placement: wall.placement,
    points: [new ImmutableVector3(start.x, start.y, 0), new ImmutableVector3(end.x, end.y, 0)],
    openings: wall.openings?.map(opening => {
      const [openingStart, openingEnd] = opening.shape.points
      return {
        points: [
          new ImmutableVector3(openingStart.x, openingStart.y, 0),
          new ImmutableVector3(openingEnd.x, openingEnd.y, 0),
        ],
      }
    }),
  }
}

export default projectWall2D

import { useEffect, useMemo } from 'react'
import { last } from 'lodash-es'
import { useControlStore } from '@editorStores'

const useModelHoverListeners = (
  callbacks?: [ModelHoverListener, ModelHoverListener],
  dependencies: unknown[] = [],
  condition = true,
): ModelHoverListener[] => {
  const hoverListeners = useControlStore(state => state.hoverListeners)
  const setHoverListeners = useControlStore(state => state.setHoverListeners)
  const removeHoverListeners = useControlStore(state => state.removeHoverListeners)

  const latestListeners = useMemo(() => {
    const latestListeners = last(hoverListeners)
    // listener array can be empty
    if (!latestListeners) return []

    return latestListeners
  }, [hoverListeners])

  useEffect(() => {
    if (!condition || !callbacks) return
    setHoverListeners(callbacks)

    return () => removeHoverListeners(callbacks)
  }, [condition, ...dependencies])

  return latestListeners
}

export default useModelHoverListeners

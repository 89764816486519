export type LoadCaseId = `${LoadCategory}+${LoadCaseTypes}`

type Options = {
  [key in LoadCaseId]?: {
    label: string
    category: LoadCategory
    loadCaseType: LoadCaseTypes
    identifier: string
    short: string
  }
}

export const loadCaseOptions: Options = {
  'Dead+DeadLoad': {
    label: 'Eigengewicht',
    category: 'Dead',
    loadCaseType: 'DeadLoad',
    identifier: 'Global Dead Load Case',
    short: 'G',
  },
  'Snow+SnowFull': {
    label: 'Schnee',
    category: 'Snow',
    loadCaseType: 'SnowFull',
    identifier: 'Snow',
    short: 'S',
  },
  'SnowPlus+SnowFull': {
    label: 'Schnee >1000m Höhe ü. NN',
    category: 'SnowPlus',
    loadCaseType: 'SnowFull',
    identifier: 'Snow_Plus',
    short: 'S',
  },
  'Wind+Wind_Global_0': {
    label: 'Wind 0°',
    category: 'Wind',
    loadCaseType: 'Wind_Global_0',
    identifier: 'Wind_Global_0',
    short: 'W',
  },
  'Wind+Wind_Global_90': {
    label: 'Wind 90°',
    category: 'Wind',
    loadCaseType: 'Wind_Global_90',
    identifier: 'Wind_Global_90',
    short: 'W',
  },
  'Wind+Wind_Global_180': {
    label: 'Wind 180°',
    category: 'Wind',
    loadCaseType: 'Wind_Global_180',
    identifier: 'Wind_Global_180',
    short: 'W',
  },
  'Wind+Wind_Global_270': {
    label: 'Wind 270°',
    category: 'Wind',
    loadCaseType: 'Wind_Global_270',
    identifier: 'Wind_Global_270',
    short: 'W',
  },
  'EarthQuake+Earthquake_0': {
    label: 'Erdbeben 0°',
    category: 'EarthQuake',
    loadCaseType: 'Earthquake_0',
    identifier: 'Earthquake_0',
    short: 'E',
  },
  'EarthQuake+Earthquake_90': {
    label: 'Erdbeben 90°',
    category: 'EarthQuake',
    loadCaseType: 'Earthquake_90',
    identifier: 'Earthquake_90',
    short: 'E',
  },
  'EarthQuake+Earthquake_180': {
    label: 'Erdbeben 180°',
    category: 'EarthQuake',
    loadCaseType: 'Earthquake_180',
    identifier: 'Earthquake_180',
    short: 'E',
  },
  'EarthQuake+Earthquake_270': {
    label: 'Erdbeben 270°',
    category: 'EarthQuake',
    loadCaseType: 'Earthquake_270',
    identifier: 'Earthquake_270',
    short: 'E',
  },
  'Live+LiveLoad_A1': {
    label: 'Nutzlast A1',
    category: 'Live',
    loadCaseType: 'LiveLoad_A1',
    identifier: 'Live A1',
    short: 'L',
  },
  'Live+LiveLoad_A2': {
    label: 'Nutzlast A2',
    category: 'Live',
    loadCaseType: 'LiveLoad_A2',
    identifier: 'Live A2',
    short: 'L',
  },
  'Live+LiveLoad_A3': {
    label: 'Nutzlast A3',
    category: 'Live',
    loadCaseType: 'LiveLoad_A3',
    identifier: 'Live A3',
    short: 'L',
  },
  'Live+LiveLoad_B1': {
    label: 'Nutzlast B1',
    category: 'Live',
    loadCaseType: 'LiveLoad_B1',
    identifier: 'Live B1',
    short: 'L',
  },
  'Live+LiveLoad_B2': {
    label: 'Nutzlast B2',
    category: 'Live',
    loadCaseType: 'LiveLoad_B2',
    identifier: 'Live B2',
    short: 'L',
  },
  'Live+LiveLoad_B3': {
    label: 'Nutzlast B3',
    category: 'Live',
    loadCaseType: 'LiveLoad_B3',
    identifier: 'Live B3',
    short: 'L',
  },
  'Live+LiveLoad_C1': {
    label: 'Nutzlast C1',
    category: 'Live',
    loadCaseType: 'LiveLoad_C1',
    identifier: 'Live C1',
    short: 'L',
  },
  'Live+LiveLoad_C2': {
    label: 'Nutzlast C2',
    category: 'Live',
    loadCaseType: 'LiveLoad_C2',
    identifier: 'Live C2',
    short: 'L',
  },
  'Live+LiveLoad_C3': {
    label: 'Nutzlast C3',
    category: 'Live',
    loadCaseType: 'LiveLoad_C3',
    identifier: 'Live C3',
    short: 'L',
  },
  'Live+LiveLoad_C4': {
    label: 'Nutzlast C4',
    category: 'Live',
    loadCaseType: 'LiveLoad_C4',
    identifier: 'Live C4',
    short: 'L',
  },
  'Live+LiveLoad_C5': {
    label: 'Nutzlast C5',
    category: 'Live',
    loadCaseType: 'LiveLoad_C5',
    identifier: 'Live C5',
    short: 'L',
  },
  'Live+LiveLoad_C6': {
    label: 'Nutzlast C6',
    category: 'Live',
    loadCaseType: 'LiveLoad_C6',
    identifier: 'Live C6',
    short: 'L',
  },
  'Live+LiveLoad_D1': {
    label: 'Nutzlast D1',
    category: 'Live',
    loadCaseType: 'LiveLoad_D1',
    identifier: 'Live D1',
    short: 'L',
  },
  'Live+LiveLoad_D2': {
    label: 'Nutzlast D2',
    category: 'Live',
    loadCaseType: 'LiveLoad_D2',
    identifier: 'Live D2',
    short: 'L',
  },
  'Live+LiveLoad_D3': {
    label: 'Nutzlast D3',
    category: 'Live',
    loadCaseType: 'LiveLoad_D3',
    identifier: 'Live D3',
    short: 'L',
  },
  'Live+LiveLoad_E1': {
    label: 'Nutzlast E1',
    category: 'Live',
    loadCaseType: 'LiveLoad_E1',
    identifier: 'Live E1',
    short: 'L',
  },
  'Live+LiveLoad_E2': {
    label: 'Nutzlast E2',
    category: 'Live',
    loadCaseType: 'LiveLoad_E2',
    identifier: 'Live E2',
    short: 'L',
  },
  'Live+LiveLoad_F1': {
    label: 'Nutzlast F1',
    category: 'Live',
    loadCaseType: 'LiveLoad_F1',
    identifier: 'Live F1',
    short: 'L',
  },
  'Live+LiveLoad_F2': {
    label: 'Nutzlast F2',
    category: 'Live',
    loadCaseType: 'LiveLoad_F2',
    identifier: 'Live F2',
    short: 'L',
  },
  'Live+LiveLoad_H': {
    label: 'Nutzlast H',
    category: 'Live',
    loadCaseType: 'LiveLoad_H',
    identifier: 'Live H',
    short: 'L',
  },
}

export const loadTypeToLabel: Record<LoadType, string> = {
  'point-load': 'Punktlast',
  'line-load': 'Linienlast',
  'area-load': 'Flächenlast',
}

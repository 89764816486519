import React, { ReactElement } from 'react'
import { CircularProgress, Fade } from '@mui/material'
import { Container, Overlay } from './styles'

interface Props {
  size?: number
  overlay?: boolean
  'data-cy'?: string
}

const LoadingIndicator = ({
  size = 28,
  overlay = false,
  'data-cy': dataCy,
}: Props): ReactElement => (
  <>
    {overlay && (
      <Fade>
        <Overlay />
      </Fade>
    )}
    <Container>
      <CircularProgress data-cy={dataCy || 'loading'} size={size} />
    </Container>
  </>
)

export default LoadingIndicator

import React, { ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { Grid, Stack, Button } from '@mui/material'
import { CentimeterField, Select } from '@ui/forms'

interface Props {
  supportIndex: number
}

const KC90 = () => {
  return (
    <>
      K<sub>c,90</sub>
    </>
  )
}

const SupportCompressionSettings = ({ supportIndex }: Props): ReactElement | null => {
  const { setValue, getValues, register } = useFormContext()
  const { enqueueSnackbar } = useSnackbar()

  const onCopy = () => {
    const currentValues = getValues()
    const settingsOnThis = currentValues.configurations[supportIndex]
    const updatedConfigurations = currentValues.configurations.map((current: SupportGeometry) => {
      return { ...settingsOnThis, relative_position: current.relative_position }
    })
    register('configurations')
    setValue('configurations', updatedConfigurations, { shouldTouch: true, shouldValidate: true })
    enqueueSnackbar('Für alle Auflager übernommen', { variant: 'default', autoHideDuration: 1000 })
  }

  return (
    <Stack direction="column" spacing={2}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6}>
          <CentimeterField
            name={`configurations[${supportIndex}].width`}
            label="Breite"
            data-cy={`support-config-width-${supportIndex}`}
          />
        </Grid>
        <Grid item xs={6}>
          <CentimeterField
            name={`configurations[${supportIndex}].length`}
            label="Länge"
            data-cy={`support-config-length-${supportIndex}`}
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            label={
              <>
                Schwellenpressung <KC90 />
              </>
            }
            name={`configurations[${supportIndex}].k_c_90`}
            options={[
              { value: 1.0, label: '1.0' },
              { value: 1.25, label: '1.25' },
              { value: 1.5, label: '1.5' },
              { value: 1.75, label: '1.75' },
            ]}
          ></Select>
        </Grid>
        <Grid item xs={6}>
          <Button onClick={onCopy} variant="outlined" size="medium" fullWidth>
            Für alle anwenden
          </Button>
        </Grid>
      </Grid>
    </Stack>
  )
}

export default SupportCompressionSettings

import React, { ReactElement } from 'react'
import { RectangularShapeDrawer } from '@structuralPlanningComponents'
import { v4 as uuid } from 'uuid'
import { useModelStore } from '@editorStores'
import { useGuidToElement, useTypeInteraction } from '@editorHooks'

interface Props {
  setActiveElement: (guid: string) => void
  resetMode: () => void
}

const VerticalRoofSlabDrawer3D = ({ setActiveElement, resetMode }: Props): ReactElement => {
  const setBlockingElement = useModelStore(state => state.setBlockingElement)

  useTypeInteraction(['roof_slabs', 'vertical_roof_slabs'])

  const addRoof = useModelStore(state => state.addVerticalRoofSlab)
  const elements = useGuidToElement()

  return (
    <RectangularShapeDrawer
      onRoofDrawStart={() => setBlockingElement('vertical_roof_slabs', true)}
      onRoofDrawEnd={() => setBlockingElement('vertical_roof_slabs', false)}
      resetMode={resetMode}
      onElementDrawn={(target, points) => {
        const targetElement = elements[target]

        const roof = {
          guid: uuid(),
          shape: { points },
          storey: targetElement.storey,
          type: 'vertical_roof_slabs' as ElementTypes,
          is_local: true,
        }

        setActiveElement(roof.guid)
        addRoof(roof)
      }}
      type="vertical_roof_slabs"
      drawElements={['slabs', 'roof_slabs']}
    />
  )
}

export default VerticalRoofSlabDrawer3D

import { box3fromPoints, LocalCoordinateSystem } from '@editorUtils'
import { filter, some } from 'lodash-es'
import { Box3, Vector3 } from 'three'
import { number, object, string, boolean } from 'yup'
import { getRectCornersFromStartAndEndPoint } from '@modugen/scene/lib/utils'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'

export const openingSchema = object({
  guid: string(),
  xStart: number(),
  yStart: number(),
  xEnd: number(),
  yEnd: number(),
  isIntersecting: boolean().test({
    name: 'is-intersecting-openings',
    message: 'Öffnung darf keine anderen Öffnungen schneiden',
    test: function () {
      const { wall } = this.options.context as { wall: ShapeObject }
      const { xStart, yStart, xEnd, yEnd } = this.parent

      const wallPoints = wall.shape.points
      const wallCoordinateSystem = LocalCoordinateSystem.makeFromPolygon(wallPoints)

      const openingStartLocal = new ImmutableVector3(xStart, yStart)
      const openingEndLocal = new ImmutableVector3(xEnd, yEnd)

      // compute missing corners of rectangle as we need to find the min/max
      // point to construct the box that is used for intersection
      const openingCorners = getRectCornersFromStartAndEndPoint(openingStartLocal, openingEndLocal)

      const drawnOpeningBox = box3fromPoints(openingCorners)

      const retransform = wallCoordinateSystem.matrixTransform.clone().invert()

      const filteredOpenings = filter(wall.openings, o => !o.is_local)

      const wallOpeningBoxes = filteredOpenings?.map(o => {
        // we need to transform the openings of global space to the local wall
        // coordinate system
        const oStart = new Vector3(o.shape.points[0].x, o.shape.points[0].y, o.shape.points[0].z)
        const oStartLocal = oStart.clone().applyMatrix4(retransform)
        const oEnd = new Vector3(o.shape.points[2].x, o.shape.points[2].y, o.shape.points[2].z)
        const oEndLocal = oEnd.clone().applyMatrix4(retransform)
        return new Box3(oStartLocal, oEndLocal)
      })

      const intersects = some(wallOpeningBoxes, box => box.intersectsBox(drawnOpeningBox))

      return !intersects
    },
  }),
})

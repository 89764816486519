export const roofSlabConfig = [
  {
    value: 'SingleSpan',
    label: 'Schubweich',
  },
  {
    value: 'RigidForce',
    label: 'Starr',
  },
  {
    value: 'WallLength',
    label: 'Wandlängen',
  },
]

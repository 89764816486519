import { useMemo, useCallback } from 'react'
import { getTopDomains } from '@scene'
import { filter, toNumber } from 'lodash-es'
import { useStructuralPlanningStore, useModelStore } from '@editorStores'

const useStiffeningSnapValues = (
  elementGuid?: string,
): [(elementGuid: string) => SnapValues[], SnapValues[]] => {
  const stiffeningProposal = useStructuralPlanningStore(state => state.mergedProposal)
  const domains = useModelStore(state => state.domains)

  const getSnapValues = useCallback(
    (elementGuid: string): SnapValues[] => {
      const elementDomains = filter(domains, ['element_guid', elementGuid])
      const topDomain = getTopDomains(elementDomains)[0]
      const stiffeningElements = filter(stiffeningProposal, ['element_guid', elementGuid])

      return stiffeningElements.reduce((acc: SnapValues[], { interval }) => {
        const { lower, upper } = interval
        const lowerSnapValues: SnapValues = {
          absolutePosition: toNumber(lower),
          relativePosition: toNumber(lower) / topDomain.length,
        }
        const upperSnapValues: SnapValues = {
          absolutePosition: toNumber(upper),
          relativePosition: toNumber(upper) / topDomain.length,
        }

        return [lowerSnapValues, upperSnapValues, ...acc]
      }, [] as SnapValues[])
    },
    [stiffeningProposal, domains],
  )

  const snapValues = useMemo(() => {
    if (!elementGuid) return []

    return getSnapValues(elementGuid)
  }, [stiffeningProposal, domains, elementGuid])

  return [getSnapValues, snapValues]
}

export default useStiffeningSnapValues

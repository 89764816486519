import React, { ReactElement, useMemo } from 'react'
import { LineCurve3, ColorRepresentation } from 'three'
import { useTheme } from '@mui/material'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { a, useSpring } from '@react-spring/three'
import { ThreeEvent } from '@react-three/fiber'
import {
  createMeshStandardMaterialMemoized,
  getPointAtPercentage,
  getShapeMatrixTransform,
  getShapeObject,
} from '../../utils'

interface Props {
  start: ImmutableVector3
  end: ImmutableVector3
  elementGuid: string
  color: string | ColorRepresentation
  onClick: (event: ThreeEvent<MouseEvent>) => void
  isTarget?: boolean
  isActive?: boolean
  disableDepthTest?: boolean
  maxScale?: number
}

const LineTransmitterItem = ({
  start,
  end,
  color,
  onClick,
  isTarget = false,
  isActive = false,
  disableDepthTest = false,
  maxScale = 1.05,
}: Props): ReactElement => {
  const {
    sceneConfig: { defaultThickness },
  } = useTheme()

  const height = isTarget ? 0.1 : -0.1

  const points = useMemo(() => {
    const offsettedStart = new ImmutableVector3(start.x, start.y, start.z - height)
    const offsettedEnd = new ImmutableVector3(end.x, end.y, end.z - height)
    return [start, end, offsettedEnd, offsettedStart]
  }, [start, end])

  const curves = useMemo(() => {
    // one connector at start end and one per three meters
    const lineHeight = Math.abs(height)
    const ratio = 1.86
    const length = start.distanceTo(end)
    const count = Math.ceil(length / ratio)

    return [...Array(count).keys()].map(index => {
      const position = getPointAtPercentage(start, end, index / count)
      const curveStart = position.add(new ImmutableVector3(0, 0, lineHeight))
      const curveEnd = position.sub(new ImmutableVector3(0, 0, lineHeight))

      return new LineCurve3(curveStart.v, curveEnd.v)
    })
  }, [start, end])

  const curveMaterial = createMeshStandardMaterialMemoized({
    color: 'black',
    polygonOffset: true,
    polygonOffsetUnits: -1250,
  })

  const matrixTransform = useMemo(() => getShapeMatrixTransform(points), [points])

  const shapeObject = useMemo(
    () => getShapeObject(points, [], matrixTransform),
    [points, matrixTransform],
  )

  const { scale } = useSpring({
    to: async next => {
      // eslint-disable-next-line no-unmodified-loop-condition
      while (isActive) {
        await next({ scale: 1 })
        await next({ scale: maxScale })
      }
      if (!isActive) {
        await next({ scale: 1 })
      }
    },
    from: { scale: 1 },
    reset: true,
    config: { duration: 800 },
  })

  return (
    <>
      {isActive &&
        curves.map((curve, index) => {
          if (index === 0 || index === curves.length + 1) return null

          return (
            <mesh key={index} material={curveMaterial}>
              <tubeGeometry args={[curve, 100, 0.006, 50, false]} />
            </mesh>
          )
        })}

      <a.mesh scale={scale.to(s => [1, 1, s])}>
        <mesh
          matrix={matrixTransform}
          matrixAutoUpdate={false}
          renderOrder={150}
          onClick={event => onClick(event)}
        >
          <extrudeBufferGeometry
            args={[
              shapeObject,
              {
                depth: defaultThickness,
                bevelEnabled: false,

                steps: 2,
              },
            ]}
          />

          <meshStandardMaterial
            polygonOffset
            polygonOffsetUnits={-2000}
            color={color as ColorRepresentation}
            opacity={isActive ? 1 : 0.4}
            transparent={true}
            depthTest={!disableDepthTest}
          />
        </mesh>
      </a.mesh>
    </>
  )
}

export default LineTransmitterItem

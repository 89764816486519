import { postRequest } from '../utils'

export const saveSingleMemberCheckSettings = {
  getKey: (projectId: string, member_guid: string) => [
    'saveMemberCheckSettings',
    projectId,
    member_guid,
  ],
  request: async (projectId: string, data: SettingsOnMember): Promise<SettingsOnMember> => {
    const { data: result } = await postRequest<SettingsOnMember>({
      url: `/projects/${projectId}/member-check-settings/${data.member_guid}`,
      data,
    })

    return result as SettingsOnMember
  },
}

import { RectangleXYZ } from '@modugen/scene/lib/types'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'

export const extrudePosition = (
  start: ImmutableVector3,
  end: ImmutableVector3,
  coordinateSystem: CoordinateSystem,

  memberHeight = 0.1,
  memberWidth = memberHeight,
): RectangleXYZ => {
  const heightDirection = coordinateSystem.z_direction
  const widthDirection = coordinateSystem.y_direction

  const lowerMiddleStartPoint = start.addScaledVector(heightDirection, -memberHeight / 2)

  const p1 = lowerMiddleStartPoint.addScaledVector(widthDirection, -memberWidth / 2)
  const p2 = lowerMiddleStartPoint.addScaledVector(widthDirection, memberWidth / 2)

  const lowerMiddleEndPoint = end.addScaledVector(heightDirection, -memberHeight / 2)

  const p3 = lowerMiddleEndPoint.addScaledVector(widthDirection, memberWidth / 2)
  const p4 = lowerMiddleEndPoint.addScaledVector(widthDirection, -memberWidth / 2)

  // points need to be in counter clockwise orientation to define extrusion
  // direction
  return [p4, p3, p2, p1]
}

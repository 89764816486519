import React, { ReactElement } from 'react'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import { useQueryParam } from 'use-query-params'
import LoadingButton from '@mui/lab/LoadingButton'
import { Container, Link, Stack, Typography } from '@mui/material'
import { Form, UploadField } from '@ui/forms'
import { BackLink } from '@ui/navigation'
import { Box } from '@ui/structure'
import { getAssemblies, getAssemblyExportDocument, getElementCrossSections } from '@queries'
import { downLoadFile } from '@utils'
import {
  createAssembly,
  editAssembly,
  deleteAssembly,
  createElementCrossSection,
  deleteElementCrossSection,
  uploadCrossSectionCSVNoProject,
  deleteAllElementCrossSections,
} from '@mutations'
import { AssemblyList } from 'src/components/manager/assemblies'
import { ConnectionList } from 'src/components/manager/connections'
import { ElementCSList } from 'src/components/manager/element_cs'
import { buildErrorMessage } from 'src/constants/errors'
import { createConnection, deleteConnection, editConnection } from 'src/state/mutations/connections'
import { getConnections } from 'src/state/queries/connections'
import ManagerTabs from './components/ManagerTabs'

interface CSVUpload {
  cross_section_csv?: File
}

const Manager = (): ReactElement => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()
  const [activeTab, setActiveTab] = useQueryParam<
    'connections' | 'assemblies' | 'components',
    'assemblies'
  >('tab')

  // QUERIES AND MUTATIONS FOR ASSEMBLIES

  const { data: assembliesData, isLoading: isLoadingAssemblies } = useQuery(
    getAssemblies.key,
    getAssemblies.request,
    {
      onError: () => {
        enqueueSnackbar('Fehler beim Laden der Aufbauten', { variant: 'error' })
      },
    },
  )

  const createAssemblyMutationResult = useMutation(createAssembly.request, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(getAssemblies.key)
      enqueueSnackbar('Aufbau erfolgreich angelegt', { variant: 'success' })
    },
    onError: (error: AxiosError) => {
      enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern des Aufbaus'), {
        variant: 'error',
      })
    },
  })

  const editAssemblyMutationResult = useMutation(editAssembly.request, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(getAssemblies.key)
      enqueueSnackbar('Aufbau erfolgreich gespeichert', { variant: 'success' })
    },
    onError: (error: AxiosError) => {
      enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern des Aufbaus'), {
        variant: 'error',
      })
    },
  })

  const { mutateAsync: handleDeleteAssembly } = useMutation(deleteAssembly.request, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(getAssemblies.key)
      enqueueSnackbar('Aufbau erfolgreich gespeichert', { variant: 'success' })
    },
    onError: (error: AxiosError) => {
      enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern des Aufbaus'), {
        variant: 'error',
      })
    },
  })

  const { mutateAsync: downloadExportDocument } = useMutation(
    async (assemblyId: string) => {
      const data = await getAssemblyExportDocument.request(assemblyId)
      return {
        data,
        assemblyId,
      }
    },
    {
      onSuccess: async ({ data, assemblyId }) => {
        if (!data) {
          enqueueSnackbar('Ergebnisse noch nicht vorhanden', { variant: 'warning' })
          return
        }

        downLoadFile(
          data,
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          `export_aufbau_${assemblyId}`,
        )
      },
      onError: (e: Error) => {
        enqueueSnackbar(e?.message || 'Fehler beim Abrufen des Dokuments', { variant: 'error' })
      },
    },
  )

  // QUERIES AND MUTATIONS FOR CONNECTIONS

  const { data: connectionsData, isLoading: isLoadingConnections } = useQuery(
    getConnections.key,
    getConnections.request,
    {
      onError: () => {
        enqueueSnackbar('Fehler beim Laden der Zugverbindungen', { variant: 'error' })
      },
    },
  )

  const createConnectionsMutationResult = useMutation(createConnection.request, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(getConnections.key)
      enqueueSnackbar('Zugverbindung erfolgreich angelegt', { variant: 'success' })
    },
    onError: (error: AxiosError) => {
      enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern der Zugverbindung'), {
        variant: 'error',
      })
    },
  })

  const editConnectionMutationResult = useMutation(editConnection.request, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(getConnections.key)
      enqueueSnackbar('Zugverbindung erfolgreich gespeichert', { variant: 'success' })
    },
    onError: (error: AxiosError) => {
      enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern der Zugverbindung'), {
        variant: 'error',
      })
    },
  })

  const { mutateAsync: handleDeleteConnection } = useMutation(deleteConnection.request, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(getConnections.key)
      enqueueSnackbar('Zugverbindung erfolgreich gespeichert', { variant: 'success' })
    },
    onError: (error: AxiosError) => {
      enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern der Zugverbindung'), {
        variant: 'error',
      })
    },
  })

  // QUERIES AND MUTATIONS FOR ELEMENT CROSS SECTIONS

  const { data: elementCrossSectionsData, isLoading: isLoadingElementCrossSections } = useQuery(
    getElementCrossSections.key,
    getElementCrossSections.request,
    {
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Laden der Element-Querschnitte'), {
          variant: 'error',
        })
      },
    },
  )

  const createElementCrossSectionMutationResult = useMutation(createElementCrossSection.request, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(getElementCrossSections.key)
      enqueueSnackbar('Element-Querschnitt erfolgreich angelegt', { variant: 'success' })
    },
    onError: (error: AxiosError) => {
      enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern des Element-Querschnitts'), {
        variant: 'error',
      })
    },
  })

  const editElementCrossSectionMutationResult = useMutation(
    async (elementCS: ElementCS) => {
      await deleteElementCrossSection.request(elementCS.guid)
      return await createElementCrossSection.request(elementCS)
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getElementCrossSections.key)
        enqueueSnackbar('Element-Querschnitt erfolgreich geändert', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(
          buildErrorMessage(error, 'Fehler beim Speichern des Element-Querschnitts'),
          { variant: 'error' },
        )
      },
    },
  )

  const { mutateAsync: handleDeleteElementCS } = useMutation(deleteElementCrossSection.request, {
    onSuccess: async () => {
      await queryClient.invalidateQueries(getElementCrossSections.key)
      enqueueSnackbar('Element-Querschnitt erfolgreich gespeichert', { variant: 'success' })
    },
    onError: (error: AxiosError) => {
      enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern des Element-Querschnitts'), {
        variant: 'error',
      })
    },
  })

  const { isLoading: isUploadingCSV, mutate: uploadCSVMutation } = useMutation(
    (data: File) => {
      return uploadCrossSectionCSVNoProject.request(data)
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getElementCrossSections.key)
        enqueueSnackbar('CSV erfolgreich hochgeladen', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim hochladen der Datei'), {
          variant: 'error',
        })
      },
    },
  )

  const { isLoading: isRemovingAllCS, mutate: removeAllCS } = useMutation(
    () => {
      return deleteAllElementCrossSections.request()
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getElementCrossSections.key)
        enqueueSnackbar('Element-Querschnitt erfolgreich gespeichert', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(
          buildErrorMessage(error, 'Fehler beim Speichern des Element-Querschnitts'),
          {
            variant: 'error',
          },
        )
      },
    },
  )

  const uploadCSVOnSubmit = (data: CSVUpload) => {
    if (data.cross_section_csv !== undefined) {
      uploadCSVMutation(data.cross_section_csv)
    }
  }

  const isMutating = isRemovingAllCS || isUploadingCSV

  return (
    <Container maxWidth="lg">
      <Box paddingX={2}>
        <BackLink />
      </Box>
      <ManagerTabs
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        isLoading={
          isLoadingAssemblies ||
          !assembliesData ||
          isLoadingConnections ||
          !connectionsData ||
          isLoadingElementCrossSections ||
          !elementCrossSectionsData
        }
        assemblyManager={
          <AssemblyList
            assemblies={assembliesData}
            onDelete={({ guid }) => handleDeleteAssembly(guid)}
            onDownload={async ({ guid }) => {
              await downloadExportDocument(guid)
            }}
            createMutation={createAssemblyMutationResult}
            editMutation={editAssemblyMutationResult}
          />
        }
        connectionManager={
          <ConnectionList
            connections={connectionsData}
            onDelete={({ guid }) => handleDeleteConnection(guid)}
            createMutation={createConnectionsMutationResult}
            editMutation={editConnectionMutationResult}
          />
        }
        componentManager={
          <>
            <Form id="system-manager-element-cs-csv-upload" onSubmit={uploadCSVOnSubmit}>
              <Stack spacing={2} sx={{ marginBottom: 4 }}>
                <UploadField
                  name={'cross_section_csv'}
                  label={'Querschnitte als CSV hochladen'}
                  accept={{ 'text/csv': [] }}
                ></UploadField>
                <Stack spacing={2} direction="row" alignItems="center">
                  <LoadingButton
                    loading={isMutating}
                    type="submit"
                    variant="contained"
                    data-cy="system-manager-element-cs-csv-submit"
                    sx={{
                      width: 'fit-content',
                      alignSelf: 'end',
                    }}
                  >
                    Hochladen
                  </LoadingButton>
                  <Link
                    href="https://docs.google.com/spreadsheets/d/1AaNEG6Ds9hsgHeZhdd-C3zJR4YFsSXdAdhDNtaByYlw/edit?gid=741948773"
                    target="_blank"
                    rel="noopener"
                  >
                    <Typography variant="h4">Beispielhafte CSV Datei</Typography>
                  </Link>
                  <LoadingButton
                    loading={isMutating}
                    onClick={() => removeAllCS()}
                    type="button"
                    variant="contained"
                    color="error"
                  >
                    Alle löschen
                  </LoadingButton>
                </Stack>
              </Stack>
            </Form>
            <ElementCSList
              elementCrossSections={elementCrossSectionsData}
              onDelete={({ guid }) => handleDeleteElementCS(guid)}
              createMutation={createElementCrossSectionMutationResult}
              editMutation={editElementCrossSectionMutationResult}
              editCSDialogText="Die Änderung an dem Element-Querschnitt wird nicht in bestehende Projekte übernommen. Die Element-Querschnitte müssen <b>jeweils</b> in den entsprechenden Projekten im Bauteil-Manager geändert werden."
            />
          </>
        }
      />
    </Container>
  )
}

export default Manager

import { array, number, object } from 'yup'

export const vector2D = object({
  x: number().required().default(0),
  y: number().required().default(0),
})

const storey_mass = object({
  mass: number().required().moreThan(0).default(0),
  location: vector2D,
})

const storey_stiffness = object({
  stiffness: vector2D,
  location: vector2D,
})

const storeyInputs = object({
  mass_point: storey_mass,
  stiffness_point: storey_stiffness,
  height: number().required().moreThan(0).default(1),
})

// We use the .data in the schema here because the array as root
// screws our validation somehow.
export const inputsPerStoreySchema = object({
  data: array(storeyInputs).default([]),
})

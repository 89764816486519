import React, { ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'
import { Grid, InputAdornment, Divider, MenuItem } from '@mui/material'
import { TextField, Select, UnitConvertedField } from '@ui/forms'
import {
  windZoneOptions,
  terrainCategoryOptions,
  windZoneToPressureProposal,
  windZoneToSpeedProposal,
} from './constants'

const BaseParameterFields = ({
  onDimensionBlur,
  onDimensionFocus,
}: BaseParameterFormActions): ReactElement => {
  const { setValue } = useFormContext()

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          label="Breite - windparallel"
          name="dimensions.width"
          type="number"
          InputProps={{
            onFocus: () => onDimensionFocus('length-y'),
            onBlur: onDimensionBlur,
            endAdornment: <InputAdornment position="end">m</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Länge"
          name="dimensions.depth"
          type="number"
          InputProps={{
            onFocus: () => onDimensionFocus('length-x'),
            onBlur: onDimensionBlur,
            endAdornment: <InputAdornment position="end">m</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Höhe"
          name="dimensions.height"
          type="number"
          InputProps={{
            onFocus: () => onDimensionFocus('length-z'),
            onBlur: onDimensionBlur,
            endAdornment: <InputAdornment position="end">m</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={6}>
        <TextField
          select
          label="Windzone"
          name={`baseParameters.wind_zone`}
          onChange={event => {
            const windZone = event.target.value as unknown as WindZone
            const pressureProposalForZone = windZoneToPressureProposal[windZone]
            const speedProposalForZone = windZoneToSpeedProposal[windZone]

            setValue('baseParameters.wind_zone', windZone)
            setValue('baseParameters.wind_speed', speedProposalForZone)
            setValue('baseParameters.wind_pressure', pressureProposalForZone)
          }}
          size="small"
          data-cy="select-wind-zone"
        >
          {Object.entries(windZoneOptions).map(([key, { value, label }]) => (
            <MenuItem value={value} key={key}>
              {label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <Select
          label="Geländekategorie"
          name="baseParameters.terrain_category"
          options={terrainCategoryOptions}
          data-cy="select-terrain-category"
        />
      </Grid>
      <Grid item xs={6}>
        <UnitConvertedField
          label={
            <span>
              Basisgeschw.druck q<sub>b</sub>
            </span>
          }
          name="baseParameters.wind_pressure"
          type="number"
          unitLabel="kN/m²"
          size="small"
          data-cy="input-wind-pressure"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label={
            <span>
              Windgeschwindigkeit v<sub>b</sub>
            </span>
          }
          name="baseParameters.wind_speed"
          data-cy="input-wind-speed"
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="end">m/s</InputAdornment>,
          }}
        />
      </Grid>
    </Grid>
  )
}

export default BaseParameterFields

import React, { ReactElement } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { Checkbox as MuiCheckbox } from '@mui/material'
import FormControl from '../FormControl'

interface Props {
  name: string
  disabled?: boolean
  'data-cy'?: string
}

const Checkbox = ({ name, disabled, 'data-cy': dataCy }: Props): ReactElement => {
  const { control } = useFormContext()
  const {
    field: { onChange, name: fieldName, value },
    fieldState: { error },
  } = useController({ name, control })

  return (
    <FormControl error={error?.message} fullWidth={false}>
      <MuiCheckbox
        name={fieldName}
        checked={value}
        onChange={e => onChange(e.target.checked)}
        data-cy={dataCy || `cy.${fieldName}`}
        disabled={disabled}
      />
    </FormControl>
  )
}

export default Checkbox

import { useMemo } from 'react'
import { reduce } from 'lodash-es'
import { useTheme } from '@mui/material'
import { useResultsStore } from '@editorStores'
import { useColorMapping } from '@editorHooks'

const useColorBundles = (enabled = true) => {
  const theme = useTheme()

  const wallRipsPositionGrouping = useResultsStore(state => state.wallRipsPositionGrouping)
  const wallLintelsPositionGrouping = useResultsStore(state => state.wallLintelsPositionGrouping)
  const beamsPositionGrouping = useResultsStore(state => state.beamsPositionGrouping)
  const columnsPositionGrouping = useResultsStore(state => state.columnsPositionGrouping)
  const purlinsPositionGrouping = useResultsStore(state => state.purlinsPositionGrouping)
  const slabBeamsPositionGrouping = useResultsStore(state => state.slabBeamsPositionGrouping)
  const roofSlabBeamsPositionGrouping = useResultsStore(
    state => state.roofSlabBeamsPositionGrouping,
  )

  const groupingToMapping = (bundles: MemberPositionBundle[]) =>
    reduce(
      bundles.map(bundle => ({
        [bundle.representative_position]: bundle.exported
          ? (theme.scenePalette.representatives.representative as string)
          : (undefined as unknown as string),
        ...reduce(
          bundle.representative_for,
          (collector, guid) => ({
            ...collector,
            [guid]: (bundle.exported
              ? theme.scenePalette.representatives.representativeFor
              : undefined) as unknown as string,
          }),
          {} as Record<string, string>,
        ),
      })),
      (collector, bundle) => ({ ...collector, ...bundle }),
      {} as Record<string, string>,
    )

  const colorMapping = useMemo(() => {
    const wallRipsMapping = wallRipsPositionGrouping
      ? groupingToMapping(wallRipsPositionGrouping)
      : {}
    const wallLintelsMapping = wallLintelsPositionGrouping
      ? groupingToMapping(wallLintelsPositionGrouping)
      : {}
    const beamsMapping = beamsPositionGrouping ? groupingToMapping(beamsPositionGrouping) : {}
    const columnsMapping = columnsPositionGrouping ? groupingToMapping(columnsPositionGrouping) : {}
    const purlinsMapping = purlinsPositionGrouping ? groupingToMapping(purlinsPositionGrouping) : {}
    const slabBeamsMapping = slabBeamsPositionGrouping
      ? groupingToMapping(slabBeamsPositionGrouping)
      : {}
    const roofSlabBeamsMapping = roofSlabBeamsPositionGrouping
      ? groupingToMapping(roofSlabBeamsPositionGrouping)
      : {}

    return {
      ...wallRipsMapping,
      ...wallLintelsMapping,
      ...beamsMapping,
      ...columnsMapping,
      ...purlinsMapping,
      ...slabBeamsMapping,
      ...roofSlabBeamsMapping,
    }
  }, [
    wallRipsPositionGrouping,
    wallLintelsPositionGrouping,
    beamsPositionGrouping,
    columnsPositionGrouping,
    roofSlabBeamsPositionGrouping,
    slabBeamsPositionGrouping,
    purlinsPositionGrouping,
  ])

  useColorMapping(enabled ? colorMapping : {})
}

export default useColorBundles

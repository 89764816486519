import { getRequest } from '../utils'

export const getHorizontalDistributionSettings: RequestTypeVariable = {
  getKey: (id: string) => ['getHorizontalDistributionSettings', id],
  request: async (projectId): Promise<HoirzontalDistributionSettings> => {
    const { data } = await getRequest<HoirzontalDistributionSettings>({
      url: `/projects/${projectId}/horizontal-distribution-settings`,
    })

    return data as HoirzontalDistributionSettings
  },
}

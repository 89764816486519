import React, { ReactElement } from 'react'
import { find, maxBy } from 'lodash-es'
import { InputAdornment } from '@mui/material'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { RelativePositionField } from '@ui/forms'
import { useModelStore } from '@editorStores'
import { useStoreSubscription } from '@editorHooks'

interface Props {
  guid: string
  length: number
}

const FormFields = ({ guid, length }: Props): ReactElement => {
  const updateRip = useModelStore(state => state.updateRip)

  useStoreSubscription({
    writeCallback: ({ relative_position, wall_guid }) => {
      const walls = useModelStore.getState().model.walls
      const wall = find(walls, { guid: wall_guid }) as ShapeObject
      const length = wall.shape.points[0].distanceTo(wall.shape.points[1])
      const wallDirection = wall.shape.points[0].directionTo(wall.shape.points[1])
      const highestZ = maxBy(wall.shape.points, v => v.z)?.z

      const start = wall.shape.points[0].addScaledVector(wallDirection, length * relative_position)
      const end = new ImmutableVector3(start.x, start.y, highestZ)

      updateRip({
        position_guid: guid,
        start,
        end,
      } as Rip)
    },
  })

  return (
    <RelativePositionField
      label="Absolute Position"
      length={length}
      size="small"
      sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
      name="relative_position"
      InputProps={{
        endAdornment: <InputAdornment position="end">m</InputAdornment>,
      }}
      data-cy="absolute-position-input"
    />
  )
}

export default FormFields

import { useMemo } from 'react'
import { mapValueKey } from '@editorUtils'
import { useModelStore } from '@editorStores'
import { useGuidToElement } from '@editorHooks'

const useGuidToElementIncludingRips = () => {
  const rips = useModelStore(state => state.model.rips)
  const lintels = useModelStore(state => state.model.lintels)

  const guidToElement = useGuidToElement()

  return useMemo(
    () => ({
      ...guidToElement,
      ...mapValueKey(rips, 'position_guid'),
      ...mapValueKey(lintels, 'position_guid'),
    }),
    [guidToElement, rips, lintels],
  )
}

export default useGuidToElementIncludingRips

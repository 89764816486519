import { countBy, find } from 'lodash-es'
import { AssemblyTypeEnum, LayerTypeEnum } from 'src/constants/assemblies'

const getTimberFrameLayer = (assembly: Assembly) => {
  const timberFrameLayer = find(assembly.layers, ['kind', LayerTypeEnum.TimberFrameLayer])
  return timberFrameLayer as TimberFrameLayer
}
export const getSlabBeamCrossSectionFromAssembly = (assembly: Assembly) => {
  const timberFrameLayer = getTimberFrameLayer(assembly)
  const slabBeamCrossSection = timberFrameLayer.standard_rip_cs
  return slabBeamCrossSection
}
export const getSlabBeamStepSizeFromAssembly = (assembly: Assembly) => {
  const timberFrameLayer = getTimberFrameLayer(assembly)
  const stepSize = timberFrameLayer.rip_step_size
  return stepSize
}

export const checkDuplicateAssemblyNames = (assemblies: Assembly[]) => {
  const shortNameCounts = countBy(assemblies, 'short_name')
  const hasDuplicate = assemblies.reduce((hasDuplicate, assembly) => {
    if (
      (assembly.usage_type === AssemblyTypeEnum.Outer ||
        assembly.usage_type === AssemblyTypeEnum.Inner) &&
      assembly.short_name !== '' &&
      shortNameCounts[assembly.short_name] > 1
    ) {
      hasDuplicate = true
    }
    return hasDuplicate
  }, false)
  // test needs to return false if something is wrong
  return !hasDuplicate
}

export const checkWallAssembliesHaveShortName = (assemblies: Assembly[]) => {
  const hasMissingShortName = assemblies.reduce((hasMissingShortName, assembly) => {
    if ([AssemblyTypeEnum.Outer, AssemblyTypeEnum.Inner].includes(assembly.usage_type)) {
      if (assembly.short_name === '') {
        hasMissingShortName = true
      }
    }
    return hasMissingShortName
  }, false)
  // test needs to return false if something is wrong
  return !hasMissingShortName
}

import { useMemo } from 'react'
import { reduce } from 'lodash-es'
import { useModelStore } from '@editorStores'

const usePositionGuidToWallGuid = (): Record<string, string> => {
  const rips = useModelStore(state => state.model.rips)
  const lintels = useModelStore(state => state.model.lintels)

  const positionGuidToWallGuid = useMemo(
    () =>
      reduce(
        [...rips, ...lintels],
        (collector, item) => ({
          ...collector,
          [item.position_guid]: item.wall_guid,
        }),
        {} as Record<string, string>,
      ),
    [rips, lintels],
  )

  return positionGuidToWallGuid
}

export default usePositionGuidToWallGuid

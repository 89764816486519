import React, { ReactElement } from 'react'
import { Typography, AccordionDetails, Stack, Grid, Alert } from '@mui/material'
import { FormAccordion, TextField, UnitConvertedField } from '@ui/forms'
import { windDirectionToAxis } from '../../constants'
import StoreyHeader from '../StoreyHeader'

interface Props {
  direction: WindDirection
  expanded?: boolean
  onExpand: (expanded: boolean) => void
}

const RoofFormFields = ({ direction, expanded, onExpand }: Props): ReactElement => {
  return (
    <FormAccordion
      fields={`results.wind_loads_roof[${direction}][0]`}
      expanded={expanded}
      onChange={(_, expanded) => onExpand(expanded)}
    >
      <StoreyHeader
        resultFieldName="results.wind_loads_roof"
        direction={direction}
        index={0}
        storey="Dach"
      />
      <AccordionDetails>
        <Stack direction="column" spacing={2}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <UnitConvertedField
                label={
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: 14,
                    }}
                  >
                    Punktlast x
                  </Typography>
                }
                name={`results.wind_loads_roof[${direction}][0].force.x`}
              />
            </Grid>

            <Grid item xs={6}>
              <UnitConvertedField
                label={
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: 14,
                    }}
                  >
                    Punktlast y
                  </Typography>
                }
                name={`results.wind_loads_roof[${direction}][0].force.y`}
              />
            </Grid>
            <Grid item xs={6}>
              <UnitConvertedField
                disabled
                conversionFactor={1}
                unitLabel={'m'}
                label={
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: 14,
                    }}
                  >
                    Angriffspunkt x
                  </Typography>
                }
                name={`results.wind_loads_per_ceiling[${direction}][0].point_of_attack.x`}
              />
            </Grid>
            <Grid item xs={6}>
              <UnitConvertedField
                disabled
                conversionFactor={1}
                unitLabel={'m'}
                label={
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: 14,
                    }}
                  >
                    Angriffspunkt y
                  </Typography>
                }
                name={`results.wind_loads_per_ceiling[${direction}][0].point_of_attack.y`}
              />
            </Grid>
          </Grid>
          <Alert severity="info">
            Windrichung ist in {windDirectionToAxis[direction].axis}{' '}
            {windDirectionToAxis[direction].sign}
          </Alert>
          <TextField
            label="Kommentar"
            placeholder="Kommentar"
            rows={2}
            name={`results.wind_loads_roof[${direction}][0].comment`}
            multiline
          />
        </Stack>
      </AccordionDetails>
    </FormAccordion>
  )
}

export default RoofFormFields

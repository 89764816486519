import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'

function convexHull(points: ImmutableVector3[]): ImmutableVector3[] {
  // Sort the points lexicographically by x and y coordinates
  const sortedPoints = [...points].sort((a, b) => (a.x === b.x ? a.y - b.y : a.x - b.x))

  // 2D cross product of OA and OB vectors, z-component of their 3D cross product
  function cross(o: ImmutableVector3, a: ImmutableVector3, b: ImmutableVector3): number {
    return (a.x - o.x) * (b.y - o.y) - (a.y - o.y) * (b.x - o.x)
  }

  // Build the lower hull
  const lower: ImmutableVector3[] = []
  for (const point of sortedPoints) {
    while (
      lower.length >= 2 &&
      cross(lower[lower.length - 2], lower[lower.length - 1], point) <= 0
    ) {
      lower.pop()
    }
    lower.push(point)
  }

  // Build the upper hull
  const upper: ImmutableVector3[] = []
  for (let i = sortedPoints.length - 1; i >= 0; i--) {
    const point = sortedPoints[i]
    while (
      upper.length >= 2 &&
      cross(upper[upper.length - 2], upper[upper.length - 1], point) <= 0
    ) {
      upper.pop()
    }
    upper.push(point)
  }

  // Concatenate lower and upper hulls, omitting the last point of each half to avoid duplication
  lower.pop()
  upper.pop()
  const convexHullPoints = lower.concat(upper)

  return convexHullPoints
}

export default convexHull

import { postRequest, retryUntilSuccessOrFailure } from 'src/state/utils'

export const saveWindLoadSettings: RequestTypeVariable = {
  getKey: (projectId: string) => ['saveWindLoadSettings', projectId],
  request: async (id: string, data: WindLoadSettings): Promise<void> => {
    await postRequest<WindLoadSettings>({
      url: `/projects/${id}/wind-load/settings`,
      data,
    })
  },
}

export const computeWindloadResults: RequestTypeVariable = {
  getKey: (projectId: string) => ['computeWindloadResults', projectId],
  request: async (id: string): Promise<void> => {
    const { data } = await postRequest<{ task_id: string }>({
      url: `/projects/${id}/wind-load/result/compute`,
    })

    await retryUntilSuccessOrFailure({
      url: `/task-status/${data?.task_id}`,
    })
  },
}

export const saveWindLoadResults: RequestTypeVariable = {
  getKey: (projectId: string) => ['saveWindLoadResults', projectId],
  request: async (id: string, data: WindloadResults): Promise<WindloadResults> => {
    const { data: result } = await postRequest<WindloadResults>({
      url: `/projects/${id}/wind-load/manual-horizontal-loads`,
      data,
    })

    return result as WindloadResults
  },
}

export const materialTypeOptions = [
  {
    value: 'softwoodMaterial',
    label: 'Nadelholz',
  },
  {
    value: 'glulamMaterial',
    label: 'Brettschichtholz',
  },
]

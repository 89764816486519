export const tabConfig = {
  'live-loads': {
    value: 'live-loads',
    label: 'Nutzlasten',
  },
  'wind-loads': {
    value: 'wind-loads',
    label: 'Windlasten',
  },
  'snow-loads': {
    value: 'snow-loads',
    label: 'Schneelasten',
  },
  'seismic-settings': {
    value: 'seismic-settings',
    label: 'Erdbebenlasten',
  },
}

import React, { ReactElement } from 'react'
import { useTheme } from '@mui/material'
import { HighlightableLine } from '@modugen/scene/lib/components/Lines/HighlightableLine'
import { config as sceneConfig } from '@modugen/scene/lib/config'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { Line } from '@react-three/drei'
import { useEnhanceClickEvent } from '../../../../hooks'

const wallWidth = 1.2

interface SimpleWall {
  guid: string
  placement: string | undefined
  points: ImmutableVector3[]
  openings:
    | {
        points: ImmutableVector3[]
      }[]
    | undefined
}

interface Props {
  wall: SimpleWall
  isHighlighted?: boolean
  clickable?: boolean
  onClick?: () => void
  secondary?: boolean
}

const Wall2D = ({ wall, isHighlighted, onClick, clickable, secondary }: Props): ReactElement => {
  const { scenePalette } = useTheme()
  const { onClickEnhanced } = useEnhanceClickEvent(wall.guid, onClick)

  return (
    <>
      <HighlightableLine
        key={wall.guid}
        lineName={wall.guid}
        line={wall.points}
        isHighlighted={!!isHighlighted}
        color={
          (wall.placement === 'Internal'
            ? scenePalette.elements2d.internalWalls
            : scenePalette.elements2d.externalWalls) as string
        }
        cursor="pointer"
        width={wallWidth}
        hoverable={clickable}
        clickable={clickable}
        onClick={onClickEnhanced}
        opacity={secondary ? 0.65 : undefined}
      />

      <group position={[0, 0, 0.1]} layers={sceneConfig.R3FNonSelectableObjectLayer}>
        {wall.openings?.map((opening, i) => (
          <Line
            key={`${wall.guid}-opening-${i}`}
            points={opening.points.map(p => p.v)}
            color={scenePalette.elements2d.openings}
          />
        ))}
      </group>
    </>
  )
}

export default Wall2D

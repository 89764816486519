import React, { ReactElement, useMemo } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import { enqueueSnackbar } from 'notistack'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { LoadingButton } from '@mui/lab'
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material'
import { Form, UnitConvertedField } from '@ui/forms'
import { useResultsInvalidation } from '@editorHooks'
import { getSimplifiedSeismicResults } from '@queries'
import { postSimplifiedSeismicResults } from '@mutations'
import { DownloadDocumentItem } from 'src/components/pages/Editor/pages/Export/components'
import { buildErrorMessage } from 'src/constants/errors'
import useStoreysForSeismic from '../../hooks/useStoreysForSeismic'
import { simplifiedSeismicResultsSchema } from './schema'

const SimplifiedSeismicResults = (): ReactElement => {
  const { projectId }: { projectId?: string } = useParams()

  const invalidateResultsQueries = useResultsInvalidation()
  const queryClient = useQueryClient()

  const { data: simplifiedSeismicResults } = useQuery({
    queryKey: getSimplifiedSeismicResults.getKey(projectId),
    queryFn: () => getSimplifiedSeismicResults.request(projectId),
    onError: (error: AxiosError) => {
      enqueueSnackbar(buildErrorMessage(error, 'Fehler beim laden der Ersatzkräfte'), {
        variant: 'error',
      })
    },
    refetchOnMount: true,
  })

  const { isLoading: isSaving, mutate } = useMutation(
    (data: SimplifiedSeismicResults) => postSimplifiedSeismicResults.request(projectId, data),
    {
      onSuccess: () => {
        invalidateResultsQueries(projectId as string)
        queryClient.invalidateQueries(getSimplifiedSeismicResults.getKey(projectId))
        enqueueSnackbar('Ersatzkräfte erfolgreich überschrieben', {
          variant: 'success',
        })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim überschreiben der Ersatzkräfte'), {
          variant: 'error',
        })
      },
    },
  )

  const storeys = useStoreysForSeismic()

  const defaultValues = useMemo(() => {
    const schemaDefault = simplifiedSeismicResultsSchema.getDefault()
    if (!simplifiedSeismicResults) {
      return schemaDefault
    }
    return { data: simplifiedSeismicResults }
  }, [simplifiedSeismicResults])

  if (!simplifiedSeismicResults) {
    return <Typography>Keine Ergebnisse vorhanden</Typography>
  }

  return (
    <Form
      validationSchema={simplifiedSeismicResultsSchema}
      defaultValues={defaultValues}
      onSubmit={data => {
        // Use .data because the array as root schema doesn't work with yup.
        // eslint-disable-next-line no-console
        console.log(data)
        mutate(data.data)
      }}
      enableReinitialize
    >
      <Grid container spacing={0} paddingTop={1} paddingBottom={1}>
        <Grid item paddingBottom={1} xs={12}>
          <Typography variant="h6" textAlign="center">
            Ersatzkräfte
          </Typography>
        </Grid>
        {storeys.map(storey => (
          <Grid item xs={12} key={`accordion-${storey}`} spacing={0}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>{storey === 0 ? 'EG' : `${storey}. Stock`}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <UnitConvertedField
                      label={
                        <span>
                          F<sub>x</sub>
                        </span>
                      }
                      name={`data.${storey}.forces.x`}
                      unitLabel="kN"
                      conversionFactor={1000}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <UnitConvertedField
                      label={
                        <span>
                          e<sub>min,y</sub>
                        </span>
                      }
                      name={`data.${storey}.e_min.y`}
                      unitLabel="m"
                      conversionFactor={1}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <UnitConvertedField
                      label={
                        <span>
                          e<sub>max,y</sub>
                        </span>
                      }
                      name={`data.${storey}.e_max.y`}
                      unitLabel="m"
                      conversionFactor={1}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <UnitConvertedField
                      label={
                        <span>
                          F<sub>y</sub>
                        </span>
                      }
                      name={`data.${storey}.forces.y`}
                      unitLabel="kN"
                      conversionFactor={1000}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <UnitConvertedField
                      label={
                        <span>
                          e<sub>min,x</sub>
                        </span>
                      }
                      name={`data.${storey}.e_min.x`}
                      unitLabel="m"
                      conversionFactor={1}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <UnitConvertedField
                      label={
                        <span>
                          e<sub>max,x</sub>
                        </span>
                      }
                      name={`data.${storey}.e_max.x`}
                      unitLabel="m"
                      conversionFactor={1}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <LoadingButton
            loading={isSaving}
            variant="contained"
            type="submit"
            data-cy="save-simplified-seismic-results-btn"
          >
            Ersatzkräfte überschreiben
          </LoadingButton>
        </Grid>
        <DownloadDocumentItem
          title="Export Erdbebenlasten"
          path="simplified-seismic-results/document"
          params={{}}
          type="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          filename="Erdbebenlasten"
          extension="docx"
        />
      </Grid>
    </Form>
  )
}

export default SimplifiedSeismicResults

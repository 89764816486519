import React, { ReactElement, ReactNode, useState } from 'react'
import { SelectedElementProblemsList, VerticalTransmitterList } from '@structuralPlanningComponents'
import { TabContext, TabPanel, TabList } from '@mui/lab'
import { Tab } from '@mui/material'
import { Box } from '@ui/structure'
import { useStructuralPlanningQueryParams } from '@structuralPlanningHooks'

interface Props {
  selectedElement: string
  children?: ReactNode
  tab?: ReactNode
  initialTabValue?: string
}

const RoofFormBase = ({ children, tab, initialTabValue, selectedElement }: Props): ReactElement => {
  const [activeTab, setActiveTab] = useState(initialTabValue || 'vertical-transmission')
  const {
    modes: { isDrawMode },
    params: { verticalTransmitter },
    actions: { setVerticalTransmitter },
  } = useStructuralPlanningQueryParams()

  return (
    <Box>
      <TabContext value={activeTab}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            '& .MuiTab-root': {
              fontSize: 12,
              paddingX: ({ spacing }) => spacing(1.5),
            },
          }}
        >
          <TabList onChange={(_, value) => setActiveTab(value)} scrollButtons="auto">
            {tab}
            <Tab value="vertical-transmission" label="Vertikale Verbindungen" />
          </TabList>
        </Box>
        {children}
        <TabPanel value="vertical-transmission">
          <VerticalTransmitterList
            selectedElement={selectedElement}
            verticalTransmitter={verticalTransmitter}
            setVerticalTransmitter={(guid, elementGuid) =>
              setVerticalTransmitter(guid, elementGuid, isDrawMode)
            }
          />
          <SelectedElementProblemsList selectedElement={selectedElement} />
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export default RoofFormBase

import React, { ReactNode, ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppState, Auth0Provider } from '@auth0/auth0-react'
import { useConfigStore } from 'src/state/stores'

const AuthenticationProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const navigate = useNavigate()
  const { auth0Domain, auth0ClientID, auth0Scopes } = useConfigStore(
    ({ auth0Domain, auth0ClientID, auth0Scopes }) => ({
      auth0Domain,
      auth0ClientID,
      auth0Scopes,
    }),
  )

  const onRedirectCallback = (appState: AppState | undefined) => {
    navigate(appState?.returnTo || window.location.pathname)
  }

  return (
    <Auth0Provider
      domain={auth0Domain || ''}
      clientId={auth0ClientID || ''}
      onRedirectCallback={onRedirectCallback}
      cacheLocation={
        import.meta.env.VITE_ENVIRONMENT === 'pipeline_test' ? 'localstorage' : 'memory'
      }
      authorizationParams={{
        redirect_uri: location.origin,
        scope: auth0Scopes,
      }}
    >
      {children}
    </Auth0Provider>
  )
}

export default AuthenticationProvider

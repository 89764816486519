export const stiffeningOptions: {
  label: string
  value: StiffeningElementStiffening
}[] = [
  {
    label: 'Aussteifend',
    value: 'Stiffening',
  },
  {
    label: 'Nicht aussteifend',
    value: 'NotStiffening',
  },
]

import { useEffect, useCallback, useRef } from 'react'
import { isFunction, find } from 'lodash-es'
import { v4 as uuid } from 'uuid'
import { ThreeEvent } from '@react-three/fiber'
import { useControlStore } from '@editorStores'

const useModelClickListeners = (
  callback?: ModelClickListener,
  dependencies: unknown[] = [],
  condition = true,
  isExclusive = false,
): ModelClickListener => {
  const id = useRef<string>(uuid())
  const clickListeners = useControlStore(state => state.clickListeners)
  const setClickListener = useControlStore(state => state.setClickListener)
  const removeClickListener = useControlStore(state => state.removeClickListener)

  const executeListeners = useCallback(
    (event: ThreeEvent<MouseEvent>) => {
      const listeners = Array.from(clickListeners.values())
      const exclusiveListener = find(listeners, ['isExclusive', true])

      if (exclusiveListener) {
        exclusiveListener.listener(event)
        return
      }

      listeners.forEach(({ listener }) => listener(event))
    },
    [clickListeners],
  )

  useEffect(() => {
    if (!condition || !isFunction(callback)) return
    setClickListener(id.current, callback, isExclusive)

    return () => removeClickListener(id.current)
  }, [condition, ...dependencies])

  return executeListeners
}

export default useModelClickListeners

import React, { ReactNode, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useQuery } from 'react-query'
import { Route, Routes, Outlet, useParams } from 'react-router-dom'
import { usePostHog } from 'posthog-js/react'
import { useAuth0 } from '@auth0/auth0-react'
import { Layout } from '@ui/structure'
import { ProtectedRoute } from 'src/components/auth'
import { Login, Home, Editor, Manager } from 'src/components/pages'
import {
  ProjectSystemManager,
  AssemblySelection,
  GlobalLoads,
  StructuralPlanning,
  Results,
  Freigabe,
} from 'src/components/pages/Editor/pages'
import Export from 'src/components/pages/Editor/pages/Export'
import ReducedEditor from './components/pages/Editor/pages/Freigabe/reducedEditor'
import { useAuthToken } from './hooks'
import { getProject } from './state/queries/project'

interface WrapperProps {
  pageName: string
  children: ReactNode
}

const AddPageTitleFromProjectWrapper: React.FC<WrapperProps> = ({
  pageName,
  children,
}: WrapperProps) => {
  const { projectId } = useParams()
  const { data: project } = useQuery({
    queryKey: getProject.getKey(projectId),
    queryFn: () => getProject.request(projectId),
  })
  const pageTitle = `Modugen Statik - ${project?.name} - ${pageName}`
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className="AddPageTitleFromProjectWrapper">{children}</div>
    </>
  )
}

const AuthNotRequiredRoutes = () => {
  return (
    <Routes>
      <Route
        path="/projects/:projectId"
        element={
          <>
            <Layout>
              <>
                <ReducedEditor />
              </>
            </Layout>
          </>
        }
      >
        <Route path="/projects/:projectId" element={<Freigabe />} />
      </Route>
    </Routes>
  )
}

const AuthRequiredRoutes = () => {
  useAuthToken()

  return (
    <Routes>
      <Route path="/authorize" element={<Login />} />
      <Route
        element={
          <ProtectedRoute
            element={
              <Layout>
                <Outlet />
              </Layout>
            }
          />
        }
      >
        <Route index element={<ProtectedRoute element={<Home />} />} />
        <Route path="/manager" element={<ProtectedRoute element={<Manager />} />} />
        <Route path="/editor/:projectId" element={<ProtectedRoute element={<Editor />} />}>
          <Route index element={<ProjectSystemManager />} />
          <Route
            path="/editor/:projectId/assembly-assignment"
            element={
              <AddPageTitleFromProjectWrapper pageName="System Manager">
                <ProjectSystemManager />
              </AddPageTitleFromProjectWrapper>
            }
          />
          <Route
            path="/editor/:projectId/assembly-selection"
            element={
              <AddPageTitleFromProjectWrapper pageName="Aufbau Zuweisung">
                <AssemblySelection />
              </AddPageTitleFromProjectWrapper>
            }
          />
          <Route
            path="/editor/:projectId/global-loads"
            element={
              <AddPageTitleFromProjectWrapper pageName="Einwirkungen">
                <GlobalLoads />
              </AddPageTitleFromProjectWrapper>
            }
          />
          <Route
            path="/editor/:projectId/structural-planning"
            element={
              <AddPageTitleFromProjectWrapper pageName="Tragwerksplanung">
                <StructuralPlanning />
              </AddPageTitleFromProjectWrapper>
            }
          />
          <Route
            path="/editor/:projectId/results"
            element={
              <AddPageTitleFromProjectWrapper pageName="Ergebnisse">
                <Results />
              </AddPageTitleFromProjectWrapper>
            }
          />
          <Route
            path="/editor/:projectId/export"
            element={
              <AddPageTitleFromProjectWrapper pageName="Export">
                <Export />
              </AddPageTitleFromProjectWrapper>
            }
          />
        </Route>
      </Route>
    </Routes>
  )
}

const App = () => {
  const { isAuthenticated, isLoading, user } = useAuth0()
  const posthog = usePostHog()

  useEffect(() => {
    // link all posthog events to a user once logged in
    if (!isLoading && isAuthenticated && user) {
      posthog.identify(user.email, user)
    }
  }, [isAuthenticated, isLoading, user])

  return (
    <>
      <AuthRequiredRoutes />
      <AuthNotRequiredRoutes />
    </>
  )
}

export default App

import React, { ReactElement } from 'react'
import { Checkbox, ListItem, ListItemText, MenuItem, Select } from '@mui/material'
import { Stack } from '@mui/system'
import DownLoadButton from '@ui/actions/DownLoadIconButton'

interface Props {
  title: string
  isLoading: boolean

  options: { label: string; value: string }[]
  selectedOptions: Array<string>
  handleChange: () => void
  onClick: () => void
}

const ExportMultiSelectItem = ({
  title,
  isLoading,
  options,
  selectedOptions,
  handleChange,
  onClick,
}: Props): ReactElement => {
  return (
    <ListItem>
      <ListItemText primary={title} />

      <Stack direction="row" spacing={1.5} ml={2}>
        <Select
          value={selectedOptions}
          multiple
          onChange={handleChange}
          size="small"
          sx={{ height: 34.57 }}
          renderValue={selected => {
            const selectedOptionsLabelValue = options.filter(({ value }) =>
              selected.includes(value),
            )
            const selectedLabels = selectedOptionsLabelValue.map(({ label }) => label)
            return selectedLabels.sort().join(', ')
          }}
        >
          {options.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              <Checkbox checked={selectedOptions.indexOf(value) > -1} />
              <ListItemText primary={label} />
            </MenuItem>
          ))}
        </Select>
        <DownLoadButton variant="outlined" loading={isLoading} onClick={onClick} size="large" />
      </Stack>
    </ListItem>
  )
}

export default ExportMultiSelectItem

import { useQueryClient } from 'react-query'
import {
  getDesignPositionBundle,
  getElementChecksBundle,
  getLoadTracingMap,
  getLoadsPerElement,
  getMemberCheckSettings,
  getResultBundle,
  getAnchorChecks,
  getDesignForces,
} from '@queries'

const useResultsInvalidation = () => {
  const queryClient = useQueryClient()

  return async (projectId: string) => {
    await Promise.all([
      queryClient.invalidateQueries(getDesignPositionBundle.getKey(projectId)),
      queryClient.invalidateQueries(getResultBundle.getKey(projectId)),
      queryClient.invalidateQueries(getLoadsPerElement.getKey(projectId)),
      queryClient.invalidateQueries(getLoadTracingMap.getKey(projectId)),
      queryClient.invalidateQueries(getMemberCheckSettings.getKey(projectId)),
      queryClient.invalidateQueries(getElementChecksBundle.getKey(projectId)),
      queryClient.invalidateQueries(getAnchorChecks.getKey(projectId)),
      queryClient.invalidateQueries(getDesignForces.getKey(projectId)),
    ])
  }
}

export default useResultsInvalidation

import * as d3 from 'd3'
import { STROKE_WIDTH_FACTOR } from '../constants'

export const plotSingleTFInsulation = (
  ref: React.MutableRefObject<SVGSVGElement | null>,
  x: number,
  y: number,
  height: number,
  width: number,
  strokeWidth: number,
): void => {
  d3.select(ref.current)
    .append('rect')
    .attr('x', x)
    .attr('y', y)
    .attr('width', width)
    .attr('height', height)
    .attr('fill', '#fffc54')
    .attr('stroke', 'black')
    .attr('stroke-width', strokeWidth)
}

export const plotSingleTF = (
  ref: React.MutableRefObject<SVGSVGElement | null>,
  x: number,
  y: number,
  height: number,
  width: number,
  strokeWidth: number,
): void => {
  const lineWidth = strokeWidth
  d3.select(ref.current)
    .append('rect')
    .attr('x', x)
    .attr('y', y)
    .attr('width', width)
    .attr('height', height)
    .attr('fill', '#f9d978')
    .attr('stroke', 'black')
    .attr('stroke-width', strokeWidth)

  d3.select(ref.current)
    .append('line')
    .style('stroke', 'black')
    .attr('stroke-width', lineWidth)
    .attr('x1', x) // x position of the first end of the line
    .attr('y1', y) // y position of the first end of the line
    .attr('x2', x + width) // x position of the second end of the line
    .attr('y2', y + height)

  d3.select(ref.current)
    .append('line')
    .style('stroke', 'black')
    .attr('stroke-width', lineWidth)
    .attr('x1', x) // x position of the first end of the line
    .attr('y1', y + height) // y position of the first end of the line
    .attr('x2', x + width) // x position of the second end of the line
    .attr('y2', y)
}

export const plotTFAssemblyLayer = (
  ref: React.MutableRefObject<SVGSVGElement | null>,
  x: number,
  y: number,
  length: number,
  thickness: number,
  insulation: TimberFrameInsulationType,
  orientation: LayerOrientation,
): void => {
  const singleTimberFrameLength = length / 12
  const insulationWidth = (length - 3 * singleTimberFrameLength) / 2
  const halfInsulationOffset = thickness / 4
  const strokeWidth = length / STROKE_WIDTH_FACTOR

  if (orientation === 'Horizontal') {
    const height = thickness
    const tfWidth = singleTimberFrameLength

    const x0 = x
    const x1 = x + length / 2 - tfWidth / 2
    const x2 = x + length - tfWidth

    plotSingleTF(ref, x0, y, height, tfWidth, strokeWidth)
    plotSingleTF(ref, x1, y, height, tfWidth, strokeWidth)
    plotSingleTF(ref, x2, y, height, tfWidth, strokeWidth)
    if (insulation === 'Full') {
      plotSingleTFInsulation(ref, x + tfWidth, y, height, insulationWidth, strokeWidth)
      plotSingleTFInsulation(
        ref,
        x + 2 * tfWidth + insulationWidth,
        y,
        height,
        insulationWidth,
        strokeWidth,
      )
    } else if (insulation === 'Half') {
      plotSingleTFInsulation(
        ref,
        x + tfWidth,
        y + halfInsulationOffset,
        height - 2 * halfInsulationOffset,
        insulationWidth,
        strokeWidth,
      )
      plotSingleTFInsulation(
        ref,
        x + 2 * tfWidth + insulationWidth,
        y + halfInsulationOffset,
        height - 2 * halfInsulationOffset,
        insulationWidth,
        strokeWidth,
      )
    }
  } else {
    const height = singleTimberFrameLength
    const tfWidth = thickness
    const y0 = y
    const y1 = y + length / 2 - singleTimberFrameLength / 2
    const y2 = y + length - singleTimberFrameLength
    plotSingleTF(ref, x, y0, height, tfWidth, strokeWidth)
    plotSingleTF(ref, x, y1, height, tfWidth, strokeWidth)
    plotSingleTF(ref, x, y2, height, tfWidth, strokeWidth)
    if (insulation === 'Full') {
      plotSingleTFInsulation(
        ref,
        x,
        y + singleTimberFrameLength,
        insulationWidth,
        thickness,
        strokeWidth,
      )
      plotSingleTFInsulation(
        ref,
        x,
        y + 2 * singleTimberFrameLength + insulationWidth,
        insulationWidth,
        thickness,
        strokeWidth,
      )
    } else if (insulation === 'Half') {
      plotSingleTFInsulation(
        ref,
        x + halfInsulationOffset,
        y + singleTimberFrameLength,
        insulationWidth,
        thickness - 2 * halfInsulationOffset,
        strokeWidth,
      )
      plotSingleTFInsulation(
        ref,
        x + halfInsulationOffset,
        y + 2 * singleTimberFrameLength + insulationWidth,
        insulationWidth,
        thickness - 2 * halfInsulationOffset,
        strokeWidth,
      )
    }
  }
}

import { useMemo } from 'react'
import { filter, find } from 'lodash-es'

const useVerticalTransmitters = (
  graph: VerticalTransmissionGraph | null,
  element: string | null,
) => {
  const verticalTransmitters = useMemo<ElementSupportItemWithTargets[] | null>(() => {
    if (!graph) return null

    const { support_targets, element_supports, element_targets } = graph
    const supports = filter(element_supports || [], ['element_guid', element])

    return supports.map(support => ({
      ...support,
      targets: filter(support_targets, ['support_guid', support.guid]).map(
        ({ target_guid }) => find(element_targets, ['guid', target_guid]) as ElementSupportItem,
      ),
    }))
  }, [element, graph])

  return verticalTransmitters
}

export default useVerticalTransmitters

import numeral from 'numeral'

numeral.register('locale', 'de', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal: function (number) {
    return number === 1 ? 'er' : 'ème'
  },
  currency: {
    symbol: '€',
  },
})
numeral.locale('de')

export const newtonToKilonewton = (input: number): number => {
  const conversion = Number(input / 1000)

  return conversion
}

export const millimeterToMeter = (input: number, round = true): number => {
  const conversion = Number(input / 1000)

  if (round) return Number(conversion.toFixed(2))

  return conversion
}

export const meterToMillimeter = (input: number, round = true): number => {
  const conversion = Number(input * 1000)

  if (round) return Number(conversion.toFixed(2))

  return conversion
}

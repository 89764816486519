import axios, { AxiosError } from 'axios'

const errors: Record<string, string> = {
  'MD-CORE-0001':
    'Deckenelement muss mindestens zwei Auflager (z.B. Wände oder Unterzüge) orthogonal zur Spannrichtung haben.',
  'MD-CORE-0002':
    'Unterzug muss mindestens zwei orthongonale Auflager (z.B. Wände oder Unterzüge) haben.',
  'MD-CORE-0004':
    'Nicht genug Pfetten vorhanden, auf denen das eingezeichnete Dach aufliegen kann. Es werden mindestens 2 benötigt.',
  'MD-CORE-0005': 'Es konnten nicht genug Auflager gefunden werden.',
  'MD-CORE-0006': 'Ausgewähltes Element nicht kompatibel als Auflager',
  'MD-CORE-0007':
    'Das gefundene Auflager konfligiert mit bestehenden Lagern. Gegebenenfalls muss zuerst ein Auflager entfernt werden, bevor ein neues hinzugefügt werden kann',
  'MD-CORE-0008': 'Es wurde keine Verbindung zum Zielelement gefunden',
  'MD-CORE-0016':
    'Fehler beim Löschen der Wandrippe. Es können nur selbst hinzugefügte Rippen gelöscht werden.',
  retriesExhausted: 'Maximale Anzahl der Versuche überschritten.',
}

const buildErrorMessage = (error: Error | AxiosError, alternativeMessage: string) => {
  if (axios.isAxiosError(error)) {
    if (error.response?.data.code) {
      // if a code is provided, a detail is always provided as well
      const { code, detail } = error.response?.data
      if (errors[code]) {
        return `${errors[code]} - Detail: ${detail} - Code: ${code}`
      } else {
        return `${alternativeMessage} - Detail: ${detail} - Code: ${code}`
      }
    } else if (error.response?.data.detail) {
      // sometimes, only a detail is provided
      return `${alternativeMessage} - Detail: ${error.response?.data.detail}`
    } else {
      // fallback message
      return `${alternativeMessage} - No detail available. This should not happen, so please notify the dev team. Also check response in dev tools for help.`
    }
  } else {
    return `${alternativeMessage} - Message: ${error.message}`
  }
}

export { errors, buildErrorMessage }

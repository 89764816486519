import React, { Fragment, ReactElement } from 'react'
import { ThreeEvent } from '@react-three/fiber'
import PositionMemberMeshElement from '../PositionMemberMeshElement'
import { BeamStepSizeAreaVisual } from '../SlabMemberMeasure'

interface Props2 {
  data: VerticalSlab[]
  selectedElement?: string
  highlightedElements?: string[]

  onClick?: (slabForVertical: VerticalSlab, event: ThreeEvent<MouseEvent>) => void
  hiddenIds?: string[]
}

const SlabBeamsWithStepSize = ({
  data,
  selectedElement,
  onClick,
  highlightedElements,
  hiddenIds = [],
}: Props2): ReactElement | null => {
  return (
    <>
      {data.map(slabForVertical => {
        return (
          <group
            key={slabForVertical.beam.guid}
            visible={!hiddenIds.includes(slabForVertical.beam.guid)}
          >
            <PositionMemberMeshElement
              key={slabForVertical.beam.guid}
              data={
                {
                  member: {
                    start: slabForVertical.beam.shape.start,
                    end: slabForVertical.beam.shape.end,
                    guid: slabForVertical.beam.guid,
                    coordinate_system: slabForVertical.beam.coordinate_system,
                  },
                  guid: slabForVertical.beam.guid,
                  position_type: 'HorizontalRip',
                } as PositionMember
              }
              onClick={e => onClick?.(slabForVertical, e)}
              isSelected={selectedElement === slabForVertical.beam.guid}
              isHighlighted={highlightedElements?.includes(slabForVertical.beam.guid)}
            />
            <BeamStepSizeAreaVisual
              slabBeam={slabForVertical.beam}
              stepSize={slabForVertical.step_size}
            />
          </group>
        )
      })}
    </>
  )
}

export { SlabBeamsWithStepSize }

import React, { useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { enqueueSnackbar, SnackbarContent, closeSnackbar } from 'notistack'
import KeyboardIcon from '@mui/icons-material/Keyboard'
import { ToggleButton } from '@ui/actions'
import { Box } from '@ui/structure'
import { ShortcutInfo } from 'src/hooks/useShortcuts'

export const ToggleShortcutInfoButton = () => {
  const [showShortcutInfo, setShowShortcutInfo] = useState(false)
  const timeOutMS = 50

  const handleShortcutInfoClick = () => {
    const newShowShortcutInfo = !showShortcutInfo
    setShowShortcutInfo(newShowShortcutInfo)
    if (newShowShortcutInfo) {
      setTimeout(() => {
        enqueueSnackbar(
          <SnackbarContent
            style={{
              width: '1200px',
              maxWidth: '100%',
              margin: 0,
              padding: 0,
            }}
          >
            <Box sx={{ padding: 2, width: '100%' }}>
              <ShortcutInfo />
            </Box>
          </SnackbarContent>,
          {
            variant: 'info',
            key: 'shortcut-info',
            preventDuplicate: true,
          },
        )
      }, timeOutMS)
    } else {
      setTimeout(() => {
        closeSnackbar('shortcut-info')
      }, timeOutMS)
    }
  }

  useHotkeys('shift+?', handleShortcutInfoClick, [showShortcutInfo])

  return (
    <ToggleButton
      hint="Shortcut Info (?)"
      onClick={handleShortcutInfoClick}
      selected={showShortcutInfo}
      Icon={KeyboardIcon}
      data-cy="btn-show-shortcut-info"
    />
  )
}

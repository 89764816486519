import { meterToMillimeter } from '@utils'

const selectTimberFramePlot = (thickness: number, width: number) => {
  const thicknessInMeters = thickness / 1000

  if (thicknessInMeters >= width) return 'TimberFrameInsulationFull'
  if (thicknessInMeters < width && thicknessInMeters > 0) return 'TimberFrameInsulationPartly'
  else return 'TimberFrameNoInsulation'
}

const toCLTLayer = ({ clt }: CLTAssemblyLayer): PlotLayer => ({
  layerType: 'CLT' + clt.layers.length + 'Layer',
  thickness: meterToMillimeter(calculateCumulativeThickness(clt.layers)),
  product: clt.unique_name,
  layerName: 'CLTLayer',
})

const toProductLayer = ({ product, thickness, color }: ProductLayer): PlotLayer => ({
  layerType: 'Formwork',
  product: product.name,
  thickness: meterToMillimeter(thickness),
  color: color,
  density: product.density,
  layerName: 'ProductLayer',
})

const toPlateLayer = ({ plate }: PlateLayer): PlotLayer => ({
  layerType: 'GypsumBoard',
  thickness: meterToMillimeter(plate.material.thickness),
  layerName: 'PlateLayer',
  product: plate.unique_name,
})

const toTimberFrameLayer = (layer: TimberFrameLayer): PlotLayer => ({
  layerType: selectTimberFramePlot(layer.insulation_thickness, layer.standard_rip_cs.shape.width),
  thickness: meterToMillimeter(layer.standard_rip_cs.shape.height),
  layerName: 'TimberFrameLayer',
  density: layer.insulation ? layer.insulation.density : 0,
  product: layer.standard_rip_cs.unique_name,
})

export const kindToConversionMap: {
  [key: string]: (layer: Layer) => PlotLayer
} = {
  'clt-assembly-layer': layer => toCLTLayer(layer as CLTAssemblyLayer),
  'product-layer': layer => toProductLayer(layer as ProductLayer),
  'plate-layer': layer => toPlateLayer(layer as PlateLayer),
  'timber-frame-layer': layer => toTimberFrameLayer(layer as TimberFrameLayer),
}

export const calculateCumulativeThickness = (layers: PlotLayer[] | CLTLayer[]) => {
  return layers.map(layer => Number(layer.thickness)).reduce((a, b) => a + b, 0)
}

export const generatePlottingLayers = (layers: Layer[]): PlotLayer[] => {
  const convertedLayers = layers.map(layer => {
    if (!layer.kind || !kindToConversionMap[layer.kind]) return null

    return kindToConversionMap[layer.kind](layer)
  })

  return convertedLayers.filter(<T>(l: T): l is NonNullable<T> => l !== null)
}

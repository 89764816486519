import React, { ReactElement, useMemo } from 'react'
import convert from 'convert'
import { isNull } from 'lodash-es'
import numeral from 'numeral'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from '@mui/material'

interface Props {
  check: VibrationCheck
}

const AdditionalVibrationCheckInformation = ({ check }: Props): ReactElement | null => {
  const renderTableBody = (check: VibrationCheck): ReactElement | null => {
    return (
      <>
        {!isNull(check.frequency_min) && (
          <TableRow>
            <TableCell>
              f<sub>min</sub>
            </TableCell>
            <TableCell>{numeral(check.frequency_min).format('0,0.00a')} Hz</TableCell>
          </TableRow>
        )}

        {!isNull(check.frequency_limit) && (
          <TableRow>
            <TableCell>
              f<sub>grenz</sub>
            </TableCell>
            <TableCell>{numeral(check.frequency_limit).format('0,0.00a')} Hz</TableCell>
          </TableRow>
        )}

        {!isNull(check.acceleration_limit) && (
          <TableRow>
            <TableCell>
              a<sub>grenz</sub>
            </TableCell>
            <TableCell>
              {numeral(check.acceleration_limit).format('0,0.00a')} m/s<sup>2</sup>
            </TableCell>
          </TableRow>
        )}

        {!isNull(check.displacement_limit) && (
          <TableRow>
            <TableCell>
              w<sub>grenz</sub>
            </TableCell>
            <TableCell>
              {numeral(convert(check.displacement_limit, 'meter').to('millimeter')).format(
                '0,0.00a',
              )}{' '}
              mm
            </TableCell>
          </TableRow>
        )}

        <TableRow>
          <TableCell>
            f<sub>e</sub>
          </TableCell>
          <TableCell>{numeral(check.natural_frequency).format('0,0.00a')} Hz</TableCell>
        </TableRow>

        {!isNull(check.acceleration) && (
          <TableRow>
            <TableCell>a</TableCell>
            <TableCell>
              {numeral(check.acceleration).format('0,0.00a')} m/s<sup>2</sup>
            </TableCell>
          </TableRow>
        )}
        {!isNull(check.deflection) && (
          <TableRow>
            <TableCell>w</TableCell>
            <TableCell>
              {numeral(convert(check.deflection, 'meter').to('millimeter')).format('0,0.00a')} mm
            </TableCell>
          </TableRow>
        )}
        <TableRow>
          <TableCell>Ergebnis</TableCell>
          <TableCell>{check.check_status.toString()}</TableCell>
        </TableRow>
      </>
    )
  }

  const tableBody = useMemo(() => renderTableBody(check), [check])

  if (isNull(tableBody)) return null

  return (
    <TableContainer>
      <Table
        stickyHeader
        size="small"
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: 'none',
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>Berechnung</TableCell>

            <TableCell>Wert</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>{tableBody}</TableBody>
      </Table>
    </TableContainer>
  )
}

export default AdditionalVibrationCheckInformation

import { number, object, string } from 'yup'

export const beamSchema = object({
  guid: string(),
  xStart: number(),
  yStart: number(),
  xEnd: number(),
  yEnd: number(),
  zStart: number(),
  zEnd: number(),
})

import React, { ReactElement } from 'react'
import { useQueryParam } from 'use-query-params'
import { Stack, Typography } from '@mui/material'
import { Box, LayoutDrawer as Layout, Drawer } from '@ui/structure'
import ManagerTabs from 'src/components/pages/Manager/components/ManagerTabs'
import AssemblyManagerForm from './components/AssemblyManagerForm'
import ConnectionManagerForm from './components/ConnectionManagerForm'
import ElementCSManagerForm from './components/ElementCSManagerForm'

const ProjectSystemManager = (): ReactElement => {
  const [activeTab, setActiveTab] = useQueryParam<'assemblies' | 'connections' | 'components'>(
    'tab',
  )
  return (
    <>
      <Layout fullHeight={true} overlayingDrawer={false}>
        <Box
          flexGrow={1}
          sx={({ spacing }) => ({
            padding: spacing(3),
            overflowY: 'auto',
          })}
        >
          <ManagerTabs
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            assemblyManager={<AssemblyManagerForm />}
            connectionManager={<ConnectionManagerForm />}
            componentManager={<ElementCSManagerForm />}
          />
        </Box>
        <Drawer>
          <Stack direction="column" spacing={2}>
            <Typography variant="body1" mt={2}>
              {activeTab === 'assemblies' &&
                `
                Wählen Sie für jede Kategorie (z.B. Innenwände) einen Standardaufbau für das Projekt
                aus. Sie können neue Aufbauten anlegen oder bestehende editieren. Diese Änderungen
                werden nur für Ihr Projekt angewendet und nicht für die globalen Aufbauten.
                `}

              {activeTab === 'connections' &&
                'Legen Sie Zugverbindungen für dieses Projekt an und legen Sie eine Priorisierung fest.'}

              {activeTab === 'components' &&
                'Legen Sie Bauteil-Querschnitte an und weisen Sie jeweils einen Standard zu'}
            </Typography>
          </Stack>
        </Drawer>
      </Layout>
    </>
  )
}

export default ProjectSystemManager

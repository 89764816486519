import React, { ReactElement, useMemo, useRef, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { clipPointsToPolygon, getLineCoordinateSystem } from '@editorUtils'
import { useTapelineSnapTargets } from '@scene'
import { find, inRange, some } from 'lodash-es'
import { Plane, Vector2 } from 'three'
import { v4 as uuid } from 'uuid'
import { useTheme } from '@mui/material'
import {
  DraggableLine,
  DraggableLineRef,
  LineHandles,
} from '@modugen/scene/lib/components/Lines/DraggableLine'
import {
  DrawController,
  DrawControllerRef,
  TransientDrawState,
} from '@modugen/scene/lib/controllers/DrawController'
import { getOrientedWorldNormalsForIntersection } from '@modugen/scene/lib/controllers/DrawController/utils'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'
import useTapelineCentersSnapTargets from '@modugen/scene/lib/hooks/useTapelineCentersSnapTargets'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { useEditElementStore, useControlStore, useModelStore, roofStoreyKey } from '@editorStores'
import {
  SnapTargetElementType,
  useDefault2DSnapConfigForElementType,
} from 'src/components/pages/Editor/hooks/useDefaultSnapConfigForElementType'
import getPlaneLineIntersection from 'src/components/pages/Editor/utils/getPlaneLineIntersection'
import { purlinEqualnessFactor } from '../../../PurlinsDrawing/components/PurlinForm/schema'
import { useModelSnapTargets, useRipSnapTargets } from '../../hooks'
import { PurlinElements } from '../Purlin2D'

const minPurlinLength = 0.1

interface Props {
  resetSelectedElement: () => void
}

const PurlinDrawer2D = ({ resetSelectedElement }: Props): ReactElement => {
  const { scenePalette } = useTheme()

  const drawControllerRef = useRef<DrawControllerRef>(null)
  const lineRef = useRef<DraggableLineRef>(null)

  const isTapelineActive = useTapelineStore(state => state.isActive)

  const snapToCornersAndEdges = useControlStore(state => state.snapToCornersAndEdges)
  const snapToAngles = useControlStore(state => state.snapToAngles)
  const snapOrthogonal = useControlStore(state => state.snapOrthogonal)

  const snapTargets = useModelSnapTargets({ xyOnly: true })
  const tapelineSnapTargets = useTapelineSnapTargets()
  const tapelineCenterTargets = useTapelineCentersSnapTargets()
  const ripSnapTargets = useRipSnapTargets()

  const addPurlin = useModelStore(state => state.addPurlin)
  const setActiveElement = useEditElementStore(state => state.setActiveElement)

  const model = useModelStore(state => state.model)

  const drawTargetGuids = useMemo(
    () => [...model.roof_slabs, ...model.slabs].map(({ guid }) => guid),
    [model],
  )

  const [drawingActive, setDrawingActive] = useState(false)

  const isTapelineDrawing = useTapelineStore(state => state.isDrawing)
  const actionMode = useControlStore(state => state.actionMode)

  // EVENTS

  const onDrawStart = (state: TransientDrawState) => {
    const { drawPoint, drawTarget } = state

    if (lineRef.current && drawPoint && drawTarget) {
      setDrawingActive(true)
      lineRef.current?.setHandleAndStartDragging(LineHandles.End, drawPoint, drawPoint)

      // the angle snapping origin needs to be defined outside of the draw
      // controller to allow for flexibility (see onLineEditStart)

      if (drawPoint && drawTarget) {
        // define the plane angular snapping needs to work inside
        const snappingNormals = getOrientedWorldNormalsForIntersection(drawTarget, true)

        if (snappingNormals) {
          drawControllerRef.current?.setAngleSnappingOrigin({
            origin: drawPoint,
            ...snappingNormals,
          })
        }
      }
    }
  }

  const onDrawEnd = (transientDrawState: TransientDrawState) => {
    setDrawingActive(false)

    if (lineRef?.current && transientDrawState.drawTarget) {
      const drawnLine = lineRef.current.stopDraggingAndGetLine()

      const guid = transientDrawState.drawTarget.object.name

      const originalRoofSlab = find([...model.roof_slabs, ...model.slabs], { guid }) as ShapeObject

      const roofSlabPoints = originalRoofSlab.shape.points

      const originalPlane = new Plane().setFromCoplanarPoints(
        roofSlabPoints[0].v,
        roofSlabPoints[1].v,
        roofSlabPoints[2].v,
      )

      const projectedStart = getPlaneLineIntersection(
        originalPlane,
        new Vector2(drawnLine.start.x, drawnLine.start.y),
      ) as ImmutableVector3
      const projectedEnd = getPlaneLineIntersection(
        originalPlane,
        new Vector2(drawnLine.end.x, drawnLine.end.y),
      ) as ImmutableVector3

      if (drawnLine.start.distanceTo(drawnLine.end) >= minPurlinLength) {
        const threshold = 0.0001

        // here we ensure the polygon start/end will be the same (i.e. the
        // purlin being exactly horizontal) IF z of start and end are close to
        // each other
        const zStart = projectedStart.z
        let zEnd = projectedEnd.z
        if (inRange(zStart, zEnd - threshold, zEnd + threshold)) {
          zEnd = projectedStart.z
        }

        // this will ensure the purlin never exceeds the edges of the original
        // roof slab
        const [clippedStart] = clipPointsToPolygon(roofSlabPoints, [
          new ImmutableVector3(projectedStart.x, projectedStart.y, zStart),
        ])
        const [clippedEnd] = clipPointsToPolygon(roofSlabPoints, [
          new ImmutableVector3(projectedEnd.x, projectedEnd.y, zEnd),
        ])

        const isHorizontal =
          clippedStart.z.toFixed(purlinEqualnessFactor) ===
          clippedEnd.z.toFixed(purlinEqualnessFactor)

        // in order to place the purlin on the exact same height and therefore
        // prevent any errors in the backend we have to move the end to the same
        // height if both ends are similar to each other
        const zEndFinal = isHorizontal ? clippedStart.z : clippedEnd.z

        const start = clippedStart
        const end = new ImmutableVector3(clippedEnd.x, clippedEnd.y, zEndFinal)

        const purlin = {
          guid: uuid(),
          shape: {
            start,
            end,
          },
          storey: roofStoreyKey,
          is_local: true,
          coordinate_system: getLineCoordinateSystem(start, end),
          type: 'purlins' as ElementTypes,
        }

        addPurlin(purlin)
        setActiveElement(purlin.guid)
      }
    }
  }

  const onDrawMouseMove = (state: TransientDrawState) => {
    if (state.drawPoint) {
      lineRef.current?.updateActiveHandle(state.drawPoint)
    }
  }

  useHotkeys(
    'esc',
    () => {
      if (drawingActive) {
        drawControllerRef.current?.abortDrawing()
        lineRef.current?.stopDraggingAndGetLine()

        // not so nice way to reset the lineref to it's defaults

        lineRef.current?.setHandleAndStartDragging(
          LineHandles.Start,
          new ImmutableVector3(0, 0, 1000),
          new ImmutableVector3(0, 0, 1001),
        )
        lineRef.current?.stopDraggingAndGetLine()
      } else resetSelectedElement()
    },
    { enabled: !isTapelineDrawing && actionMode !== 'hide' },
    [drawingActive, resetSelectedElement],
  )

  // EFFECTS
  useDefault2DSnapConfigForElementType(SnapTargetElementType.PURLINS)

  return (
    <>
      <DrawController
        ref={drawControllerRef}
        enabled={!isTapelineActive}
        snapToCornersAndEdges={snapToCornersAndEdges}
        snapToAngles={snapToAngles}
        orthoSnap={snapOrthogonal}
        enableIndicator
        color={scenePalette.elements3d.beams as string}
        additionalSnapTargets={[
          ...snapTargets,
          ...tapelineSnapTargets,
          ...ripSnapTargets,
          ...tapelineCenterTargets,
        ]}
        onDrawStart={onDrawStart}
        onMouseMove={onDrawMouseMove}
        onDrawEnd={onDrawEnd}
        snapAngle={90}
        isValidDrawStart={intersections => {
          return !some(
            intersections,
            intersection => intersection.object.name === PurlinElements.Line,
          )
        }}
        xyOnly
        indicatorType="crosshair"
        isValidDrawTarget={object => drawTargetGuids.includes(object.name)}
      />

      {/* TEMP LINE */}

      <DraggableLine
        ref={lineRef}
        line={{ start: new ImmutableVector3(0, 0, 1000), end: new ImmutableVector3(0, 0, 1001) }}
        lineName={PurlinElements.Line}
        handleName={PurlinElements.Handle}
        color={scenePalette.elements3d.beams as string}
        showIndicators={false}
      />
    </>
  )
}

export default PurlinDrawer2D

import { useEffect } from 'react'
import { elementTypes } from '@scene'
import { last } from 'lodash-es'
import { useDebounce } from 'use-debounce'
import { useMarqueeSelectionStore } from '@modugen/scene/lib/controllers/MarqueeSelectionController/marqueeSelectionStore'
import { createInteractableByType, useElementSelectionStore, useModelStore } from '@editorStores'
import useModelClickListeners from './useModelClickListeners'

interface Arguments {
  highlightedIds?: Set<string>
  allowSelectHighlighted?: boolean
}

const useElementSelection = ({ allowSelectHighlighted = false }: Arguments = {}) => {
  const { isActive, selectedObjects, clearSelectedObjects } = useMarqueeSelectionStore()
  const [debouncedSelectedObjects] = useDebounce(selectedObjects, 10)
  const getModelStoreState = useModelStore.getState

  const {
    setSelectedStandaloneIds,
    setSelectionEnabled,
    selectedStandAloneIds,
    addSelectedStandaloneIds,
    deselectStandAloneId,
    deselectAllIds,
    highlightedIds,
    resetHighlightedIds,
  } = useElementSelectionStore()

  useModelClickListeners(
    event => {
      const {
        name: id,
        userData: { elementType },
      } = event.object
      const interactableByType =
        last(getModelStoreState().interactableByTypeConfigs)?.interactableByType ||
        createInteractableByType(elementTypes)

      const isTypeInteractable = interactableByType[elementType as ElementTypes]

      if (!isTypeInteractable) return

      const isHighlighted = highlightedIds?.has(id)

      if (isHighlighted && !allowSelectHighlighted) return

      if (selectedStandAloneIds.has(id)) deselectStandAloneId(id)
      else addSelectedStandaloneIds([id])
    },
    [selectedStandAloneIds, highlightedIds],
  )

  useEffect(() => {
    if (!isActive || !debouncedSelectedObjects) {
      clearSelectedObjects()
      return
    }
    const interactableByType =
      last(getModelStoreState().interactableByTypeConfigs)?.interactableByType ||
      createInteractableByType(elementTypes)

    // using loop for performance
    const ids = new Array(debouncedSelectedObjects.length)
    for (let i = 0; i < debouncedSelectedObjects.length; ++i) {
      const id = debouncedSelectedObjects[i].name
      const { elementType } = debouncedSelectedObjects[i].userData
      const isTypeInteractable = interactableByType[elementType as ElementTypes]

      if (!id || !isTypeInteractable) continue

      const isHighlighted = highlightedIds?.has(id)

      if (!isHighlighted || (isHighlighted && allowSelectHighlighted)) {
        ids[i] = debouncedSelectedObjects[i].name
      }
    }

    setSelectedStandaloneIds(ids.filter(Boolean))
  }, [isActive, debouncedSelectedObjects])

  useEffect(() => {
    setSelectionEnabled(true)

    return () => {
      setSelectionEnabled(false)
      deselectAllIds()
      resetHighlightedIds()
    }
  }, [])
}

export default useElementSelection

import Decimal from 'decimal.js'
import { every, reject } from 'lodash-es'
import { Vector2 } from 'three'
import { boolean, object, string } from 'yup'

export const createRipSchema = (guid: string, wallGuid: string) =>
  object({
    guid: string().default(guid),
    wall_guid: string().default(wallGuid),
    relative_position: string()
      .default('0')
      .test('is-relative', `Must be relative`, value => {
        try {
          const decimalValue = new Decimal(value)
          return decimalValue.gte(0) && decimalValue.lte('1')
        } catch (error) {
          return false
        }
      }),
    isOverlapping: boolean().test({
      name: 'is-overlapping-rip',
      message: 'Rippe darf nicht genau an der gleichen Position wie andere Rippen stehen',
      test: function () {
        const { rips, wall } = (this.options.context || {}) as { rips: Rip[]; wall: ShapeObject }

        const ripsWithoutCurrentRip = reject(rips, { position_guid: this.parent.guid })

        const relPosition = this.parent.relative_position

        const wallLength = wall.shape.points[0].distanceTo(wall.shape.points[1])
        const wallStart = wall.shape.points[0]
        const wallDirection = wall.shape.points[0].directionTo(wall.shape.points[1])

        const ripStart = wallStart.addScaledVector(wallDirection, wallLength * relPosition)

        return every(ripsWithoutCurrentRip, rip => rip.start.distanceTo(ripStart) > 0.001)
      },
    }),

    isIntersecting: boolean().test({
      name: 'is-intersecting-opening',
      message: 'Rippe darf nicht innerhalb von Öffnungen stehen',
      test: function () {
        const { wall } = (this.options.context || {}) as { wall: ShapeObject }
        const relPosition = this.parent.relative_position

        const wallLength = wall.shape.points[0].distanceTo(wall.shape.points[1])
        const wallStart = new Vector2(wall.shape.points[0].x, wall.shape.points[0].y)
        const intervals = wall.openings?.map(opening => {
          const start = new Vector2(opening.shape.points[0].x, opening.shape.points[0].y)
          const end = new Vector2(opening.shape.points[1].x, opening.shape.points[1].y)

          const absStart = wallStart.distanceTo(start)
          const absEnd = wallStart.distanceTo(end)

          const relStart = absStart / wallLength
          const relEnd = absEnd / wallLength

          return [relStart, relEnd]
        })

        return every(
          intervals as number[][],
          ([intervalStart, intervalEnd]) =>
            !(relPosition > intervalStart && relPosition < intervalEnd),
        )
      },
    }),
  })

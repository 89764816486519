export const formStateToLayer = (values: TimberFrameLayerFormState): TimberFrameLayer => {
  const { crossSectionStandardRip, crossSectionLintel, ...layer } = values.layer

  return {
    ...layer,
    standard_rip_cs: crossSectionStandardRip,
    lintel_cs: crossSectionLintel,
  }
}

export const layerToFormState = (layer: TimberFrameLayer): TimberFrameLayerFormState => {
  // eslint-disable
  const { standard_rip_cs, lintel_cs, ...rest } = layer
  // eslint-enable

  const materialTypeStandardRip = standard_rip_cs?.material?.identifier.startsWith('SW')
    ? 'softwoodMaterial'
    : 'glulamMaterial'

  const materialTypeLintel = lintel_cs?.material?.identifier.startsWith('SW')
    ? 'softwoodMaterial'
    : 'glulamMaterial'

  const hasInsulation = rest.insulation !== null

  return {
    materialTypeStandardRip,
    materialTypeLintel,
    layer: {
      ...rest,
      crossSectionStandardRip: standard_rip_cs,
      crossSectionLintel: lintel_cs,
      hasInsulation: hasInsulation,
    },
  }
}

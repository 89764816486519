import { array, boolean, number, string, object } from 'yup'

const configurationsSchema = object({
  relative_position: number().min(0).max(1),
  width: number().positive('Breite muss größer 0 sein'),
  length: number().positive('Länge muss größer 0 sein'),
  continuous_support: boolean(),
})

const vibrationSettingsSchema = object({
  slab_weight_per_area: number().positive(),
  slab_width: number().positive(),
  step_size: number().positive(),
  requirement: string().required(),
  screed_data: object({ screed: string(), fill: string() }),
  screed_height: number().positive(),
  screed_e_modul: number().positive(),
})

const steelSupportConfiguration = object({
  design_support_material_strength: number(),
  base_plate_geometry: object({
    thickness: number(),
    width: number(),
    length: number(),
  }),
  weld_thickness: number(),
})

export const schemaTimberSlab = object({
  configurations: array().of(configurationsSchema),
  vibration: vibrationSettingsSchema.nullable(),
  shear_check_settings: object({
    reduce_point_loads_close_to_supports: boolean().default(false),
    reduce_shear_force: boolean().default(false),
  }),
})

export const schemaTimber = object({
  configurations: array().of(configurationsSchema),
  shear_check_settings: object({
    reduce_point_loads_close_to_supports: boolean().default(false),
    reduce_shear_force: boolean().default(false),
  }),
})

export const schemaSteelColumn = object({
  configurations: steelSupportConfiguration.nullable(),
})

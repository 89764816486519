import { inRange } from 'lodash-es'
import { toImmutable } from '@modugen/scene/lib'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'

const equalizeClosePolygonEdgesHeight = (edges: ImmutableVector3[], threshold = 0.01) => {
  const editableEdges = edges.map(p => p.v)

  editableEdges.forEach((outerEdge, outerIndex) => {
    for (let i = 0; i < editableEdges.length; i++) {
      const innerEdge = editableEdges[i]

      if (i !== outerIndex) {
        if (inRange(innerEdge.z, outerEdge.z - threshold, outerEdge.z + threshold)) {
          outerEdge.z = innerEdge.z
        }
      }
    }
  })

  return editableEdges.map(edge => toImmutable(edge))
}

export default equalizeClosePolygonEdgesHeight

import React, { ReactElement, useCallback } from 'react'
import { ArrowOrientationSlab, SimpleShape } from '@scene'
import { ColorRepresentation, DoubleSide } from 'three'
import { useTheme } from '@mui/material'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { useSceneStore } from '@editorStores'
import { useEnhanceClickEvent } from '../../hooks'

const VerticalSlab2D = ({ slab, onClick, isActive = false }: SlabProps): ReactElement => {
  const { scenePalette } = useTheme()

  // state picking due to performance reasons
  const mappedColor = useSceneStore(
    useCallback(state => state.guidToColor[slab.guid], [slab.guid]),
  ) as ColorRepresentation | undefined

  const { onClickEnhanced } = useEnhanceClickEvent(slab.guid, onClick)

  return (
    <>
      <SimpleShape
        key={slab.guid}
        name={slab.guid}
        points={slab.shape.points.map(p => new ImmutableVector3(p.x, p.y, p.z))}
        color={mappedColor || scenePalette.elements3d.vertical_slabs}
        meshMaterialProps={{
          opacity: isActive ? 0.7 : 0.3,
          transparent: true,
          side: DoubleSide,
          depthWrite: false,
        }}
        outlines
        renderOrder={2}
        onClick={onClickEnhanced ? event => onClickEnhanced(event.object.name) : undefined}
        cursor={onClickEnhanced ? 'pointer' : 'auto'}
      />
      <ArrowOrientationSlab slab={slab} />
    </>
  )
}

export default VerticalSlab2D

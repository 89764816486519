import sanitizeFilename from 'sanitize-filename'

export const downLoadFile = (
  data: Blob,
  type: BlobType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  filename: string,
) => {
  const fileURL = window.URL.createObjectURL(
    new Blob([data], {
      type,
    }),
  )
  const link = document.createElement('a')
  link.href = fileURL
  link.download = sanitizeFilename(filename).substring(0, 128)
  link.click()
  window.URL.revokeObjectURL(fileURL)
}

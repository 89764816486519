import React, { ReactNode, ReactElement } from 'react'
import { createPortal } from 'react-dom'
import { useControlStore } from '@editorStores'

interface Props {
  children: ReactNode
}

export const FilterPortal = ({ children }: Props): ReactElement => {
  const filterPortalElement = useControlStore(state => state.filterPortalElement)

  return <>{filterPortalElement && createPortal(<>{children}</>, filterPortalElement)}</>
}

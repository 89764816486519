import { transform, capitalize, lowerCase } from 'lodash'
import { deleteRequest, postRequest } from 'src/state/utils'

export const saveSelectedAssemblyGuids: RequestTypeVariable = {
  getKey: (projectId: string) => ['saveSelectedAssembyGuids', projectId],
  request: async ({ projectId, input }): Promise<{ [key: string]: SelectedAssembly }> => {
    const { data } = await postRequest<{ [key: string]: SelectedAssembly }>({
      url: `/projects/${projectId}/selected-assemblies`,
      data: transform(input, (result: { [key: string]: SelectedAssembly }, val, key: string) => {
        result[lowerCase(key)] = {
          ...val,
          category: key,
        }
      }),
    })

    // object key casing is not compatible with rest of types
    return transform(
      data as { [key: string]: SelectedAssembly },
      (result: { [key: string]: SelectedAssembly }, val, key: string) => {
        result[capitalize(key)] = val
      },
    )
  },
}

export const deleteAssembly = {
  key: 'deleteAssembly',
  request: async (id: string): Promise<Assembly[]> => {
    const { data } = await deleteRequest<Assembly[]>({
      url: `/assemblies/${id}`,
    })

    return data as Assembly[]
  },
}

export const deleteProjectAssembly = {
  getKey: (projectId: string) => ['deleteProjectAssembly', projectId],
  request: async (projectId: string, id: string): Promise<Assembly[]> => {
    const { data } = await deleteRequest<Assembly[]>({
      url: `/projects/${projectId}/assemblies/${id}`,
    })

    return data as Assembly[]
  },
}

export const createAssembly = {
  key: 'createAssembly',
  request: async (data: Assembly): Promise<CLTAssembly | TimberFrameAssembly> => {
    const { data: result } = await postRequest<CLTAssembly | TimberFrameAssembly>({
      url: `/assemblies`,
      data,
    })

    return result as CLTAssembly | TimberFrameAssembly
  },
}

export const createProjectAssembly = {
  getKey: (projectId: string) => ['createProjectAssembly', projectId],
  request: async (id: string, data: Assembly): Promise<CLTAssembly | TimberFrameAssembly> => {
    const { data: result } = await postRequest<CLTAssembly | TimberFrameAssembly>({
      url: `/projects/${id}/assemblies`,
      data,
    })

    return result as CLTAssembly | TimberFrameAssembly
  },
}

export const editAssembly = {
  key: 'editAssembly',
  request: async (data: Assembly): Promise<CLTAssembly | TimberFrameAssembly> => {
    const { data: result } = await postRequest<CLTAssembly | TimberFrameAssembly>({
      url: `/assemblies/${data.guid}`,
      data,
    })

    return result as CLTAssembly | TimberFrameAssembly
  },
}

export const editProjectAssembly = {
  getKey: (projectId: string) => ['editProjectAssembly', projectId],
  request: async (
    projectId: string,
    data: Assembly,
  ): Promise<CLTAssembly | TimberFrameAssembly> => {
    const { data: result } = await postRequest<CLTAssembly | TimberFrameAssembly>({
      url: `/projects/${projectId}/assemblies/${data.guid}`,
      data,
    })

    return result as CLTAssembly | TimberFrameAssembly
  },
}

export const editAssemblyAssignment = {
  getKey: (projectId: string) => ['editAssemblyAssignment', projectId],
  request: async (projectId: string, data: AssemblyAssignment[]): Promise<AssemblyAssignment[]> => {
    const { data: result } = await postRequest<AssemblyAssignment[]>({
      url: `/projects/${projectId}/assembly-assignment`,
      data,
    })

    return result as AssemblyAssignment[]
  },
}

import { cloneDeep } from 'lodash-es'

export const rotateArray = <T>(array: Array<T>, positions: number) => {
  let num = (positions || 0) % array.length
  if (num < 0) {
    num += array.length
  }
  const clonedArray = cloneDeep(array)

  const removed = clonedArray.splice(0, num)

  clonedArray.push(...removed)

  return clonedArray
}

export const swapElements = <T extends Array<unknown>>(arr: T, i1: number, i2: number): T => {
  const newArr = cloneDeep(arr)

  const tmp = newArr[i1]
  newArr[i1] = newArr[i2]
  newArr[i2] = tmp

  return newArr
}

/**
 * Assumes the array is a loop. So e.g. if you try to access the last element +
 * 1 you will get the first array item
 */
export const nthLooped = <T>(array: Array<T>, index: number) => {
  if (index < 0) {
    const calculatedIndex = array.length - ((-index - 1) % array.length) - 1

    return array[calculatedIndex]
  }

  return array[index % array.length]
}

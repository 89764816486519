import { useCallback } from 'react'
import { find } from 'lodash-es'
import { StringParam, useQueryParams, ArrayParam } from 'use-query-params'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'
import { useModelStore } from '@editorStores'

type WallTab = 'connectors' | 'geometry' | 'assembly' | 'vertical-transmission'

interface Params {
  mode?: string | null
  drawShapeType?: string | null
  load?: string | null
  elements?: string[]
  stiffeningElements?: string[]
  connector?: string
  verticalTransmitter?: string
  activeWallTab?: WallTab | null

  drawAreaLoadElement?: string | null
  storey?: string | null
}

const useStructuralPlanningQueryParams = () => {
  const model = useModelStore(state => state.model)
  const rips = useModelStore(state => state.model.rips)
  const lintels = useModelStore(state => state.model.lintels)

  const isTapelineActive = useTapelineStore(state => state.isActive)

  const [params, setQuery] = useQueryParams({
    mode: StringParam,
    drawShapeType: StringParam,
    connector: StringParam,
    verticalTransmitter: StringParam,
    load: StringParam,
    elements: ArrayParam,
    stiffeningElements: ArrayParam,
    hiddenFilters: ArrayParam,
    activeWallTab: StringParam,
    drawAreaLoadElement: StringParam,
    storey: StringParam,
  })

  const {
    mode,
    drawShapeType,
    elements,
    stiffeningElements,
    connector,
    verticalTransmitter,
    activeWallTab,
    load,
    drawAreaLoadElement,
    storey,
  } = params

  // set all parameters that are not passed to undefined
  const setQueryReset = useCallback(
    (newParams: Params = {}) => {
      const newPramsWithHidden = {
        ...newParams,
      }

      const result = Object.entries(params).reduce((acc: Params, [key]) => {
        // @ts-ignore
        if (newPramsWithHidden[key]) {
          return {
            ...acc,
            // @ts-ignore
            [key]: newPramsWithHidden[key],
          }
        }

        return {
          ...acc,
          [key]: undefined,
        }
      }, {})

      setQuery(result)
    },
    [params, setQuery],
  )

  const toggleMode = useCallback(
    (newMode?: StructuralPlanningModes, reset = true) => {
      if (!newMode || mode === newMode) setQueryReset({ storey })
      else if (reset)
        setQueryReset({
          mode: newMode,
          storey,
        })
      else setQuery({ mode: newMode })
    },
    [mode, setQuery, setQueryReset, storey],
  )

  const toggleLoadTypeMode = useCallback(
    (newMode: 'line-load' | 'point-load' | 'area-load') => {
      setQueryReset({
        mode: newMode,
        // @ts-ignore
        elements,
      })
    },
    [elements, setQueryReset],
  )

  const setMode = useCallback(
    (mode: StructuralPlanningModes, reset = true) => {
      if (reset) setQueryReset({ mode })
      else setQuery({ mode })
    },
    [setQuery, setQueryReset],
  )

  const setDrawShapeType = useCallback(
    (drawShapeType: DrawShapeType, reset = true) => {
      if (reset) setQueryReset({ drawShapeType: drawShapeType })
      else setQuery({ drawShapeType: drawShapeType })
    },
    [setQuery, setQueryReset],
  )

  const setElements = useCallback(
    (elements: string[], elementType?: ElementTypes | 'rip' | 'lintel') => {
      setQueryReset({
        mode: elements.length ? elementType || mode : undefined,
        elements: elements,
      })
    },
    [mode, setQueryReset],
  )

  const resetElements = useCallback(() => {
    setQuery({
      elements: undefined,
    })
  }, [setQuery])

  const setStiffeningElement = useCallback(
    (stiffeningElementGuid: string) => {
      setQueryReset({
        // @ts-ignore
        elements,
        stiffeningElements: [stiffeningElementGuid],
        mode,
        // @ts-ignore
        activeWallTab: 'geometry',
      })
    },
    [elements, mode, setQueryReset],
  )

  const setConnector = useCallback(
    (connectorGuid: string, elementGuid: string) => {
      if (isTapelineActive) {
        return
      }
      setQueryReset({
        // @ts-ignore
        mode: mode,
        elements: [elementGuid],
        connector: connectorGuid,
        // @ts-ignore
        activeWallTab: 'connector',
      })
    },
    [isTapelineActive, mode, setQueryReset],
  )

  const setVerticalTransmitter = useCallback(
    (transmitterGuid: string, elementGuid: string, transmitterOnly = false) => {
      if (isTapelineActive) {
        return
      }
      if (transmitterOnly) {
        setQuery({
          verticalTransmitter: transmitterGuid,
        })

        return
      }

      const modelElement = find(
        [
          ...model.walls,
          ...model.slabs,
          ...model.vertical_slabs,
          ...model.beams,
          ...model.purlins,
          ...model.columns,
          ...model.vertical_roof_slabs,
        ],
        ['guid', elementGuid],
      )

      const rip = find(rips, { position_guid: elementGuid })

      const lintel = find(lintels, { position_guid: elementGuid })

      let type: ElementTypes | 'rip' | 'lintel' | undefined = modelElement?.type
      if (rip) type = 'rip'
      if (lintel) type = 'lintel'

      setQueryReset({
        verticalTransmitter: transmitterGuid,
        mode: type,
        elements: [elementGuid],
        activeWallTab: 'vertical-transmission',
        storey,
      })
    },
    [
      isTapelineActive,
      lintels,
      model.beams,
      model.columns,
      model.purlins,
      model.slabs,
      model.vertical_roof_slabs,
      model.vertical_slabs,
      model.walls,
      rips,
      setQuery,
      setQueryReset,
      storey,
    ],
  )

  const setActiveWallTab = useCallback(
    (activeWallTab: WallTab) => {
      setQueryReset({
        // @ts-ignore
        elements,
        mode,
        activeWallTab,
        storey,
      })
    },
    [elements, mode, setQueryReset, storey],
  )

  const setStorey = useCallback(
    (storey: string | null) => {
      if (storey === 'Dach') return
      setQueryReset({
        storey,
        mode: storey === null || mode === 'storey-selection' ? undefined : mode,
      })
    },
    [mode, setQueryReset],
  )

  const selectLoad = useCallback(
    (load: ElementLoad) => {
      setQueryReset({
        load: load.guid,
        mode: load.load_type,
        elements: [load.element_guid],
      })
    },
    [setQueryReset],
  )

  const setDrawAreaLoadElement = useCallback(
    (drawAreaLoadElement?: string) => {
      setQueryReset({
        mode: 'area-load',
        drawAreaLoadElement,
        load,

        // @ts-ignore
        elements,
      })
    },
    [setQueryReset, load, elements],
  )

  return {
    modes: {
      isSelectionMode: mode === 'selection',
      isRoofSlabMode: mode === 'roof_slabs' || mode === 'slabs',
      isVerticalSlabMode: mode === 'vertical_slabs',
      isBeamMode: mode === 'beams',
      isRoofMode: mode === 'vertical_roof_slabs',
      isColumnMode: mode === 'columns',
      isPurlinMode: mode === 'purlins',
      /**
       * A wall has been selected (either inner or outer wall)
       */
      isWallMode: mode === 'inner_walls' || mode === 'outer_walls',
      isPositionMode: mode === 'position',
      isStiffeningMode: mode === 'stiffening',
      isLoadMode: mode === 'line-load' || mode === 'point-load' || mode === 'area-load',
      /**
       * No mode has been selected and we are in global and not floorplan mode
       * (no storey has been selecte)
       */
      isNeutralMode: !storey && !mode,
      isDrawingBeamsMode: mode === 'draw-beams',
      isDrawingColumnsMode: mode === 'draw-columns',
      isDrawingPurlinsMode: mode === 'draw-purlins',
      isDrawingVerticalSlabsMode: mode === 'draw-vertical-slabs',
      isDrawingVerticalRoofMode: mode === 'draw-vertical-roofs',
      isDrawingSlabMode: mode === 'draw-slabs',
      isDrawingRoofMode: mode === 'draw-roofs',
      isDrawingWallsMode: mode === 'draw-walls',
      isDrawingOpeningsMode: mode === 'draw-openings',
      isStoreySelectionMode: mode === 'storey-selection',
      isDrawMode:
        mode === 'draw-vertical-slabs' ||
        mode === 'draw-beams' ||
        mode === 'draw-columns' ||
        mode === 'draw-walls' ||
        mode === 'draw-openings' ||
        mode === 'draw-vertical-roofs' ||
        mode === 'draw-purlins' ||
        mode === 'wall-rip',
      isRipMode: mode === 'rip',
      isWallRipMode: mode === 'wall-rip',
      isWallLintelMode: mode === 'wall-lintel',
      isLintelMode: mode === 'lintel',
      isFoundationMode: mode === 'foundation',
    },
    params: {
      mode,
      drawShapeType,
      selectedElements: elements,
      selectedStiffeningElements: stiffeningElements,
      connectorGuid: connector,
      activeWallTab,

      load,
      verticalTransmitter,

      loadType:
        mode === 'line-load' || mode === 'point-load' || mode === 'area-load' ? mode : undefined,
      drawAreaLoadElement,
      storey,
    },
    actions: {
      resetElements,
      toggleMode,
      toggleLoadTypeMode,
      setMode,
      setDrawShapeType,
      setElements,
      setStiffeningElement,
      setVerticalTransmitter,
      setConnector,
      setActiveWallTab,
      selectLoad,
      setDrawAreaLoadElement,
      reset: setQueryReset,
      setStorey,
    },
  }
}

export default useStructuralPlanningQueryParams

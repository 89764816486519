import React, { ReactElement } from 'react'
import Download from '@mui/icons-material/Download'
import { LoadingButton, LoadingButtonProps } from '@mui/lab'
import { SvgIconPropsSizeOverrides } from '@mui/material'
import { OverridableStringUnion } from '@mui/types'

interface Props extends LoadingButtonProps {
  iconFontSize?: OverridableStringUnion<
    'inherit' | 'large' | 'medium' | 'small',
    SvgIconPropsSizeOverrides
  >
}

const DownLoadIconButton = ({ iconFontSize = 'small', ...props }: Props): ReactElement => (
  <LoadingButton {...props}>
    <Download fontSize={iconFontSize} />
  </LoadingButton>
)

export default DownLoadIconButton

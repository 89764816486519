import { QueryClient } from 'react-query'
import axios, { AxiosError } from 'axios'

const WRITE_CONFLICT_ERROR_CODE = 429

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: (failureCount: number, error) => {
        const val =
          axios.isAxiosError(error) &&
          (error as AxiosError).response?.status === WRITE_CONFLICT_ERROR_CODE &&
          failureCount <= 3

        return val
      },
    },
  },
})

export default queryClient

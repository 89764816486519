import { useEffect } from 'react'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'
import { SnappingFeaturesEnabled, useControlStore } from '@editorStores'

const defaultSnapTargetConfig = () => {
  return {
    snapToCornersAndEdges: true,
    snapToAngle: false,
    snapOrthogonal: true,
    snapToTapelineCenters: true,
  } as SnappingFeaturesEnabled
}

export enum SnapTargetElementType {
  WALLS = 'walls',
  PURLINS = 'purlins',
  COLUMNS = 'columns',
  BEAMS = 'beams',
  VERTICAL_SLABS = 'vertical_slabs',
  VERTICAL_ROOF_SLABS = 'vertical_roof_slabs',
}

export const defaultSnapTargets: Record<SnapTargetElementType, SnappingFeaturesEnabled> = {
  walls: defaultSnapTargetConfig(),
  purlins: defaultSnapTargetConfig(),
  columns: defaultSnapTargetConfig(),
  beams: defaultSnapTargetConfig(),
  vertical_slabs: defaultSnapTargetConfig(),
  vertical_roof_slabs: defaultSnapTargetConfig(),
}

export const useDefault2DSnapConfigForElementType = (elementType: SnapTargetElementType) => {
  const controlStore = useControlStore()
  const tapelineStore = useTapelineStore()
  return useEffect(() => {
    const config = defaultSnapTargets[elementType]
    controlStore.setSnapOrthogonal(config.snapOrthogonal)
    tapelineStore.setSnapOrthogonal(config.snapOrthogonal)
    controlStore.setSnapToAngles(config.snapToAngle)
    tapelineStore.setSnapToAngles(config.snapToAngle)
    controlStore.setSnapToCornersAndEdges(config.snapToCornersAndEdges)
    tapelineStore.setSnapToCornersAndEdges(config.snapToCornersAndEdges)
    controlStore.setSnapToTapelineCenters(config.snapToTapelineCenters)
    tapelineStore.setSnapToCenters(config.snapToTapelineCenters)
  }, [])
}

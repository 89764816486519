import React, { ReactElement, useCallback } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useParams } from 'react-router-dom'
import { useResultsQueries, useResultsQueryParams } from '@resultsHooks'
import { ArrowBack, Error } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Button, CircularProgress, Stack } from '@mui/material'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'
import { SwitchLabeled } from '@ui/actions'
import { Info, LoadingIndicator } from '@ui/feedback'
import { NavbarPortalCenter, NavBreadcrumbs } from '@ui/navigation'
import { Box, Drawer, LayoutDrawer } from '@ui/structure'
import BottomDrawer from '@ui/structure/BottomDrawer'
import { useControlStore, useResultsStore } from '@editorStores'
import { useHighlightFromParams, useSelectionMode } from '@editorHooks'
import { FilterPortal } from '@editorComponents'
import SceneControlled from '../../components/SceneControlled'
import { ResultsScene, LocalResults, GlobalTabs } from './components'
import AnchorDataGrid from './components/AnchorDataGrid'

const Results = (): ReactElement => {
  const { projectId } = useParams()

  const { isSelectionMode, unsetSelectionMode } = useSelectionMode()

  // APP STATE

  const isTapelineDrawing = useTapelineStore(state => state.isDrawing)
  const actionMode = useControlStore(state => state.actionMode)
  const isDrawerExpanded = useControlStore(state => state.isDrawerExpanded)
  const setIsDrawerExpanded = useControlStore(state => state.setIsDrawerExpanded)
  const isAnchorCalculationMode = useControlStore(state => state.isAnchorCalculationMode)
  const setIsAnchorCalculationMode = useControlStore(state => state.setIsAnchorCalculationMode)
  const isBottomDrawerExpanded = useControlStore(state => state.isBottomDrawerExpanded)
  const setIsBottomDrawerExpanded = useControlStore(state => state.setIsBottomDrawerExpanded)

  const elementLoads = useResultsStore(state => state.elementLoads)
  const positionLoads = useResultsStore(state => state.positionLoads)
  const visibility = useResultsStore(state => state.resultElementVisibility)
  const changeResultElementVisibility = useResultsStore(
    state => state.changeResultElementVisibility,
  )

  // QUERY PARAMS

  const {
    modes: { isLocalMode },
    actions: { resetSelection, setIsLocalMode },
  } = useResultsQueryParams()

  // QUERIES

  const {
    compute,
    computeChecks,
    isComputing,
    isComputingChecks,
    isLoadingInitial,
    initialLoadingSuccess,
    isLoadingDesignChecks,
    isLoadingDesignPositions,
    isLoadingDesignForces,
  } = useResultsQueries(projectId as string)

  // HOTKEYS

  const escAction = useCallback(() => {
    if (isSelectionMode) unsetSelectionMode()
    else if (isLocalMode) setIsLocalMode(false)
    else resetSelection()
  }, [isSelectionMode, unsetSelectionMode, resetSelection, isLocalMode, setIsLocalMode])

  useHotkeys('esc', escAction, { enabled: !isTapelineDrawing && actionMode !== 'hide' }, [
    escAction,
  ])

  // SCENE COLORING

  useHighlightFromParams(['selectedElement'])

  const isLoadingResults =
    isLoadingDesignChecks ||
    isLoadingDesignPositions ||
    isComputing ||
    isComputingChecks ||
    isLoadingDesignForces

  return (
    <>
      <FilterPortal>
        <SwitchLabeled
          checked={visibility.wallPositions}
          onChange={() => changeResultElementVisibility('wallPositions', !visibility.wallPositions)}
          label="Wandpositionen"
        />
        <SwitchLabeled
          checked={visibility.slabPositions}
          onChange={() => changeResultElementVisibility('slabPositions', !visibility.slabPositions)}
          label="Deckenbalken"
        />
        <SwitchLabeled
          checked={visibility.roofSlabPositions}
          onChange={() =>
            changeResultElementVisibility('roofSlabPositions', !visibility.roofSlabPositions)
          }
          label="Dachbalken"
        />
      </FilterPortal>

      <NavbarPortalCenter>
        <NavBreadcrumbs extension />
      </NavbarPortalCenter>

      <LayoutDrawer>
        <SceneControlled>
          <ResultsScene />
        </SceneControlled>

        <Drawer
          variant="persistent"
          expanded={isDrawerExpanded}
          onExpand={setIsDrawerExpanded}
          bottomDrawerExpanded={isBottomDrawerExpanded}
        >
          {isLoadingInitial ? (
            <LoadingIndicator />
          ) : (
            <>
              {initialLoadingSuccess && elementLoads && positionLoads ? (
                <>{isLocalMode ? <LocalResults /> : <GlobalTabs />}</>
              ) : (
                <Box
                  display="flex"
                  flexGrow={1}
                  alignItems="center"
                  justifyContent="center"
                  height="100%"
                >
                  <Info icon={<Error color="error" />}>Daten noch nicht verfügbar</Info>
                </Box>
              )}
            </>
          )}

          <Stack
            direction="column"
            display="flex"
            alignItems="center"
            justifyContent="space-evenly"
            spacing={2}
          >
            {!isLoadingInitial && isLoadingResults && (
              <Box>
                <CircularProgress data-cy="loading-results-indicator" size={28} />
              </Box>
            )}
            <Box>
              {isLocalMode ? (
                <Button
                  startIcon={<ArrowBack />}
                  onClick={() => setIsLocalMode(false)}
                  variant="outlined"
                  data-cy="global-view-btn"
                >
                  Global
                </Button>
              ) : (
                <Stack
                  direction="row"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-evenly"
                  spacing={2}
                >
                  <LoadingButton
                    variant="contained"
                    onClick={() => computeChecks(projectId as string)}
                    disabled={isLoadingResults}
                    data-cy="recalculate-checks"
                  >
                    Nachweise berechnen
                  </LoadingButton>
                  <LoadingButton
                    variant="contained"
                    onClick={() => compute(projectId as string)}
                    disabled={isLoadingResults}
                    data-cy="recalculate-results"
                  >
                    Ergebnisse berechnen
                  </LoadingButton>
                </Stack>
              )}
            </Box>
          </Stack>
        </Drawer>

        <BottomDrawer
          isOpen={isAnchorCalculationMode}
          setIsOpen={setIsAnchorCalculationMode}
          expanded={isBottomDrawerExpanded}
          setIsDrawerExpanded={setIsBottomDrawerExpanded}
          sideDrawerExpanded={isDrawerExpanded}
        >
          <AnchorDataGrid />
        </BottomDrawer>
      </LayoutDrawer>
    </>
  )
}

export default Results

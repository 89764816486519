import React, { ChangeEvent, ReactElement, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import { chunk } from 'lodash-es'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import OpenIcon from '@mui/icons-material/OpenInNew'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  TablePagination,
} from '@mui/material'
import { AlertDialog } from '@ui/feedback'
import { Box } from '@ui/structure'

interface Props {
  projects: ProjectData[]
  onClick: (project: ProjectData) => void
  onDelete: (project: ProjectData) => void
  onEdit: (project: ProjectData) => void
  onDuplicate: (project: ProjectData) => void
  defaultRowsPerPage?: number
}

const ProjectTable = ({
  projects,
  onClick,
  onDelete,
  onEdit,
  onDuplicate,
  defaultRowsPerPage = 25,
}: Props): ReactElement => {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState<number>(defaultRowsPerPage)

  const projectsSorted = useMemo(() => {
    return [...projects].sort((a, b) =>
      dayjs(a.created_timestamp).isAfter(dayjs(b.created_timestamp)) ? -1 : 1,
    )
  }, [projects])

  const chunks = useMemo(() => chunk(projectsSorted, rowsPerPage), [projectsSorted, rowsPerPage])
  const projectsInPage = chunks[page] || chunks[page - 1] || []

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>Projekt Name</TableCell>
              <TableCell>Erstellt am</TableCell>
              <TableCell align="right">Aktionen</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projectsInPage.map(project => {
              const { name, guid, created_timestamp } = project

              return (
                <TableRow
                  key={guid}
                  sx={() => ({
                    '&:last-child td, &:last-child th': { border: 0 },
                  })}
                >
                  <TableCell component="th" scope="row">
                    {name}
                  </TableCell>
                  <TableCell>
                    <Typography>{dayjs(created_timestamp).format('DD.MM.YYYY')}</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Box display="flex" justifyContent="flex-end">
                      <IconButton onClick={() => onClick(project)}>
                        <OpenIcon fontSize="small" />
                      </IconButton>
                      <IconButton onClick={async () => onEdit(project)}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton onClick={async () => onDuplicate(project)}>
                        <ContentCopyIcon fontSize="small" />
                      </IconButton>
                      <AlertDialog
                        title="Projekt löschen?"
                        text={`Bestätigen Sie, dass Sie das Projekt ${name} wirklich löschen wollen`}
                        onConfirm={async () => onDelete(project)}
                      >
                        <IconButton>
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </AlertDialog>
                    </Box>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        labelRowsPerPage="Projekte pro Seite"
        labelDisplayedRows={({ from, to, count }) => {
          return `${from}–${to} von ${count !== -1 ? count : `mehr als ${to}`}`
        }}
        component="div"
        count={projectsSorted.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(e, p) => setPage(p)}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  )
}

export default ProjectTable

import React, { ReactElement, useMemo, useEffect, useCallback } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { getAreaLoadTitle, getLineLoadTitle, getPointLoadTitle } from '@editorUtils'
import { AxiosError } from 'axios'
import { filter, find, reject } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { ContentCopy, SyncProblem } from '@mui/icons-material'
import Delete from '@mui/icons-material/Delete'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { LoadingButton, TabContext, TabPanel, TabList } from '@mui/lab'
import {
  Typography,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Stack,
  Button,
} from '@mui/material'
import { AddButton, VisibilityToggle } from '@ui/actions'
import { Form, FormAccordion, FormTab } from '@ui/forms'
import { Box } from '@ui/structure'
import { useElementLoadStore, useModelStore, useStructuralPlanningStore } from '@editorStores'
import {
  useAllDomains,
  useDomainVisibility,
  useModelClickListeners,
  useResultsInvalidation,
} from '@editorHooks'
import { getElementLoads } from '@queries'
import { saveLoadsOnElement } from '@mutations'
import { buildErrorMessage } from 'src/constants/errors'
import { useStructuralPlanningQueryParams } from '../../hooks'
import AreaLoadFieldArray from './AreaLoadFieldArray'
import AreaLoadFields from './AreaLoadFields'
import LineLoadFields from './LineLoadFields'
import LoadFieldArray from './LoadFieldArray'
import PointLoadFields from './PointLoadFields'
import { createLoadsSchema } from './schema'

const LoadList = (): ReactElement => {
  const queryClient = useQueryClient()

  const invalidateResults = useResultsInvalidation()

  const { projectId }: { projectId?: string } = useParams()
  const { enqueueSnackbar } = useSnackbar()

  const {
    params: { load: activeLoadGuid, loadType, selectedElements, drawAreaLoadElement },
    actions: { resetElements, selectLoad: setLoad, setElements, toggleLoadTypeMode },
  } = useStructuralPlanningQueryParams()

  const loads = useElementLoadStore(state => state.loads)

  const domains = useModelStore(state => state.domains)

  const hiddenLoads = useStructuralPlanningStore(state => state.hiddenLoads)
  const setHiddenLoads = useStructuralPlanningStore(state => state.setHiddenLoads)

  const rips = useModelStore(state => state.model.rips)
  const lintels = useModelStore(state => state.model.lintels)

  const loadsOfElement = useMemo(
    () => (selectedElements?.[0] ? filter(loads, { element_guid: selectedElements?.[0] }) : []),
    [loads, selectedElements],
  )

  const allDomains = useAllDomains()

  const groupedLoads = useMemo(() => {
    return loadsOfElement.reduce(
      (acc: Record<LoadType, ElementLoad[]>, load) => {
        const domain = find(allDomains, { guid: load.domain_guid })

        const loadWithDomain = {
          ...load,
          domain,
        }

        return {
          ...acc,
          [load.load_type]: [...acc[load.load_type as LoadType], loadWithDomain],
        }
      },
      {
        'line-load': [],
        'point-load': [],
        'area-load': [],
      },
    )
  }, [loadType, rips, lintels, loadsOfElement])

  const activeLoad = useMemo(() => {
    return find(groupedLoads[loadType as LoadType], ['guid', activeLoadGuid]) as ElementLoad
  }, [activeLoadGuid, groupedLoads, loadType])

  useDomainVisibility([activeLoad?.domain_guid])

  const { mutate: saveLoads, isLoading: isSaving } = useMutation(
    (loads: ElementLoad[]) => saveLoadsOnElement.request(projectId, selectedElements?.[0], loads),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getElementLoads.getKey(projectId))
        invalidateResults(projectId as string)
        enqueueSnackbar('Lasten erfolgreich gespeichert', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim speichern der Lasten'), {
          variant: 'error',
        })
      },
    },
  )

  const toggleShowSingleLoad = useCallback(
    (load: string) => {
      if (!loads) return

      const allLoadGuids = loads.map(el => el.guid)

      if (!hiddenLoads.includes(load) && hiddenLoads.length === allLoadGuids.length - 1) {
        setHiddenLoads([])
        return
      }

      setHiddenLoads(reject(allLoadGuids, guid => load === guid))
    },
    [loads, hiddenLoads],
  )

  // EVENTS

  useModelClickListeners(event => setElements([event.object.name]), [], !drawAreaLoadElement)

  // EFFECTS

  useEffect(() => resetElements(), [])

  useEffect(() => () => setHiddenLoads([]), [])

  const LoadArray = ({
    onAdd,
    onRemove,
    onCopy,
    loads,
  }: {
    onAdd: () => void
    onRemove: (guid: string) => void
    onCopy: (guid: string) => void
    loads: ElementLoad[]
  }) => (
    <Box>
      <Box>
        {loads.map((load, index) => (
          <FormAccordion
            fields={`${loadType}[${index}]`}
            // @ts-ignore
            key={load.id}
            expanded={load.guid === activeLoadGuid}
            onChange={(_, expanded) => {
              if (expanded) setLoad(load)
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} id={load.guid}>
              <Box display="flex" justifyContent="space-between" flexGrow="1">
                <Stack direction="row" spacing={1}>
                  <Typography variant="body2" data-cy={`load-${index}-title`}>
                    {load.load_type === 'point-load' &&
                      getPointLoadTitle(load as PointLoadWithDomain, index)}
                    {load.load_type === 'line-load' &&
                      getLineLoadTitle(load as LineLoadWithDomain, index)}
                    {load.load_type === 'area-load' && getAreaLoadTitle(load, index)}
                  </Typography>
                  {load.isLocal && <SyncProblem fontSize="small" color="action" />}
                </Stack>
                <Stack direction="row">
                  <VisibilityToggle
                    visible={!hiddenLoads.includes(load.guid)}
                    onClick={(visible, event) => {
                      event.stopPropagation()
                      if (hiddenLoads.includes(load.guid))
                        setHiddenLoads(reject(hiddenLoads, guid => guid === load.guid))
                      else setHiddenLoads([...hiddenLoads, load.guid])
                    }}
                    onRightClick={() => toggleShowSingleLoad(load.guid)}
                    size="small"
                    sx={{ padding: 0.5, marginY: -0.5 }}
                  />
                  <IconButton
                    sx={{ padding: 0.5, marginY: -0.5 }}
                    onClick={event => {
                      event.stopPropagation()
                      onCopy(load.guid)
                    }}
                  >
                    <ContentCopy fontSize="small" />
                  </IconButton>
                  <IconButton
                    sx={{ padding: 0.5, marginY: -0.5 }}
                    onClick={event => {
                      event.stopPropagation()
                      onRemove(load.guid)
                    }}
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                </Stack>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              {load.load_type === 'point-load' && (
                <PointLoadFields
                  selectedElement={load.element_guid}
                  index={index}
                  active={load.guid === activeLoadGuid}
                />
              )}
              {load.load_type === 'line-load' && (
                <LineLoadFields
                  selectedElement={load.element_guid}
                  index={index}
                  active={load.guid === activeLoadGuid}
                />
              )}
              {load.load_type === 'area-load' && (
                <AreaLoadFields
                  selectedElement={load.element_guid}
                  index={index}
                  active={load.guid === activeLoadGuid}
                />
              )}
            </AccordionDetails>
          </FormAccordion>
        ))}
      </Box>
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button variant="outlined" onClick={() => resetElements()}>
          Auswahl aufheben
        </Button>
      </Box>
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Box mr={1}>
          <AddButton variant="outlined" onClick={onAdd} data-cy="btn-add-load">
            Belastung hinzufügen
          </AddButton>
        </Box>

        <LoadingButton
          data-cy="btn-save"
          type="submit"
          loading={isSaving}
          color="primary"
          variant="contained"
        >
          speichern
        </LoadingButton>
      </Box>
    </Box>
  )

  if (!selectedElements?.[0])
    return (
      <Box component="div" p={1} border={1} borderColor="grey.200" borderRadius={1}>
        <Typography mb={1} data-cy="choose-element-hint">
          Wähle ein Element an um Lasten anzuzeigen und hinzuzufügen
        </Typography>
      </Box>
    )

  return (
    <>
      <Form
        key={`${selectedElements[0]}-${loads.length}`}
        defaultValues={groupedLoads}
        validationSchema={createLoadsSchema()}
        validationContext={domains}
        onSubmit={loads =>
          saveLoads([...loads['point-load'], ...loads['line-load'], ...loads['area-load']])
        }
      >
        <TabContext value={loadType || 'point-load'}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              '& .MuiTab-root': {
                fontSize: 12,
                paddingX: ({ spacing }) => spacing(1.5),
              },
            }}
          >
            <TabList onChange={(_, value) => toggleLoadTypeMode(value)}>
              <FormTab
                data-cy="tab-point-load"
                label="Punktlasten"
                value="point-load"
                fields="point-load"
              />
              <FormTab
                data-cy="tab-line-load"
                label="Linienlasten"
                value="line-load"
                fields="line-load"
              />
              <FormTab
                data-cy="tab-area-load"
                label="Flächenlasten"
                value="area-load"
                fields="area-load"
              />
            </TabList>
          </Box>
          <TabPanel value="line-load">
            <Box marginY={-1} marginX={-2}>
              {loadType === 'line-load' && (
                <LoadFieldArray key={isSaving.toString()}>{LoadArray}</LoadFieldArray>
              )}
            </Box>
          </TabPanel>
          <TabPanel value="point-load">
            <Box marginY={-1} marginX={-2}>
              {loadType === 'point-load' && (
                <LoadFieldArray key={isSaving.toString()}>{LoadArray}</LoadFieldArray>
              )}
            </Box>
          </TabPanel>
          <TabPanel value="area-load">
            <Box marginY={-1} marginX={-2}>
              {loadType === 'area-load' && (
                <AreaLoadFieldArray key={isSaving.toString()}>{LoadArray}</AreaLoadFieldArray>
              )}
            </Box>
          </TabPanel>
        </TabContext>
      </Form>
    </>
  )
}

export default LoadList

export const defaultElementCSValues = (type: ElementCSType) => {
  return {
    materialType: 'softwoodMaterial',
    guid: '',
    cross_section: {
      shape: {
        height: 0.0,
        width: 0.0,
      },
      material: {
        kind: '',
        identifier: '',
      },
    },
    kind: type,
    usage_class: 'One',
  }
}

import { object, mixed, string, number, array } from 'yup'
import { windDirectionAssignments } from '../OuterWallAssignment/constants'
import { windDirections } from '../RoofAssignment/constants'

const forceSchema = object({
  x: number().default(0),
  y: number().default(0),
  z: number().default(0),
})

const createOuterWallAssignmentSchema = (message: string) =>
  array().of(string()).required().min(1, message).default([])

const createWindloadCeilingSchema = (slabGuid: string) =>
  object({
    slab_guid: string().default(slabGuid),
    force: forceSchema,
    point_of_attack: forceSchema,
    comment: string().default(''),
  })

const windloadRoofSchema = object({
  force: forceSchema,
  point_of_attack: forceSchema,
  comment: string().default(''),
})

export const createWindLoadsSchema = () => {
  const roofDirectionAssignmentSchema = object({
    shapes: array().default([]),
    configurations: array().min(1, 'Bitte weisen sie mindestens eine Dachfläche zu').default([]),
  })

  return object({
    dimensions: object({
      height: number().required().moreThan(0).default(0),
      depth: number().required().moreThan(0).default(0),
      width: number().required().moreThan(0).default(0),
    }),
    baseParameters: object({
      wind_zone: mixed().oneOf([1, 2, 3, 4]).default(1),
      wind_area_type: string().nullable().default('Inland'),
      wind_speed: number().required().moreThan(0).default(0),
      wind_pressure: number().required().moreThan(0).default(0),
      terrain_category: mixed().oneOf(['I', 'II', 'III', 'IV']).default('I'),
    }),
    outerWallAssignment: object({
      ...windDirectionAssignments.reduce<{
        [key: string]: any // eslint-disable-line
      }>((acc, { value, label }) => {
        acc[value] = createOuterWallAssignmentSchema(
          `Bitte weisen Sie ${label} mindestens eine Außenwand zu`,
        )

        return acc
      }, {}),
      wind_direction: string().default('Direction0'),
    }),
    roofAssignment: object({
      ...windDirections.reduce((acc, direction) => {
        acc = {
          ...acc,
          [direction]: roofDirectionAssignmentSchema,
        }

        return acc
      }, {}),
    }),
  })
}

export const createManualWindLoadsSchema = (slabs: string[]) => {
  return object({
    results: object({
      wind_loads_per_ceiling: object({
        ...windDirections.reduce((acc, direction) => {
          acc = {
            ...acc,
            [direction]: array()
              .of(createWindloadCeilingSchema('guid'))
              .default(slabs.map(slab => createWindloadCeilingSchema(slab).getDefault())),
          }

          return acc
        }, {}),
      }),
      wind_loads_roof: object({
        ...windDirections.reduce((acc, direction) => {
          acc = {
            ...acc,
            [direction]: array().of(windloadRoofSchema).default([windloadRoofSchema.getDefault()]),
          }

          return acc
        }, {}),
      }),
    }),
  })
}

import React, { ReactElement } from 'react'
import { cloneDeep } from 'lodash-es'
import { Button, Grid, MenuItem, Stack } from '@mui/material'
import { Form, TextField, UnitConvertedField } from '@ui/forms'
import { useElementSelectionStore } from '@editorStores'
import { snowLoadConfigurationSchema } from '../../schema'
import Assignments from '../Assignments'
import { loadCaseCategories } from './constants'

interface Props {
  addConfigurations: (config: SnowLoadConfiguration[]) => void
  removeConfigurations: (loadCase: LoadCase, distributedLoad: number) => void
  configurations: SnowLoadConfiguration[]
}

const SNOW_LOADS_ASSIGNMENT_FORM = 'snow-loads-assignment'

const AssignmentForm = ({
  addConfigurations,
  configurations,
  removeConfigurations,
}: Props): ReactElement => {
  const selectedStandAloneIds = useElementSelectionStore(state => state.selectedStandAloneIds)
  const hasSelection = !!selectedStandAloneIds.size

  const deselectAllIds = useElementSelectionStore(state => state.deselectAllIds)

  return (
    <Form
      key={SNOW_LOADS_ASSIGNMENT_FORM}
      id={SNOW_LOADS_ASSIGNMENT_FORM}
      onSubmit={data => {
        const configs = [...selectedStandAloneIds].map(id => ({
          element_guid: id,
          load_per_area: data.load_per_area,
          load_case: data.load_case,
        }))
        addConfigurations(cloneDeep(configs))
        deselectAllIds()
      }}
      validationSchema={snowLoadConfigurationSchema}
      defaultValues={snowLoadConfigurationSchema.getDefault()}
      data-cy="snow-loads-form"
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            select
            disabled={!hasSelection}
            label="Lastfall"
            name="load_case.category"
            size="small"
            fullWidth
            sx={{ bgcolor: 'grey.50' }}
            InputProps={{
              type: 'hidden',
            }}
          >
            {loadCaseCategories.map(({ label, value }, index) => (
              <MenuItem value={value} key={index}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={6}>
          <UnitConvertedField
            label="Flächenlast"
            name="load_per_area"
            type="number"
            disabled={!hasSelection}
            unitLabel="kN/m²"
            size="small"
            data-cy="load-per-area-input"
          />
        </Grid>
      </Grid>

      <Stack direction="row" spacing={1} mt={2}>
        <Button
          type="submit"
          disabled={!selectedStandAloneIds.size}
          size="small"
          variant="contained"
          fullWidth
          data-cy="assign"
        >
          Zuweisen
        </Button>

        <Button
          onClick={deselectAllIds}
          disabled={!selectedStandAloneIds.size}
          size="small"
          variant="outlined"
          fullWidth
        >
          Auswahl aufheben
        </Button>
      </Stack>

      <Assignments configurations={configurations} removeConfigurations={removeConfigurations} />
    </Form>
  )
}

export default AssignmentForm

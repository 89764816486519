import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { isEmpty } from 'lodash-es'

// Bug with react router: return of params is empty object on component unmount
// leads to incorrectly triggered queries because e.g. projectId changes to undefined
// use with params that stay the same during component render cycle
const usePersistentParams = () => {
  const [stateParams, setStateParams] = useState({})
  const params = useParams()

  useEffect(() => {
    if (!isEmpty(stateParams)) return

    setStateParams(params)
  }, [params])

  return isEmpty(stateParams) ? params : stateParams
}

export default usePersistentParams

import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'

/**
 * This method will sort the provided points counter-clockwise. Only x and y
 * coordinates are used
 */
const sortPointsCounterClockwise = (points: ImmutableVector3[]) => {
  const center = { x: 0, y: 0, z: 0 }
  for (let i = 0; i < points.length; i++) {
    center.x += points[i].x
    center.y += points[i].y
  }
  center.x /= points.length
  center.y /= points.length
  const sortedPoints = [...points].sort(function (a, b) {
    const angleA = Math.atan2(a.y - center.y, a.x - center.x)
    const angleB = Math.atan2(b.y - center.y, b.x - center.x)
    return angleA - angleB
  })
  return sortedPoints
}

export default sortPointsCounterClockwise

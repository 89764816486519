import * as d3 from 'd3'
import formworkHorizontal from '@img/scattered_formwork_horizontal.svg'
import formworkVertical from '@img/scattered_formwork_vertical.svg'
import { STROKE_WIDTH_FACTOR } from '../constants'

export const plotPlateLayer = (
  ref: React.MutableRefObject<SVGSVGElement | null>,
  x: number,
  y: number,
  length: number,
  thickness: number,
  orientation: LayerOrientation,
): void => {
  const strokeWidth = length / STROKE_WIDTH_FACTOR
  let width
  let height
  if (orientation === 'Horizontal') {
    width = length
    height = thickness
  } else {
    width = thickness
    height = length
  }

  d3.select(ref.current)
    .append('rect')
    .attr('x', x)
    .attr('y', y)
    .attr('width', width)
    .attr('height', height)
    .attr('fill', '#d9d9d9')
    .attr('stroke', 'black')
    .attr('stroke-width', strokeWidth)
}

export const plotScatteredFormwork = (
  ref: React.MutableRefObject<SVGSVGElement | null>,
  x: number,
  y: number,
  length: number,
  thickness: number,
  color: string | undefined,
  orientation: LayerOrientation,
): void => {
  const strokeWidth = length / STROKE_WIDTH_FACTOR

  if (orientation === 'Horizontal') {
    const width = length
    const height = thickness
    d3.select(ref.current)
      .append('image')
      .attr('href', formworkHorizontal)
      .attr('x', x)
      .attr('y', y)
      .attr('width', width)
      .attr('height', height)
      .attr('preserveAspectRatio', 'none')
    d3.select(ref.current)
      .append('rect')
      .attr('x', x)
      .attr('y', y)
      .attr('width', width)
      .attr('height', height)
      .attr('fill', color || 'none')
      .attr('stroke', 'black')
      .attr('stroke-width', strokeWidth)
  } else {
    const width = thickness
    const height = length
    d3.select(ref.current)
      .append('image')
      .attr('href', formworkVertical)
      .attr('x', x)
      .attr('y', y)
      .attr('width', width)
      .attr('height', height)
      .attr('preserveAspectRatio', 'none')
    d3.select(ref.current)
      .append('rect')
      .attr('x', x)
      .attr('y', y)
      .attr('width', width)
      .attr('height', height)
      .attr('fill', color || 'none')
      .attr('stroke', 'black')
      .attr('stroke-width', strokeWidth)
  }
}

import React from 'react'
import { Button, Stack, Typography } from '@mui/material'

const ErrorFallBackUI = ({ resetError }: { resetError: () => void }) => {
  return (
    <Stack spacing={2} padding={4}>
      <Typography variant="h3">Sie haben einen Fehler gefunden</Typography>
      <Typography variant="h5">
        Es tut uns leid, dass auf unserer Seite ein Problem aufgetreten ist. Wir beheben es
      </Typography>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        onClick={() => {
          resetError()
          window.location.reload()
        }}
      >
        zurücksetzen
      </Button>
    </Stack>
  )
}

export default ErrorFallBackUI

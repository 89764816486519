import { useMemo } from 'react'
import { reduce } from 'lodash-es'
import { useResultsStore } from '@editorStores'

const mapRepresentativeFors = (bundles: MemberPositionBundle[]) => {
  return reduce(
    bundles,
    (collector, bundle) => {
      const mapped = reduce(
        bundle.representative_for,
        (representativeCollector, guid) => ({
          ...representativeCollector,
          [guid]: bundle.representative_position,
        }),
        {} as Record<string, string>,
      )

      return { ...collector, ...mapped }
    },
    {} as Record<string, string>,
  )
}

const useIdsOfGroup = () => {
  const beamsPositionGrouping = useResultsStore(state => state.beamsPositionGrouping)
  const columnsPositionGrouping = useResultsStore(state => state.columnsPositionGrouping)
  const purlinsPositionGrouping = useResultsStore(state => state.purlinsPositionGrouping)
  const wallRipsPositionGrouping = useResultsStore(state => state.wallRipsPositionGrouping)
  const wallLintelsPositionGrouping = useResultsStore(state => state.wallLintelsPositionGrouping)
  const slabBeamsPositionGrouping = useResultsStore(state => state.slabBeamsPositionGrouping)
  const roofSlabBeamsPositionGrouping = useResultsStore(
    state => state.roofSlabBeamsPositionGrouping,
  )

  const beamIdsPartOfGroup = useMemo(
    () => beamsPositionGrouping && mapRepresentativeFors(beamsPositionGrouping),
    [beamsPositionGrouping],
  )
  const columnIdsPartOfGroup = useMemo(
    () => columnsPositionGrouping && mapRepresentativeFors(columnsPositionGrouping),
    [columnsPositionGrouping],
  )
  const purlinIdsPartOfGroup = useMemo(
    () => purlinsPositionGrouping && mapRepresentativeFors(purlinsPositionGrouping),
    [purlinsPositionGrouping],
  )
  const wallRipIdsPartOfGroup = useMemo(
    () => wallRipsPositionGrouping && mapRepresentativeFors(wallRipsPositionGrouping),
    [wallRipsPositionGrouping],
  )
  const wallLintelIdsPartOfGroup = useMemo(
    () => wallLintelsPositionGrouping && mapRepresentativeFors(wallLintelsPositionGrouping),
    [wallLintelsPositionGrouping],
  )
  const slabBeamIdsPartOfGroup = useMemo(
    () => slabBeamsPositionGrouping && mapRepresentativeFors(slabBeamsPositionGrouping),
    [slabBeamsPositionGrouping],
  )
  const roofSlabBeamIdsPartOfGroup = useMemo(
    () => roofSlabBeamsPositionGrouping && mapRepresentativeFors(roofSlabBeamsPositionGrouping),
    [roofSlabBeamsPositionGrouping],
  )

  const idsOfGroup = useMemo(
    () => ({
      ...beamIdsPartOfGroup,
      ...columnIdsPartOfGroup,
      ...purlinIdsPartOfGroup,
      ...wallRipIdsPartOfGroup,
      ...wallLintelIdsPartOfGroup,
      ...slabBeamIdsPartOfGroup,
      ...roofSlabBeamIdsPartOfGroup,
    }),
    [
      beamIdsPartOfGroup,
      columnIdsPartOfGroup,
      purlinIdsPartOfGroup,
      wallRipIdsPartOfGroup,
      wallLintelIdsPartOfGroup,
      slabBeamIdsPartOfGroup,
      roofSlabBeamIdsPartOfGroup,
    ],
  )

  return idsOfGroup
}

export default useIdsOfGroup

import React, { ReactElement } from 'react'
import produce from 'immer'
import { Stack } from '@mui/material'
import { Select, TextField } from '@ui/forms'
import { useModelStore } from '@editorStores'
import { useStoreSubscription } from '@editorHooks'

interface Props {
  wall: ShapeObject
  isEditable: boolean
  isPlacementEditable: boolean
}

const FormFields = ({ wall, isEditable, isPlacementEditable }: Props): ReactElement => {
  const updateWall = useModelStore(state => state.updateWall)

  useStoreSubscription({
    writeCallback: ({ xStart, xEnd, yStart, yEnd, placement }) => {
      const newWall = produce(wall => {
        wall.shape.points[0].x = xStart
        wall.shape.points[0].y = yStart
        wall.shape.points[1].x = xEnd
        wall.shape.points[1].y = yEnd
        wall.shape.points[2].x = xEnd
        wall.shape.points[2].y = yEnd
        wall.shape.points[3].x = xStart
        wall.shape.points[3].y = yStart
        wall.placement = placement
      }, wall)()

      updateWall(newWall)
    },
    compareValues: true,
  })

  return (
    <Stack
      spacing={1.25}
      border={1}
      p={1}
      pt={1.5}
      pb={1.25}
      borderColor="grey.200"
      borderRadius={1}
      sx={{ '& .MuiFormControlLabel-label': { ml: 0.5 } }}
    >
      <Stack direction="row" spacing={1} alignItems="center">
        <TextField
          label="X Start"
          type="number"
          size="small"
          sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
          name="xStart"
          unit="m"
          disabled={!isEditable}
          data-cy="x-start-input"
        />

        <TextField
          label="Y Start"
          type="number"
          size="small"
          sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
          name="yStart"
          unit="m"
          disabled={!isEditable}
          data-cy="y-start-input"
        />
      </Stack>

      <Stack direction="row" spacing={1} alignItems="center">
        <TextField
          label="X Ende"
          type="number"
          size="small"
          sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
          name="xEnd"
          unit="m"
          disabled={!isEditable}
          data-cy="x-end-input"
        />

        <TextField
          label="Y Ende"
          type="number"
          size="small"
          sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
          name="yEnd"
          unit="m"
          disabled={!isEditable}
          data-cy="y-end-input"
        />
      </Stack>

      <Select
        label="Platzierung auswählen"
        name="placement"
        options={[
          {
            value: 'External',
            label: 'Extern',
          },
          {
            value: 'Internal',
            label: 'Intern',
          },
        ]}
        disabled={!isPlacementEditable}
      />
    </Stack>
  )
}

export default FormFields

import React, { ReactElement } from 'react'
import { Typography } from '@mui/material'
import { Box } from '@ui/structure'

interface Props {
  main?: string
  crumbs?: string[]
  extension?: boolean
}

const NavBreadcrumbs = ({ main, crumbs = [], extension = false }: Props): ReactElement => {
  return (
    <Box display="flex">
      {!extension && <Typography sx={{ color: 'white', fontWeight: 300 }}>{main}</Typography>}
      {crumbs.map(name => (
        <Typography key={`nav-crumbs-${name}`} sx={{ color: 'white' }}>
          &nbsp;/&nbsp;{name}
        </Typography>
      ))}
    </Box>
  )
}

export default NavBreadcrumbs

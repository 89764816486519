import React, { memo } from 'react'
import { useFormContext } from 'react-hook-form'
import { Grid } from '@mui/material'
import { TextField, UnitConvertedField } from '@ui/forms'
import { useElementLoadStore } from '@editorStores'
import { useStoreSubscription } from '@editorHooks'
import LoadCaseSelect from '../LoadCaseSelect'

interface Props {
  selectedElement?: string
  index: number
  active: boolean
}

const AreaLoadFields = ({ selectedElement, index, active }: Props) => {
  const { watch, setValue } = useFormContext()
  const disabled = !selectedElement
  const fieldName = `area-load[${index}]`
  const editLoadsPerElement = useElementLoadStore(state => state.editLoadsPerElement)

  useStoreSubscription({
    writeCallback: editLoadsPerElement,
    fieldName,
    compareValues: true,
    active,
  })

  const category: LoadCategory = watch(`${fieldName}.load_case.category`)
  const loadCaseType: LoadCaseTypes = watch(`${fieldName}.load_case.load_case_type`)

  return (
    <Grid container spacing={2}>
      <Grid item md={6}>
        <UnitConvertedField
          label="Kraft"
          type="number"
          name={`${fieldName}.force.z`}
          disabled={disabled}
          unitLabel="kN"
        />
      </Grid>
      <Grid item md={6}>
        <LoadCaseSelect
          onSelect={({ identifier, category, loadCaseType }) => {
            setValue(`${fieldName}.load_case.identifier`, identifier)
            setValue(`${fieldName}.load_case.category`, category)
            setValue(`${fieldName}.load_case.load_case_type`, loadCaseType)
          }}
          value={`${category}+${loadCaseType}`}
          disabled={disabled}
        />
      </Grid>
      <Grid item md={12}>
        <TextField
          label="Kommentar"
          placeholder="Kommentar"
          rows={2}
          name={`${fieldName}.comment`}
          multiline
          disabled={disabled}
        />
      </Grid>
    </Grid>
  )
}

export default memo(AreaLoadFields)

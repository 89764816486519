import { isUndefined } from 'lodash-es'
import { v4 as uuid } from 'uuid'

export const augmentProposal = (data: StiffeningSegment[]) => {
  if (!data) return []

  return data.map(interval => ({
    ...interval,
    localId: uuid(),
    selectable: isUndefined(interval.selectable) ? 'Selectable' : interval.selectable,
  }))
}

import { positionToDomain } from '@editorUtils'
import { getTopDomains, globalToLocalForce } from '@scene'
import { filter, find } from 'lodash-es'
import { v4 as uuid } from 'uuid'
import { createLineLoadSchema, createPointLoadSchema } from './schema'

export const topDomainOptions = (domains: Domain[]) => {
  const topDomains = domains.length === 1 ? domains : getTopDomains(domains) || []

  return topDomains.map(({ guid, end, start }, index) => ({
    label: `Topdomain ${index + 1}`,
    value: guid,
    length: end.sub(start).length(),
  }))
}

export const toKiloNewton = (value: number) => value / 1000

export const getDefaultForce = (domain?: Domain) =>
  domain
    ? globalToLocalForce(
        {
          x: 0,
          y: 0,
          z: -1000,
        },
        domain,
      )
    : {
        x: 0,
        y: 0,
        z: -1000,
      }

export const toAbsPosition = (value: number, domainLength: number) =>
  (value * domainLength).toFixed(2)

export const createLoad = ({
  loadType,
  domains,
  positions,
  element_guid,
}: {
  loadType: 'point-load' | 'line-load'
  domains: Domain[]
  positions: Position[]
  element_guid: string
}) => {
  const elementDomains = filter(domains, ['element_guid', element_guid])
  const topDomains = getTopDomains(elementDomains)
  const position = find(positions, { position_guid: element_guid })

  let domain: Domain | undefined
  if (position) {
    domain = positionToDomain(position)
  } else if (elementDomains.length) {
    if (elementDomains.length === 1) {
      domain = elementDomains[0]
    } else {
      domain = topDomains[0]
    }
  }

  if (!domain) throw new Error('Domain not found for element')

  const defaultValues = (loadType === 'point-load' ? createPointLoadSchema : createLineLoadSchema)(
    domain,
  ).getDefault()

  return {
    ...defaultValues,
    domain_guid: domain.guid,
    domain: domain,
    element_guid,
    guid: uuid(),
  }
}

import React, { ReactElement, useMemo } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { useElementHistory } from '@hooks'
import { useSnackbar } from 'notistack'
import { TypeOf } from 'yup'
import { Button, Stack } from '@mui/material'
import { SaveButton } from '@ui/actions'
import { Form } from '@ui/forms'
import { useEditElementStore, useModelStore } from '@editorStores'
import { useResultsInvalidation } from '@editorHooks'
import { getModel } from '@queries'
import { updateFoundation as updateFoundationQuery } from '@mutations'
import FormFields from '../../../RoofDrawing/components/RoofForm/components/FormFields'
import { foundationSchema } from './schema'

interface Props {
  selectedElement: string
}

const FoundationForm = ({ selectedElement }: Props): ReactElement => {
  const { projectId } = useParams()

  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const invalidateResults = useResultsInvalidation()

  const foundation = useModelStore(state => state.model.foundation)
  const updateFoundation = useModelStore(state => state.updateFoundation)
  const setActiveElement = useEditElementStore(state => state.setActiveElement)

  const defaultValues = useMemo(() => {
    return {
      ...foundationSchema.getDefault(),
      guid: foundation.guid,
      points: foundation.shape.points.map(p => ({ x: p.x, y: p.y, z: p.z })),
    }
  }, [foundation])

  // HISTORY

  const { setStale } = useElementHistory({
    element: foundation,
    getCurrentElement: () => {
      const foundation = useModelStore.getState().model.foundation
      return foundation
    },
    removeElement: () => {
      // do nothing
    },
    resetElement: slab => updateFoundation(slab),
    dependencies: [selectedElement],
  })

  // MUTATIONS

  const { mutateAsync, isLoading } = useMutation(
    (data: TypeOf<typeof foundationSchema>) =>
      updateFoundationQuery.request(projectId as string, data.points),
    {
      onSuccess: async () => {
        invalidateResults(projectId as string)

        setStale(foundation)
        queryClient.invalidateQueries(getModel.getKey(projectId))

        enqueueSnackbar('Fundament erfolgreich gespeichert', { variant: 'success' })
      },
      onError: () => {
        enqueueSnackbar('Fehler beim Speichern des Fundaments', { variant: 'error' })
      },
    },
  )

  if (!foundation) {
    return <></>
  }

  return (
    <>
      <Form
        key={selectedElement}
        onSubmit={data => mutateAsync(data)}
        defaultValues={defaultValues}
        validationSchema={foundationSchema}
        enableReinitialize
        data-cy="foundation-geometry-form"
      >
        {selectedElement && (
          <FormFields
            selectedElement={selectedElement}
            slabs={[foundation]}
            updateSlab={updateFoundation}
          />
        )}
        <Stack direction="row" justifyContent="end" spacing={1} mt={1}>
          <Button variant="outlined" onClick={() => setActiveElement(null)}>
            Auswahl aufheben
          </Button>
        </Stack>
        <Stack direction="row" justifyContent="end" spacing={1} mt={1}>
          <SaveButton
            type="submit"
            loading={isLoading}
            fullWidth
            disabled={false}
            data-cy="btn-submit"
          >
            Speichern
          </SaveButton>
        </Stack>
      </Form>
    </>
  )
}

export default FoundationForm

import { postRequest } from 'src/state/utils'

const submitApproval = {
  getKey: (projectId: string) => ['submitApproval', projectId],
  request: async (id: string, data: ApprovalSchema): Promise<ApprovalSchema> => {
    const { data: result } = await postRequest<ApprovalSchema>({
      url: `/approval/${id}/submitApproval`,
      data,
    })
    return result as ApprovalSchema
  },
}

const submitDisapproval = {
  getKey: (projectId: string) => ['submitDisapproval', projectId],
  request: async (id: string, data: DisapprovalSchema): Promise<DisapprovalSchema> => {
    const { data: result } = await postRequest<DisapprovalSchema>({
      url: `/approval/${id}/submitDisapproval`,
      data,
    })
    return result as DisapprovalSchema
  },
}

export { submitApproval, submitDisapproval }

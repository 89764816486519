import { object, number, array, string } from 'yup'

const schemaSupportConfig = object({
  width: number().test('is-converted-integer', 'value is not integer', value => {
    if (value === undefined) return false
    return Number.isInteger(value * 1000)
  }),

  length: number().test('is-converted-integer', 'value is not integer', value => {
    if (value === undefined) return false
    return Number.isInteger(value * 1000)
  }),
  k_c_90: number().oneOf([1.0, 1.25, 1.5, 1.75]).required(),
})

const schemaCrossSection = object({
  element_cs: object({
    material: object({ identifier: string() }),
    shape: object({
      width: number().test('is-converted-integer', 'value is not integer', value => {
        if (value === undefined) return false
        return Number.isInteger(value * 1000)
      }),
      height: number().test('is-converted-integer', 'value is not integer', value => {
        if (value === undefined) return false
        return Number.isInteger(value * 1000)
      }),
    }),
  }),
})

export const schemaFormBundles = object({
  formBundles: array().of(
    object({
      supportConfig: schemaSupportConfig,
      targetCrossSection: schemaCrossSection.nullable().default(null),
    }),
  ),
})

import { useMemo } from 'react'
import { roofStoreyKey, useModelStore } from '@editorStores'

interface SlabElevation {
  lower: number
  upper: number
}

/**
 *
 * @param storey
 * @returns The upper/lower boundaries of provided storey. In case provided
 * storey is roof storey this will return undefined
 */
const useSlabElevation = (storey: string): SlabElevation | undefined => {
  const model = useModelStore(state => state.model)

  const result = useMemo(() => {
    if (storey === roofStoreyKey) {
      return
    }

    const [lower, upper] = model.storey_boundaries[storey]

    return {
      lower,
      upper,
    }
  }, [model, storey])

  return result as SlabElevation
}

export default useSlabElevation

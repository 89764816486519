import React, { ReactElement, ReactNode } from 'react'
import { useFieldArray, useFormContext, UseFieldArrayReturn } from 'react-hook-form'

interface Methods extends Omit<UseFieldArrayReturn, 'fields'> {
  fields: any // eslint-disable-line
}

interface Props {
  name: string
  children: (methods: Methods) => ReactNode
}

const FieldArray = ({ name, children }: Props): ReactElement => {
  const { control } = useFormContext()
  const methods = useFieldArray({
    name,
    control,
  })

  return <>{children(methods)}</>
}

export default FieldArray

import React from 'react'
import { useTheme } from '@mui/material'
import { Html } from '@react-three/drei'
import { Vector3 } from '@react-three/fiber'

interface Props {
  label: string
  position: Vector3
  style?: Record<string, string>
  highlighted?: boolean
  onClick?: () => void
  visible?: boolean
}

const Label = ({ label, position, style, onClick, highlighted = false, visible = true }: Props) => {
  const theme = useTheme()

  if (!visible) return <></>

  return (
    <Html
      position={position}
      // zIndex is not really working on Html elements, hence this is a little
      // hack https://github.com/pmndrs/drei/issues/702 for this to work the
      // scene must be moved though. As it will only recalculate once moved
      zIndexRange={
        highlighted ? [Number.MAX_SAFE_INTEGER - 10, Number.MAX_SAFE_INTEGER] : undefined
      }
    >
      <div
        onClick={() => onClick && onClick()}
        style={{
          fontSize: 12,
          borderRadius: 2,
          padding: theme.spacing(0.5),
          color: theme.palette.grey[100],
          // @ts-ignore
          backgroundColor: theme.palette.primary.main,
          cursor: onClick ? 'pointer' : 'default',
          ...(highlighted
            ? {
                backgroundColor: theme.scenePalette.highlight,
                color: theme.palette.text.primary,
              }
            : {}),
          ...style,
        }}
      >
        {label}
      </div>
    </Html>
  )
}

export default Label

import React, { ReactElement, useEffect } from 'react'
import { AddingSlabsMesh } from '@scene'
import { find, max, toNumber } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { useModelStore } from '@editorStores'
import useStructuralPlanningQueryParams from '../../../hooks/useStructuralPlanningQueryParams'

const SlabSelection = (): ReactElement => {
  const {
    actions: { setStorey },
  } = useStructuralPlanningQueryParams()

  const model = useModelStore(state => state.model)

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  useEffect(() => {
    enqueueSnackbar('Wähle ein Dach- oder Deckenfläche zum Zeichnen in der Grundrissansicht.', {
      variant: 'info',
      key: 'select-slab-reference',
    })
    return () => {
      closeSnackbar('select-slab-reference')
    }
  }, [])

  const onSlabSelect = (guid: string) => {
    const maxStorey = max(
      Object.keys(model.storey_boundaries).map(s => toNumber(s)),
    )?.toString() as string

    const slab = find(model.slabs, { guid })

    // in case slab is not found a roof has been selected
    setStorey(slab ? slab.storey : maxStorey)
  }

  return (
    <AddingSlabsMesh
      data={[...model.slabs, ...model.roof_slabs]}
      onClick={({ object }) => onSlabSelect(object.userData.guid)}
    />
  )
}

export default SlabSelection

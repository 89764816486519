import { useCallback, useMemo } from 'react'
import { useLabelsStore } from '@editorStores'

const useElementLabel = () => {
  const elementLabels = useLabelsStore(state => state.elementLabels)

  const positionLabels = useLabelsStore(state => state.positionLabels)

  const elementGuidsToElementLabel = useMemo(
    () => Object.fromEntries(elementLabels.map(label => [label.element_guid, label.name])),
    [elementLabels],
  )

  const elementGuidsToPositionLabel = useMemo(
    () => Object.fromEntries(positionLabels.map(label => [label.element_guid, label.name])),
    [positionLabels],
  )

  const getElementLabel = useCallback(
    (element: string) => {
      return elementGuidsToElementLabel[element] || elementGuidsToPositionLabel[element] || element
    },
    [elementGuidsToElementLabel, elementGuidsToPositionLabel],
  )

  return getElementLabel
}

export default useElementLabel

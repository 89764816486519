import React, { ReactElement, useState } from 'react'
import { useFormContext, useFieldArray } from 'react-hook-form'
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormHelperText,
} from '@mui/material'
import { DragDropContext, Droppable, Draggable, DropResult } from '@hello-pangea/dnd'
import { SelectableButton } from '@ui/actions'
import { useFieldArrayErrors } from '@ui/forms/hooks'
import { Box } from '@ui/structure'
import LayerDialog from '../../LayerDialog'
import LayerListItem from '../LayerListItem'
import { layerOptionsCLT, layerOptionsTimberFrame } from './constants'

const LayerList = (): ReactElement => {
  const { watch, getValues, setValue } = useFormContext()
  const [openDialogType, setOpenDialogType] = useState<{
    type?: string
    data?: Layer
    onSubmit: (layer: Layer) => void
  } | null>(null)
  const error = useFieldArrayErrors('layers')

  const { append, remove, move } = useFieldArray({
    name: 'layers',
  })

  const watchKind = watch('kind')
  const values = getValues()
  const selectableOptions =
    watchKind === 'timber-frame-assembly' ? layerOptionsTimberFrame : layerOptionsCLT

  // fieldArray has no replace method
  const replaceLayerAtIndex = (index: number, layer: Layer) => {
    const layers = getValues().layers.map((existingLayer: Layer, layerIndex: number) =>
      layerIndex === index ? layer : existingLayer,
    )
    setValue('layers', layers)
  }

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) return

    move(result.source.index, result.destination.index)
  }

  const openNewLayerDialog = (type: string) => {
    setOpenDialogType({
      type,
      onSubmit: (layer: Layer) => {
        append(layer)
        setOpenDialogType(null)
      },
    })
  }

  const openEditLayerDialog = (index: number) => {
    const layer = getValues().layers[index]

    setOpenDialogType({
      type: layer.kind,
      data: layer,
      onSubmit: (layer: Layer) => {
        replaceLayerAtIndex(index, layer)
        setOpenDialogType(null)
      },
    })
  }

  const SelectButton = (
    <SelectableButton
      key={watchKind}
      options={selectableOptions}
      onClick={({ value }) => openNewLayerDialog(value)}
      buttonProps={{
        size: 'small',
        variant: 'outlined',
      }}
      executeOnSelect
      data-cy="layer-select"
    >
      erstellen
    </SelectableButton>
  )

  return (
    <>
      {!values.layers.length ? (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Typography>Sie haben noch keine Schichten erstellt</Typography>
          <Box
            mt={2}
            sx={({ palette }) => ({
              '& .MuiButton-root': {
                borderColor: palette.primary.light,
              },
            })}
          >
            {SelectButton}
          </Box>
          {error && <FormHelperText error>{error.message}</FormHelperText>}
        </Box>
      ) : (
        <>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="h6">Schichten</Typography>
            {SelectButton}
          </Box>
          <Box>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Box pl={2}>Art</Box>
                    </TableCell>
                    <TableCell>Produkt</TableCell>
                    <TableCell align="right">Dicke [mm]</TableCell>
                    <TableCell align="right">Dichte [kg/m3]</TableCell>
                    <TableCell align="right">Aktionen</TableCell>
                  </TableRow>
                </TableHead>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {provided => (
                      <TableBody
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        sx={{ width: '100%' }}
                      >
                        {values.layers.map((layer: Layer, index: number) => (
                          <Draggable key={index} draggableId={layer.kind + index} index={index}>
                            {(provided, snapshot) => (
                              <LayerListItem
                                layer={layer}
                                onEdit={() => openEditLayerDialog(index)}
                                onDelete={() => remove(index)}
                                provided={provided}
                                isDragging={snapshot.isDragging}
                              />
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </TableBody>
                    )}
                  </Droppable>
                </DragDropContext>
              </Table>
            </TableContainer>
            {error && <FormHelperText error>{error.message}</FormHelperText>}
          </Box>
        </>
      )}
      {openDialogType && (
        <LayerDialog
          type={openDialogType.type}
          data={openDialogType?.data}
          onClose={() => setOpenDialogType(null)}
          onSubmit={openDialogType.onSubmit}
        />
      )}
    </>
  )
}

export default LayerList

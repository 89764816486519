import React, { ReactElement, useState, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Grid, Divider, Switch, FormControlLabel } from '@mui/material'
import {
  SelectLoadable,
  Select,
  TextField,
  MillimeterField,
  FormTab,
  CentimeterField,
} from '@ui/forms'
import { Box } from '@ui/structure'
import { getSoftWoodMaterials, getGluelamMaterials } from '@queries'
import { remapMaterialIdentifier } from 'src/components/pages/Editor/components/SingleElementCSForm/components/FormFields'
import { materialTypeOptions } from '../constants'
import { tabConfig } from './constants'

const TimberFrameLayerFormFields = (): ReactElement => {
  const { watch, setValue, getValues } = useFormContext()
  const materialTypeStandardRipWatch = watch('materialTypeStandardRip')
  const materialTypeLintelWatch = watch('materialTypeLintel')
  const hasInsulation = watch('layer.hasInsulation')

  const [tab, setTab] = useState<keyof typeof tabConfig>('standard')
  const savedInsulation = useRef<ProductLayer | null>(null)

  const handleInsulationToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked
    setValue('layer.hasInsulation', checked)

    if (checked) {
      // Restore saved values when turning insulation on
      if (savedInsulation.current) {
        setValue('layer.insulation.name', savedInsulation.current.product.name)
        setValue('layer.insulation.density', savedInsulation.current.product.density)
        setValue('layer.insulation_thickness', savedInsulation.current.thickness)
      } else {
        // just assume something
        setValue('layer.insulation.name', 'Isolierung')
        setValue('layer.insulation.density', 1)
        setValue('layer.insulation_thickness', 1.0e-3)
      }
    } else if (!checked) {
      // Save current values before turning insulation off
      savedInsulation.current = {
        product: {
          name: getValues('layer.insulation.name'),
          density: getValues('layer.insulation.density'),
        },
        thickness: getValues('layer.insulation_thickness'),
        kind: 'timber-frame-layer',
      }

      // Clear form values
      setValue('layer.insulation', null)
      setValue('layer.insulation_thickness', null)
    }
  }

  return (
    <Box mt={-2}>
      <TabContext value={tab}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            '& .MuiTab-root': {
              fontSize: 12,
              paddingX: ({ spacing }) => spacing(1.5),
            },
          }}
        >
          <TabList onChange={(_, value) => setTab(value)}>
            <FormTab
              {...tabConfig.standard}
              fields="layer.crossSectionStandardRip"
              data-cy="tab-standard-rip"
            />
            <FormTab {...tabConfig.lintel} fields="layer.crossSectionLintel" data-cy="tab-lintel" />
          </TabList>
        </Box>

        <TabPanel value={tabConfig.standard.value} sx={{ margin: 0 }}>
          <Grid container spacing={2} marginX={-2}>
            <Grid item xs={12}>
              <Select
                label="Standard Rippe Materialtyp"
                name="materialTypeStandardRip"
                options={materialTypeOptions}
                data-cy="select-standard-rip"
              />
            </Grid>
            <Grid item xs={12}>
              <SelectLoadable
                label="Standard Rippe Material"
                name="layer.crossSectionStandardRip.material"
                placeholder="Material auswählen"
                query={
                  materialTypeStandardRipWatch === 'softwoodMaterial'
                    ? getSoftWoodMaterials
                    : getGluelamMaterials
                }
                renderOptions={(data: Material[] = []) =>
                  data.map(material => ({
                    value: material,
                    label: remapMaterialIdentifier(material.identifier),
                  }))
                }
                data-cy="crosssection-standard-material"
              />
            </Grid>
            <Grid item xs={6}>
              <CentimeterField
                label="Querschnittbreite"
                name="layer.crossSectionStandardRip.shape.width"
                data-cy="crosssection-standard-shape-width"
              />
            </Grid>
            <Grid item xs={6}>
              <CentimeterField
                tooltip={{
                  text: 'Bestimmt die Dicke der Schicht',
                }}
                label="Querschnitthöhe"
                name="layer.crossSectionStandardRip.shape.height"
                data-cy="crosssection-standard-shape-height"
              />
            </Grid>
            <Grid item xs={6}>
              <CentimeterField label="Standard Rippe Abstand" name="layer.rip_step_size" />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={tabConfig.lintel.value} sx={{ margin: 0 }}>
          <Grid container spacing={2} marginX={-2}>
            <Grid item xs={12}>
              <Select
                label="Fenstersturz Materialtyp"
                name="materialTypeLintel"
                options={materialTypeOptions}
                data-cy="select-lintel"
              />
            </Grid>
            <Grid item xs={12}>
              <SelectLoadable
                label="Fenstersturz Material"
                name="layer.crossSectionLintel.material"
                placeholder="Material auswählen"
                query={
                  materialTypeLintelWatch === 'softwoodMaterial'
                    ? getSoftWoodMaterials
                    : getGluelamMaterials
                }
                renderOptions={(data: Material[] = []) =>
                  data.map(material => ({
                    value: material,
                    label: remapMaterialIdentifier(material.identifier),
                  }))
                }
                data-cy="crosssection-lintel-material"
              />
            </Grid>
            <Grid item xs={6}>
              <CentimeterField
                tooltip={{
                  text: 'Bestimmt die Dicke der Schicht',
                }}
                label="Querschnittbreite"
                name="layer.crossSectionLintel.shape.width"
                data-cy="crosssection-lintel-shape-width"
              />
            </Grid>
            <Grid item xs={6}>
              <CentimeterField
                label="Querschnitthöhe"
                name="layer.crossSectionLintel.shape.height"
                data-cy="crosssection-lintel-shape-height"
              />
            </Grid>
          </Grid>
        </TabPanel>
      </TabContext>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <FormControlLabel
            control={
              <Switch
                checked={hasInsulation}
                onChange={handleInsulationToggle}
                name="layer.hasInsulation"
                data-cy="layer-has-insulation"
              />
            }
            label="Isolierung"
          />
        </Grid>
        {hasInsulation && (
          <>
            <Grid item xs={12}>
              <TextField
                label="Isolierung Name"
                name="layer.insulation.name"
                data-cy="layer-insulation-name"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Isolierung Dichte"
                name="layer.insulation.density"
                type="number"
                unit="kg/㎥"
                data-cy="layer-insulation-density"
              />
            </Grid>
            <Grid item xs={6}>
              <MillimeterField
                label="Isolierung Breite"
                name="layer.insulation_thickness"
                data-cy="layer-insulation-thickness"
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  )
}

export default TimberFrameLayerFormFields

import React, { ReactElement, useEffect, useMemo } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { useElementHistory } from '@hooks'
import { AxiosError } from 'axios'
import { find } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { Divider, Tooltip, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { useCameraStore } from '@modugen/scene/lib/controllers/CameraController/cameraStore'
import { useEditElementStore, useModelStore } from '@editorStores'
import { useBlockScene, useElementLabel, useResultsInvalidation } from '@editorHooks'
import { getModel, getVerticalTransmissionGraph, getAssemblyAssignment } from '@queries'
import { createVerticalSlab, deleteVerticalSlab, updateVerticalSlab } from '@mutations'
import { buildErrorMessage } from 'src/constants/errors'
import { getElementLabels } from 'src/state/queries/labels'
import { RectangularShapeForm } from '../../../RectangularShape'
import { MetadataForm } from './components/MetadataForm'

interface Props {
  selectedElement: string
  geometryEditable?: boolean
  activeStorey?: string
  /**
   * Whether to show toggle to change drawing mode
   */
  showDrawSlabOnElement?: boolean
}

const VerticalSlabsForm = ({
  selectedElement,
  geometryEditable = true,
  activeStorey,
  showDrawSlabOnElement = false,
}: Props): ReactElement | null => {
  const drawSlabOnElement = useEditElementStore(state => state.drawSlabOnElement)
  const setDrawSlabOnElement = useEditElementStore(state => state.setDrawSlabOnElement)

  const isOrthographic = useCameraStore(state => state.isOrthographic)

  const verticalSlabs = useModelStore(state => state.model.vertical_slabs)
  const removeSlab = useModelStore(state => state.removeVerticalSlab)
  const updateSlab = useModelStore(state => state.updateVerticalSlab)
  const updateStorey = useModelStore(state => state.updateStorey)

  const removeVerticalSlab = useModelStore(state => state.removeVerticalSlab)
  const setTypeVisibility = useModelStore(state => state.setTypeVisibility)

  const { enqueueSnackbar } = useSnackbar()
  const { projectId }: { projectId?: string } = useParams()
  const client = useQueryClient()

  const invalidateResults = useResultsInvalidation()

  const getLabel = useElementLabel()

  const verticalSlab = useMemo(() => {
    return find(verticalSlabs, ['guid', selectedElement]) as VerticalSlab
  }, [verticalSlabs, selectedElement])

  const updateSlabOrientation = useModelStore(state => state.updateSlabOrientation)
  const updateVerticalSlabM = useModelStore(state => state.updateVerticalSlab)

  const model = useModelStore(state => state.model)

  const storeys = useMemo(() => [...Object.keys(model.storey_boundaries), 'Dach'], [model])

  useEffect(() => {
    // remove vertical roof slab when component unmounts
    // when vertical roof slab.is_local is still true
    // this means that the element was not saved
    return () => {
      const verticalSlabsInState = useModelStore.getState().model.vertical_slabs
      const selectedVerticalSlabInState = find(verticalSlabsInState, ['guid', selectedElement])

      // slab has to be saved otherwise remove
      if (selectedVerticalSlabInState?.is_local)
        removeVerticalSlab(selectedVerticalSlabInState.guid)
    }
  }, [selectedElement])

  // mutations

  const { mutateAsync: handleCreate, isLoading: isCreating } = useMutation(
    (slab: ShapeObject) => createVerticalSlab.request(projectId, slab),
    {
      onSuccess: async (data: ShapeObject) => {
        setSlabStale(data)
        await Promise.all([
          client.invalidateQueries(getModel.getKey(projectId)),
          client.invalidateQueries(getVerticalTransmissionGraph.getKey(projectId)),
          client.invalidateQueries(getAssemblyAssignment.getKey(projectId)),
          client.invalidateQueries(getElementLabels.getKey(projectId)),
        ])
        invalidateResults(projectId as string)
        enqueueSnackbar('Decke erfolgreich gespeichert', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        const errorMessage = buildErrorMessage(error, 'Fehler beim Speichern der Decke')
        enqueueSnackbar(errorMessage, { variant: 'error' })
      },
    },
  )

  const { mutateAsync: handleUpdate, isLoading: isUpdating } = useMutation(
    (slab: ShapeObject) => updateVerticalSlab.request(projectId, slab),
    {
      onSuccess: async (data: ShapeObject) => {
        setSlabStale(data)
        await Promise.all([
          client.invalidateQueries(getModel.getKey(projectId)),
          client.invalidateQueries(getVerticalTransmissionGraph.getKey(projectId)),
          client.invalidateQueries(getAssemblyAssignment.getKey(projectId)),
        ])
        invalidateResults(projectId as string)
        enqueueSnackbar('Decke erfolgreich gespeichert', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        const errorMessage = buildErrorMessage(error, 'Fehler beim Speichern der Decke')
        enqueueSnackbar(errorMessage, { variant: 'error' })
      },
    },
  )

  const { mutateAsync: handleDelete, isLoading: isDeleting } = useMutation(
    (slabGuid: string) => deleteVerticalSlab.request(projectId, slabGuid),
    {
      onSuccess: async () => {
        await Promise.all([
          client.invalidateQueries(getModel.getKey(projectId)),
          client.invalidateQueries(getVerticalTransmissionGraph.getKey(projectId)),
          client.invalidateQueries(getAssemblyAssignment.getKey(projectId)),
        ])
        invalidateResults(projectId as string)
        enqueueSnackbar('Decke erfolgreich gelöscht', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        const errorMessage = buildErrorMessage(error, 'Fehler beim Löschen der Decke')
        enqueueSnackbar(errorMessage, { variant: 'error' })
      },
    },
  )

  const { setStale: setSlabStale } = useElementHistory({
    element: verticalSlab,
    getCurrentElement: () => {
      const slabs = useModelStore.getState().model.vertical_slabs
      return find(slabs, ['guid', selectedElement]) as ShapeObject
    },
    removeElement: slab => removeSlab(slab.guid),
    resetElement: slab => updateSlab(slab),
    dependencies: [selectedElement],
  })

  useBlockScene(isCreating || isDeleting || isUpdating)

  useEffect(() => {
    setTypeVisibility('vertical_slabs' as ElementTypes, true)
  }, [])

  const ElementSwitch = () =>
    showDrawSlabOnElement ? (
      <Stack direction="row" spacing={1} alignItems="center" sx={{ m: 2 }}>
        <Tooltip title="Wenn diese Einstellung auf 'auf Element zeichnen' steht, wird das Element auf welchem die Maus bei Starten des Zeichnens ruht als Referenzelement für die Zeichenebene genutzt. Andernfalls wird das aktive Stockwerk als Referenz verwendet. ">
          <ToggleButtonGroup
            value={drawSlabOnElement}
            exclusive
            onChange={() => setDrawSlabOnElement(!drawSlabOnElement)}
            fullWidth={true}
          >
            <ToggleButton value={false} aria-label="is horizontal purlin" fullWidth={true}>
              <Typography>Auf Stockwerk zeichnen</Typography>
            </ToggleButton>
            <ToggleButton value={true} aria-label="is non horizontal rafter" fullWidth={true}>
              <Typography>Auf Element zeichnen</Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Tooltip>
      </Stack>
    ) : (
      <></>
    )

  if (!verticalSlab) return <ElementSwitch />

  return (
    <>
      <ElementSwitch />

      <Divider />
      <RectangularShapeForm
        element={verticalSlab}
        onCreate={handleCreate}
        onDelete={handleDelete}
        onUpdate={handleUpdate}
        onDeleteLocal={removeVerticalSlab}
        initialTab={isOrthographic ? 'geometry' : 'vertical-transmission'}
        label={getLabel(selectedElement)}
        geometryEditable={geometryEditable}
        isDeleting={isDeleting}
        updateOrientation={updateSlabOrientation}
        updateShape={updateVerticalSlabM}
        storeys={storeys}
        updateStorey={updateStorey}
        activeStorey={activeStorey}
      />

      {/* TODO: test this element  */}
      <MetadataForm element={verticalSlab} setSlabStale={setSlabStale} />
    </>
  )
}

export default VerticalSlabsForm

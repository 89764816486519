export const layerToFormState = (layer?: PlateLayer): PlateLayerFormState | undefined => {
  if (!layer) return

  return {
    producer: layer.plate.producer,
    layer: {
      ...layer,
    },
  }
}

export const formStateToLayer = (formState: PlateLayerFormState): Layer => {
  const { layer } = formState

  return layer
}

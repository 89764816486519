import Decimal from 'decimal.js'
import { object, string } from 'yup'

export const createSlabBeamSchema = (guid: string, slabGuid: string) =>
  object({
    guid: string().default(guid),
    slab_guid: string().default(slabGuid),
    relative_position: string()
      .default('0')
      .test('is-relative', `Balken liegt außerhalb der Deckenposition`, value => {
        try {
          const decimalValue = new Decimal(value)
          return decimalValue.gte(0) && decimalValue.lte('1')
        } catch (error) {
          return false
        }
      }),
  })

import React, { ReactElement, useCallback, useMemo } from 'react'
import { ColorRepresentation } from 'three'
import { useTheme } from '@mui/material'
import { DraggableLine } from '@modugen/scene/lib/components/Lines/DraggableLine'
import { HighlightableLine } from '@modugen/scene/lib/components/Lines/HighlightableLine'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { useSceneStore } from '@editorStores'
import { useEnhanceClickEvent } from '../../hooks'

export enum BeamElements {
  Line = 'BeamLine',
  Handle = 'BeamHandle',
}
interface Props {
  beam: ShapeObjectLine
  isActive?: boolean
  onClick?: (beamGuid: string) => void
  isSecondary?: boolean
}

const Beam2D = ({ beam, onClick, isActive = false, isSecondary = false }: Props): ReactElement => {
  const { scenePalette } = useTheme()
  const points = useMemo(
    () => [beam.shape.start, beam.shape.end].map(({ x, y, z }) => new ImmutableVector3(x, y, z)),
    [beam],
  )

  // state picking due to performance reasons
  const mappedColor = useSceneStore(
    useCallback(state => state.guidToColor[beam.guid], [beam.guid]),
  ) as ColorRepresentation | undefined

  const { onClickEnhanced, onClickBase } = useEnhanceClickEvent(beam.guid, onClick)

  if (isSecondary) {
    return (
      <HighlightableLine
        key={beam.guid}
        line={points}
        color={scenePalette.elements3d.beams as string}
        isHighlighted={false}
        hoverable={false}
        clickable={false}
        opacity={0.65}
        onClick={onClickBase}
      />
    )
  }

  return (
    <DraggableLine
      line={{ start: points[0], end: points[1] }}
      color={(mappedColor || scenePalette.elements3d.beams) as string}
      selectedColor={scenePalette.elements3d.beams as string}
      isSelected={isActive}
      showLengthIndicator={isActive}
      onClick={onClickEnhanced ? () => onClickEnhanced(beam.guid) : undefined}
      showIndicators={false}
      lineName={BeamElements.Line}
      hoverable={onClickEnhanced !== undefined}
    />
  )
}

export default Beam2D

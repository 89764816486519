import React, { ReactElement } from 'react'
import { Button } from '@mui/material'
import { SaveButton } from '@ui/actions'
import { DialogBase } from '@ui/feedback'
import { layerNames } from '../constants'
import { layerForms } from './constants'

const FORM_ID = 'layer-form'

interface Props {
  onClose: () => void
  onSubmit: (layer: Layer) => void
  data?: Layer
  type?: string
  open?: boolean
}

const CreateProductLayerDialog = ({
  onClose,
  onSubmit,
  data,
  type,
  open = true,
}: Props): ReactElement | null => {
  if (!type) return null
  const LayerForm = layerForms[type]
  const title = data ? 'Schicht editieren' : `${layerNames[type]} erstellen`

  return (
    <DialogBase
      open={open}
      onClose={onClose}
      title={title}
      dialogProps={{
        maxWidth: 'xs',
        fullWidth: true,
      }}
    >
      <LayerForm id={FORM_ID} defaultValues={data} onSubmit={onSubmit} />
      <>
        <Button onClick={onClose}>Abbrechen</Button>
        <SaveButton form={FORM_ID} type="submit" data-cy="save-layer">
          {data ? 'Speichern' : 'Hinzufügen'}
        </SaveButton>
      </>
    </DialogBase>
  )
}

export default CreateProductLayerDialog

import React, { ReactElement } from 'react'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Tab } from '@mui/material'
import { Box, Drawer, LayoutDrawer as Layout } from '@ui/structure'
import SceneControlled from '../../components/SceneControlled'
import {
  WindLoadsTab,
  LiveLoadsTab,
  SnowLoadsTab,
  WindLoadsScene,
  SeismicSettingsScene,
} from './components'
import SeismicSettingsTab from './components/SeismicSettingsTab'
import { tabConfig } from './constants'
import useGlobalLoadsQueryParams from './hooks/useGlobalLoadsQueryParams'

const GlobalLoads = (): ReactElement => {
  const {
    params: { tab },
    actions: { toggleTab },
  } = useGlobalLoadsQueryParams()

  return (
    <Layout>
      <SceneControlled>
        {tab === 'wind-loads' && <WindLoadsScene />}
        {tab === 'seismic-settings' && <SeismicSettingsScene />}
      </SceneControlled>
      <Drawer>
        <Box>
          <TabContext value={tab || 'live-loads'}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
              }}
            >
              <TabList onChange={(e, v) => toggleTab(v)}>
                <Tab
                  value={tabConfig['live-loads'].value}
                  label={tabConfig['live-loads'].label}
                  data-cy="tab-live-loads"
                  sx={{ margin: 0 }}
                />
                <Tab
                  value={tabConfig['wind-loads'].value}
                  label={tabConfig['wind-loads'].label}
                  data-cy="tab-wind-loads"
                  sx={{ margin: 0 }}
                />
                <Tab
                  value={tabConfig['snow-loads'].value}
                  label={tabConfig['snow-loads'].label}
                  data-cy="tab-snow-loads"
                  sx={{ margin: 0 }}
                />
                <Tab
                  value={tabConfig['seismic-settings'].value}
                  label={tabConfig['seismic-settings'].label}
                  data-cy="tab-seismic-settings"
                  sx={{ margin: 0 }}
                />
              </TabList>
            </Box>

            <TabPanel value={tabConfig['live-loads'].value}>
              <LiveLoadsTab />
            </TabPanel>
            <TabPanel value={tabConfig['wind-loads'].value}>
              <WindLoadsTab />
            </TabPanel>
            <TabPanel value={tabConfig['snow-loads'].value}>
              <SnowLoadsTab />
            </TabPanel>
            <TabPanel value={tabConfig['seismic-settings'].value} sx={{ p: 1 }}>
              <SeismicSettingsTab />
            </TabPanel>
          </TabContext>
        </Box>
      </Drawer>
    </Layout>
  )
}

export default GlobalLoads

/* eslint-disable @typescript-eslint/no-explicit-any */
import { mergeWith, isNull } from 'lodash-es'
import { ObjectSchema } from 'yup'

const getFieldCount = (schema: ObjectSchema<any>): number => {
  let fieldCount = 0

  Object.entries(schema.fields).forEach(([fieldName, field]) => {
    // @ts-expect-error
    if (field.type === 'array' && field.innerType?._nodes) {
      fieldCount +=
        // @ts-expect-error
        (data[fieldName]?.length || 0) * field.innerType._nodes.length
    } else {
      fieldCount += 1
    }
  })

  return fieldCount
}

const extractData = (data: object, schema: ObjectSchema<any>): any => {
  return Object.entries(schema.fields).reduce((result, [fieldName]) => {
    // @ts-expect-error
    result[fieldName] = data[fieldName]

    return result
  }, {})
}

export const validateData = (
  store: any,
  schema: ObjectSchema<any>,
  strict = false,
  options = {},
): {
  fieldCount: number
  errors: string[]
  isValid: boolean
} => {
  const data = extractData(store, schema)
  const fieldCount = getFieldCount(schema)
  let errors = []

  try {
    schema.validateSync(data, {
      abortEarly: false,
      context: { strict },
      ...options,
    })
  } catch ({ errors: validationErrors }) {
    // @ts-expect-error
    errors = validationErrors || []
  }

  return {
    fieldCount,
    errors,
    isValid: errors.length === 0,
  }
}

export const createInitialStateWithValidation = (
  serverState: any,
  initialState: any,
  validationSchema: ObjectSchema<any>,
): any => {
  const validation = validateData(initialState, validationSchema)

  return {
    ...mergeWith(serverState, initialState, (serverValue, initialValue) =>
      isNull(serverValue) ? initialValue : serverValue,
    ),
    validation,
    validationSchema,
  }
}

export const createInitialState = (serverState: any, initialState: any): any => {
  return {
    ...mergeWith(serverState, initialState, (serverValue, initialValue) =>
      isNull(serverValue) ? initialValue : serverValue,
    ),
  }
}

import { array, number, object } from 'yup'
import { vector2D } from '../SimplifiedSeismicModelInputsForm/schema'

const storeyResults = object({
  e_min: vector2D,
  e_max: vector2D,
  forces: vector2D,
  storey_idx: number().integer().required(),
})

// We use the .data in the schema here because the array as root
// screws our validation somehow.
export const simplifiedSeismicResultsSchema = object({
  data: array(storeyResults).default([]),
})

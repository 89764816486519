import React, { ReactElement, ReactNode, FC, useState, useEffect, useRef } from 'react'
import { Paper, SvgIconProps, SxProps, Theme } from '@mui/material'
import ToggleButton from '../ToggleButtonGroup'

interface Props {
  hint?: string
  children: ReactNode
  Icon: FC<SvgIconProps>
  style?: SxProps<Theme>
  iconSize?: 'small' | 'inherit' | 'medium' | 'large'
  extendTo?: 'right' | 'left'
  onClick?: () => void
  'data-cy'?: string
  toggleType?: 'hover' | 'click'
  iconColour?: 'primary' | 'secondary'
}

const ToggleButtonDropDown = ({
  hint,
  children,
  Icon,
  style,
  iconSize,
  extendTo = 'left',
  toggleType = 'click',
  iconColour = 'secondary',
  onClick = () => null,
  'data-cy': dataCy,
}: Props): ReactElement => {
  const [open, setOpen] = useState(false)
  const targetRef = useRef<unknown>(null)

  useEffect(() => {
    const close = (event: MouseEvent) => {
      if (event.target !== targetRef.current) {
        setOpen(false)
      }
    }

    document.addEventListener('click', close)
    return () => document.removeEventListener('click', close)
  }, [])

  return (
    <ToggleButton
      sx={{
        '&:hover': {
          '.controlsContainer': {
            display: toggleType === 'hover' ? 'block' : undefined,
          },
        },
        ...style,
      }}
      Icon={Icon}
      iconSize={iconSize}
      // as the dropdown contains buttons itself, the parent element cannot be
      // a button. we basically just want the style of a ToggleButton but none
      // of its functionality
      component="div"
      hint={hint}
      disableRipple
      onClick={event => {
        targetRef.current = event.target
        setOpen(!open)
        onClick()
      }}
      data-cy={dataCy}
      iconColour={iconColour}
    >
      <Paper
        className="controlsContainer"
        sx={{
          display: toggleType === 'hover' ? 'none' : open ? 'block' : 'none',
          position: 'absolute',
          right: extendTo === 'left' ? 0 : undefined,
          left: extendTo === 'right' ? 0 : undefined,
          top: theme => theme.spacing(3.5),
          p: 1.25,
          zIndex: 3,
        }}
        onClick={e => e.stopPropagation()}
      >
        {children}
      </Paper>
    </ToggleButton>
  )
}

export default ToggleButtonDropDown

import React, { ReactElement, useMemo } from 'react'
import { getProblematicElements } from '@structuralPlanningUtils'
import { filter } from 'lodash-es'
import DangerousIcon from '@mui/icons-material/Dangerous'
import { Divider, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'
import { useStructuralPlanningStore } from '@editorStores'
import { useElementTypes, useGuidToDomain } from '@editorHooks'
import usePositionGuidToWallGuid from '../../../../hooks/usePositionGuidToWallGuid'

interface Props {
  selectedElement: string
}

const SelectedElementProblemsList = ({ selectedElement }: Props): ReactElement => {
  const problemViewActive = useStructuralPlanningStore(state => state.problemViewActive)
  const verticalTransmissionGraph = useStructuralPlanningStore(
    state => state.verticalTransmissionGraph,
  )
  const elementTypes = useElementTypes()
  const positionGuidToWallGuid = usePositionGuidToWallGuid()

  const guidToDomain = useGuidToDomain()

  const allProblems = useMemo(
    () =>
      getProblematicElements(
        verticalTransmissionGraph,
        elementTypes,
        positionGuidToWallGuid,
        guidToDomain,
      ).problemsOnElements,
    [verticalTransmissionGraph, elementTypes, positionGuidToWallGuid, guidToDomain],
  )
  const problems = useMemo(
    () => filter(allProblems, ['element_guid', selectedElement]),
    [selectedElement, allProblems],
  )

  return (
    <>
      {problemViewActive && problems.length > 0 && (
        <>
          <Divider sx={{ my: 0.5 }}>Probleme</Divider>
          <List>
            {problems.map(({ message }, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                  <DangerousIcon />
                </ListItemIcon>
                <ListItemText primary={message} />
              </ListItem>
            ))}
          </List>
        </>
      )}
    </>
  )
}

export default SelectedElementProblemsList

import { getRequest } from '../utils'

export const getDesignForces: RequestTypeVariable = {
  getKey: id => ['getDesignForces', id],
  request: async (id: string): Promise<DesignSupportForcesOnPosition[]> => {
    const { data } = await getRequest<DesignSupportForcesOnPosition[]>({
      url: `/projects/${id}/design-support-forces`,
    })

    return data as DesignSupportForcesOnPosition[]
  },
}

import { Drawer as MuiDrawer, SvgIcon } from '@mui/material'
import { CSSObject, Theme, styled } from '@mui/material/styles'

const drawerWidth = 200

const paperMixin = (theme: Theme, transparent = true): CSSObject => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: `calc(100% - ${theme.distances.navBarHeight})`,
  top: theme.distances.navBarHeight,
  paddingTop: theme.spacing(2),
  background: transparent ? 'transparent' : '#fff',
})

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('all', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  bottom: 0,
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('all', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  borderRight: 'none',

  '&:hover': {
    background: '#fff',

    '& .open-nav': {
      display: 'block',
    },
  },
})

export const CloseIcon = styled(SvgIcon, { shouldForwardProp: prop => prop !== 'open' })<{
  open: boolean
}>`
  width: 24;
  height: 14;
  display: flex;
  alignitems: center;
  justifycontent: center;
  transform: rotate(${({ open }) => (open ? '180' : '0')}deg);
  marginright: ${({ open, theme }) => (open ? 0 : theme.spacing(2))};
  transition: all 0.4s ease;
`

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open' && prop !== 'transparent',
})<{
  transparent: boolean
}>(({ theme, open, transparent }) => ({
  position: 'fixed',
  zIndex: 1,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': {
      ...openedMixin(theme),
      ...paperMixin(theme, transparent),
    },
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': {
      ...closedMixin(theme),
      ...paperMixin(theme, transparent),
    },
  }),
}))

import React, { ReactElement, FC, ReactNode } from 'react'
import { Tooltip, SvgIconProps, ToggleButtonProps } from '@mui/material'
import { ToggleButton as StyledToggleButton } from './styles'

interface Props extends Omit<ToggleButtonProps, 'value'> {
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  selected?: boolean
  hint?: string
  Icon: FC<SvgIconProps>
  disabled?: boolean
  children?: ReactNode
  value?: string
  inverted?: boolean
  component?: string
  iconSize?: 'small' | 'inherit' | 'medium' | 'large'
  iconColour?: 'primary' | 'secondary'
}

const ToggleButton = ({
  hint = '',
  Icon,
  iconSize = 'small',
  selected,
  disabled,
  onClick,
  children,
  value = 'snap',
  inverted = false,
  iconColour = 'secondary',
  ...buttonProps
}: Props): ReactElement => {
  return (
    <Tooltip title={hint}>
      <StyledToggleButton
        size="small"
        onClick={onClick}
        selected={selected}
        disabled={disabled}
        className="MuiToggleButtonGroup-grouped MuiToggleButtonGroup-groupedHorizontal"
        value={value}
        inverted={inverted}
        {...{
          ...buttonProps,
        }}
      >
        <Icon fontSize={iconSize} color={iconColour} />
        {children}
      </StyledToggleButton>
    </Tooltip>
  )
}

export default ToggleButton

import { getRequest } from '../utils'

export const getElementLabels: RequestTypeVariable = {
  getKey: (id: string) => ['getElementLabels', id],
  request: async (projectId): Promise<ElementLabel[] | null> => {
    const { data } = await getRequest<ElementLabel[]>({
      url: `/projects/${projectId}/element-labels`,
    })

    return data
  },
}

export const getPositionLabels: RequestTypeVariable = {
  getKey: (id: string) => ['getPositionLabels', id],
  request: async (projectId): Promise<PositionLabel[] | null> => {
    const { data } = await getRequest<PositionLabel[]>({
      url: `/projects/${projectId}/position-labels`,
    })

    return data
  },
}

import { Plane, Vector3 } from 'three'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'

const infiniteLineIntersection = (
  plane: Plane,
  lineStart: ImmutableVector3,
  lineEnd: ImmutableVector3,
) => {
  const lineDirection = lineStart.directionTo(lineEnd)

  // Calculate the dot product of the plane's normal and the line's direction
  const dotProduct = plane.normal.dot(lineDirection.v)

  // Check if the line is nearly parallel to the plane
  const parallelThreshold = 1e-6
  if (Math.abs(dotProduct) > parallelThreshold) {
    // Calculate the parameter at which the line intersects the plane
    const t = plane.normal.dot(plane.coplanarPoint(new Vector3()).sub(lineStart.v)) / dotProduct

    // Calculate the intersection point
    const intersectionPoint = lineStart.add(lineDirection.multiplyScalar(t))

    return intersectionPoint
  }

  return null
}

export default infiniteLineIntersection

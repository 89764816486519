import React, { ReactElement, ReactNode } from 'react'
import { BoxProps } from '@mui/system'
import { Box } from '..'

interface Props extends BoxProps {
  children: ReactNode
}

const BorderedBox = ({ children, ...props }: Props): ReactElement => {
  return (
    <Box component="div" p={1} border={1} borderColor="grey.200" borderRadius={1} {...props}>
      {children}
    </Box>
  )
}

export default BorderedBox

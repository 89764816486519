import React, { ReactElement } from 'react'
import { useWatch } from 'react-hook-form'
import numeral from 'numeral'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material'
import FormAccordion from '@ui/forms/FormAccordion'
import { useModelStore } from '@editorStores'
import AssignmentForm from '..'
import { liveLoadCategoryOptions } from '../../../constants'
import { storeyNumToCeilingZLevel, storeyNumToStoreyLabel } from '../../utils'

interface Props {
  storey: number
  onOpenAccordion: (storey: number) => void
  openAccordion: number
}

const FormFields = ({ storey, onOpenAccordion, openAccordion }: Props): ReactElement => {
  const storeyBoundaries = useModelStore(state => state.model.storey_boundaries)
  const storeyZLevel = storeyNumToCeilingZLevel(storey, storeyBoundaries)
  const config: LiveLoadConfiguration = useWatch({ name: `configurations[${storey}]` })

  const label =
    config.load_case.load_case_type &&
    liveLoadCategoryOptions[config.load_case.load_case_type as LiveLoadLoadCaseType].label

  const formattedLoadPerArea = numeral(config.load_per_area).format('0[.][00]a')
  const formattedSeparatingWallLoad = numeral(config.separating_wall_load).format('0[.][00]a')

  return (
    <FormAccordion
      fields={`configurations[${storey}]`}
      key={storey}
      expanded={storey === openAccordion}
      onChange={(_, expanded) => {
        if (expanded) onOpenAccordion(storey)
      }}
    >
      <AccordionSummary
        key={storey.toString()}
        expandIcon={<ExpandMoreIcon />}
        id={storey.toString()}
      >
        <Stack direction="column">
          <Typography variant="body2">
            {`Decke ${storeyNumToStoreyLabel(storey)} bei z=${storeyZLevel}m`}
          </Typography>
          <Typography variant="body2">
            {`${label} (${formattedLoadPerArea}N/m²) - `}
            {`Trennwandzuschlag ${formattedSeparatingWallLoad}N/m²`}
          </Typography>
          <Typography variant="body2"></Typography>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        {storey === openAccordion && <AssignmentForm index={storey} />}
      </AccordionDetails>
    </FormAccordion>
  )
}

export default FormFields

export type WallSubTab = 'wall-rips' | 'wall-lintels' | 'stiffening' | 'standard-rips'

export const tabConfig: Record<WallSubTab, { value: WallSubTab; label: string }> = {
  'wall-rips': {
    value: 'wall-rips',
    label: 'Rippen',
  },
  'wall-lintels': {
    value: 'wall-lintels',
    label: 'Stürze',
  },
  stiffening: {
    value: 'stiffening',
    label: 'Wandscheiben',
  },
  'standard-rips': {
    value: 'standard-rips',
    label: 'Standardrippen',
  },
}

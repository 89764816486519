import { useCallback } from 'react'
import {
  ArrayParam,
  BooleanParam,
  NumberParam,
  StringParam,
  useQueryParams,
} from 'use-query-params'
import { WallSubTab } from '../components/GlobalTabs/components/WallSubTab/constants'
import { Tab } from '../components/GlobalTabs/constants'
import { LocalTab, tabConfig } from '../components/LocalResults/constants'

interface Params {
  // tabs
  tab?: Tab | null
  loadTab?: LoadType | null
  wallSubTab?: WallSubTab | null
  localTab?: LocalTab | null

  // selected elements
  selectedElement?: string | null
  selectedLoad?: string | null
  selectedStiffeningSegment?: string | null
  selectedConnector?: string | null
  selectedMemberPosition?: string | null
  selectedCheckPosition?: number | null

  isLocalMode?: boolean
}

const useResultsQueryParams = () => {
  const [params, setQuery] = useQueryParams({
    // tabs
    tab: StringParam,
    loadTab: StringParam,
    wallSubTab: StringParam,
    localTab: StringParam,

    // selected elements
    selectedElement: StringParam,
    selectedLoad: StringParam,
    selectedStiffeningSegment: StringParam,
    selectedConnector: StringParam,
    selectedMemberPosition: StringParam,
    selectedCheckPosition: NumberParam,

    hiddenFilters: ArrayParam,

    isLocalMode: BooleanParam,
  })

  const {
    // tabs
    tab,
    loadTab,
    wallSubTab,
    localTab,

    // selected elements
    selectedElement,
    selectedLoad,
    selectedStiffeningSegment,
    selectedConnector,
    selectedMemberPosition,
    selectedCheckPosition,

    isLocalMode,
  } = params as Params

  // set all parameters that are not passed to
  // undefined
  const setQueryReset = useCallback(
    (newParams: Params = {}) => {
      const result = Object.entries(params).reduce((acc: Params, [key]) => {
        // @ts-ignore
        if (newParams[key] != null) {
          return {
            ...acc,
            // @ts-ignore
            [key]: newParams[key],
          }
        }

        return {
          ...acc,
          [key]: undefined,
        }
      }, {})

      setQuery({
        ...result,
      })
    },
    [params],
  )

  const selectElement = useCallback(
    (selectedElement: string) => {
      setQueryReset({
        selectedElement,
        loadTab,
        localTab,
        tab,
        selectedConnector,
      })
    },
    [loadTab, localTab, tab, setQueryReset],
  )

  const setTab = (tab: Tab) => {
    setQueryReset({
      tab,
    })
  }

  const setIsLocalMode = (isLocalMode: boolean, guid?: string, localTab?: LocalTab) => {
    setQueryReset({
      tab,
      wallSubTab,
      localTab: localTab,
      selectedElement: guid || selectedElement,
      isLocalMode,
      selectedConnector,
    })
  }

  const selectLoad = (load: string | null) => {
    setQueryReset({
      selectedElement,
      loadTab,
      selectedLoad: load,
      localTab: tabConfig.loads.value,
      tab,
      isLocalMode,
      selectedConnector,
    })
  }

  const setLoadTab = (loadTab: LoadType) => {
    setQueryReset({
      selectedElement,
      loadTab,
      localTab,
      wallSubTab,
      tab,
      isLocalMode,
      selectedConnector,
    })
  }

  const setWallSubTab = (tab: WallSubTab) => {
    setQueryReset({
      tab: 'walls',
      wallSubTab: tab,
      selectedConnector,
    })
  }

  const selectStiffeningSegment = (segment: string) => {
    setQueryReset({
      selectedStiffeningSegment: segment,
      wallSubTab: 'stiffening',
      tab: 'walls',
    })
  }

  const setLocalTab = (localTab: LocalTab) => {
    setQueryReset({
      localTab,
      selectedElement,
      loadTab,
      tab,
      wallSubTab,
      isLocalMode,
      selectedConnector,
    })
  }

  const selectConnector = (connector?: string) => {
    setQuery({
      ...params,
      selectedConnector: connector,
    })
  }

  const selectPosition = useCallback(
    (position: string, newTab?: Tab, wallSubTabProvided?: WallSubTab) => {
      setQueryReset({
        selectedElement: position,
        loadTab,
        localTab,
        tab: newTab || tab,
        wallSubTab: wallSubTabProvided || wallSubTab,
        selectedConnector,
      })
    },
    [loadTab, wallSubTab, localTab, tab, setQueryReset],
  )

  const selectMemberPosition = useCallback(
    (position: string) => {
      setQueryReset({
        selectedMemberPosition: position,
        selectedElement,
        localTab,
        isLocalMode,
        tab,
        wallSubTab,
      })
    },
    [setQueryReset, selectedElement, localTab, isLocalMode],
  )

  const setSelectedCheckPosition = useCallback(
    (checkPosition?: number) => {
      setQueryReset({
        selectedCheckPosition: checkPosition,
        selectedElement,
        localTab,
        isLocalMode,
        tab,
        wallSubTab,
      })
    },
    [setQueryReset, selectedElement, localTab, isLocalMode],
  )

  const resetSelection = useCallback(() => {
    setQueryReset({
      tab,
      wallSubTab,
    })
  }, [tab, wallSubTab, setQueryReset])

  return {
    modes: {
      isLocalMode,
    },
    params: {
      selectedElement,
      tab,
      selectedLoad,
      loadTab,
      wallSubTab,
      selectedStiffeningSegment,
      localTab,
      selectedConnector,

      selectedMemberPosition,
      selectedCheckPosition,
    } as Params,
    actions: {
      selectElement,
      resetSelection,
      setTab,
      selectLoad,
      setLoadTab,
      setWallSubTab,
      selectStiffeningSegment,
      setLocalTab,
      selectConnector,
      selectPosition,
      selectMemberPosition,
      setSelectedCheckPosition,
      setIsLocalMode,
    },
  }
}

export default useResultsQueryParams

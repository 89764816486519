import { number, object, string } from 'yup'

export const snowLoadConfigurationSchema = object({
  load_case: object({
    identifier: string().default('Snow'),
    category: string().default('Snow'),
    load_case_type: string().default('SnowFull'),
  }),
  load_per_area: number().required().moreThan(0).default(0),
})

import React, { ReactElement, useMemo } from 'react'
import { VerticalSlabsForm } from '@structuralPlanningComponents'
import { find } from 'lodash-es'
import { useEditElementStore, useControlStore, useModelStore } from '@editorStores'
import { useElementType } from '@editorHooks'
import { FilterPortal } from '@editorComponents'
import { useStructuralPlanningQueryParams } from '@structuralPlanningHooks'
import PurlinForm from '../../../PurlinsDrawing/components/PurlinForm'
import { VerticalRoofForm } from '../../../VerticalRoof'
import BeamForm from '../BeamForm'
import ColumnForm from '../ColumnForm'
import Filters from '../Filters'
import WallForm from '../WallForm'
import { WallRipForm } from '../WallRipForm'

const ElementForm = (): ReactElement | null => {
  const {
    modes: {
      isDrawingVerticalSlabsMode,
      isDrawingBeamsMode,
      isDrawingColumnsMode,
      isDrawingWallsMode,
      isDrawingVerticalRoofMode,
      isDrawingPurlinsMode,
      isWallRipMode,
    },
    params: { selectedElements, storey },
  } = useStructuralPlanningQueryParams()
  const activeElement = useEditElementStore(state => state.activeElement)
  const elementType = useElementType(activeElement)

  const orthographicDrawMode = useControlStore(state => state.orthographicDrawMode)

  const rips = useModelStore(state => state.model.rips)

  const activeElementIsRip = useMemo(
    () => !!find(rips, { position_guid: activeElement }),
    [rips, activeElement],
  )

  return (
    <>
      {orthographicDrawMode && (
        <FilterPortal>
          <Filters />
        </FilterPortal>
      )}
      {(isDrawingVerticalSlabsMode || elementType === 'vertical_slabs') && (
        <>
          <VerticalSlabsForm
            key={activeElement}
            selectedElement={activeElement as string}
            activeStorey={storey || undefined}
            showDrawSlabOnElement
          />
        </>
      )}
      {(isDrawingBeamsMode || elementType === 'beams') && (
        <>
          <BeamForm key={activeElement} selectedElement={activeElement as string} />
        </>
      )}
      {(isDrawingColumnsMode || elementType === 'columns') && (
        <ColumnForm
          key={activeElement}
          selectedElement={activeElement as string}
          activeElement={selectedElements?.[0] as string}
        />
      )}

      {(isDrawingWallsMode ||
        ((elementType === 'inner_walls' || elementType === 'outer_walls') && !isWallRipMode)) && (
        <WallForm
          key={activeElement}
          selectedElement={activeElement as string}
          storeySelected={!!storey}
        />
      )}

      {(isDrawingVerticalRoofMode || elementType === 'vertical_roof_slabs') && (
        <>
          <VerticalRoofForm
            key={activeElement}
            selectedElement={activeElement || undefined}
            geometryEditable={true}
            activeStorey={storey || undefined}
            showDrawRoofOnElement
          />
        </>
      )}

      {(isDrawingPurlinsMode || elementType === 'purlins') && activeElement && (
        <PurlinForm selectedElement={activeElement} />
      )}
      {activeElement && activeElementIsRip && <WallRipForm positionGuid={activeElement} />}
    </>
  )
}

export default ElementForm

export const tabConfig = {
  standard: {
    value: 'standard',
    label: 'Standardrippe',
  },
  lintel: {
    value: 'lintel',
    label: 'Fenstersturz',
  },
}

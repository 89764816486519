import React, { ReactElement, useMemo } from 'react'
import { mapValueKey } from '@editorUtils'
import { filter, find } from 'lodash-es'
import { useModelStore } from '@editorStores'
import StiffeningMeshElement from '../StiffeningMeshElement'

interface Props extends IntervalProps {
  data: StiffeningSegment[]
  elementGuids?: string[]
  hiddenElements?: Set<string>
  pointerPropagation?: boolean
  noPointerInteractions?: boolean
  showNonInteractable?: boolean
}

const StiffeningMesh = ({
  data,
  elementGuids,
  pointerPropagation,
  noPointerInteractions,
  hiddenElements = new Set(),
  showNonInteractable = false,
  ...intervalProps
}: Props): ReactElement | null => {
  const model = useModelStore(state => state.model)

  const combinedElements = useMemo(() => {
    if (!model || !data) return []

    const wallsById = mapValueKey(model.walls)

    return data
      .filter(({ element_guid }) => !hiddenElements.has(element_guid) && wallsById[element_guid])
      .map(item => ({
        guid: item.guid,
        interval: item,
        points: wallsById[item.element_guid]?.shape.points || [],
      }))
  }, [data, model, hiddenElements])

  const [interactableElements, nonInteractableElements] = useMemo(() => {
    const interactable = filter(
      combinedElements,
      ({ interval: { element_guid } }) => !elementGuids || elementGuids.includes(element_guid),
    )

    const nonInteractable = filter(
      combinedElements,
      ({ interval: { guid } }) => !find(interactable, ['interval.guid', guid]),
    )

    return [interactable, nonInteractable]
  }, [combinedElements, elementGuids])

  return (
    <group>
      {interactableElements.map((item, index) => (
        <StiffeningMeshElement
          key={`${item.interval.element_guid}-${item.interval.interval.lower}-${item.interval.interval.upper}`}
          index={index}
          pointerPropagation={pointerPropagation}
          noPointerInteractions={noPointerInteractions}
          interval={item.interval}
          wallPoints={item.points}
          {...intervalProps}
        />
      ))}
      {showNonInteractable &&
        nonInteractableElements.map((item, index) => (
          <StiffeningMeshElement
            key={`non-interactable-${item.interval.element_guid}-${item.interval.interval.lower}-${item.interval.interval.upper}`}
            index={index}
            pointerPropagation
            noPointerInteractions
            interval={item.interval}
            wallPoints={item.points}
            opacity={0.15}
            {...intervalProps}
          />
        ))}
    </group>
  )
}

export default StiffeningMesh

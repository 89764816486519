export const windDirectionAssignments: {
  value: WindloadSelection
  label: string
}[] = [
  {
    label: 'Wind D',
    value: 'elements_wind_D',
  },
  {
    label: 'Wind ABC links primär',
    value: 'elements_wind_ABC_left_main',
  },
  {
    label: 'Wind E',
    value: 'elements_wind_E',
  },
  {
    label: 'Wind ABC rechts primär',
    value: 'elements_wind_ABC_right_main',
  },
]

export const windDirectionWatches = windDirectionAssignments.map(
  ({ value }) => `outerWallAssignment.${value}`,
)

import { useEffect } from 'react'
import { useControlStore } from '@editorStores'

const useBlockScene = (blocked: boolean) => {
  const isSceneBlocked = useControlStore(state => state.isSceneBlocked)
  const setSceneBlocked = useControlStore(state => state.setSceneBlocked)

  useEffect(() => {
    setSceneBlocked(blocked)

    return () => setSceneBlocked(false)
  }, [blocked])

  return isSceneBlocked
}

export default useBlockScene

export const windZoneOptions = [
  {
    value: 1,
    label: 'Windzone 1',
  },
  {
    value: 2,
    label: 'Windzone 2',
  },
  {
    value: 3,
    label: 'Windzone 3',
  },
  {
    value: 4,
    label: 'Windzone 4',
  },
]

export const terrainCategoryOptions = [
  {
    value: 'I',
    label: 'Kategorie 1',
  },
  {
    value: 'II',
    label: 'Kategorie 2',
  },
  {
    value: 'III',
    label: 'Kategorie 3',
  },
  {
    value: 'IV',
    label: 'Kategorie 4',
  },
]

export const windZoneToPressureProposal: Record<WindZone, number> = {
  1: 320,
  2: 390,
  3: 470,
  4: 560,
}

export const windZoneToSpeedProposal: Record<WindZone, number> = {
  1: 22.5,
  2: 25,
  3: 27.5,
  4: 30,
}

import { getRequest } from 'src/state/utils'

const getProjectForApproval: RequestTypeVariable = {
  getKey: (id: string) => ['getProjectForApproval', id],
  request: async (id: string): Promise<ProjectForApproval> => {
    const res = await getRequest<ProjectForApproval>({
      url: `/approval/${id}/model`,
    })
    return res.data as ProjectForApproval
  },
}

export { getProjectForApproval }

import { getRequest } from '../utils'

export const getDesignPositionBundle: RequestTypeVariable = {
  getKey: id => ['getDesignPositionBundle', id],
  request: async (id: string): Promise<DesignPositionBundle> => {
    const { data } = await getRequest<DesignPositionBundle>({
      url: `/projects/${id}/design-position-bundle`,
    })

    return data as DesignPositionBundle
  },
}

export const getElementChecksBundle: RequestTypeVariable = {
  getKey: id => ['getElementChecksBundle', id],
  request: async (id: string): Promise<ElementCheckBundle> => {
    const { data } = await getRequest<ElementCheckBundle>({
      url: `/projects/${id}/element-check-bundle`,
    })

    return data as ElementCheckBundle
  },
}

export const getResultBundle: RequestTypeVariable = {
  getKey: id => ['getResultBundle', id],
  request: async (id: string): Promise<ResultBundle> => {
    const { data } = await getRequest<ResultBundle>({
      url: `/projects/${id}/result-view-model-data`,
    })

    return data as ResultBundle
  },
}

import { isNull } from 'lodash-es'
import { postRequest, retryUntilSuccessOrFailure } from '../utils'

export const computeResults = {
  key: 'computeResults',
  request: async (projectId: string): Promise<void> => {
    const { data: horizontalForcesComputeData } = await postRequest<{ task_id: string } | null>({
      url: `/projects/${projectId}/horizontal-forces/compute`,
    })

    if (isNull(horizontalForcesComputeData)) throw new Error('Cannot compute horizontal forces yet')

    await retryUntilSuccessOrFailure({
      url: `/task-status/${horizontalForcesComputeData?.task_id}`,
    })

    const { data: tfComputeData } = await postRequest<{ task_id: string } | null>({
      url: `/projects/${projectId}/tensile-forces/compute`,
    })

    if (isNull(tfComputeData)) throw new Error('Cannot compute tensile forces yet')

    await retryUntilSuccessOrFailure({
      url: `/task-status/${tfComputeData?.task_id}`,
    })

    const { data: verticalForcesComputeData } = await postRequest<{ task_id: string } | null>({
      url: `/projects/${projectId}/vertical-forces/compute`,
    })

    if (isNull(verticalForcesComputeData)) throw new Error('Cannot compute vertical forces yet')

    await retryUntilSuccessOrFailure({
      url: `/task-status/${verticalForcesComputeData?.task_id}`,
    })

    const { data: elementCheckBundleData } = await postRequest<{ task_id: string } | null>({
      url: `/projects/${projectId}/element-check-bundle/compute`,
    })

    if (isNull(elementCheckBundleData)) throw new Error('Cannot compute element check bundle yet')

    await retryUntilSuccessOrFailure({
      url: `/task-status/${elementCheckBundleData?.task_id}`,
    })
  },
}

export const saveManualLoadsPerSupport: RequestTypeVariable = {
  getKey: (projectId: string) => ['saveManualLoadsPerSupport', projectId],
  request: async (id: string, data: ManualLoadOnSupport[]): Promise<ManualLoadOnSupport[]> => {
    const { data: result } = await postRequest<ManualLoadOnSupport[]>({
      url: `/projects/${id}/manual-loads-per-support`,
      data: data,
    })

    return result as ManualLoadOnSupport[]
  },
}

import React from 'react'
import { ListItem, ListItemText, useTheme } from '@mui/material'
import DownLoadButton from '@ui/actions/DownLoadIconButton'

interface Props {
  title: string
  onClick: () => void
  isLoading?: boolean
  disabled?: boolean
  'data-cy'?: string
}

const ExportItem = ({
  title,
  onClick,
  isLoading = false,
  disabled = false,
  'data-cy': dataCy,
}: Props) => {
  const theme = useTheme()

  return (
    <ListItem>
      <ListItemText
        primary={title}
        primaryTypographyProps={{ color: disabled ? theme.palette.text.disabled : undefined }}
      />
      <DownLoadButton
        disabled={disabled}
        variant="outlined"
        loading={isLoading}
        onClick={onClick}
        data-cy={`button-${dataCy}`}
      />
    </ListItem>
  )
}

export default ExportItem

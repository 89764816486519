export const loadCaseCategories: {
  value: string
  label: string
}[] = [
  {
    value: 'Snow',
    label: 'Schnee',
  },
  {
    value: 'SnowPlus',
    label: 'Schnee >1000m Höhe ü. NN',
  },
]

import useElementTypes from './useElementTypes'

const useElementType = (guid: string | null | undefined): ElementTypes | undefined => {
  const elementTypes = useElementTypes()

  if (!guid) return undefined

  return elementTypes[guid]
}

export default useElementType

import React, { ReactElement } from 'react'
import { useFieldArray, useWatch } from 'react-hook-form'
import { toNumber } from 'lodash-es'
import { Delete, Toc } from '@mui/icons-material'
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from '@mui/material'
import { Info } from '@ui/feedback'
import { Box } from '@ui/structure'

interface Props {
  index: number
}

const IntervalList = ({ index }: Props): ReactElement => {
  const intervals: RelativeInterval[] = useWatch({
    name: `bundles[${index}].smoothing_settings.intervals`,
  })

  const { remove } = useFieldArray({ name: `bundles[${index}].smoothing_settings.intervals` })

  if (intervals?.length === 0) {
    return (
      <Box display="flex" justifyContent="center">
        <Info icon={<Toc />}>Keine Intervalle definiert</Info>
      </Box>
    )
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Intervall</TableCell>
            <TableCell>Start</TableCell>
            <TableCell>Ende</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {intervals?.map(({ lower, upper }, index) => (
            <TableRow
              key={index}
              sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
            >
              <TableCell>{index + 1}</TableCell>
              <TableCell>{toNumber(lower).toFixed(2).replace('.', ',')}</TableCell>
              <TableCell>{toNumber(upper).toFixed(2).replace('.', ',')}</TableCell>
              <TableCell align="right">
                <IconButton onClick={() => remove(index)}>
                  <Delete fontSize="small" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default IntervalList

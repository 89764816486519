import React, { ReactElement } from 'react'
import { Drawer2D, useTapelineSnapTargets } from '@scene'
import { v4 as uuid } from 'uuid'
import { useTheme } from '@mui/material'
import useTapelineCentersSnapTargets from '@modugen/scene/lib/hooks/useTapelineCentersSnapTargets'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { useEditElementStore, useModelStore } from '@editorStores'
import {
  SnapTargetElementType,
  useDefault2DSnapConfigForElementType,
} from 'src/components/pages/Editor/hooks/useDefaultSnapConfigForElementType'
import { useModelSnapTargets } from '../../hooks'

interface Props {
  activeStorey: string
}

const minWallLength = 0.1

const WallDrawer2D = ({ activeStorey }: Props): ReactElement => {
  const { scenePalette } = useTheme()

  const addWall = useModelStore(state => state.addWall)
  const { storey_boundaries } = useModelStore(state => state.model)
  const setActiveElement = useEditElementStore(state => state.setActiveElement)

  const targets = useModelSnapTargets({ xyOnly: true })
  const tapelineTargets = useTapelineSnapTargets(true)
  const tapelineCenterTargets = useTapelineCentersSnapTargets()

  // EFFECTS
  useDefault2DSnapConfigForElementType(SnapTargetElementType.WALLS)

  return (
    <>
      <Drawer2D
        minLength={minWallLength}
        additionalSnapTargets={[...targets, ...tapelineTargets, ...tapelineCenterTargets]}
        color={scenePalette.elements2d.internalWalls}
        onDrawStart={() => setActiveElement(null)}
        onDrawEnd={(start, end) => {
          const [lowerBoundary, upperBoundary] = storey_boundaries[activeStorey]

          const points = [
            new ImmutableVector3(start.x, start.y, lowerBoundary),
            new ImmutableVector3(end.x, end.y, lowerBoundary),
            new ImmutableVector3(end.x, end.y, upperBoundary),
            new ImmutableVector3(end.x, end.y, upperBoundary),
          ]

          const wall = {
            shape: { points },
            placement: 'Internal' as WallPlacement,
            guid: uuid(),
            storey: activeStorey,
            is_local: true,
            type: 'inner_walls' as ElementTypes,
          }

          addWall(wall)
          setActiveElement(wall.guid)
        }}
      />
    </>
  )
}

export default WallDrawer2D

export const tabConfig = {
  assemblies: {
    value: 'assemblies',
    label: 'Aufbauten',
  },
  connections: {
    value: 'connections',
    label: 'Zugverbindungen',
  },
  components: {
    value: 'components',
    label: 'Bauteile',
  },
}

import React, { ReactElement, useRef, memo, useCallback, Fragment } from 'react'
import { Group } from 'three'
import { useTheme } from '@mui/material/styles'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { Html } from '@react-three/drei'
import { ThreeEvent } from '@react-three/fiber'
import { useSceneStore } from 'src/components/pages/Editor/stores/sceneStore'
import ArrowOrientationSlab from '../ArrowOrientationSlab'
import DomainMesh from '../DomainMesh'
import MemberMeshElement from '../MemberMeshElement'
import ShapeMesh from '../ShapeMesh'
import { modelName } from '../constants'
import { beamThickness } from './constants'

interface ModelMeshProps {
  model: PlanarModel
  translucent?: boolean
  invisible?: boolean
  applyThickness?: boolean
  interactableByType: VisibilityByType
  blockingElements: BlockingByType
  onClick: (event: ThreeEvent<MouseEvent>) => void
  onPointerOver: (event: ThreeEvent<MouseEvent>) => void
  onPointerLeave: (event: ThreeEvent<MouseEvent>) => void
  hiddenElements?: Set<string>
  selectedElementIds: Set<string>
  highlightedIds: Set<string>
  highlightedSecondaryIds?: Set<string>
  noPointerInteractions?: boolean
  guidToCrossSection: Record<string, { shape: { height: number; width: number } }>
}

const ModelMesh = ({
  model,
  onClick,
  onPointerOver,
  onPointerLeave,
  interactableByType,
  blockingElements,
  translucent,
  invisible,
  hiddenElements = new Set(),
  selectedElementIds,
  highlightedIds,
  highlightedSecondaryIds = new Set(),
  applyThickness,
  noPointerInteractions = false,
  guidToCrossSection,
}: ModelMeshProps): ReactElement | null => {
  const { scenePalette, sceneOffsets } = useTheme()
  const groupRef = useRef<Group>(null)

  const getShapeColor = useCallback(
    ({ guid, type }: ShapeObject, highlightedSecondary: boolean) => {
      // if (selectedElementIds.has(id)) return scenePalette.selection
      if (highlightedIds.has(guid)) return scenePalette.highlight
      if (type === 'vertical_slabs') return scenePalette.elements3d.vertical_slabs
      if (type === 'slabs') return scenePalette.elements3d.slabs
      if (type === 'vertical_roof_slabs') return scenePalette.elements3d.vertical_roof_slabs
      if (type === 'roof_slabs') return scenePalette.elements3d.roof_slabs
      if (type === 'foundation') return scenePalette.elements3d.foundation
      if (type === 'columns' && highlightedSecondary) return scenePalette.elements3d.targets

      return undefined
    },
    [
      highlightedIds,
      scenePalette.highlight,
      scenePalette.elements3d.vertical_slabs,
      scenePalette.elements3d.slabs,
      scenePalette.elements3d.vertical_roof_slabs,
      scenePalette.elements3d.roof_slabs,
      scenePalette.elements3d.foundation,
      scenePalette.elements3d.targets,
    ],
  )

  const guidToColor = useSceneStore(state => state.guidToColor)

  const getMemberColor = useCallback(
    ({ guid, type }: ShapeObjectLine, highlightedSecondary: boolean) => {
      const mappedColor = guidToColor[guid]
      if (highlightedSecondary) return scenePalette.elements3d.targets

      if (type === 'columns') return mappedColor || scenePalette.elements3d.columns
      if (type === 'beams') return mappedColor || scenePalette.elements3d.beams
      if (type === 'purlins') return mappedColor || scenePalette.elements3d.beams
    },
    [
      guidToColor,
      scenePalette.elements3d.beams,
      scenePalette.elements3d.columns,
      scenePalette.elements3d.targets,
    ],
  )

  const getTypeMaterial = useCallback(({ type }: ShapeObject, selected: boolean) => {
    if (type === 'vertical_slabs' || type === 'vertical_roof_slabs') {
      return {
        opacity: selected ? 1 : 0.5,
        transparent: true,
      }
    }

    return {}
  }, [])

  const getOutlinesColor = useCallback(
    (selected: boolean, highlightedSecondary: boolean): string | undefined => {
      if (selected) return scenePalette.highlightDark as string
      if (highlightedSecondary) return scenePalette.elements3d.targets as string

      return undefined
    },
    [scenePalette.elements3d.targets, scenePalette.highlightDark],
  )

  return (
    <group ref={groupRef} name={modelName}>
      {[...model.beams, ...model.columns, ...model.purlins].map(element => {
        const {
          guid,
          type,
          domains = [],
          coordinate_system,
          shape: { start, end },
        } = element

        const visible = !hiddenElements.has(element.guid)
        const selected = selectedElementIds.has(element.guid)
        const highlightedSecondary = highlightedSecondaryIds.has(element.guid)

        return (
          <Fragment key={guid}>
            <MemberMeshElement
              key={guid}
              name={guid}
              start={start}
              end={end}
              height={guidToCrossSection[guid]?.shape.height || beamThickness}
              width={guidToCrossSection[guid]?.shape.width || beamThickness}
              coordinateSystem={coordinate_system}
              color={getMemberColor(element, highlightedSecondary)}
              opacity={translucent ? 0.33 : 1}
              visible={!invisible && visible}
              cursor={interactableByType[type as ElementTypes] ? 'pointer' : undefined}
              noPointerInteractions={
                !interactableByType[type as ElementTypes] || noPointerInteractions
              }
              onPointerMove={onPointerOver}
              onPointerLeave={onPointerLeave}
              outlinesColor={getOutlinesColor(selected, highlightedSecondary)}
              onClick={onClick}
              userData={{
                elementType: type,
                isBlocking: type !== undefined ? blockingElements[type] : undefined,
                start,
                end,
              }}
            />

            {selected && visible && (
              <DomainMesh domains={domains} width={0.02} color={scenePalette.highlightDark} />
            )}
            {highlightedSecondary && visible && (
              <DomainMesh domains={domains} width={0.02} color={scenePalette.elements3d.targets} />
            )}
          </Fragment>
        )
      })}

      {[
        ...model.walls,
        ...model.roof_slabs,
        ...model.slabs,
        ...model.vertical_slabs,
        ...model.vertical_roof_slabs,
        model.foundation,
      ].map(element => {
        const {
          guid,
          type,
          domains = [],
          color,
          shape: { points: originalPoints },
        } = element

        // Don't render shapes with < 3 points
        if (originalPoints.length < 3) return <></>

        const visible = !hiddenElements.has(element.guid)
        const selected = selectedElementIds.has(element.guid)
        const highlightedSecondary = highlightedSecondaryIds.has(element.guid)
        const isFoundation = type === 'foundation'
        const renderDomainsAsSelected = !isFoundation && selected && visible
        const renderDomainsAsHighlightedSecondary = !isFoundation && highlightedSecondary && visible

        let movedPoints = originalPoints
        if (type === 'vertical_roof_slabs') {
          movedPoints = originalPoints.map(
            point =>
              new ImmutableVector3(point.x, point.y, point.z + sceneOffsets.verticalRoofSlabs.z),
          )
        }

        if (type === 'vertical_slabs') {
          movedPoints = originalPoints.map(
            point => new ImmutableVector3(point.x, point.y, point.z + sceneOffsets.verticalSlabs.z),
          )
        }

        return (
          <Fragment key={guid}>
            <ShapeMesh
              key={guid}
              data={{
                ...element,
                shape: {
                  ...element.shape,
                  points: movedPoints,
                },
              }}
              translucent={translucent}
              visible={!invisible && visible}
              cursor={interactableByType[type as ElementTypes] ? 'pointer' : undefined}
              noPointerInteractions={
                !interactableByType[type as ElementTypes] || noPointerInteractions
              }
              shapeColor={getShapeColor(element, highlightedSecondary) || color}
              onPointerMove={onPointerOver}
              onPointerLeave={onPointerLeave}
              outlinesColor={getOutlinesColor(selected, highlightedSecondary)}
              onClick={onClick}
              applyThickness={applyThickness}
              userData={{
                elementType: type,
                isBlocking: type !== undefined ? blockingElements[type] : undefined,
                points: originalPoints,
              }}
              meshMaterialProps={getTypeMaterial(element, selected)}
            />

            {renderDomainsAsSelected && (
              <DomainMesh domains={domains} width={0.02} color={scenePalette.highlightDark} />
            )}
            {renderDomainsAsHighlightedSecondary && (
              <DomainMesh domains={domains} width={0.02} color={scenePalette.elements3d.targets} />
            )}
            {(type === 'vertical_slabs' || type === 'vertical_roof_slabs') &&
              !invisible &&
              visible && (
                <ArrowOrientationSlab
                  slab={element}
                  color={type === 'vertical_roof_slabs' ? '#fff' : undefined}
                />
              )}
          </Fragment>
        )
      })}
      {model.walls.length && (
        <mesh>
          <Html
            style={{
              display: 'none',
            }}
          >
            <div data-cy="model-rendered" />
          </Html>
        </mesh>
      )}
    </group>
  )
}

export default memo(ModelMesh)

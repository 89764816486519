import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import { UseMutateAsyncFunction } from 'react-query'
import { mapValueKey } from '@editorUtils'
import { useResultsQueryParams } from '@resultsHooks'
import { AxiosError } from 'axios'
import { some } from 'lodash-es'
import { useSnackbar } from 'notistack'
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { CentimeterField, Form, Select, TextField } from '@ui/forms'
import SubmitButton from '@ui/forms/SubmitButton'
import { Box } from '@ui/structure'
import { useResultsStore, useSystemManagerStore } from '@editorStores'
import { useElementLabel } from '@editorHooks'
import { getSupportsOfElement } from '../../utils'
import { schemaFormBundles } from './schema'

interface FormBundle {
  check: SupportCompressionStructuralCheck | SteelCompressionCheck
  supportConfig: SupportGeometry
  targetCrossSection?: ElementCSAssignment
}

interface SecondaryHighlight {
  index: number
  field: 'cs_height' | 'cs_width' | 'setting_length' | 'setting_width'
}

interface Props {
  onSaveMemberCheckSettings: UseMutateAsyncFunction<
    SettingsOnMember,
    unknown,
    SettingsOnMember,
    void
  >
  onSaveCrossSectionAssignment: UseMutateAsyncFunction<void, AxiosError, ElementCSAssignment, void>
  handleClose: () => void
  isLoading: boolean
  supportCompressionChecks: SupportCompressionStructuralCheck[] | SteelCompressionCheck[]
  checkSettings: TimberCheckSettings | TimberSlabCheckSettings
  elementType: ElementTypes
}

const CompressionSettingsForm = ({
  onSaveMemberCheckSettings,
  onSaveCrossSectionAssignment,
  handleClose,
  isLoading,
  supportCompressionChecks,
  checkSettings,
  elementType,
}: Props): ReactElement | null => {
  const [secondaryHighlight, setSecondaryHighlight] = useState<SecondaryHighlight | null>(null)
  const { enqueueSnackbar } = useSnackbar()

  const {
    params: { selectedCheckPosition },
    actions: { setSelectedCheckPosition },
  } = useResultsQueryParams()

  const verticalTransmissionGraph = useResultsStore(state => state.verticalTransmissionGraph)

  const elementCrossSectionAssignment = useSystemManagerStore(
    state => state.elementCrossSectionAssignment,
  )

  const elementGuidToCrossSection = useMemo(
    () => mapValueKey(elementCrossSectionAssignment, 'element_guid'),
    [elementCrossSectionAssignment],
  )

  const elementsSupportingSelected = verticalTransmissionGraph
    ? getSupportsOfElement(checkSettings.element_guid, verticalTransmissionGraph)
    : []

  const getLabel = useElementLabel()

  const defaultValues = useMemo(() => {
    const formBundles = supportCompressionChecks.map(check => {
      const supportConfig = checkSettings.support_configs.find(
        config => Number(config.relative_position) === check.relative_position,
      )

      const targetGuid = elementsSupportingSelected.find(
        ({ relativePosition }) => Number(relativePosition) === check.relative_position,
      )?.guid

      const targetCrossSection = targetGuid ? elementGuidToCrossSection[targetGuid] : null

      return {
        check,
        supportConfig,
        targetCrossSection,
      }
    })

    return { formBundles }
  }, [
    checkSettings,
    elementCrossSectionAssignment,
    elementsSupportingSelected,
    supportCompressionChecks,
  ])

  const hasSupportWithCrossSection = useMemo(() => {
    // Leverage default values here so we don't need to work out the supports again
    const hasCrossSections = defaultValues.formBundles.map(bundle => !!bundle.targetCrossSection)

    return hasCrossSections.some(result => result)
  }, [defaultValues])

  useEffect(() => () => setSelectedCheckPosition(undefined), [])

  const onSubmit = (data: { formBundles: FormBundle[] }) => {
    const formBundles: FormBundle[] = data.formBundles

    // We save everything each time, only because it is simpler that way
    const updatedConfigs = checkSettings.support_configs.map(config => {
      const bundle = formBundles.find(
        bundle => bundle.supportConfig.relative_position === config.relative_position,
      )
      return bundle ? bundle.supportConfig : config
    })

    const hasCrossSection = some(formBundles.map(bundle => !!bundle.targetCrossSection))

    onSaveMemberCheckSettings({
      ...checkSettings,
      support_configs: updatedConfigs,
    })
      .then(() => {
        formBundles.forEach(bundle => {
          const updatedCrossSection = bundle.targetCrossSection
          if (updatedCrossSection) onSaveCrossSectionAssignment(updatedCrossSection)
        })
      })
      // Error snackbar messages are handled by the individual mutation functions
      .then(() => {
        const message = hasCrossSection
          ? 'Einstellungen und Querschnitts-Zuweisung erfolgreich gespeichert'
          : 'Einstellungen erfolgreich gespeichert'

        enqueueSnackbar(
          'Sie müssen die Berechnung neu starten um die neuen Einstellungen zu verwenden',
          { variant: 'warning' },
        )
        enqueueSnackbar(message, {
          variant: 'success',
        })
      })
  }

  return (
    <Form
      onSubmit={data => {
        onSubmit(data)
        handleClose()
      }}
      defaultValues={defaultValues}
      validationSchema={schemaFormBundles}
      key={checkSettings.element_guid}
      data-cy={'frm-compression-settings'}
    >
      <Stack direction="column" spacing={2}>
        <TableContainer
          component={Paper}
          sx={{ maxHeight: '55vh', width: hasSupportWithCrossSection ? '1000px' : '500px' }}
        >
          <Table stickyHeader size="small" sx={{ '.MuiTableCell-root': { paddingX: 1 } }}>
            <TableHead>
              <TableRow>
                <TableCell>#</TableCell>
                <TableCell>µ</TableCell>
                <TableCell>Pos</TableCell>
                {hasSupportWithCrossSection && (
                  <>
                    <TableCell align="center">darunterliegendes Bauteil</TableCell>
                    <TableCell align="center">Material (des darunterliegenden Bauteils)</TableCell>
                    <TableCell align="center">Breite (des darunterliegenden Bauteils)</TableCell>
                    <TableCell align="center">Höhe (des darunterliegenden Bauteils)</TableCell>
                  </>
                )}
                <TableCell align="center">b (Auflagerbreite)</TableCell>
                <TableCell align="center">l_A + ü (eff. Auflagerlänge)</TableCell>
                <TableCell>k_c,90</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {defaultValues.formBundles.map(({ check, targetCrossSection }, index) => {
                return (
                  <TableRow
                    key={check.relative_position}
                    onClick={() => setSelectedCheckPosition(check.relative_position)}
                    selected={selectedCheckPosition === check.relative_position}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{check.max_utilization.toFixed(2)}</TableCell>
                    <TableCell>{check.relative_position.toFixed(2)}</TableCell>
                    {hasSupportWithCrossSection && (
                      <>
                        <TableCell>
                          {targetCrossSection ? getLabel(targetCrossSection.element_guid) : 'n/a'}
                        </TableCell>
                        <TableCell>
                          {targetCrossSection && (
                            <TextField
                              name={`formBundles[${index}].targetCrossSection.element_cs.material.identifier`}
                              disabled={true}
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          {targetCrossSection && (
                            <CentimeterField
                              sx={{
                                border: 1,
                                borderColor:
                                  (elementType === 'lintels' || elementType === 'rips') &&
                                  secondaryHighlight?.index === index &&
                                  secondaryHighlight?.field === 'cs_width'
                                    ? 'green'
                                    : 'transparent',
                              }}
                              name={`formBundles[${index}].targetCrossSection.element_cs.shape.width`}
                              data-cy={`txt-cs-width-${index}`}
                              disabled={targetCrossSection.element_cs.material.kind === 'Steel'}
                              onFocus={() => {
                                setSecondaryHighlight({
                                  index,
                                  field: 'setting_length',
                                })
                              }}
                              onBlur={() => {
                                setSecondaryHighlight(null)
                              }}
                            />
                          )}
                        </TableCell>
                        <TableCell>
                          {targetCrossSection && (
                            <CentimeterField
                              sx={{
                                border: 1,
                                borderColor:
                                  (elementType === 'lintels' || elementType === 'rips') &&
                                  secondaryHighlight?.index === index &&
                                  secondaryHighlight?.field === 'cs_height'
                                    ? 'green'
                                    : 'transparent',
                              }}
                              name={`formBundles[${index}].targetCrossSection.element_cs.shape.height`}
                              data-cy={`txt-cs-height-${index}`}
                              disabled={targetCrossSection.element_cs.material.kind === 'Steel'}
                              onFocus={() => {
                                setSecondaryHighlight({
                                  index,
                                  field: 'setting_width',
                                })
                              }}
                              onBlur={() => {
                                setSecondaryHighlight(null)
                              }}
                            />
                          )}
                        </TableCell>
                      </>
                    )}
                    <TableCell>
                      <CentimeterField
                        name={`formBundles[${index}].supportConfig.width`}
                        data-cy={`txt-config-width-${index}`}
                        sx={{
                          border: 1,
                          borderColor:
                            (elementType === 'lintels' || elementType === 'rips') &&
                            secondaryHighlight?.index === index &&
                            secondaryHighlight?.field === 'setting_width'
                              ? 'green'
                              : 'transparent',
                        }}
                        onFocus={() => {
                          setSecondaryHighlight({
                            index,
                            field: 'cs_height',
                          })
                        }}
                        onBlur={() => {
                          setSecondaryHighlight(null)
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <CentimeterField
                        name={`formBundles[${index}].supportConfig.length`}
                        data-cy={`txt-config-length-${index}`}
                        sx={{
                          border: 1,
                          borderColor:
                            (elementType === 'lintels' || elementType === 'rips') &&
                            secondaryHighlight?.index === index &&
                            secondaryHighlight?.field === 'setting_length'
                              ? 'green'
                              : 'transparent',
                        }}
                        onFocus={() => {
                          setSecondaryHighlight({
                            index,
                            field: 'cs_width',
                          })
                        }}
                        onBlur={() => {
                          setSecondaryHighlight(null)
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      <Select
                        name={`formBundles[${index}].supportConfig.k_c_90`}
                        options={[
                          { value: 1.0, label: '1.0' },
                          { value: 1.25, label: '1.25' },
                          { value: 1.5, label: '1.5' },
                          { value: 1.75, label: '1.75' },
                        ]}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack direction="row" justifyContent={'space-between'}>
          <Box />
          <SubmitButton
            loading={isLoading}
            fullWidth={false}
            data-cy={`btn-compression-settings-submit`}
          />
        </Stack>
      </Stack>
    </Form>
  )
}

export default CompressionSettingsForm

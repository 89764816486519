import { Calculate } from '@mui/icons-material'
import { ReactComponent as AssemblyIcon } from 'src/assets/icons/assemblyIcon.svg'
import { ReactComponent as ExportIcon } from 'src/assets/icons/exportIcon.svg'
import { ReactComponent as LoadsIcon } from 'src/assets/icons/loadsIcon.svg'
import { ReactComponent as StructuralPlateIcon } from 'src/assets/icons/structuralIcon.svg'

export const navigationConfig = [
  {
    label: 'Aufbau Auswahl',
    route: (id: string) => `/editor/${id}`,
    icon: AssemblyIcon,
  },
  {
    label: 'Aufbau Zuweisung',
    route: (id: string) => `/editor/${id}/assembly-selection`,
    icon: AssemblyIcon,
  },
  {
    label: 'Globale Lasten',
    route: (id: string) => `/editor/${id}/global-loads`,
    icon: LoadsIcon,
  },
  {
    label: 'Tragwerksplanung',
    route: (id: string) => `/editor/${id}/structural-planning`,
    icon: StructuralPlateIcon,
  },
  {
    label: 'Ergebnisse',
    route: (id: string) => `/editor/${id}/results`,
    icon: Calculate,
  },
  {
    label: 'Dokumentation',
    route: (id: string) => `/editor/${id}/export`,
    icon: ExportIcon,
  },
]

import React, { ReactElement } from 'react'
import { SimpleShape } from '@scene'
import { DoubleSide } from 'three'
import { useTheme } from '@mui/material'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { useEnhanceClickEvent } from '../../hooks'

interface Props {
  slab: ShapeObject
  onClick?: () => void
}

const Slab2D = ({ slab, onClick }: Props): ReactElement => {
  const { scenePalette } = useTheme()
  const { onClickEnhanced } = useEnhanceClickEvent(slab.guid, onClick)

  return (
    <SimpleShape
      key={slab.guid}
      name={slab.guid}
      points={slab.shape.points.map(p => new ImmutableVector3(p.x, p.y, p.z))}
      color={scenePalette.elements3d.slabs}
      meshMaterialProps={{
        opacity: 0.2,
        transparent: true,
        side: DoubleSide,
        depthWrite: false,
        polygonOffset: true,
        polygonOffsetFactor: -1,
      }}
      outlines
      onClick={onClickEnhanced}
      cursor={onClickEnhanced ? 'pointer' : 'auto'}
    />
  )
}

export default Slab2D

type Options = {
  [key in LiveLoadLoadCaseType]: {
    label: string
  }
}

export const liveLoadCategoryOptions: Options = {
  LiveLoad_A1: {
    label: 'Nutzlast A1',
  },
  LiveLoad_A2: {
    label: 'Nutzlast A2',
  },
  LiveLoad_A3: {
    label: 'Nutzlast A3',
  },
  LiveLoad_B1: {
    label: 'Nutzlast B1',
  },
  LiveLoad_B2: {
    label: 'Nutzlast B2',
  },
  LiveLoad_B3: {
    label: 'Nutzlast B3',
  },
  LiveLoad_C1: {
    label: 'Nutzlast C1',
  },
  LiveLoad_C2: {
    label: 'Nutzlast C2',
  },
  LiveLoad_C3: {
    label: 'Nutzlast C3',
  },
  LiveLoad_C4: {
    label: 'Nutzlast C4',
  },
  LiveLoad_C5: {
    label: 'Nutzlast C5',
  },
  LiveLoad_C6: {
    label: 'Nutzlast C6',
  },
  LiveLoad_D1: {
    label: 'Nutzlast D1',
  },
  LiveLoad_D2: {
    label: 'Nutzlast D2',
  },
  LiveLoad_D3: {
    label: 'Nutzlast D3',
  },
  LiveLoad_E1: {
    label: 'Nutzlast E1',
  },
  LiveLoad_E2: {
    label: 'Nutzlast E2',
  },
  LiveLoad_F1: {
    label: 'Nutzlast F1',
  },
  LiveLoad_F2: {
    label: 'Nutzlast F2',
  },
  LiveLoad_H: {
    label: 'Nutzlast H',
  },
}

export const liveLoadCaseToLoadProposal: Record<LiveLoadLoadCaseType, number> = {
  LiveLoad_A1: 1000,
  LiveLoad_A2: 1500,
  LiveLoad_A3: 2000,
  LiveLoad_B1: 2000,
  LiveLoad_B2: 3000,
  LiveLoad_B3: 5000,
  LiveLoad_C1: 3000,
  LiveLoad_C2: 4000,
  LiveLoad_C3: 5000,
  LiveLoad_C4: 5000,
  LiveLoad_C5: 5000,
  LiveLoad_C6: 7500,
  LiveLoad_D1: 2000,
  LiveLoad_D2: 5000,
  LiveLoad_D3: 5000,
  LiveLoad_E1: 5000,
  LiveLoad_E2: 7500,
  LiveLoad_F1: 3000,
  LiveLoad_F2: 5000,
  LiveLoad_H: 1000,
}

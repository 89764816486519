import { isNull } from 'lodash-es'
import { postRequest, retryUntilSuccessOrFailure } from '../utils'

export const computeCheckResults = {
  key: 'computeCheckResults',
  request: async (projectId: string): Promise<void> => {
    const { data: elementCheckBundleData } = await postRequest<{ task_id: string } | null>({
      url: `/projects/${projectId}/element-check-bundle/compute`,
    })

    if (isNull(elementCheckBundleData)) throw new Error('Cannot compute element check bundle yet')

    await retryUntilSuccessOrFailure({
      url: `/task-status/${elementCheckBundleData?.task_id}`,
    })
  },
}

import React, { useMemo } from 'react'
import { filter } from 'lodash-es'
import { Stack } from '@mui/material'
import { Select } from '@ui/forms'
import { topDomainOptions } from '../utils'

interface Props {
  name: string
  selectedElement?: string
  'data-cy'?: string
  domains: Domain[]
}

const SelectDomain = ({ selectedElement, name, domains, 'data-cy': dataCy }: Props) => {
  const topDomains = useMemo(() => {
    const elementDomains = filter(domains, { element_guid: selectedElement }) || []
    const topDomains = topDomainOptions(elementDomains)

    if (!topDomains.length) return []

    return topDomains
  }, [selectedElement, domains])

  return (
    <Stack spacing={2}>
      <Select
        label="Kante auswählen"
        name={name}
        options={topDomains}
        disabled={!selectedElement}
        data-cy={dataCy}
      />
    </Stack>
  )
}

export default SelectDomain

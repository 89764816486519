import { useEffect } from 'react'
import { elementTypes } from '@scene'
import { isArray } from 'lodash-es'
import { v4 as uuid } from 'uuid'
import { createInteractableByType, useModelStore } from '@editorStores'

const useTypeInteraction = (
  type: (ElementTypes | 'all' | 'none') | ElementTypes[],
  condition = true,
) => {
  const addInteractableByTypeConfig = useModelStore(state => state.addInteractableByTypeConfig)
  const removeInteractableByTypeConfig = useModelStore(
    state => state.removeInteractableByTypeConfig,
  )

  useEffect(() => {
    if (!condition) return

    const configUuid = uuid()

    if (type === 'all')
      addInteractableByTypeConfig({
        uuid: configUuid,
        interactableByType: createInteractableByType(elementTypes),
      })
    else if (type === 'none')
      addInteractableByTypeConfig({
        uuid: configUuid,
        interactableByType: createInteractableByType([]),
      })
    else
      addInteractableByTypeConfig({
        uuid: configUuid,
        interactableByType: createInteractableByType(isArray(type) ? type : [type]),
      })

    return () => removeInteractableByTypeConfig(configUuid)
  }, [type, condition])
}

export default useTypeInteraction

import { useMemo } from 'react'
import { find } from 'lodash-es'
import { useModelStore, useSystemManagerStore } from '@editorStores'

const useSlabBeamToAssembly = () => {
  const model = useModelStore(state => state.model)
  const assemblies = useSystemManagerStore(state => state.assemblies)
  const assemblyAssignment = useSystemManagerStore(state => state.assemblyAssignment)
  return useMemo(() => {
    return [...model.vertical_slabs, ...model.vertical_roof_slabs].reduce((collector, slab) => {
      const slabAssemblyGuid = find(assemblyAssignment, ['element_guid', slab.guid])?.assembly_guid
      const slabAssembly = find(assemblies, ['guid', slabAssemblyGuid])
      if (slabAssembly) {
        collector[slab.beam.guid] = slabAssembly
      }
      return collector
    }, {} as Record<string, Assembly>)
  }, [model.vertical_slabs, model.vertical_roof_slabs, assemblies, assemblyAssignment])
}

export default useSlabBeamToAssembly

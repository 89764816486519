import React, { ReactElement } from 'react'
import {
  lineLikeElementTypes,
  roofSlabLikeElementTypes,
  slabLikeElementTypes,
  wallLikeElementTypes,
} from '@scene'
import { Divider, Stack } from '@mui/material'
import { SwitchLabeled } from '@ui/actions'
import { useModelStore } from '@editorStores'
import { StoreySelect } from '@editorComponents'
import { useFilterState } from '../../../../hooks/useFilterState'
import useStructuralPlanningQueryParams from '../../../../hooks/useStructuralPlanningQueryParams'

const Filters = (): ReactElement => {
  const visibleStoreys = useModelStore(state => state.visibleStoreys)
  const availableStoreys = useModelStore(state => state.availableStoreys)
  const typeVisibility = useModelStore(state => state.visibilityByType)
  const setTypeVisibility = useModelStore(state => state.setTypeVisibility)
  const toggleAllStoreysVisibility = useModelStore(state => state.toggleAllStoreysVisibility)
  const toggleSingleStoreyVisibility = useModelStore(state => state.toggleSingleStoreyVisibility)
  const { showRips } = useFilterState()
  const makeSwitchesForGroup = (
    group: {
      value: ElementTypes
      label: string
    }[],
  ) => {
    return group.map(({ value, label }) => (
      <Stack key={value} direction="row" alignItems="center">
        <SwitchLabeled
          checked={typeVisibility[value as ElementTypes]}
          onChange={event => setTypeVisibility(value as ElementTypes, event.target.checked)}
          label={label}
          data-cy={`${value}-switch`}
        />
      </Stack>
    ))
  }

  const {
    params: { storey },
    actions: { setStorey },
  } = useStructuralPlanningQueryParams()

  return (
    <>
      <StoreySelect
        visibleStoreys={visibleStoreys}
        availableStoreys={availableStoreys}
        toggleAll={toggleAllStoreysVisibility}
        toggleSingle={toggleSingleStoreyVisibility}
        clickMain={setStorey}
        selectedStorey={storey || undefined}
      />
      <Divider sx={{ my: 0.5 }} />
      {makeSwitchesForGroup(wallLikeElementTypes)}
      <Divider sx={{ my: 0.5 }} />
      {makeSwitchesForGroup(slabLikeElementTypes)}
      <Divider sx={{ my: 0.5 }} />
      {makeSwitchesForGroup(roofSlabLikeElementTypes)}
      <Divider sx={{ my: 0.5 }} />
      {makeSwitchesForGroup(lineLikeElementTypes)}
      <Divider sx={{ my: 0.5 }} />
      <SwitchLabeled
        checked={showRips}
        onChange={e => setTypeVisibility('rips' as ElementTypes, e.target.checked)}
        label="Rippen"
      />
    </>
  )
}

export default Filters

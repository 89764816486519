import React, { ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'
import { SaveButton } from '@ui/actions'

interface Props {
  isLoading: boolean
}

const SubmitButton = ({ isLoading }: Props): ReactElement => {
  const {
    formState: { isSubmitting },
  } = useFormContext()

  return (
    <SaveButton type="submit" loading={isLoading || isSubmitting} data-cy="export-settings-submit">
      Speichern
    </SaveButton>
  )
}

export default SubmitButton

import { filter, first, flatten, reduce } from 'lodash'
import { Vector2, Vector3 } from 'three'
import { LineString, point, polygon } from '@turf/helpers'
import { booleanContains, Feature, lineIntersect, lineString, Properties } from '@turf/turf'

export const makeTurfPolygonFromPoints = (points: (Point | Vector3 | Vector2)[]) => {
  const allPoints = [...points, first(points) as Point | Vector3 | Vector2]
  return polygon([allPoints.map(p => [p.x, p.y])])
}

/**
 * This method will clip a line by a polygon. It assumes that the line will not
 * be clipped multiple times (e.g. by a polygon that forms a U or some other
 * weird shape)
 * @param lineStart
 * @param lineEnd
 * @param polygon
 * @returns the clipped line in 2d space
 */
export const clipLineToPolygon = (lineStart: Vector2, lineEnd: Vector2, polygon: Vector2[]) => {
  const clippingPolygon = makeTurfPolygonFromPoints(polygon)

  const line = lineString([
    [lineStart.x, lineStart.y],
    [lineEnd.x, lineEnd.y],
  ])

  const startPoint = point([lineStart.x, lineStart.y])
  const endPoint = point([lineEnd.x, lineEnd.y])

  if (booleanContains(clippingPolygon, line)) {
    return [lineStart, lineEnd]
  }

  const allLines = reduce(
    polygon,
    (collector, item, i) => {
      const next = polygon[i === polygon.length - 1 ? 0 : i + 1]
      const linePoints = lineString([
        [item.x, item.y],
        [next.x, next.y],
      ])

      return [...collector, linePoints]
    },
    [] as Feature<LineString, Properties>[],
  )

  if (booleanContains(clippingPolygon, line)) {
    return [lineStart, lineEnd]
  }

  const intersections = allLines.map(polygonLine => lineIntersect(polygonLine, line))
  const filteredIntersections = filter(
    intersections,
    intersection => intersection.features.length !== 0,
  )
  const intersectionPoints = flatten(
    filteredIntersections.map(intersection => intersection.features),
  )

  if (intersectionPoints.length === 0) {
    return undefined
  }

  if (intersectionPoints.length === 2) {
    const start = intersectionPoints[0].geometry.coordinates
    const end = intersectionPoints[1].geometry.coordinates

    return [new Vector2(start[0], start[1]), new Vector2(end[0], end[1])]
  }

  if (intersectionPoints.length === 1) {
    const containedPoint = (booleanContains(clippingPolygon, startPoint) ? startPoint : endPoint)
      .geometry.coordinates
    const intersectionPoint = intersectionPoints[0].geometry.coordinates

    return [
      new Vector2(intersectionPoint[0], intersectionPoint[1]),
      new Vector2(containedPoint[0], containedPoint[1]),
    ]
  }
}

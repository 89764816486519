import { useMemo } from 'react'
import { useModelStore } from '@editorStores'
import mapValueKey from '../utils/mapValueKey'

const useGuidToElement = (): Record<string, ShapeObject | ShapeObjectLine> => {
  const model = useModelStore(state => state.model)

  const guidToElement = useMemo(
    () => ({
      ...mapValueKey(model.walls),
      ...mapValueKey(model.beams),
      ...mapValueKey(model.columns),
      ...mapValueKey(model.purlins),
      ...mapValueKey(model.roof_slabs),
      ...mapValueKey(model.slabs),
      ...mapValueKey(model.vertical_roof_slabs),
      ...mapValueKey(model.vertical_slabs),
    }),
    [model],
  )

  return guidToElement
}

export default useGuidToElement

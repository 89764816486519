export const tabConfig: WindAngle[] = ['0', '90', '180', '270']

export const seismicMethodsTabConfig = {
  excel: {
    value: 'excel',
    label: 'Excel oder RFEM',
  },
  simplified: {
    value: 'simplified',
    label: 'Vereinfachtes Verfahren',
  },
}

export type SeismicMethodTab = keyof typeof seismicMethodsTabConfig

export const seismicDirections: SeismicDirection[] = [
  'direction_0',
  'direction_90',
  'direction_180',
  'direction_270',
]

export const angleToDirection: Record<WindAngle, SeismicDirection> = {
  '0': 'direction_0',
  '90': 'direction_90',
  '180': 'direction_180',
  '270': 'direction_270',
}

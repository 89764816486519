import { useMemo } from 'react'
import { useModelStore } from '../stores/modelStore'
import { positionToDomain } from '../utils/positionToDomain'

const useAllDomains = (): Domain[] => {
  const domains = useModelStore(state => state.domains)
  const rips = useModelStore(state => state.model.rips)
  const lintels = useModelStore(state => state.model.lintels)

  const positionDomains = useMemo<Domain[]>(
    () => [...rips, ...lintels].map(position => positionToDomain(position)),
    [rips, lintels],
  )

  const allDomains = useMemo<Domain[]>(
    () => [...positionDomains, ...domains],
    [domains, positionDomains],
  )
  return allDomains
}

export default useAllDomains

import { object, number, string } from 'yup'

export const productLayerSchema = object({
  product: object({
    density: number().required().moreThan(0).default(0),
    weight: number().required().default(0),
    name: string().required().default(''),
    producer: string().default('').nullable(),
    description: string().default('').nullable(),
  }),
  thickness: number().required().moreThan(0).default(0),
  kind: string().default('product-layer'),
  color: string().required().default('#767676'),
})

import React, { ReactElement } from 'react'
import { BugReport } from '@mui/icons-material'
import { ToggleButton } from '@ui/actions'

interface Props {
  isActive?: boolean
  setIsActive?: (isActive: boolean) => void
}

const ProblemViewControls = ({ isActive = false, setIsActive }: Props): ReactElement => {
  return (
    <ToggleButton
      value="snap"
      size="small"
      onClick={() => setIsActive?.(!isActive)}
      selected={isActive}
      Icon={BugReport}
    />
  )
}

export default ProblemViewControls

import React, { ReactElement } from 'react'
import { SwitchLabeled } from '@ui/actions'
import { useStructuralPlanningStore } from '@editorStores'

const WallModeFilters = (): ReactElement => {
  const visibility = useStructuralPlanningStore(state => state.structuralPlanningElementVisibility)
  const changeVisibility = useStructuralPlanningStore(
    state => state.changeStructuralPlanningElementVisibility,
  )

  return (
    <>
      <SwitchLabeled
        checked={visibility.stiffening}
        onChange={() => changeVisibility('stiffening', !visibility.stiffening)}
        label="Aussteiffende Elemente"
      />
    </>
  )
}

export default WallModeFilters

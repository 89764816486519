import React, { ReactElement } from 'react'
import { Divider } from '@mui/material'
import { SwitchLabeled } from '@ui/actions'
import { useModelStore, useStructuralPlanningStore } from '@editorStores'
import { useFilterState } from '../../../hooks/useFilterState'

const WallModeFilters = (): ReactElement => {
  const { showRips, showLintels } = useFilterState()
  const toggleTypeVisibility = useModelStore(state => state.toggleTypeVisibility)

  const visibility = useStructuralPlanningStore(state => state.structuralPlanningElementVisibility)
  const changeVisibility = useStructuralPlanningStore(
    state => state.changeStructuralPlanningElementVisibility,
  )

  return (
    <>
      <SwitchLabeled
        checked={showLintels}
        onChange={() => toggleTypeVisibility('lintels' as ElementTypes)}
        label="Fensterstürze"
        data-cy="lintels-visibility-switch"
      />
      <SwitchLabeled
        checked={showRips}
        onChange={() => toggleTypeVisibility('rips' as ElementTypes)}
        label="Rippen"
        data-cy="rips-visibility-switch"
      />
      <Divider sx={{ my: 0.5 }} />
      <SwitchLabeled
        checked={visibility.stiffening}
        onChange={() => changeVisibility('stiffening', !visibility.stiffening)}
        label="Aussteiffende Elemente"
      />
      <Divider sx={{ my: 0.5 }} />
      <SwitchLabeled
        checked={visibility.verticalTransmitters}
        onChange={() => changeVisibility('verticalTransmitters', !visibility.verticalTransmitters)}
        label="Vertikale Auflager"
      />
      <SwitchLabeled
        checked={visibility.connectors}
        onChange={() => changeVisibility('connectors', !visibility.connectors)}
        label="Zugauflager"
      />
    </>
  )
}

export default WallModeFilters

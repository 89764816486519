import React, { ReactElement, useMemo } from 'react'
import { useController } from 'react-hook-form'
import { useQuery } from 'react-query'
import { find, isUndefined, isNull, isFunction } from 'lodash-es'
import { Select as MuiSelect, MenuItem, Typography } from '@mui/material'
import FormControl from '../FormControl'

interface Props {
  name: string
  query: RequestType
  renderOptions: (data: any) => { value: any; label: string }[] // eslint-disable-line
  placeholder?: string
  label?: string
  disabled?: boolean
  onChangeFinished?: () => void
  tooltip?: TooltipContents
  'data-cy'?: string
}

const SelectLoadable = ({
  name,
  query,
  placeholder = 'Bitte auswählen',
  label = '',
  tooltip,
  disabled = false,
  onChangeFinished,
  renderOptions,
  'data-cy': dataCy,
}: Props): ReactElement => {
  const { data, isLoading, error: backendError } = useQuery(query.key, query.request)
  const backendErroMessage = backendError ? 'Daten konnten nicht geladen werden' : undefined
  const {
    field: { onChange, onBlur, name: fieldName, value, ref },
    fieldState: { error },
  } = useController({
    name,
  })

  const options = useMemo(() => {
    if (!data) return

    return renderOptions(data)
  }, [data, renderOptions])

  const Placeholder = (
    <Typography
      sx={({ palette }) => ({
        color: palette.grey[500],
      })}
    >
      {placeholder}
    </Typography>
  )

  return (
    <FormControl
      label={label}
      error={error?.message || backendErroMessage}
      isLoading={isLoading}
      tooltip={tooltip}
    >
      <MuiSelect
        onChange={(...args) => {
          onChange(...args)
          isFunction(onChangeFinished) && onChangeFinished()
        }}
        onBlur={onBlur}
        value={isNull(value) ? '' : value}
        placeholder={placeholder}
        name={fieldName}
        inputRef={ref}
        size="small"
        disabled={disabled}
        displayEmpty
        renderValue={() => {
          if (isNull(value) || isUndefined(value)) {
            return Placeholder
          }

          return find(options, ['value', value])?.label || Placeholder
        }}
        data-cy={dataCy}
      >
        {options &&
          options.map(({ value, label }, index: number) => (
            <MenuItem
              key={value.toString() + index}
              value={value}
              data-cy={`${dataCy}-item-${index}`}
            >
              {label}
            </MenuItem>
          ))}
      </MuiSelect>
    </FormControl>
  )
}

export default SelectLoadable

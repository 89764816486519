import { isUndefined } from 'lodash-es'
import { string } from 'yup'
import * as yup from 'yup'

export const createLintelSchema = (guid: string, wallGuid: string) =>
  yup.object().shape({
    guid: string().default(guid),
    wall_guid: string().default(wallGuid),
    relative_start: yup
      .number()
      .min(0)
      .max(1)
      .test(
        'is-less-than-relative-end',
        'Start Position must be less than End Position',
        function (value) {
          return !isUndefined(value) ? value < this.parent.relative_end : false
        },
      ),
    relative_end: yup
      .number()
      .min(0)
      .max(1)
      .test(
        'is-more-than-relative-start',
        'End Position must be greater than Start Position',
        function (value) {
          return value ? value > this.parent.relative_start : false
        },
      ),
  })

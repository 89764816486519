import React, { ReactElement } from 'react'
import { Card, Typography } from '@mui/material'
import { Box } from '@ui/structure'
import { Icon } from './styles'

interface Props {
  iconSrc: string
  title: string
  text: string
}

const IconCard = ({ iconSrc, title, text }: Props): ReactElement => {
  return (
    <Card elevation={4} sx={{ height: '100%' }}>
      <Box display="flex" flexDirection="column" padding={4}>
        <Icon src={iconSrc} />
        <Typography variant="h4">{title}</Typography>
        <Box mt={2}>
          <Typography>{text}</Typography>
        </Box>
      </Box>
    </Card>
  )
}

export default IconCard

import React, { ReactElement, useState, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { reduce } from 'lodash-es'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Typography, AccordionSummary, AccordionDetails } from '@mui/material'
import { FormAccordion } from '@ui/forms'
import { useModelStore } from '@editorStores'
import SlabFormFields from '../SlabFormFields'

interface Props {
  direction: SeismicDirection
}

const FormFields = ({ direction }: Props): ReactElement => {
  const { watch } = useFormContext()

  const seismicEnabled: boolean = watch('do_seismic_analysis')

  const seismicForcesPerSlab: { [key: string]: SeismicForcesOnSlab } =
    watch('seismic_forces_per_slab')

  const slabIds = Object.keys(seismicForcesPerSlab)

  const [selectedGuid, setSelectedGuid] = useState<string>(slabIds[0])

  const model = useModelStore(state => state.model)

  const slabToStorey = useMemo(() => {
    let slabToStorey: Record<string, string> = {}

    Object.entries(model.storey_assignment.slab_storey_id_assignment).map(
      ([storey, slabs]) =>
        (slabToStorey = {
          ...slabToStorey,
          ...reduce(
            slabs,
            (collector, slab) => ({ ...collector, [slab]: storey }),
            {} as Record<string, string>,
          ),
        }),
    )

    return slabToStorey
  }, [model.storey_assignment.slab_storey_id_assignment])

  return (
    <>
      {slabIds.map(slabGuid => (
        <FormAccordion
          fields={`seismic_forces_per_slab[${slabGuid}]`}
          disabled={!seismicEnabled}
          key={slabGuid}
          expanded={slabGuid === selectedGuid}
          onChange={(_, expanded) => {
            if (expanded) setSelectedGuid(slabGuid)
          }}
        >
          <AccordionSummary
            key={slabToStorey[slabGuid]}
            expandIcon={<ExpandMoreIcon />}
            id={slabToStorey[slabGuid]}
          >
            <Typography variant="body2">
              {slabToStorey[slabGuid] === '0' ? 'EG' : `${slabToStorey[slabGuid]}. Stock`}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {slabGuid === selectedGuid && (
              <>
                <SlabFormFields
                  fieldPath={`seismic_forces_per_slab[${slabGuid}][${direction}]`}
                  formEnabled={seismicEnabled}
                />
              </>
            )}
          </AccordionDetails>
        </FormAccordion>
      ))}
      <FormAccordion
        fields={`seismic_forces_roof`}
        disabled={!seismicEnabled}
        expanded={selectedGuid === 'roof'}
        onChange={(_, expanded) => {
          if (expanded) setSelectedGuid('roof')
        }}
      >
        <AccordionSummary key={'roof'} expandIcon={<ExpandMoreIcon />} id={'roof'}>
          <Typography variant="body2">{`Dach`}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {selectedGuid === 'roof' && (
            <>
              <SlabFormFields
                fieldPath={`seismic_forces_roof[${direction}]`}
                formEnabled={seismicEnabled}
              />
            </>
          )}
        </AccordionDetails>
      </FormAccordion>
    </>
  )
}

export default FormFields

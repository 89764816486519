import React, { ReactElement, useMemo, useEffect } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { checkIfElementSupportsColumnLike } from '@structuralPlanningUtils'
import { AxiosError } from 'axios'
import { find } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { TabPanel } from '@mui/lab'
import { Stack, Tab, Typography } from '@mui/material'
import { useCameraStore } from '@modugen/scene/lib/controllers/CameraController/cameraStore'
import { useModelStore, useStructuralPlanningStore } from '@editorStores'
import {
  useBlockScene,
  useElementLabel,
  useElementTypes,
  useResultsInvalidation,
} from '@editorHooks'
import {
  getVerticalTransmissionGraph,
  getAssemblyAssignment,
  getModel,
  getElementCrossSectionAssignment,
} from '@queries'
import { createBeam, deleteBeam, updateBeam as updateBeamReq } from '@mutations'
import { buildErrorMessage } from 'src/constants'
import useElementHistory from 'src/hooks/useElementHistory'
import { getElementLabels } from 'src/state/queries/labels'
import SingleElementCSForm from '../../../../../../components/SingleElementCSForm'
import BeamFormBase from './BeamFormBase'
import GeometryForm from './GeometryForm'

interface Props {
  selectedElement: string
}

const BeamForm = ({ selectedElement }: Props): ReactElement | null => {
  const isOrthographic = useCameraStore(state => state.isOrthographic)

  const verticalTransmissionGraph = useStructuralPlanningStore(
    state => state.verticalTransmissionGraph,
  )

  const elementTypes = useElementTypes()
  const elementType = elementTypes[selectedElement]

  const { beams } = useModelStore(state => state.model)
  const removeBeam = useModelStore(state => state.removeBeam)
  const updateBeam = useModelStore(state => state.updateBeam)
  const setTypeVisibility = useModelStore(state => state.setTypeVisibility)

  const { enqueueSnackbar } = useSnackbar()
  const { projectId }: { projectId?: string } = useParams()
  const client = useQueryClient()

  const invalidateResults = useResultsInvalidation()

  const getLabel = useElementLabel()

  const { mutateAsync, isLoading } = useMutation(
    (beam: ShapeObjectLine) => createBeam.request(projectId, beam),
    {
      onSuccess: async (data: ShapeObjectLine) => {
        setBeamStale(data)
        await Promise.all([
          client.invalidateQueries(getModel.getKey(projectId)),
          client.invalidateQueries(getVerticalTransmissionGraph.getKey(projectId)),
          client.invalidateQueries(getAssemblyAssignment.getKey(projectId)),
          client.invalidateQueries(getElementLabels.getKey(projectId)),
          client.invalidateQueries(getElementCrossSectionAssignment.getKey(projectId)),
        ])
        invalidateResults(projectId as string)
        enqueueSnackbar('Unterzug erfolgreich gespeichert', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern des Unterzugs'), {
          variant: 'error',
        })
      },
    },
  )

  const { mutateAsync: update, isLoading: isUpdating } = useMutation(
    (beam: ShapeObjectLine) => updateBeamReq.request(projectId, beam),
    {
      onSuccess: async (data: ShapeObjectLine) => {
        setBeamStale(data)
        await Promise.all([
          client.invalidateQueries(getModel.getKey(projectId)),
          client.invalidateQueries(getVerticalTransmissionGraph.getKey(projectId)),
          client.invalidateQueries(getAssemblyAssignment.getKey(projectId)),
        ])
        invalidateResults(projectId as string)
        enqueueSnackbar('Unterzug erfolgreich gespeichert', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern des Unterzugs'), {
          variant: 'error',
        })
      },
    },
  )

  const { mutateAsync: handleDelete, isLoading: isDeleting } = useMutation(
    (beamGuid: string) => {
      const beamSupportsColumnLike = checkIfElementSupportsColumnLike(
        beamGuid,
        verticalTransmissionGraph,
        elementTypes,
      )
      if (beamSupportsColumnLike) {
        throw new Error(
          'Unterzug erhält Lasten aus einer Rippe oder einer Stütze. ' +
            'Entfernen Sie zunächst die Verbindung der Rippe bzw. Stütze zum Unterzug und löschen Sie dann den Unterzug',
        )
      }
      return deleteBeam.request(projectId, beamGuid)
    },
    {
      onSuccess: async () => {
        await Promise.all([
          client.invalidateQueries(getModel.getKey(projectId)),
          client.invalidateQueries(getVerticalTransmissionGraph.getKey(projectId)),
          client.invalidateQueries(getAssemblyAssignment.getKey(projectId)),
        ])
        invalidateResults(projectId as string)
        enqueueSnackbar('Unterzug erfolgreich gelöscht', { variant: 'success' })
      },
      onError: (error: Error | AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Löschen des Unterzugs'), {
          variant: 'error',
        })
      },
    },
  )

  useBlockScene(isLoading || isDeleting || isUpdating)

  const beam = useMemo(() => {
    return find(beams, ['guid', selectedElement]) as ShapeObjectLine
  }, [beams, selectedElement])

  const { setStale: setBeamStale } = useElementHistory({
    element: beam,
    getCurrentElement: () => {
      const beams = useModelStore.getState().model.beams
      return find(beams, ['guid', selectedElement]) as ShapeObjectLine
    },
    removeElement: beam => removeBeam(beam.guid),
    resetElement: beam => updateBeam(beam),
    dependencies: [selectedElement],
  })

  useEffect(() => {
    setTypeVisibility('beams' as ElementTypes, true)
  }, [])

  if (!beam) return null

  if (beam.is_local) {
    return (
      <GeometryForm
        beam={beam}
        handleSubmit={() => mutateAsync(beam)}
        handleDelete={() => removeBeam(beam.guid)}
      />
    )
  }

  return (
    <BeamFormBase
      initialTabValue={isOrthographic ? 'geometry' : 'vertical-transmission'}
      selectedElement={beam.guid}
      tab={<Tab value="geometry" label="Bauteil" data-cy="tab-geometry" />}
    >
      <TabPanel value="geometry">
        <Stack direction="column" spacing={2}>
          <Typography variant="h6">{getLabel(selectedElement)}</Typography>

          <SingleElementCSForm
            selectedElement={selectedElement}
            elementType={elementType as ElementTypes}
          />
          <GeometryForm
            beam={beam}
            handleSubmit={() => update(beam)}
            handleDelete={() => handleDelete(beam.guid)}
            isDeleting={isDeleting}
          />
        </Stack>
      </TabPanel>
    </BeamFormBase>
  )
}

export default BeamForm

import React, { ReactElement, useEffect } from 'react'
import { TabContext, TabPanel, TabList } from '@mui/lab'
import { Stack, Typography, Tab } from '@mui/material'
import { SaveButton } from '@ui/actions'
import { Checkbox, Form, UploadField } from '@ui/forms'
import { Box } from '@ui/structure'
import useGlobalLoadsQueryParams from '../../../../hooks/useGlobalLoadsQueryParams'
import { FormFields } from './components'
import { angleToDirection, tabConfig } from './constants'

interface Props {
  onSubmit: (values: SeismicSettings) => void
  isLoading: boolean
  isSaving: boolean
  data?: SeismicSettings
}

const SeismicSettingsForm = ({ onSubmit, isSaving, data }: Props): ReactElement => {
  const defaultValue = tabConfig[0]

  const {
    params: { activeSeismicAngle },
    actions: { setActiveSeismicAngle },
  } = useGlobalLoadsQueryParams()

  const navigateTab = (value: string) => {
    // set to null to remove query param
    setActiveSeismicAngle(value === defaultValue ? null : (value as WindAngle))
  }

  useEffect(() => {
    setActiveSeismicAngle(activeSeismicAngle || defaultValue)
  }, [activeSeismicAngle])

  return (
    <>
      <Form onSubmit={onSubmit} defaultValues={data} data-cy="seismic-settings-form">
        <Stack direction="column" spacing={2}>
          <Box p={1} border={1} borderColor="grey.200" borderRadius={1}>
            <Stack
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography>Vereinfachtes Erdbeben aktivieren</Typography>
              <Checkbox name="do_seismic_analysis" />
            </Stack>
            <Stack
              direction="row"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography>
                Alle Kräfte und Exzentrizitäten müssen <strong>mit ihrem Vorzeichen</strong> in
                Bezug auf das <strong>globale Koordinatensystem</strong> eingegeben werden.
              </Typography>
            </Stack>
            <TabContext value={activeSeismicAngle || defaultValue}>
              <Box
                sx={{
                  '& .MuiTabs-root': {
                    minHeight: ({ spacing }) => spacing(4),
                  },
                  '& .MuiTab-root': {
                    fontSize: 12,
                    paddingX: ({ spacing }) => spacing(1.5),
                    height: ({ spacing }) => spacing(3),
                    minHeight: ({ spacing }) => spacing(3),
                  },
                }}
              >
                <TabList onChange={(_, value) => navigateTab(value)}>
                  {tabConfig.map(angle => (
                    <Tab
                      value={angle}
                      label={`${angle}°`}
                      key={angle}
                      data-cy={`tab-angle-${angle}`}
                    />
                  ))}
                </TabList>
                {tabConfig.map(angle => (
                  <TabPanel value={angle} key={angle}>
                    <FormFields direction={angleToDirection[angle]} />
                  </TabPanel>
                ))}
              </Box>
            </TabContext>
          </Box>
          <Box p={1} border={1} borderColor="grey.200" borderRadius={1}>
            <UploadField
              name="seismic_results_file"
              label="Spektralanalyse-Ergebnisse aus RFEM (Alternativ zu vereinfachtem Verfahren)"
            ></UploadField>
          </Box>
          <Box>
            <SaveButton loading={isSaving} type="submit" data-cy="save-seismic-settings-btn">
              Speichern
            </SaveButton>
          </Box>
        </Stack>
      </Form>
    </>
  )
}

export default SeismicSettingsForm

import knot from '@img/ifc-knot.png'
import stab from '@img/ifc-stab.png'
import model from '@img/logic-model.png'

export const cardContents = [
  {
    iconSrc: knot,
    title: 'IFC',
    text: 'Im ersten Schritt lesen wir 3D Entwurfspläne ein und verarbeiten diese automatisch.',
  },
  {
    iconSrc: model,
    title: 'Semantisches Modell',
    text: 'Durch intelligente Analysen erstellen wir ein vollständiges semantisches Gebäudemodell.',
  },
  {
    iconSrc: stab,
    title: 'Statik',
    text: 'Daraus leiten wir gemeinsam mit dem Statiker eine vollständige, prüfbare Statik für Holzgebäude ab.',
  },
]

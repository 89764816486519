import React, { ReactElement } from 'react'
import { Form } from '@ui/forms'
import TimberFormFields from './TimberFormFields'

const CheckSettingsForm = ({
  formId,
  checkSettings,
  elementType,
  supportingElementCrossSections,
  showShearCheckSettings,
  onSettingsUpdate,
  setHasChanged,
}: {
  formId: string
  elementType: ElementTypes
  checkSettings: SettingsOnMember
  supportingElementCrossSections: (ElementCSAssignment | null)[] // Order is the aligned to the order of support config
  showShearCheckSettings: boolean
  onSettingsUpdate: (data: SettingsOnMember) => void
  setHasChanged: () => void
}): ReactElement | null => {
  if (checkSettings.setting_type !== 'timber') return <></>

  return (
    <Form
      id={formId}
      onSubmit={data => onSettingsUpdate(data)}
      defaultValues={checkSettings}
      key={checkSettings.element_guid}
      data-cy={'frm-cs-opt-compression-settings'}
    >
      <TimberFormFields
        checkSettings={checkSettings}
        setHasChanged={setHasChanged}
        elementType={elementType}
        supportingElementCrossSections={supportingElementCrossSections}
        showShearCheckSettings={showShearCheckSettings}
      />
    </Form>
  )
}

export default CheckSettingsForm

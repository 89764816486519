// we often need to transform a list to a value key mapping. This hook does this
import { reduce } from 'lodash-es'

// and memoizes the result
const mapValueKey = <T>(data: T[], key = 'guid'): Record<string, T> => {
  return reduce(
    data,
    (collector, item) => ({
      ...collector,
      // @ts-ignore
      [item[key]]: item,
    }),
    {} as Record<string, T>,
  )
}

export default mapValueKey

import React from 'react'
import { Divider, Stack, Switch, Typography } from '@mui/material'
import { SwitchLabeled } from '@ui/actions'
import { useModelStore } from '@editorStores'
import {
  slabLikeElementTypes,
  wallLikeElementTypes,
  roofSlabLikeElementTypes,
  lineLikeElementTypes,
  foundationLikeElementTypes,
} from 'src/components/scene/constants'

const VisibilitySwitches = () => {
  const typeVisibility = useModelStore(state => state.visibilityByType)
  const setTypeVisibility = useModelStore(state => state.setTypeVisibility)
  const translucent = useModelStore(state => state.translucent)
  const setTranslucent = useModelStore(state => state.setTranslucent)

  const makeSwitchesForGroup = (
    group: {
      value: ElementTypes
      label: string
    }[],
  ) => {
    return group.map(({ value, label }) => (
      <Stack key={value} direction="row" alignItems="center">
        <SwitchLabeled
          checked={typeVisibility[value as ElementTypes]}
          onChange={event => setTypeVisibility(value as ElementTypes, event.target.checked)}
          label={label}
          data-cy={`${value}-switch`}
        />
      </Stack>
    ))
  }

  return (
    <>
      <Stack direction="row" alignItems="center">
        <Switch
          checked={translucent}
          onChange={event => setTranslucent(event.target.checked)}
          size="small"
        />
        <Typography sx={{ p: 1 }}>Transparent</Typography>
      </Stack>
      <Divider sx={{ my: 0.5 }} />
      {makeSwitchesForGroup(foundationLikeElementTypes)}
      <Divider sx={{ my: 0.5 }} />
      {makeSwitchesForGroup(wallLikeElementTypes)}
      <Divider sx={{ my: 0.5 }} />
      {makeSwitchesForGroup(slabLikeElementTypes)}
      <Divider sx={{ my: 0.5 }} />
      {makeSwitchesForGroup(roofSlabLikeElementTypes)}
      <Divider sx={{ my: 0.5 }} />
      {makeSwitchesForGroup(lineLikeElementTypes)}
    </>
  )
}

export default VisibilitySwitches

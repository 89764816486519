export const tabConfig: WindAngle[] = ['0', '90', '180', '270']

export const windDirections: WindDirection[] = [
  'Direction0',
  'Direction90',
  'Direction180',
  'Direction270',
]

export const windAngleToDirection: Record<WindAngle, WindDirection> = {
  '0': 'Direction0',
  '90': 'Direction90',
  '180': 'Direction180',
  '270': 'Direction270',
}

export const roofAreaTypeConfig: {
  value: RoofAreaTypes
  label: string
}[] = [
  {
    value: 'Luv',
    label: 'Luv',
  },
  {
    value: 'Right',
    label: 'windparallel rechts',
  },
  {
    value: 'Lee',
    label: 'Lee',
  },
  {
    value: 'Left',
    label: 'windparallel links',
  },
]
export const roofAreaTypes: RoofAreaTypes[] = roofAreaTypeConfig.map(({ value }) => value)

export const roofTypeConfig: {
  value: RoofTypes
  label: string
  initialValues: Omit<RoofFormConfig, 'roofType'>
}[] = [
  {
    value: 'GableRoof',
    label: 'Sattel',
    initialValues: {
      flowArea: 'Luv',
      alpha: null,
      isShortSide: true,
    },
  },
  {
    value: 'HipRoof',
    label: 'Walm',
    initialValues: {
      flowArea: 'Luv',
      alpha: 0,
      isShortSide: true,
    },
  },
  {
    value: 'FlatRoof',
    label: 'Flach',
    initialValues: {
      flowArea: null,
      alpha: null,
      isShortSide: null,
    },
  },
  {
    value: 'ShedRoof',
    label: 'Pult',
    initialValues: {
      flowArea: 'Luv',
      alpha: null,
      isShortSide: true,
    },
  },
]

export const roofTypes: RoofTypes[] = roofTypeConfig.map(({ value }) => value)

import React, { ReactElement, useMemo } from 'react'
import { maxBy, minBy, reduce, toNumber } from 'lodash-es'
import { Vector3 } from 'three'
import { toImmutable } from '@modugen/scene/lib/utils'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { ThreeEvent } from '@react-three/fiber'
import SimpleStiffeningMeshElement from '../SimpleStiffeningMeshElement'

const stiffeningThickness = 0.02

interface Props {
  intervals: StiffeningSegment[]
  model: PlanarModel
  onClick?: (guid: string | undefined, event: ThreeEvent<MouseEvent>) => void
  highlightedIds?: string[]
}

/**
 * Display all stiffening elements defined in intervals. Will extract walls from
 * ParsingResult and map with the intervals defined in intervals
 */
const SimpleStiffeningMesh = ({
  intervals,
  model,
  onClick,
  highlightedIds = [],
}: Props): ReactElement => {
  const wallToPoints = useMemo(
    () =>
      reduce(
        model.walls,
        (collector, wall) => ({
          ...collector,
          [wall.guid]: wall.shape.points,
        }),
        {} as Record<string, ImmutableVector3[]>,
      ),
    [model],
  )

  const combinedElements = useMemo(
    () =>
      intervals.map(interval => {
        const wallPoints = wallToPoints[interval.element_guid]

        const [initialStart, end] = wallPoints
        const direction = end.sub(initialStart).normalize()
        let height = 0
        if (interval.effective_height === undefined) {
          // Stiffening Proposals don't have a height yet
          const zMax = maxBy(wallPoints, 'z')?.z || 0
          const zMin = minBy(wallPoints, 'z')?.z || 0
          height = zMax - zMin
        } else {
          height = interval.effective_height
        }

        const bottomLeft = new Vector3().addVectors(
          initialStart.v,
          direction.multiplyScalar(toNumber(interval.interval.lower)).v,
        )
        const distance = toNumber(interval.interval.upper) - toNumber(interval.interval.lower)

        const bottomRight = new Vector3().addVectors(
          bottomLeft,
          direction.multiplyScalar(distance).v,
        )
        const heightOffset = new Vector3(0, 0, height)
        const topRight = new Vector3().addVectors(bottomRight, heightOffset)
        const topLeft = new Vector3().addVectors(bottomLeft, heightOffset)
        const directedPoints = [bottomLeft, bottomRight, topRight, topLeft]

        const v1 = bottomRight.clone().sub(bottomLeft).normalize()
        const v2 = topLeft.clone().sub(bottomLeft).normalize()
        const extrusionDirection = v1.cross(v2)

        const intervalPoints = directedPoints.map(point =>
          point.clone().add(extrusionDirection.clone().multiplyScalar(-stiffeningThickness / 2)),
        )

        return {
          interval,
          wallPoints,
          intervalPoints,
        }
      }),
    [intervals, model],
  )

  return (
    <>
      {combinedElements.map(item => (
        <SimpleStiffeningMeshElement
          key={item.interval.guid}
          points={item.intervalPoints.map(p => toImmutable(p))}
          thickness={stiffeningThickness}
          onClick={e => onClick?.(item.interval.guid, e)}
          highlighted={highlightedIds.includes(item.interval.guid as string)}
        />
      ))}
    </>
  )
}

export default SimpleStiffeningMesh

import { useMemo } from 'react'
import { isUndefined, reject } from 'lodash-es'
import { useModelStore } from '../../../stores/modelStore'

const useElementPositionType = (guid: string | undefined): PositionGroupingType | undefined => {
  const model = useModelStore(state => state.model)

  const beams = useMemo(() => model.beams.map(({ guid }) => guid), [model])
  const columns = useMemo(() => model.columns.map(({ guid }) => guid), [model])
  const purlins = useMemo(() => model.purlins.map(({ guid }) => guid), [model])

  const rips = useModelStore(state => state.model.rips)
  const lintels = useModelStore(state => state.model.lintels)
  const verticalSlabs = useModelStore(state => state.model.vertical_slabs)
  const verticalRoofSlabs = useModelStore(state => state.model.vertical_roof_slabs)

  const slabBeams = useMemo(
    () =>
      reject(
        verticalSlabs?.map(slab => slab.beam?.guid),
        guid => isUndefined(guid),
      ) as string[],
    [verticalSlabs],
  )

  const roofSlabBeams = useMemo(
    () =>
      reject(
        verticalRoofSlabs?.map(slab => slab.beam?.guid),
        guid => isUndefined(guid),
      ) as string[],
    [verticalRoofSlabs],
  )

  const positionOfSelectedElement = useMemo(() => {
    if (!guid) return undefined

    if (beams.includes(guid)) return 'beams'
    if (columns.includes(guid)) return 'columns'
    if (purlins.includes(guid)) return 'purlins'
    if (slabBeams.includes(guid)) return 'slab-beams'
    if (roofSlabBeams.includes(guid)) return 'roof-slab-beams'
    if (lintels?.map(({ position_guid }) => position_guid).includes(guid)) return 'wall-lintels'
    if (rips?.map(({ position_guid }) => position_guid).includes(guid)) return 'wall-rips'
  }, [guid, rips, lintels, beams, columns, purlins, slabBeams, roofSlabBeams])

  return positionOfSelectedElement
}

export default useElementPositionType

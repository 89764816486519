import { StringParam, useQueryParams } from 'use-query-params'
import { SeismicMethodTab } from '../components/SeismicSettingsTab/components/SeismicSettingsForm/constants'
import { tabConfig as windAssignmentTagConfig } from '../components/WindloadsTab/components/WindLoadsForm/constants'
import { tabConfig } from '../constants'

type LoadsTab = keyof typeof tabConfig
type WindAssignmentTab = keyof typeof windAssignmentTagConfig

interface Params {
  activeRoofAssignmentTab?: WindAngle | null
  buildingDimension?: Dimensions | null
  tab?: LoadsTab | null
  windAssignmentTab?: WindAssignmentTab | null
  activeSeismicAngle?: WindAngle | null
  activeSeismicMethodTab?: SeismicMethodTab | null
}

const useGlobalLoadsQueryParams = () => {
  const [params, setQuery] = useQueryParams({
    activeWindAngle: StringParam,
    buildingDimension: StringParam,
    tab: StringParam,
    windAssignmentTab: StringParam,
    activeSeismicAngle: StringParam,
    activeSeismicMethodTab: StringParam,
  })

  const {
    activeWindAngle,
    buildingDimension,
    tab,
    windAssignmentTab,
    activeSeismicAngle,
    activeSeismicMethodTab,
  } = params

  // set all parameters that are not passed to undefined
  const setQueryReset = (newParams: Params = {}) => {
    const result = Object.entries(params).reduce((acc: Params, [key]) => {
      // @ts-ignore
      if (newParams[key]) {
        return {
          ...acc,
          // @ts-ignore
          [key]: newParams[key],
        }
      }

      return {
        ...acc,
        [key]: undefined,
      }
    }, {})

    setQuery(result)
  }

  const reset = () => setQueryReset()

  const setActiveWindAngle = (activeWindAngle?: WindAngle | null) => {
    setQuery({ activeWindAngle })
  }

  const setBuildingDimension = (dimension?: Dimensions | null) => {
    setQuery({ buildingDimension: dimension })
  }

  const toggleTab = (tab?: LoadsTab) => {
    setQueryReset({ tab })
  }

  const toggleWindAssignmentTab = (windAssignmentTab?: WindAssignmentTab) => {
    setQueryReset({ windAssignmentTab, tab: tab as LoadsTab })
  }

  const setActiveSeismicAngle = (activeSeismicAngle?: WindAngle | null) => {
    setQuery({ activeSeismicAngle })
  }

  const setActiveSeismicMethodTab = (activeSeismicMethodTab?: SeismicMethodTab) => {
    setQuery({ activeSeismicMethodTab })
  }

  return {
    params: {
      activeRoofAssignmentTab: activeWindAngle,
      buildingDimension,
      tab,
      windAssignmentTab: windAssignmentTab || 'base-params',
      activeSeismicAngle,
      activeSeismicMethodTab,
    } as Params,
    actions: {
      reset,
      setActiveWindAngle,
      setBuildingDimension,
      toggleTab,
      toggleWindAssignmentTab,
      setActiveSeismicAngle,
      setActiveSeismicMethodTab,
    },
  }
}

export default useGlobalLoadsQueryParams

import { array, number, object, string } from 'yup'

// Define the point schema
const point = object({
  x: number().default(0),
  y: number().default(0),
  z: number().default(0),
})

export const schema = object({
  guid: string().required(),
  points: array().of(point),
})

// Main schema with custom validation to ensure all z values are the same
export const foundationSchema = object({
  guid: string().required(),
  points: array()
    .of(point)
    .test('all-z-same', 'Alle z-Koordinaten müssen gleich sein für Fundamente.', points => {
      if (!points || points.length === 0) return true // If no points, validation passes
      const zValues = points.map(p => p.z)
      return zValues.every(z => z === zValues[0])
    }),
})

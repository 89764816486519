import { useState, useEffect } from 'react'
import { isNull, mergeWith, omit } from 'lodash-es'
import { GetState, SetState } from 'zustand'

interface Arguments<T extends object> {
  data: Partial<T>
  setState: SetState<T>
  getState: GetState<T>
  dependency: string | number
  omitFields?: (state: T) => (keyof T)[]
}

const useStoreReinitializer = <T extends object>({
  data,
  setState,
  getState,
  dependency,
  omitFields,
}: Arguments<T>) => {
  const [initialized, setInitialized] = useState(false)

  useEffect(() => {
    const currentState = getState()

    const omittedData = omit(data, omitFields ? omitFields(currentState) : [])

    const newState = mergeWith(omittedData, currentState, (stateOfProps, currentState) =>
      isNull(stateOfProps) ? currentState : stateOfProps,
    )

    setState(newState)
    setInitialized(true)
  }, [dependency])

  return initialized
}

export default useStoreReinitializer

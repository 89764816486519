import React, { useMemo } from 'react'
import { BasicMesh } from '@modugen/scene/lib/components/BasicMesh'
import { theme } from 'src/styles'
import Arrow from '../Arrow'
import ShapeMesh from '../ShapeMesh'

interface Props {
  loadDisplayData: LineLoadDisplayData
  onClick?: () => void
  isActive?: boolean
}

const LineLoadMeshElement = ({ loadDisplayData, onClick, isActive = false }: Props) => {
  const { magnitudePoints: loadPoint, shapeObject, label, endLabel } = loadDisplayData

  const directionStart = useMemo(() => loadPoint[0].from.clone().sub(loadPoint[0].to), [loadPoint])
  const directionEnd = useMemo(() => loadPoint[1].from.clone().sub(loadPoint[1].to), [loadPoint])

  return (
    <BasicMesh cursor="pointer" onClick={onClick}>
      <Arrow
        onClick={onClick}
        direction={directionStart.clone().normalize()}
        origin={loadPoint[0].to}
        length={directionStart.length()}
        label={label}
        highlighted={isActive}
      />
      <ShapeMesh
        data={shapeObject}
        applyThickness
        onClick={onClick}
        meshMaterialProps={{
          color: theme.palette.grey[800],
          polygonOffset: true,
          polygonOffsetUnits: -100 + 1 * 10,
          opacity: 0.4,
          transparent: true,
        }}
      />
      <Arrow
        onClick={onClick}
        direction={directionEnd.clone().normalize()}
        origin={loadPoint[1].to}
        length={directionEnd.length()}
        label={endLabel}
        highlighted={isActive}
      />
    </BasicMesh>
  )
}

export default LineLoadMeshElement

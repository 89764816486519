export const LayerTypeEnum = Object.freeze({
  TimberFrameLayer: 'timber-frame-layer' as LayerType,
  ProductLayer: 'product-layer' as LayerType,
  PlateLayer: 'plate-layer' as LayerType,
})

export const AssemblyTypeEnum = Object.freeze({
  Inner: 'Inner' as AssemblyTypes,
  Outer: 'Outer' as AssemblyTypes,
  Ceiling: 'Ceiling' as AssemblyTypes,
  Roof: 'Roof' as AssemblyTypes,
})

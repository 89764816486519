import { Line3, Plane, Vector2, Vector3 } from 'three'
import { toImmutable } from '@modugen/scene/lib/utils'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'

/**
 * Calculates the intersection point on the plane from a point defined on the xy
 * plane assuming a height direction of x: 0, y: 0, z: 1
 * @param plane
 * @param point2D
 */
const getPlaneLineIntersection = (plane: Plane, point2D: Vector2): ImmutableVector3 | null => {
  const start = new ImmutableVector3(point2D.x, point2D.y, 0)
  const end = new ImmutableVector3(point2D.x, point2D.y, Number.MAX_SAFE_INTEGER)

  const line = new Line3(start.v, end.v)

  const intersection = plane.intersectLine(line, new Vector3())

  return intersection ? toImmutable(intersection) : null
}

export default getPlaneLineIntersection

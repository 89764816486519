import React, { ReactElement, useEffect, useMemo } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { useElementHistory } from '@hooks'
import { AxiosError } from 'axios'
import { find, toNumber } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { TabPanel } from '@mui/lab'
import { Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useCameraStore } from '@modugen/scene/lib/controllers/CameraController/cameraStore'
import { DeleteButton } from '@ui/actions'
import { Box } from '@ui/structure'
import { useEditElementStore, useModelStore } from '@editorStores'
import { useBlockScene, useResultsInvalidation } from '@editorHooks'
import { useModelInvalidation } from '@structuralPlanningHooks'
import {
  createWall,
  deleteWall,
  translateWall,
  updateWallPlacement,
  updateWallStartOrEnd,
} from '@mutations'
import { buildErrorMessage } from 'src/constants'
import { GeometryForm, WallFormBase } from './components'

interface Props {
  selectedElement: string
  geometryEditable?: boolean
  storeySelected: boolean
}

const WallForm = ({
  selectedElement,
  geometryEditable = true,
  storeySelected,
}: Props): ReactElement | null => {
  const isOrthographic = useCameraStore(state => state.isOrthographic)

  const { walls } = useModelStore(state => state.model)

  const removeWall = useModelStore(state => state.removeWall)
  const updateWall = useModelStore(state => state.updateWall)

  const setActiveElement = useEditElementStore(state => state.setActiveElement)

  const { enqueueSnackbar } = useSnackbar()
  const { projectId }: { projectId?: string } = useParams()

  const invalidateModel = useModelInvalidation()
  const invalidateResults = useResultsInvalidation()

  // MUTATIONS

  const { mutateAsync, isLoading } = useMutation(
    (wall: ShapeObject) =>
      createWall.request(projectId, {
        p1: wall.shape.points[0],
        p2: wall.shape.points[1],
        placement: wall.placement,
        storey_idx: toNumber(wall.storey),
      }),
    {
      onSuccess: async (data: ShapeObject) => {
        setWallStale(wall)
        await invalidateModel(projectId as string)
        updateWall(data)
        invalidateResults(projectId as string)
        enqueueSnackbar('Wand erfolgreich gespeichert', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern der Wand'), {
          variant: 'error',
        })
      },
    },
  )

  const { mutateAsync: handleDelete, isLoading: isDeleting } = useMutation(
    (wallGuid: string) => deleteWall.request(projectId, wallGuid),
    {
      onSuccess: async () => {
        await invalidateModel(projectId as string)
        setActiveElement(null)
        removeWall(wall.guid)
        invalidateResults(projectId as string)
        enqueueSnackbar('Wand erfolgreich gelöscht', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Löschen der Wand'), {
          variant: 'error',
        })
      },
    },
  )

  const { mutateAsync: handleUpdate, isLoading: isUpdating } = useMutation(
    async (wall: ShapeObject) => {
      const [start, end] = wall.shape.points
      const updatedWall: UpdatedWall = {
        new_start: {
          x: start.x,
          y: start.y,
        },
        new_end: {
          x: end.x,
          y: end.y,
        },
      }

      const wallPlacementResponse = await updateWallPlacement.request(
        projectId,
        wall.guid,
        wall.placement,
      )

      if (wall.is_updated) {
        return await updateWallStartOrEnd.request(projectId, wall.guid, updatedWall)
      } else if (wall.is_translated) {
        return await translateWall.request(projectId, wall.guid, updatedWall)
      }
      return wallPlacementResponse
    },
    {
      onSuccess: async (wall: ShapeObject) => {
        setWallStale(wall)
        await invalidateModel(projectId as string)
        updateWall(wall)
        setActiveElement(null)

        invalidateResults(projectId as string)
        enqueueSnackbar('Wand erfolgreich verändert', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Ändern der Wand'), {
          variant: 'error',
        })
      },
    },
  )

  useBlockScene(isDeleting || isLoading || isUpdating)

  const wall = useMemo(() => {
    return find(walls, ['guid', selectedElement]) as ShapeObject
  }, [walls, selectedElement])

  useEffect(() => {
    return () => {
      if (wall && wall.is_local) removeWall(wall.guid)
    }
  }, [wall?.guid])

  const { setStale: setWallStale, reset } = useElementHistory({
    element: wall,
    getCurrentElement: () => {
      const walls = useModelStore.getState().model.walls
      return find(walls, ['guid', selectedElement]) as ShapeObject
    },
    removeElement: wall => removeWall(wall.guid),
    resetElement: wall => {
      updateWall({
        ...wall,
        is_translated: undefined,
        is_updated: undefined,
        is_local: undefined,
      })
    },
    dependencies: [selectedElement],
  })

  // HOTKEYS

  useHotkeys(
    'enter',
    () => {
      if (wall.is_translated || wall.is_updated) {
        handleUpdate(wall)
      }
      if (wall.is_local) {
        mutateAsync(wall)
      }
    },
    { enabled: wall && (wall.is_translated || !!wall.is_updated || wall.is_local) },
    [wall, updateWall],
  )

  if (!storeySelected) return null

  if (!wall)
    return (
      <Box component="div" p={1} border={1} borderColor="grey.200" borderRadius={1} mb={2}>
        <Typography mb={1}>Sie haben nun die Möglichkeit neue Wände einzuzeichnen</Typography>
      </Box>
    )

  if (wall.is_local) {
    return (
      <Stack direction="column" spacing={2}>
        <GeometryForm
          wall={wall}
          handleSubmit={() => mutateAsync(wall)}
          handleDelete={() => {
            setActiveElement(null)
            removeWall(wall.guid)
          }}
          isEditable
          isSaveable
          isSubmitting={isLoading}
        />
      </Stack>
    )
  }

  return (
    <WallFormBase initialTabValue={isOrthographic ? 'geometry' : 'vertical-transmission'}>
      <TabPanel value="geometry">
        {geometryEditable ? (
          <GeometryForm
            wall={wall}
            handleSubmit={() => handleUpdate(wall)}
            handleDelete={() => handleDelete(wall.guid)}
            isDeleting={isDeleting}
            isEditable={false}
            isSaveable
            isSubmitting={isUpdating}
            isResettable={!!wall.is_updated || wall.is_translated}
            handleReset={reset}
          />
        ) : (
          <DeleteButton
            onClick={() => handleDelete(wall.guid)}
            loading={isDeleting}
            data-cy="btn-delete-wall"
          >
            Löschen
          </DeleteButton>
        )}
      </TabPanel>
    </WallFormBase>
  )
}

export default WallForm

import React, { ReactElement, useEffect, memo, useRef } from 'react'
import { isNull, mergeWith } from 'lodash-es'

interface Props {
  data: any // eslint-disable-line
  storeApi: any // eslint-disable-line
}

// Zustand context does not update create store when props change
// use with caution
const StoreReinitializer = ({ data, storeApi }: Props): ReactElement => {
  const initialRenderPassed = useRef(false)
  const { setState, getState } = storeApi()

  useEffect(() => {
    if (!initialRenderPassed.current) {
      initialRenderPassed.current = true
      return
    }

    const currentState = getState()

    const newState = mergeWith(data, currentState, (stateOfProps, currentState) =>
      isNull(stateOfProps) ? currentState : stateOfProps,
    )

    setState(newState)
  }, [...Object.values(data)])

  return <></>
}

export default memo(StoreReinitializer)

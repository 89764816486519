const positionGroupingTypeToElementTypeMapping: {
  [key in PositionGroupingType]: ElementTypes
} = {
  'wall-rips': 'rips',
  'wall-lintels': 'lintels',
  'slab-beams': 'slab_beams',
  'roof-slab-beams': 'roof_slab_beams',
  beams: 'beams',
  purlins: 'purlins',
  columns: 'columns',
}

export const getElementType = (positionGroupingType: PositionGroupingType): ElementTypes => {
  return positionGroupingTypeToElementTypeMapping[positionGroupingType]
}

import React, { ReactElement, useMemo } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import convert from 'convert'
import { isUndefined } from 'lodash'
import numeral from 'numeral'
import {
  TextField as MuiTextField,
  InputProps,
  OutlinedTextFieldProps,
  InputAdornment,
} from '@mui/material'
import FormControl from '../FormControl'
import NumberInput from '../NumberInput'

type Unit = 'cm' | 'm' | 'mm' | 'km'

interface UnitFieldProps extends Omit<OutlinedTextFieldProps, 'variant' | 'type'> {
  name: string
  placeholder?: string
  label?: string
  inputLabel?: string
  tooltip?: TooltipContents
  InputProps?: InputProps
  staticValue?: number | string
  internalUnit: Unit
  displayUnit: Unit
  numeralFormatter: string
}

const UnitField = ({
  name,
  rows = 1,
  multiline = false,
  autoFocus = false,
  placeholder = '',
  disabled = false,
  label = '',
  inputLabel,
  InputProps = {},
  numeralFormatter = '0.[00]',
  staticValue,
  tooltip,
  internalUnit,
  displayUnit,
  ...textFieldProps
}: UnitFieldProps): ReactElement => {
  const { control } = useFormContext()
  const {
    field: { onChange, onBlur, name: fieldName, value, ref },
    fieldState: { error },
  } = useController({ name, control })

  const displayValue = useMemo(() => {
    const usedValue = isUndefined(staticValue) ? value : staticValue

    return !usedValue
      ? usedValue
      : numeral(convert(Number(usedValue), internalUnit).to(displayUnit)).format(numeralFormatter)
  }, [value, staticValue])

  return (
    <FormControl label={!inputLabel ? label : undefined} error={error?.message} tooltip={tooltip}>
      <MuiTextField
        variant="outlined"
        onChange={event => {
          const value = event.target.value.length
            ? convert(Number(event.target.value), displayUnit).to(internalUnit)
            : event.target.value
          onChange(value)
        }}
        onBlur={onBlur}
        value={displayValue}
        type="text"
        placeholder={placeholder}
        autoFocus={autoFocus}
        name={fieldName}
        disabled={disabled}
        multiline={multiline}
        rows={rows}
        inputRef={ref}
        size="small"
        InputProps={{
          endAdornment: <InputAdornment position="end">{displayUnit}</InputAdornment>,
          // eslint-disable-next-line
          inputComponent: NumberInput as any,
          ...InputProps,
        }}
        label={inputLabel}
        {...textFieldProps}
      />
    </FormControl>
  )
}

// some common variations

type CentimeterFieldProps = Omit<
  UnitFieldProps,
  'internalUnit' | 'displayUnit' | 'numeralFormatter'
>

const CentimeterField = ({ ...textFieldProps }: CentimeterFieldProps): ReactElement => {
  return (
    <UnitField {...textFieldProps} internalUnit="m" displayUnit="cm" numeralFormatter="0.[0]" />
  )
}

type MillimeterFieldProps = Omit<
  UnitFieldProps,
  'internalUnit' | 'displayUnit' | 'numeralFormatter'
> & {
  numeralFormatter?: string
}

const MillimeterField = ({
  numeralFormatter = '0',
  ...textFieldProps
}: MillimeterFieldProps): ReactElement => {
  return (
    <UnitField
      {...textFieldProps}
      internalUnit="m"
      displayUnit="mm"
      numeralFormatter={numeralFormatter}
    />
  )
}

export { UnitField, CentimeterField, MillimeterField }

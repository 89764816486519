import React, { ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'
import { Grid } from '@mui/material'
import { Select, TextField } from '@ui/forms'
import {
  SIA_groundTypeCombinationOptions,
  groundTypeCombinationOptions,
  seismicStandardOptions,
  seismicZoneOptions,
  SIA_seismicZoneOptions,
} from '../constants'

const SeismicBaseParameters = (): ReactElement => {
  const { watch } = useFormContext()

  const seismicStandard: SeismicStandard = watch('standard_used')

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Select label="Norm" name="standard_used" options={seismicStandardOptions} />
      </Grid>

      <Grid item xs={6}>
        <Select
          label="Erdbebenzone"
          name="seismic_zone"
          options={seismicStandard === 'DIN_4109' ? seismicZoneOptions : SIA_seismicZoneOptions}
        />
      </Grid>

      <Grid item xs={6}>
        <Select
          label="Untergrundverhältnisse"
          name="ground_type_combination"
          options={
            seismicStandard === 'DIN_4109'
              ? groundTypeCombinationOptions
              : SIA_groundTypeCombinationOptions
          }
        />
      </Grid>

      <Grid item xs={12}>
        <TextField label="Q-Verhaltensbeiwert" name="q_value" type="number" />
      </Grid>
    </Grid>
  )
}

export default SeismicBaseParameters

import React, { ReactElement } from 'react'
import { Form } from '@ui/forms'
import FormFields from './components/FormFields'
import { productLayerSchema } from './schema'

const ProductLayerForm = ({ id, onSubmit, defaultValues }: LayerFormProps): ReactElement => {
  return (
    <Form
      id={id}
      validationSchema={productLayerSchema}
      defaultValues={defaultValues || productLayerSchema.getDefault()}
      onSubmit={onSubmit}
    >
      <FormFields />
    </Form>
  )
}

export default ProductLayerForm

import { Line3 } from 'three'
import { toImmutable } from '@modugen/scene/lib'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { booleanPointInPolygon, point, polygon } from '@turf/turf'

const calculateDistance = (point1: ImmutableVector3, point2: ImmutableVector3): number => {
  const dx = point2.x - point1.x
  const dy = point2.y - point1.y
  const dz = point2.z - point1.z
  return Math.sqrt(dx * dx + dy * dy + dz * dz)
}

export const calculateNearestPointOnLineSegment = (
  point: ImmutableVector3,
  line: Line3,
): ImmutableVector3 => {
  const lineSegmentDistance = calculateDistance(toImmutable(line.start), toImmutable(line.end))
  if (lineSegmentDistance === 0) {
    return toImmutable(line.start)
  }

  const t =
    ((point.x - line.start.x) * (line.end.x - line.start.x) +
      (point.y - line.start.y) * (line.end.y - line.start.y) +
      (point.z - line.start.z) * (line.end.z - line.start.z)) /
    (lineSegmentDistance * lineSegmentDistance)

  if (t <= 0) {
    return toImmutable(line.start)
  } else if (t >= 1) {
    return toImmutable(line.end)
  } else {
    const x = line.start.x + t * (line.end.x - line.start.x)
    const y = line.start.y + t * (line.end.y - line.start.y)
    const z = line.start.z + t * (line.end.z - line.start.z)

    return new ImmutableVector3(x, y, z)
  }
}

/**
 * This method will take all points and will check if it is within the
 * shape defined by polygon points. If any opening point is not exactly within the
 * wall it will be placed on the nearest corner point of the wall
 */
export const clipPointsToPolygon = (
  polygonPoints: ImmutableVector3[],
  pointsToCap: ImmutableVector3[],
) => {
  const localPolygonPoints = [...polygonPoints, polygonPoints[0]].map(p => [p.x, p.y])

  const localPolygon = polygon([localPolygonPoints])

  return pointsToCap.map(globalPointToCap => {
    const isWithinPolygon = booleanPointInPolygon(
      point([globalPointToCap.x, globalPointToCap.y]),
      localPolygon,
    )

    if (isWithinPolygon) return globalPointToCap

    let nearestCorner: { distance: number; point: ImmutableVector3 } | undefined
    for (let i = 0; i < polygonPoints.length; i++) {
      const currentPoint = polygonPoints[i]
      const nextPoint = i + 1 === polygonPoints.length ? polygonPoints[0] : polygonPoints[i + 1]

      const line = new Line3(currentPoint.v, nextPoint.v)
      const pointOnLine = calculateNearestPointOnLineSegment(globalPointToCap, line)
      const distance = pointOnLine.distanceTo(globalPointToCap)

      if (!nearestCorner || distance < nearestCorner.distance)
        nearestCorner = { distance, point: pointOnLine }
    }

    return nearestCorner?.point || globalPointToCap
  })
}

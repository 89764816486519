import { useMemo } from 'react'
import { reduce } from 'd3'
import { useModelStore } from '@editorStores'

const useElementTypes = (): Record<string, ElementTypes | undefined> => {
  const model = useModelStore(state => state.model)
  const rips = useModelStore(state => state.model.rips)
  const lintels = useModelStore(state => state.model.lintels)

  const elementsByType = useMemo(() => {
    const model_elements = [
      ...model.walls,
      ...model.roof_slabs,
      ...model.slabs,
      ...model.beams,
      ...model.purlins,
      ...model.columns,
      ...model.vertical_slabs,
      ...model.vertical_roof_slabs,
    ]

    const reduced_elements = reduce(
      model_elements,
      (collector, element) => ({
        ...collector,
        [element.guid]: element.type,
      }),
      {} as Record<string, ElementTypes | undefined>,
    )

    const reduced_rips = rips.reduce((collector, element) => {
      collector[element.position_guid] = 'rips'
      return collector
    }, {} as Record<string, ElementTypes | undefined>)

    const reduced_lintels = lintels.reduce((collector, element) => {
      collector[element.position_guid] = 'lintels'
      return collector
    }, {} as Record<string, ElementTypes | undefined>)

    const reduced_slab_beams = model.vertical_slabs.reduce((collector, element) => {
      if (element.beam) {
        collector[element.beam.guid] = 'slab_beams'
      }
      return collector
    }, {} as Record<string, ElementTypes | undefined>)

    const reduced_roof_slab_beams = model.vertical_roof_slabs.reduce((collector, element) => {
      if (element.beam) {
        collector[element.beam.guid] = 'roof_slab_beams'
      }
      return collector
    }, {} as Record<string, ElementTypes | undefined>)

    return {
      ...reduced_elements,
      ...reduced_rips,
      ...reduced_lintels,
      ...reduced_slab_beams,
      ...reduced_roof_slab_beams,
    }
  }, [model, rips, lintels])

  return elementsByType
}

export default useElementTypes

import React, { ReactElement } from 'react'
import { Calculate } from '@mui/icons-material'
import { Info } from '@ui/feedback'
import Box from '@ui/structure/Box'
import PositionResultsGrid from '../PositionResultsGrid'

interface Props {
  grouping: MemberPositionBundleWithMaxUtilization[] | null
  positionType: PositionGroupingType

  isDownloadingExportDocument: boolean
  onClickDownloadExportDocument: () => void
}

const GroupingsTab = ({
  grouping,
  isDownloadingExportDocument,
  onClickDownloadExportDocument,
  positionType,
}: Props): ReactElement => {
  if (!grouping)
    return (
      <Box display="flex" flexGrow={1} alignItems="center" justifyContent="center">
        <Info icon={<Calculate />}>Daten noch nicht verfügbar</Info>
      </Box>
    )

  if (grouping.length === 0)
    return (
      <Box display="flex" flexGrow={1} alignItems="center" justifyContent="center">
        <Info icon={<Calculate />}>Keine Elemente vorhanden</Info>
      </Box>
    )

  return (
    <PositionResultsGrid
      bundles={grouping}
      positionType={positionType}
      isDownloadingExportDocument={isDownloadingExportDocument}
      onClickDownloadExportDocument={onClickDownloadExportDocument}
    />
  )
}

export default GroupingsTab

export const THICKNESS_SCALING_FACTOR = 10
export const STROKE_WIDTH_FACTOR = 300

export const demoLayers = [
  {
    plate: {
      format_length: 1.25,
      format_height: 2,
      material: {
        unique_name: 'Gipsfaser-Platte',
        identifier: 'FEGFP18',
        f_m90k: 4300000,
        f_m0k: 4300000,
        f_rk: 1600000,
        f_pk: 1600000,
        f_c90k: 7300000,
        E_m90k: 3800000000,
        E_m0k: 3800000000,
        G_rk: 1600000000,
        G_pk: 1600000000,
        E_c90: 800000000,
        f_v90k_ip: 3400000,
        f_v0k_ip: 3400000,
        f_m90k_ip: 4000000,
        f_m0k_ip: 4000000,
        f_c90k_ip: 8500000,
        f_c0k_ip: 8500000,
        f_t90k_ip: 0,
        f_t0k_ip: 2300000,
        G_v90k_ip: 1600000000,
        G_v0k_ip: 1600000000,
        E_m90k_ip: 3800000000,
        E_m0k_ip: 3800000000,
        E_c90k_ip: 3800000000,
        E_c0k_ip: 3800000000,
        E_t90k_ip: 3800000000,
        E_t0k_ip: 3800000000,
        density: 1150,
        thickness: 0.018,
        kind: 'Fermacell',
        producer: 'Fermacell',
        description: 'name:Gipsfaser Platte,type:GFP,kind:fermacell,ETA-03/0050',
      },
      unique_name: 'FEGFP18_000',
      producer: null,
      description: null,
    },
    fastener: {
      diameter: 0.00153,
      length: 0.045,
      steel_strength: 900000000,
      f_head_k: 36000000,
      diameter_nailhead: null,
      producer: 'Haubold',
      unique_name: 'KG',
      description: null,
      pydantic_kind: 'fastener-staple',
      explicit_yield_moment: 0.62,
      back_size: 0.0113,
      f_ax_k: 4900000,
      identifier: 'HauKG45',
    },
    fastener_distance: 0.006,
    double_penetration: true,
    kind: 'plate-layer',
  },
  {
    unique_name: 'timber-frame-layer',
    standard_rip_cs: {
      height: 0.12,
      width: 0.22,
      material: {
        e_0: 9000000000,
        e_0_05: 6000000000,
        e_90: 300000000,
        g: 560000000,
        f_mk: 18000000,
        f_t0k: 11000000,
        f_t90k: 400000,
        f_c0k: 18000000,
        f_c90k: 2200000,
        f_vk: 3400000,
        density_05: 320,
        density: 380,
        kind: 'C18',
        producer: null,
        unique_name: 'Nadelholz C18',
        description: null,
        identifier: 'SW18',
      },
      processed: false,
      producer: null,
      unique_name: 'Nadelholz C18_012_022',
      description: null,
    },
    external_rip_cs: {
      height: 0.12,
      width: 0.22,
      material: {
        e_0: 9000000000,
        e_0_05: 6000000000,
        e_90: 300000000,
        g: 560000000,
        f_mk: 18000000,
        f_t0k: 11000000,
        f_t90k: 400000,
        f_c0k: 18000000,
        f_c90k: 2200000,
        f_vk: 3400000,
        density_05: 320,
        density: 380,
        kind: 'C18',
        producer: null,
        unique_name: 'Nadelholz C18',
        description: null,
        identifier: 'SW18',
      },
      processed: false,
      producer: null,
      unique_name: 'Nadelholz C18_012_022',
      description: null,
    },
    upper_chord_cs: {
      height: 0.12,
      width: 0.22,
      material: {
        e_0: 9000000000,
        e_0_05: 6000000000,
        e_90: 300000000,
        g: 560000000,
        f_mk: 18000000,
        f_t0k: 11000000,
        f_t90k: 400000,
        f_c0k: 18000000,
        f_c90k: 2200000,
        f_vk: 3400000,
        density_05: 320,
        density: 380,
        kind: 'C18',
        producer: null,
        unique_name: 'Nadelholz C18',
        description: null,
        identifier: 'SW18',
      },
      processed: false,
      producer: null,
      unique_name: 'Nadelholz C18_012_022',
      description: null,
    },
    lower_chord_cs: {
      height: 0.12,
      width: 0.22,
      material: {
        e_0: 9000000000,
        e_0_05: 6000000000,
        e_90: 300000000,
        g: 560000000,
        f_mk: 18000000,
        f_t0k: 11000000,
        f_t90k: 400000,
        f_c0k: 18000000,
        f_c90k: 2200000,
        f_vk: 3400000,
        density_05: 320,
        density: 380,
        kind: 'C18',
        producer: null,
        unique_name: 'Nadelholz C18',
        description: null,
        identifier: 'SW18',
      },
      processed: false,
      producer: null,
      unique_name: 'Nadelholz C18_012_022',
      description: null,
    },
    lintel_member_cs: {
      height: 0.12,
      width: 0.22,
      material: {
        e_0: 9000000000,
        e_0_05: 6000000000,
        e_90: 300000000,
        g: 560000000,
        f_mk: 18000000,
        f_t0k: 11000000,
        f_t90k: 400000,
        f_c0k: 18000000,
        f_c90k: 2200000,
        f_vk: 3400000,
        density_05: 320,
        density: 380,
        kind: 'C18',
        producer: null,
        unique_name: 'Nadelholz C18',
        description: null,
        identifier: 'SW18',
      },
      processed: false,
      producer: null,
      unique_name: 'Nadelholz C18_012_022',
      description: null,
    },
    bearing_member_cs: {
      height: 0.12,
      width: 0.22,
      material: {
        e_0: 9000000000,
        e_0_05: 6000000000,
        e_90: 300000000,
        g: 560000000,
        f_mk: 18000000,
        f_t0k: 11000000,
        f_t90k: 400000,
        f_c0k: 18000000,
        f_c90k: 2200000,
        f_vk: 3400000,
        density_05: 320,
        density: 380,
        kind: 'C18',
        producer: null,
        unique_name: 'Nadelholz C18',
        description: null,
        identifier: 'SW18',
      },
      processed: false,
      producer: null,
      unique_name: 'Nadelholz C18_012_022',
      description: null,
    },
    rip_step_size: 13,
    insulation: {
      density: 2,
      unique_name: 'insualtion',
      producer: null,
      description: null,
    },
    insulation_thickness: 120,
    kind: 'timber-frame-layer',
  },
]

import React, { ReactElement, useMemo } from 'react'
import { getShapeLength } from '@scene'
import { find, findIndex, filter, toNumber } from 'lodash-es'
import { Form } from '@ui/forms'
import { Box } from '@ui/structure'
import { useStructuralPlanningStore, useModelStore } from '@editorStores'
import StiffeningElementFormFields from '../StiffeningElementFormFields'
import { createStiffeningElementSchema } from '../schema'

interface Props {
  stiffeningElementGuid: string
  FORM_ID: string
  onSave: () => void
}

const StiffeningElementForm = ({
  stiffeningElementGuid,
  FORM_ID,
  onSave,
}: Props): ReactElement | null => {
  const model = useModelStore(state => state.model)
  const updateInterval = useStructuralPlanningStore(state => state.updateInterval)
  const mergedProposal = useStructuralPlanningStore(state => state.mergedProposal)
  const proposal = useStructuralPlanningStore(state => state.proposal)

  const [element, validationSchema] = useMemo(() => {
    if (!mergedProposal.length) return []

    const interval = find(mergedProposal, ['guid', stiffeningElementGuid]) as StiffeningSegment
    const proposalIntervals = filter(proposal, ['element_guid', interval.element_guid])
    const restrictedIntervals = filter(proposalIntervals, ['selectable', 'NotSelectable']).map(
      ({ interval }) => interval,
    )
    const siblingIntervals = filter(mergedProposal, ['element_guid', interval.element_guid])
    const wallElement = find(model.walls, ['guid', interval.element_guid])

    if (!wallElement) return []

    const elementLength = parseFloat(getShapeLength(wallElement.shape.points).toFixed(3))

    // check interval overlapping
    const index = findIndex(siblingIntervals, ['guid', stiffeningElementGuid])
    const stiffeningElement = siblingIntervals[index]
    const min = index ? siblingIntervals[index - 1].interval.upper : 0
    const isLast = index + 1 === siblingIntervals.length
    const max = isLast ? elementLength : siblingIntervals[index + 1].interval.lower

    return [
      stiffeningElement,
      createStiffeningElementSchema({
        min: toNumber(min),
        max: toNumber(max),
        restrictedIntervals,
      }),
    ]
  }, [mergedProposal, stiffeningElementGuid, model])

  if (!element) return null

  const {
    localId,
    guid,
    interval: { upper, lower },
    effective_height,
  } = element

  return (
    <Box>
      <Box mt={2}>
        <Form
          key={localId}
          id={FORM_ID}
          onSubmit={onSave}
          validationSchema={validationSchema}
          defaultValues={{
            length: toNumber(upper) - toNumber(lower),
            lower,
            upper,
            effective_height,
          }}
        >
          <StiffeningElementFormFields
            onChange={(value, effective_height) =>
              updateInterval(guid as string, value, effective_height)
            }
          />
        </Form>
      </Box>
    </Box>
  )
}

export default StiffeningElementForm

import { object, string, number, array, ref, boolean } from 'yup'
import { formatLocalForce } from '@ui/forms/LoadForceField/utils'
import { getDefaultForce } from './utils'

function relativePositionTest(value: number) {
  if (isNaN(value)) return false

  if (value < 0 || value > 1) {
    // @ts-ignore
    return this.createError({
      message: 'Darf nicht außerhalb der Kante liegen',
    })
  }

  return true
}
/* eslint-enable */

const createDomainForceSchema = (domain?: Domain) =>
  object()
    .shape({
      x: number(),
      y: number(),
      z: number(),
    })
    .default(getDefaultForce(domain))
    .test({
      name: 'z smaller than 0',
      message: 'Muss kleiner 0 sein',
      // @ts-ignore
      test: function (value: Force) {
        const domain = this.parent.domain

        const z = domain ? formatLocalForce(value, this.parent.domain) : value.z

        if (Number(z) >= 0) return false

        return true
      },
    })

const getForceSchema = () =>
  object()
    .shape({
      x: number(),
      y: number(),
      z: number().lessThan(0),
    })
    .default(getDefaultForce(undefined))

export const createDefaultVectorShape = (x = 0, y = 0, z = 0) =>
  object().shape({
    x: number().required().default(x),
    y: number().required().default(y),
    z: number().required().default(z),
  })

export const coordinateSystemSchema = object({
  origin: createDefaultVectorShape(),
  x_direction: createDefaultVectorShape(1),
  y_direction: createDefaultVectorShape(0, 1),
  z_direction: createDefaultVectorShape(0, 0, 1),
})

export const createPointLoadSchema = (domain?: Domain) =>
  object({
    load_type: string().default('point-load'),
    element_guid: string(),
    load_case: object({
      identifier: string()
        .required('Dieses Feld ist erforderlich')
        .default('Global Dead Load Case'),
      category: string().default('Dead'),
      load_case_type: string().default('DeadLoad'),
    }),
    force: createDomainForceSchema(domain),
    relative_position: number().required('Dieses Feld ist erforderlich').default(1).test({
      name: 'check if support exceeds edge length',
      message: 'Dieses Feld ist erforderlich',
      test: relativePositionTest,
    }),
    domain_guid: string().required('Dieses Feld ist erforderlich'),
    domain_length: number().default(0),
    comment: string().default(''),
    isLocal: boolean().default(true),
    domain: object(),
  })

export const createAreaLoadSchema = () =>
  object({
    load_type: string().default('area-load'),
    element_guid: string(),
    load_case: object({
      identifier: string()
        .required('Dieses Feld ist erforderlich')
        .default('Global Dead Load Case'),
      category: string().default('Dead'),
      load_case_type: string().default('DeadLoad'),
    }),
    force: getForceSchema(),
    // domain_guid: string().required('Dieses Feld ist erforderlich'),
    comment: string().default(''),
    isLocal: boolean().default(true),
    coordinate_system: coordinateSystemSchema,
  })

export const createLineLoadSchema = (domain?: Domain) =>
  object({
    load_type: string().default('line-load'),
    element_guid: string(),
    load_case: object({
      identifier: string()
        .required('Dieses Feld ist erforderlich')
        .default('Global Dead Load Case'),
      category: string().default('Dead'),
      load_case_type: string().default('DeadLoad'),
    }),
    start: createDomainForceSchema(domain),
    end: createDomainForceSchema(domain),
    relative_end: number()
      .moreThan(ref('relative_start'), 'Muss größer als die Startposition sein')
      .required('Dieses Feld ist erforderlich')
      .default(1)
      .test({
        name: 'check if support exceeds edge length',
        message: 'Dieses Feld ist erforderlich',
        // @ts-ignore
        test: relativePositionTest,
      }),
    relative_start: number()
      .lessThan(ref('relative_end'), 'Muss kleiner als die Endposition sein')
      .default(0)
      .test({
        name: 'check if support exceeds edge length',
        message: 'Dieses Feld ist erforderlich',
        test: relativePositionTest,
      })
      .required('Dieses Feld ist erforderlich'),
    domain_guid: string().required('Dieses Feld ist erforderlich'),
    comment: string().default(''),
    isLocal: boolean().default(true),
    domain: object(),
  })

export const createLoadsSchema = () =>
  object({
    'line-load': array().of(createLineLoadSchema()),
    'point-load': array().of(createPointLoadSchema()),
    'area-load': array().of(createAreaLoadSchema()),
  })

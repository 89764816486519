import { getRequest } from '../utils'

export const getConnections: RequestType = {
  key: 'getConnections',
  request: async (): Promise<Connection[]> => {
    const { data } = await getRequest<Connection[]>({
      url: '/connections',
    })

    return data as Connection[]
  },
}

export const getProjectConnections: RequestTypeVariable = {
  getKey: id => ['getProjectConnections', id],
  request: async (id: string): Promise<ProjectConnection[]> => {
    const { data } = await getRequest<ProjectConnection[]>({
      url: `/projects/${id}/connections`,
    })

    return data as ProjectConnection[]
  },
}

export const getProjectConnectionPrioritization: RequestTypeVariable = {
  getKey: id => ['getProjectConnectionPrioritization', id],
  request: async (id: string): Promise<ProjectConnectionPrioritization> => {
    const { data } = await getRequest<ProjectConnectionPrioritization>({
      url: `/projects/${id}/connection-prioritization`,
    })

    return data as ProjectConnectionPrioritization
  },
}

import { useConfigStore } from '@stores'
import { getRequest } from 'src/state/utils'

export const getProjects = {
  key: 'getProjects',
  request: async (): Promise<ProjectData[]> => {
    const { apiRoutes } = useConfigStore.getState()

    const res = await getRequest<ProjectData[]>({
      url: apiRoutes.fetchProjects(),
    })

    return res.data as ProjectData[]
  },
}

export const getProject: RequestTypeVariable = {
  getKey: (id: string) => ['getProject', id],
  request: async (id: string): Promise<Project> => {
    const res = await getRequest<Project>({
      url: `/projects/${id}`,
    })

    return res.data as Project
  },
}

export const getResponsibleEngineers: RequestType = {
  key: 'getResponsibleEngineers',
  request: async (): Promise<string[]> => {
    const res = await getRequest<string[]>({
      url: `/all-responsible-engineers`,
    })

    return res.data as string[]
  },
}

export const getProcessorNames: RequestType = {
  key: 'getProcessorNames',
  request: async (): Promise<string[]> => {
    const res = await getRequest<string[]>({
      url: `/all-processor-names`,
    })

    return res.data as string[]
  },
}

export const getConstructionCompanies: RequestType = {
  key: 'getConstructionCompanies',
  request: async (): Promise<string[]> => {
    const res = await getRequest<string[]>({
      url: `/all-construction-companies`,
    })

    return res.data as string[]
  },
}

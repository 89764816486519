import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'

const getLineCoordinateSystem = (
  start: ImmutableVector3,
  end: ImmutableVector3,
): CoordinateSystem => {
  const x_direction = start.directionTo(end)
  const y_direction = new ImmutableVector3(0, 0, 1)
  const z_direction = x_direction.cross(y_direction)

  return {
    TOLERANCE: 1,
    origin: start,
    x_direction,
    y_direction,
    z_direction,
  }
}

export default getLineCoordinateSystem

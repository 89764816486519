import { clipPointsToPolygon } from '@editorUtils'
import { Plane, Vector3 } from 'three'
import { toImmutable } from '@modugen/scene/lib'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'

/**
 * This method takes a given point, projects it to the plane that is defined by
 * param area and will move it within the area if there is a certain threshold
 * met
 * @returns projected point to the area
 * @throws error if the provided area is not a plane
 */
export const pointToPolygonArea = (
  point: ImmutableVector3,
  area: ImmutableVector3[],
  clipPoint = true,
): ImmutableVector3 => {
  if (area.length < 3) throw new Error('Not enough points provided to define area')

  const plane = new Plane().setFromCoplanarPoints(area[0].v, area[1].v, area[2].v)

  const projected = toImmutable(plane.projectPoint(point.v, new Vector3()))

  const [clippedPoint] = clipPointsToPolygon(area, [projected])

  return clipPoint ? clippedPoint : projected
}

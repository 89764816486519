import React, { ReactElement } from 'react'
import { Typography } from '@mui/material'
import { Box } from '@ui/structure'
import { useConfigStore } from '@stores'
import SimplifiedSeismicConfigForm from './components/SimplifiedSeismicConfigForm'
import SimplifiedSeismicModelInputsForm from './components/SimplifiedSeismicModelInputsForm'
import SimplifiedSeismicResults from './components/SimplifiedSeismicResults'

const SimpflifiedSeismicTab = (): ReactElement => {
  const appConfig = useConfigStore.getState()

  if (!appConfig.ff_simplified_seismic) {
    return (
      <Box p={2} paddingTop={0} border={1} borderColor="grey.200" borderRadius={1}>
        <Typography>Vereinfachter Erdbebennachweis noch nicht verfügbar.</Typography>
      </Box>
    )
  }

  return (
    <Box p={2} paddingTop={0} border={1} borderColor="grey.200" borderRadius={1}>
      <SimplifiedSeismicConfigForm />
      <SimplifiedSeismicModelInputsForm />
      <SimplifiedSeismicResults />
    </Box>
  )
}

export default SimpflifiedSeismicTab

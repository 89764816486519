import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { useConfigStore } from '@stores'
import { errors } from 'src/constants/errors'

axios.interceptors.response.use(
  // success
  response => response,
  // error
  error => {
    const status = error?.response?.status
    // case data is not available yet on the server
    if (error.config?.method === 'get' && (status === 409 || status === 424)) return { data: null }
    return Promise.reject(error)
  },
)

const apiRequest = async <T>(request: AxiosRequestConfig): Promise<AxiosResponse<T | null>> => {
  const config = useConfigStore.getState()

  return axios.request<T | null>({
    method: 'GET',
    baseURL: config.apiURL,
    ...request,
    headers: {
      ...request.headers,
    },
    transformResponse: data => {
      if (data instanceof Blob) return data

      try {
        return JSON.parse(data, (key, value) => {
          if (value && typeof value === 'object' && Object.keys(value).length === 5) {
            const isVector =
              'x' in value &&
              'y' in value &&
              'z' in value &&
              'the_norm' in value &&
              'vector_type' in value

            if (isVector) {
              return new ImmutableVector3(value.x, value.y, value.z)
            }
          }
          return value
        })
      } catch (e) {
        return data
      }
    },
  })
}

export const timeout = (ms: number): Promise<never> => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const getRequest = <T>(request: AxiosRequestConfig): Promise<AxiosResponse<T | null>> => {
  return apiRequest<T>(request)
}

export const postRequest = <T>(request: AxiosRequestConfig): Promise<AxiosResponse<T | null>> => {
  return apiRequest<T>({
    method: 'POST',
    ...request,
  })
}

export const patchRequest = <T>(request: AxiosRequestConfig): Promise<AxiosResponse<T | null>> => {
  return apiRequest<T>({
    method: 'PATCH',
    ...request,
  })
}

export const deleteRequest = <T>(request: AxiosRequestConfig): Promise<AxiosResponse<T | null>> => {
  return apiRequest<T>({
    method: 'DELETE',
    ...request,
  })
}

export const retryUntilSuccessOrFailure = async <T>(
  request: AxiosRequestConfig,
  maxRetries = 60 * 10,
  retryInterval = 1000,
): Promise<AxiosResponse<T | null>> => {
  let response
  let retries = 0

  while (retries < maxRetries && !response) {
    try {
      response = await getRequest<T>(request)
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError
        if (axiosError.response?.status !== 420) {
          throw axiosError
        }
      }
    }

    retries += 1
    await timeout(retryInterval)
  }

  if (!response) throw new Error(errors.retriesExhausted)

  return response
}

export const uploadFile = ({
  url,
  file,
  field,
}: {
  url: string
  file: File
  field: string
}): Promise<AxiosResponse<unknown>> => {
  const formData = new FormData()
  formData.append(field, file)

  return postRequest({
    url,
    data: formData,
  })
}

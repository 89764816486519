import { BufferGeometry, Line3, Mesh, Plane, Vector3 } from 'three'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'

const getLinePlaneIntersectionTarget = (
  lineStart: ImmutableVector3,
  lineEnd: ImmutableVector3,
  plane: Plane,
  z: number,
): Mesh | undefined => {
  const line = new Line3(lineStart.v, lineEnd.v)
  const intersection = plane.intersectLine(line, new Vector3())

  if (intersection) {
    const start = new Vector3(intersection.x, intersection.y, z)

    const startGeometry = new BufferGeometry().setFromPoints([start])
    return new Mesh(startGeometry)
  }

  return undefined
}

export default getLinePlaneIntersectionTarget

import _ from 'lodash'

export function circularShiftRight<T>(array: T[], positions: number): T[] {
  const length = array.length
  const shift = ((positions % length) + length) % length // Normalize the shift value
  if (shift === 0) return array.slice() // Return a shallow copy if no shift is needed

  return _.concat(_.takeRight(array, shift), _.dropRight(array, shift))
}

export function circularShiftRightShape(shape: PolygonShape, positions: number): PolygonShape {
  return {
    shape_type: shape.shape_type,
    points: circularShiftRight(shape.points, positions),
  } as PolygonShape
}

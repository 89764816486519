import { useMemo } from 'react'
import { signedDistanceInDirectionOf } from '@geometry'
import { Vector3 } from 'three'
import { toImmutable } from '@modugen/scene/lib/utils'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'

interface Arguments {
  slab: ShapeObject
  offset?: number
}

const useSlabOrientation = ({ slab, offset = 0.001 }: Arguments) => {
  return useMemo(() => {
    const { points } = slab.shape
    // arrow direction is orthogonal to direction of first two points
    const p0 = points[0]
    const p1 = points[1]
    const pOther = points[points.length - 1]

    const vx = p1.sub(p0)
    const vy = pOther.sub(p0)

    const vz = new ImmutableVector3().crossVectors(vx, vy)
    const spanDirection = new ImmutableVector3().crossVectors(vz, vx).normalize()

    const distancesInSpanDirection = points.map(p =>
      signedDistanceInDirectionOf(p0, p, spanDirection),
    )
    const maxDistanceInSpanDirection = Math.max(...distancesInSpanDirection)
    const baseAxisCenter = new Vector3().addVectors(p0.v, p1.v).multiplyScalar(0.5)
    const center = baseAxisCenter.addScaledVector(spanDirection.v, maxDistanceInSpanDirection * 0.5)
    const length = Math.min(maxDistanceInSpanDirection / 2, 1)
    const origin = center.add(spanDirection.v.normalize().multiplyScalar(-length / 2))

    return {
      direction: spanDirection,
      length,
      center: toImmutable(origin.setZ(origin.z + offset)),
      normal: vz.normalize(),
    }
  }, [slab.shape.points])
}

export default useSlabOrientation

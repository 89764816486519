import React, { ReactElement } from 'react'
import { useTheme } from '@mui/material'
import ArrowOrientation from '../ArrowOrientation'
import { useSlabOrientation } from '../hooks'

interface Props {
  slab: ShapeObject
  color?: string
}

const ArrowOrientationSlab = ({ slab, color }: Props): ReactElement => {
  const { sceneOffsets } = useTheme()

  const offset =
    0.01 +
    (slab.type === 'vertical_roof_slabs'
      ? sceneOffsets.verticalRoofSlabs.z
      : sceneOffsets.verticalSlabs.z)

  const { direction, length, center, normal } = useSlabOrientation({ slab, offset })

  return (
    <ArrowOrientation
      direction={direction}
      length={length}
      origin={center}
      normal={normal}
      color={color}
    />
  )
}

export default ArrowOrientationSlab

import { Line3, Plane, Vector3 } from 'three'
import { toImmutable } from '@modugen/scene/lib'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'

/**
 * projects point onto plane (in z Direction 0, 0, 1). Will only work for points on plane that are on z >= 0
 */
const projectPointOntoPlane = (plane: Plane, pointToProject: ImmutableVector3) => {
  // if we use negative numbers for the line start here `intersectLine` below
  // will return points that are not exactly on the plane (using distanceToPlane
  // will return a distance > 0). Hence we limit this here to points between 0
  // and Number.MAX_VALUE
  const lineStart = new ImmutableVector3(pointToProject.x, pointToProject.y, 0)
  const lineEnd = new ImmutableVector3(pointToProject.x, pointToProject.y, Number.MAX_VALUE)

  const line = new Line3(lineStart.v, lineEnd.v)

  const intersectionPoint = plane.intersectLine(line, new Vector3())

  if (intersectionPoint === null) throw new Error('No intersection found')

  return toImmutable(intersectionPoint)
}

export default projectPointOntoPlane

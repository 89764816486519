import { HexColorInput } from 'react-colorful'
import { styled } from '@mui/system'

interface CircleProps {
  backgroundColor: string
}

export const ColorPickerCircle = styled('div', {
  shouldForwardProp: prop => prop !== 'backgroundColor',
})<CircleProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  width: 40px;
  height: 40px;
  border: 1px solid;
  border-color: ${({ theme }) => theme.palette.grey[400]};
  border-radius: 50px;
  cursor: poiner;
`

export const ColorPickerContainer = styled('div')`
  position: relative;

  .react-colorful {
    position: absolute;
    top: -195px;
    left: 32px;
    z-index: 1;
  }
`

export const HashTypography = styled('div')`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.text.secondary};
  margin-right: ${({ theme }) => theme.spacing(1)};
`

export const ColorTextField = styled(HexColorInput)`
  padding: 8px;
  width: 100%;
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 16px;
  border: ${({ theme }) => `1px solid ${theme.palette.grey[400]}`};
  border-radius: 4px;

  '&:focus': {
    border: ${({ theme }) => `1px solid ${theme.palette.primary.main}`};
    outline: 'none';
    border-radius: theme.spacing(0.5);
  }
`

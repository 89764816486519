import React, { ReactElement, useMemo, useState } from 'react'
import { useFieldArray } from 'react-hook-form'
import { reduce } from 'lodash-es'
import { Typography, AccordionDetails, Stack, Grid, Alert } from '@mui/material'
import { FormAccordion, TextField, UnitConvertedField } from '@ui/forms'
import { useModelStore } from '@editorStores'
import { windDirectionToAxis } from '../../constants'
import RoofFormFields from '../RoofFormFields'
import StoreyHeader from '../StoreyHeader'

interface Props {
  direction: WindDirection
}

const FormFields = ({ direction }: Props): ReactElement => {
  const model = useModelStore(state => state.model)
  const [activeStorey, setActiveStorey] = useState<number | 'Dach' | null>(null)

  const fields = useFieldArray({
    name: `results.wind_loads_per_ceiling[${direction}]`,
  }).fields as unknown as WindloadCeilingResult[]

  const assignmentMapping = useMemo(() => {
    let slabToStorey: Record<string, string> = {}

    Object.entries(model.storey_assignment.slab_storey_id_assignment).map(
      ([storey, slabs]) =>
        (slabToStorey = {
          ...slabToStorey,
          ...reduce(
            slabs,
            (collector, slab) => ({ ...collector, [slab]: storey }),
            {} as Record<string, string>,
          ),
        }),
    )

    return slabToStorey
  }, [model.storey_assignment.slab_storey_id_assignment])

  return (
    <>
      {fields.map((result, index) => (
        <FormAccordion
          key={index}
          fields={index.toString()}
          expanded={index === activeStorey}
          onChange={(_, expanded) => setActiveStorey(expanded ? index : null)}
        >
          <StoreyHeader
            resultFieldName="results.wind_loads_per_ceiling"
            direction={direction}
            index={index}
            storey={assignmentMapping[result.slab_guid]}
          />
          <AccordionDetails>
            <Stack direction="column" spacing={2}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <UnitConvertedField
                    label={
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: 14,
                        }}
                      >
                        Punktlast x
                      </Typography>
                    }
                    name={`results.wind_loads_per_ceiling[${direction}][${index}].force.x`}
                  />
                </Grid>
                <Grid item xs={6}>
                  <UnitConvertedField
                    label={
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: 14,
                        }}
                      >
                        Punktlast y
                      </Typography>
                    }
                    name={`results.wind_loads_per_ceiling[${direction}][${index}].force.y`}
                  />
                </Grid>
                <Grid item xs={6}>
                  <UnitConvertedField
                    conversionFactor={1}
                    unitLabel={'m'}
                    disabled
                    label={
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: 14,
                        }}
                      >
                        Angriffspunkt x
                      </Typography>
                    }
                    name={`results.wind_loads_per_ceiling[${direction}][${index}].point_of_attack.x`}
                  />
                </Grid>
                <Grid item xs={6}>
                  <UnitConvertedField
                    conversionFactor={1}
                    unitLabel={'m'}
                    disabled
                    label={
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: 14,
                        }}
                      >
                        Angriffspunkt y
                      </Typography>
                    }
                    name={`results.wind_loads_per_ceiling[${direction}][${index}].point_of_attack.y`}
                  />
                </Grid>
              </Grid>
              <Alert severity="info">
                Windrichung ist in {windDirectionToAxis[direction].axis}{' '}
                {windDirectionToAxis[direction].sign}
              </Alert>
              <TextField
                label="Kommentar"
                placeholder="Kommentar"
                rows={2}
                name={`results.wind_loads_per_ceiling[${direction}][${index}].comment`}
                multiline
              />
            </Stack>
          </AccordionDetails>
        </FormAccordion>
      ))}
      <RoofFormFields
        direction={direction}
        expanded={activeStorey === 'Dach'}
        onExpand={expanded => setActiveStorey(expanded ? 'Dach' : null)}
      />
    </>
  )
}

export default FormFields

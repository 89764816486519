import React, { ReactElement, useState, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { get, find, filter, toNumber } from 'lodash-es'
import { Typography, TextField, MenuItem, Stack, Button } from '@mui/material'
import { ThreeEvent } from '@react-three/fiber'
import { SelectedItem, ErrorField } from '@ui/forms'
import { Box } from '@ui/structure'
import { useElementSelectionStore, useControlStore } from '@editorStores'
import { SceneMouseTooltip } from 'src/components/pages/Editor/components'
import ElementSelection from '../../../../../ElementSelection'
import { roofTypes, roofTypeConfig } from '../../constants'
import { createConfigFromType, configToLabel } from '../../utils'
import GableRoofFields from '../GableRoofFields'
import HipRoofFields from '../HipRoofFields'

interface Props {
  direction: WindDirection
}

// const here to avoid cyclical dependency
const roofTypeComponents = {
  HipRoof: HipRoofFields,
  GableRoof: GableRoofFields,
  FlatRoof: null,
  // exactly same data structure
  ShedRoof: GableRoofFields,
}

const configInitialState: RoofFormConfig = {
  roofType: roofTypes[0],
  flowArea: null,
  alpha: null,
  isShortSide: null,
}

const RoofFormFields = ({ direction }: Props): ReactElement => {
  const selectedStandAloneIds = useElementSelectionStore(state => state.selectedStandAloneIds)
  const hasSelection = !!selectedStandAloneIds.size
  const deselectAllIds = useElementSelectionStore(state => state.deselectAllIds)
  const setHighlightedIds = useElementSelectionStore(state => state.setHighlightedIds)
  const addHiddenElementIds = useControlStore(state => state.addHiddenElementIds)
  const removeHiddenElementIds = useControlStore(state => state.removeHiddenElementIds)
  const [config, setConfig] = useState<RoofFormConfig>({ ...configInitialState })
  const [errors, setErrors] = useState({})

  const FormFields = roofTypeComponents[config.roofType]
  const setConfigField: SetConfigField = (name, value) => {
    setConfig(config => ({
      ...config,
      [name]: value,
    }))
  }
  const { setValue, getValues, watch, trigger, formState } = useFormContext()

  const currentAssignmentPath = `roofAssignment.${direction}.configurations`
  const selectedWatch: [] = watch(currentAssignmentPath)

  const selectedSortedByAttributes = selectedWatch.reduce<{
    [key: string]: string[]
  }>((acc, config: RoofConfig) => {
    const configStringified = configToLabel(config)

    acc[configStringified] = [...(acc[configStringified] || []), config.element_guid]
    return acc
  }, {})

  const onPointerOver = (event: ThreeEvent<MouseEvent>) => {
    if (!event.object.name) return null

    const assignment = find(selectedWatch, ['element_guid', event.object.name])

    if (!assignment) return null

    return configToLabel(assignment)
  }

  const createAssignment = () => {
    if (config.roofType === 'HipRoof' && toNumber(config.alpha) === 0) {
      setErrors({ alpha: 'Wert muss größer 0 sein' })
      return
    }

    const current = get(getValues(), currentAssignmentPath)
    const selectedIds = [...selectedStandAloneIds]

    setValue(currentAssignmentPath, [
      ...(current || []),
      ...selectedIds.map(id => createConfigFromType(id, config)),
    ])
    deselectAllIds()
    if (formState.submitCount) trigger()
  }

  useEffect(() => {
    if (!selectedWatch) return
    setHighlightedIds(selectedWatch.map(({ element_guid }) => element_guid))
  }, [selectedWatch])

  useEffect(() => {
    // bug in yup default with lazy validation
    // https://github.com/jquense/yup/issues/699
    const values = getValues()
    const parent = get(values, currentAssignmentPath)

    if (!parent) setValue(currentAssignmentPath, {})
  }, [])

  useEffect(() => {
    const initialValues = find(roofTypeConfig, ['value', config.roofType])?.initialValues

    setConfig({
      ...(initialValues || configInitialState),
      roofType: config.roofType,
    })
  }, [config.roofType])

  return (
    <>
      <SceneMouseTooltip onPointerOver={onPointerOver} />
      <ElementSelection />
      <Box p={1} m={-1} border={1} borderColor="grey.200" borderRadius={1}>
        <Typography variant="h6" textAlign="center">
          Ausgewählt: {selectedStandAloneIds.size}
        </Typography>
        <Typography mb={1}>
          Weisen Sie für jede Windrichtung jedem Dachelement eine Konfiguration zu
        </Typography>

        <Box mb={1}>
          <TextField
            select
            disabled={!hasSelection}
            label="Dachform"
            value={config.roofType}
            onChange={event => setConfigField('roofType', event.target.value as RoofTypes)}
            size="small"
            fullWidth
            sx={{ mt: 1, bgcolor: 'grey.50' }}
            data-cy="roof-type-select"
          >
            {roofTypeConfig.map(({ label, value }, index) => (
              <MenuItem data-cy={`roof-type-select-${value}`} value={value} key={index}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </Box>

        {FormFields && (
          <FormFields
            errors={errors}
            setConfigField={setConfigField}
            config={config}
            disabled={!hasSelection}
          />
        )}

        <Stack direction="row" spacing={1} mt={2}>
          <Button
            onClick={createAssignment}
            disabled={!selectedStandAloneIds.size}
            size="small"
            variant="contained"
            data-cy="btn-submit"
            fullWidth
          >
            Zuweisen
          </Button>

          <Button
            onClick={deselectAllIds}
            disabled={!selectedStandAloneIds.size}
            size="small"
            variant="outlined"
            fullWidth
          >
            Auswahl aufheben
          </Button>
        </Stack>

        <Stack direction="column" mt={1} spacing={1}>
          <Typography>Bestehende Zuweisungen:</Typography>
          <ErrorField data-cy="missing-roof-assignment" name={currentAssignmentPath} />
          {Object.entries(selectedSortedByAttributes).map(([label, ids]) => {
            return (
              <SelectedItem
                key={label}
                title={label}
                onDelete={() => {
                  const current = { ...get(getValues(), currentAssignmentPath) }

                  setValue(
                    currentAssignmentPath,
                    filter(current, ({ element_guid }) => !ids.includes(element_guid)),
                  )
                  if (formState.submitCount) trigger()
                }}
                onVisible={visible => {
                  if (visible) removeHiddenElementIds(ids)
                  else addHiddenElementIds(ids)
                }}
                count={ids.length}
              />
            )
          })}
        </Stack>
      </Box>
    </>
  )
}

export default RoofFormFields

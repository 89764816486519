import { object, string, boolean, number } from 'yup'

export const cltLayerSchema = object({
  producer: string().required().default(''),
  layer: object({
    kind: string().default('clt-assembly-layer'),
    unique_name: string(),
    clt: object().required().default(undefined),
    description: string().nullable().default(null),
    double_penetration: boolean().default(true),
    fastener: object().default({}),
    fastener_distance: number().default(0),
  }),
})

import React from 'react'
import CopyIcon from '@mui/icons-material/ContentCopy'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import { IconButton } from '@mui/material'
import { AlertDialog } from '@ui/feedback'

interface DeleteCSButtonProps {
  onDelete: () => void
}

const DeleteCSButton = ({ onDelete }: DeleteCSButtonProps) => {
  return (
    <AlertDialog
      title="Querschnitt löschen?"
      text="Sind Sie sicher, dass Sie diesen Querschnitt löschen wollen?"
      onConfirm={async () => onDelete()}
    >
      <IconButton>
        <DeleteIcon fontSize="small" />
      </IconButton>
    </AlertDialog>
  )
}

interface DuplicateCSButtonProps {
  onDuplicate: () => void
}

const DuplicateCSButton = ({ onDuplicate }: DuplicateCSButtonProps) => {
  return (
    <IconButton onClick={onDuplicate}>
      <CopyIcon fontSize="small" />
    </IconButton>
  )
}

interface EditCSButtonProps {
  dialogText: string
  onEdit: () => void
}

const EditCSButton = ({ dialogText, onEdit }: EditCSButtonProps) => {
  return (
    <AlertDialog title="Querschnitt ändern?" text={dialogText} onConfirm={onEdit}>
      <IconButton>
        <EditIcon fontSize="small" />
      </IconButton>
    </AlertDialog>
  )
}

interface SetCSAsStandardButtonProps {
  onClick: () => void
  isStandard: boolean
  'data-cy'?: string
}

const FavoriteCSButton = ({
  onClick,
  isStandard,
  'data-cy': dataCy,
}: SetCSAsStandardButtonProps) => {
  return isStandard ? (
    <>
      <IconButton onClick={onClick} data-cy={dataCy}>
        <FavoriteIcon />
      </IconButton>
    </>
  ) : (
    <>
      <IconButton onClick={onClick} data-cy={dataCy}>
        <FavoriteBorderIcon />
      </IconButton>
    </>
  )
}

export { DeleteCSButton, DuplicateCSButton, EditCSButton, FavoriteCSButton }

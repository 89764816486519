import React, { ReactElement, useMemo } from 'react'
import useMeasure from 'react-use/lib/useMeasure'
import { Typography } from '@mui/material'
import { Box } from '@ui/structure'
import { calculateCumulativeThickness, generatePlottingLayers } from '../utils'
import PlotIllustration from './PlotIllustration'
import { THICKNESS_SCALING_FACTOR } from './constants'

interface Props {
  assemblyType: AssemblyTypes
  layers?: Layer[]
  hasBorder?: boolean
  outsideWidth?: number
}

const AssemblyPlot = ({
  assemblyType,
  layers,
  hasBorder = true,
  outsideWidth,
}: Props): ReactElement => {
  const [widthRef, { width }] = useMeasure()
  const orientation =
    assemblyType === 'Outer' || assemblyType === 'Inner' ? 'Vertical' : 'Horizontal'

  const plottedLayers = useMemo(() => {
    if (!layers) return

    return generatePlottingLayers(layers)
  }, [layers])

  // This is neccessary for the plot to fit into the card
  const thickness = useMemo(() => {
    if (!plottedLayers) return 0

    return calculateCumulativeThickness(plottedLayers) / THICKNESS_SCALING_FACTOR
  }, [plottedLayers])

  const viewBox = useMemo(
    () =>
      `0 0  ${orientation === 'Vertical' ? thickness : width / 2} ${
        orientation === 'Vertical' ? width / 2 : thickness
      } `,
    [orientation, thickness, width],
  )

  return (
    <Box
      sx={({ shape, palette, spacing }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: hasBorder ? `1px solid ${palette.grey[400]}` : 'none',
        borderRadius: shape.borderRadius,
        paddingTop: spacing(0.5),
        minHeight: width / 2,
        width: outsideWidth || '100%',
      })}
      ref={(e: HTMLDivElement) => e && widthRef(e)}
    >
      {plottedLayers?.length ? (
        <PlotIllustration
          viewBox={viewBox}
          layers={plottedLayers}
          orientation={orientation}
          length={width / 2}
          thicknessScalingFactor={0.1}
          height={width / 2}
        />
      ) : (
        <Typography
          sx={{
            fontSize: 12,
          }}
        >
          Keine Schichten ausgewählt
        </Typography>
      )}
    </Box>
  )
}

export default AssemblyPlot

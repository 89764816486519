import { UseSpringProps } from '@react-spring/core'

export const ARROW_OFFSET = 2
export const ARROW_DISTANCE = 0.3

const getDistance = (i: number): number => 0.7 - i * 0.08
const common = {
  loop: true,
  reset: true,
}

export const springConfigByAngle: {
  [key: string]: (i: number) => Partial<UseSpringProps>
} = {
  '0': (i: number) => ({
    to: async next => {
      await next({ 'position-x': 0, opacity: 0 })
      await next({ 'position-x': getDistance(i), opacity: 1 })
    },
    from: { 'position-x': getDistance(i), opacity: 1 },
    ...common,
  }),
  '90': (i: number) => ({
    to: async next => {
      await next({ 'position-y': 0, opacity: 0 })
      await next({ 'position-y': getDistance(i), opacity: 1 })
    },
    from: { 'position-y': getDistance(i), opacity: 1 },
    ...common,
  }),
  '180': (i: number) => ({
    to: async next => {
      await next({ 'position-x': getDistance(i), opacity: 0 })
      await next({ 'position-x': 0, opacity: 1 })
    },
    from: { 'position-x': 0, opacity: 1 },
    ...common,
  }),
  '270': (i: number) => ({
    to: async next => {
      await next({ 'position-y': getDistance(i), opacity: 0 })
      await next({ 'position-y': 0, opacity: 1 })
    },
    from: { 'position-y': 0, opacity: 1 },
    ...common,
  }),
}

import React, { ReactElement, useMemo } from 'react'
import {
  createMeshStandardMaterialMemoized,
  getShapeEdges,
  getShapeMatrixTransform,
  getShapeObject,
} from '@scene'
import { ColorRepresentation, DoubleSide } from 'three'
import { useTheme } from '@mui/material'
import { BasicMesh, BasicMeshProps } from '@modugen/scene/lib/components/BasicMesh'
import { config as sceneConfig } from '@modugen/scene/lib/config'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { MeshStandardMaterialProps } from '@react-three/fiber'

interface Props extends BasicMeshProps {
  points: ImmutableVector3[]
  openings?: ImmutableVector3[][]
  thickness?: number
  translucent?: boolean
  /**
   * Can also be set using meshMaterialProps. Used for easier access
   */
  color?: string | ColorRepresentation
  meshMaterialProps?: MeshStandardMaterialProps

  userData?: Record<string, unknown>
  outlines?: boolean
  outlinesColor?: string | ColorRepresentation
}

const SimpleShape = ({
  points,
  openings = [],
  thickness = 0.01,
  translucent = false,
  color,
  userData = {},
  meshMaterialProps,
  outlines = false,
  outlinesColor,
  ...meshProps
}: Props): ReactElement => {
  const { scenePalette } = useTheme()

  const matrixTransform = useMemo(() => getShapeMatrixTransform(points), [points])

  const shapeObject = useMemo(
    () => getShapeObject(points, openings, matrixTransform),
    [points, openings, matrixTransform],
  )

  const material = useMemo(
    () =>
      createMeshStandardMaterialMemoized({
        side: DoubleSide,
        // @ts-ignore
        color: color || scenePalette.elements3d.walls,
        opacity: translucent ? 0.33 : 1,
        transparent: translucent,
        ...meshMaterialProps,
      }),
    [color, translucent, meshMaterialProps],
  )

  const edges = useMemo(() => getShapeEdges(shapeObject, thickness), [shapeObject, thickness])

  return (
    <BasicMesh
      // why disabling matrixAutoUpdate should be fine:
      // https://github.com/pmndrs/react-three-fiber/issues/635#issuecomment-682439509
      matrix={matrixTransform}
      matrixAutoUpdate={false}
      userData={{
        ...userData,
        points,
      }}
      material={material}
      {...meshProps}
    >
      <extrudeGeometry
        args={[
          shapeObject,
          {
            depth: thickness,
            bevelEnabled: false,
          },
        ]}
      />

      {outlines && (
        <lineSegments
          layers={sceneConfig.R3FNonSelectableObjectLayer}
          geometry={edges}
          renderOrder={100}
          visible={meshProps.visible}
        >
          <lineBasicMaterial color={outlinesColor || scenePalette.outlines} />
        </lineSegments>
      )}
    </BasicMesh>
  )
}

export default SimpleShape

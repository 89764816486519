import React, { ReactElement, useMemo, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { projectPointOntoPlane, sortPointsCounterClockwise } from '@editorUtils'
import { filter, find, inRange, reduce, toNumber } from 'lodash-es'
import { Plane } from 'three'
import { AddCircleOutline, AdsClick, ErrorOutline, RemoveCircleOutline } from '@mui/icons-material'
import { Divider, IconButton, Stack, Tooltip, Typography } from '@mui/material'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { SwitchLabeled } from '@ui/actions'
import { TextField } from '@ui/forms'
import { Box } from '@ui/structure'
import { useEditElementStore } from '@editorStores'
import { useSelectionMode, useStoreSubscription } from '@editorHooks'
import { roofDrawingDecimals, roofPlaneThreshold } from 'src/components/pages/Editor/config'

export const roofPointSelectionKey = 'select-new-roof-point'
interface Props {
  selectedElement: string
  slabs: ShapeObject[]
  updateSlab: (slab: { guid: string; shape: PolygonShape }) => void
}

const FormFields = ({ selectedElement, slabs, updateSlab }: Props): ReactElement => {
  const { setValue } = useFormContext()
  const { setSelectionMode } = useSelectionMode()

  const formPoints = useWatch({ name: 'points' }) as { x: number; y: number; z: number }[]
  const newPoint = useWatch({ name: 'newPoint' })

  const setActiveRoofPointIndex = useEditElementStore(state => state.setActiveRoofPointIndex)
  const activeRoofPointIndex = useEditElementStore(state => state.activeRoofPointIndex)

  const [zComputed, setZComputed] = useState<boolean>(true)

  const roof = useMemo(
    () => find(slabs, { guid: selectedElement }),
    [slabs, selectedElement],
  ) as ShapeObject

  const currentRoofPlane = useMemo(
    () =>
      new Plane().setFromCoplanarPoints(
        roof.shape.points[0].v,
        roof.shape.points[1].v,
        roof.shape.points[2].v,
      ),
    [roof],
  )

  useStoreSubscription({
    compareValues: true,
    fieldName: 'points',
    writeCallback: points => {
      const roofPoints = points.map(
        ({ x, y, z }: { x: number; y: number; z: number }) =>
          new ImmutableVector3(toNumber(x), toNumber(y), toNumber(z)),
      )

      updateSlab({
        guid: selectedElement,
        shape: {
          points: roofPoints,
        },
      })
    },
  })

  useStoreSubscription({
    compareValues: true,
    fieldName: 'newPoint.x',
    writeCallback: x => {
      setValue(
        'newPoint.z',
        zComputed
          ? (
              projectPointOntoPlane(
                currentRoofPlane,
                new ImmutableVector3(toNumber(x), toNumber(newPoint.y), toNumber(newPoint.z)),
              ) as ImmutableVector3
            ).z
          : newPoint.z,
      )
    },
  })

  useStoreSubscription({
    compareValues: true,
    fieldName: 'newPoint.y',
    writeCallback: y => {
      setValue(
        'newPoint.z',
        zComputed
          ? (
              projectPointOntoPlane(
                currentRoofPlane,
                new ImmutableVector3(toNumber(newPoint.x), toNumber(y), toNumber(newPoint.z)),
              ) as ImmutableVector3
            ).z
          : newPoint.z,
      )
    },
  })

  // in order to show errors immediately (and not only on submit of the form),
  // we use a memo here to get any points that are not coplanar to the roof
  // plane and display them below
  const errorMessages = useMemo(() => {
    return reduce(
      roof.shape.points,
      (collector, p, i) => {
        const distance = currentRoofPlane.distanceToPoint(p.v)
        if (!inRange(distance, -roofPlaneThreshold, roofPlaneThreshold)) {
          const message = `Punkt ${
            i + 1
          } ist nicht innerhalb der gleichen Fläche wie die ersten 3 Punkte des Daches`
          return [...collector, message]
        }

        return collector
      },
      [] as string[],
    )
  }, [roof, currentRoofPlane])

  const removePoint = (indexToRemove: number) => {
    const newPoints = filter(formPoints, (_, i) => i !== indexToRemove)
    setValue('points', newPoints)
  }

  const addPoint = () => {
    const newPoints = [...formPoints, newPoint]
    const rotatedPoints = sortPointsCounterClockwise(newPoints)
    setValue('points', rotatedPoints)
  }

  const choosePoint = () => {
    setSelectionMode({
      key: roofPointSelectionKey,
      message: `Sie haben nun die Möglichkeit innerhalb der Fläche einen Punkt anzuwählen`,
    })
  }

  return (
    <>
      <Stack
        spacing={2}
        border={1}
        p={1}
        pt={1.5}
        pb={1.25}
        borderColor="grey.200"
        borderRadius={1}
        sx={{ '& .MuiFormControlLabel-label': { ml: 0.5 } }}
      >
        <Stack spacing={1.25}>
          {roof.shape.points.map((_, inputI) => (
            <Stack
              key={`${roof.guid}-${roof.shape.points.length}-${inputI}`}
              direction="row"
              spacing={1}
              alignItems="flex-end"
            >
              <TextField
                label={`X P${inputI + 1}`}
                type="number"
                size="small"
                sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
                name={`points[${inputI}].x`}
                unit="m"
                onFocus={() => setActiveRoofPointIndex(inputI)}
                onBlur={() => setActiveRoofPointIndex(null)}
                inputProps={{ decimalScale: roofDrawingDecimals }}
                onChange={e => {
                  setValue(`points[${inputI}].x`, toNumber(e.target.value))
                  if (zComputed && activeRoofPointIndex) {
                    const inputX = toNumber(e.target.value)
                    const p = roof.shape.points[activeRoofPointIndex]
                    const pProjected = projectPointOntoPlane(
                      currentRoofPlane,
                      new ImmutableVector3(inputX, p.y, p.z),
                    )
                    setValue(`points[${inputI}].z`, pProjected.z)
                  }
                }}
              />

              <TextField
                label={`Y P${inputI + 1}`}
                type="number"
                size="small"
                sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
                name={`points[${inputI}].y`}
                unit="m"
                onFocus={() => setActiveRoofPointIndex(inputI)}
                onBlur={() => setActiveRoofPointIndex(null)}
                inputProps={{ decimalScale: roofDrawingDecimals }}
                onChange={e => {
                  setValue(`points[${inputI}].y`, e.target.value)
                  if (zComputed && activeRoofPointIndex) {
                    const inputY = toNumber(e.target.value)
                    const p = roof.shape.points[activeRoofPointIndex]
                    const pProjected = projectPointOntoPlane(
                      currentRoofPlane,
                      new ImmutableVector3(p.x, inputY, p.z),
                    )
                    setValue(`points[${inputI}].z`, pProjected.z)
                  }
                }}
              />

              <TextField
                label={`Z P${inputI + 1}`}
                type="number"
                size="small"
                sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
                name={`points[${inputI}].z`}
                unit="m"
                disabled={zComputed}
                onFocus={() => setActiveRoofPointIndex(inputI)}
                onBlur={() => setActiveRoofPointIndex(null)}
                inputProps={{ disableDecimalScale: true }}
              />

              <Tooltip
                title={roof.shape.points.length < 4 ? 'Weniger als 3 Punkte ist nicht möglich' : ''}
                placement="left"
              >
                <IconButton
                  onClick={() => removePoint(inputI)}
                  disabled={roof.shape.points.length < 4}
                >
                  <RemoveCircleOutline />
                </IconButton>
              </Tooltip>
            </Stack>
          ))}

          <Box pt={2}>
            <Divider />
          </Box>

          <Stack direction="row" spacing={1} alignItems="flex-start">
            <Stack direction="row" pt={1.5} spacing={1}>
              <TextField
                label={`X P${roof.shape.points.length + 1}`}
                type="number"
                size="small"
                sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
                name={`newPoint.x`}
                unit="m"
                inputProps={{ decimalScale: roofDrawingDecimals }}
              />

              <TextField
                label={`Y P${roof.shape.points.length + 1}`}
                type="number"
                size="small"
                sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
                name={`newPoint.y`}
                unit="m"
                inputProps={{ decimalScale: roofDrawingDecimals }}
              />

              <TextField
                label={`Z P${roof.shape.points.length + 1}`}
                type="number"
                size="small"
                sx={{ width: '100%', bgcolor: 'grey.50', flexGrow: 1 }}
                name={`newPoint.z`}
                unit="m"
                disabled={zComputed}
                inputProps={{ disableDecimalScale: true }}
              />
            </Stack>

            <Stack direction="column">
              <Tooltip title="Wähle einen Punkt in der Scene an" placement="left">
                <IconButton onClick={choosePoint}>
                  <AdsClick />
                </IconButton>
              </Tooltip>

              <Tooltip
                title={roof.shape.points.length < 4 ? 'Weniger als 3 Punkte ist nicht möglich' : ''}
                placement="left"
              >
                <IconButton onClick={addPoint}>
                  <AddCircleOutline />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
        </Stack>

        {errorMessages.map(message => (
          <Box
            key={message}
            sx={({ spacing, palette }) => ({
              display: 'flex',
              padding: spacing(0.5),
              background: '#fff',
              color: palette.error.main,
              border: '1px solid',
              borderColor: palette.error.main,
              borderRadius: '4px',
            })}
          >
            <ErrorOutline fontSize="small" sx={{ marginRight: ({ spacing }) => spacing(0.5) }} />
            <Typography sx={{ fontSize: 12 }}>{message}</Typography>
          </Box>
        ))}

        <>
          <Box pt={1}>
            <Divider />
          </Box>
          <Stack direction="row" alignItems="center" justifyContent="space-between" pb={1}>
            <SwitchLabeled
              checked={zComputed}
              onChange={() => setZComputed(!zComputed)}
              label="Z Wert anhand Dachfläche berechnen"
            />
          </Stack>
        </>
      </Stack>
    </>
  )
}

export default FormFields

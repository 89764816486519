import { useMemo } from 'react'
import { find } from 'lodash-es'
import { useAllDomains } from '@editorHooks'

const useDomain = (domainGuid: string): Domain | undefined => {
  const allDomains = useAllDomains()

  const domain = useMemo(() => {
    return find(allDomains, ['guid', domainGuid])
  }, [domainGuid, allDomains])

  return domain
}

export default useDomain

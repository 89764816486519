import produce from 'immer'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'

export const translateOpening = (
  opening: Opening,
  direction: ImmutableVector3,
  distance: number,
) => {
  const newOpening = produce(opening => {
    opening.shape.points = opening.shape.points.map(p => {
      return p.addScaledVector(direction, distance)
    })
  }, opening)()

  return newOpening
}

import React, { ReactElement, ReactNode } from 'react'
import produce from 'immer'
import { cloneDeep } from 'lodash-es'
import create from 'zustand'
import createContext from 'zustand/context'
import { combine } from 'zustand/middleware'
import { validate } from '../middleware'

interface Props {
  children: ReactNode
}

interface ElementSelectionInitialState {
  selectionEnabled: boolean
  selectedGroupIds: Set<string>
  selectedStandAloneIds: Set<string>
  highlightedIds: Set<string>
  highlightedSecondaryIds: Set<string>
}

interface ElementSelectionStoreType extends ElementSelectionInitialState {
  clear: () => void
  setSelectionEnabled: (selectionEnabled: boolean) => void
  setSelectedStandaloneIds: (selectedStandAloneIds: string[]) => void
  addSelectedStandaloneIds: (selectedStandAloneIds: string[]) => void
  setSecondaryHighlightedIds: (ids: string[]) => void
  setHighlightedIds: (ids: string[]) => void
  deselectStandAloneId: (id: string) => void
  resetHighlightedIds: () => void
  deselectAllIds: () => void
}

const initialState: ElementSelectionInitialState = {
  selectionEnabled: false,
  selectedGroupIds: new Set(),
  selectedStandAloneIds: new Set(),
  highlightedIds: new Set(),
  highlightedSecondaryIds: new Set(),
}

const createStore = () =>
  create(
    validate(
      combine(cloneDeep(initialState), set => ({
        clear: () => set(initialState),

        setSelectionEnabled: (selectionEnabled: boolean) => set({ selectionEnabled }),

        setSecondaryHighlightedIds: (ids: string[]) =>
          set(
            produce(state => {
              state.highlightedSecondaryIds = new Set([...ids])
            }),
          ),

        setHighlightedIds: (ids: string[]) =>
          set(
            produce(state => {
              state.highlightedIds = new Set([...ids])
            }),
          ),

        resetHighlightedIds: () =>
          set(
            produce(state => {
              state.highlightedIds = new Set()
            }),
          ),

        addSelectedStandaloneIds: (selectedStandAloneIds: string[]) =>
          set(
            produce(state => {
              state.selectedStandAloneIds = new Set([
                ...state.selectedStandAloneIds,
                ...selectedStandAloneIds,
              ])
            }),
          ),

        setSelectedStandaloneIds: (selectedStandAloneIds: string[]) =>
          set(
            produce(state => {
              state.selectedStandAloneIds = new Set([...selectedStandAloneIds])
            }),
          ),

        deselectStandAloneId: (id: string) =>
          set(
            produce(state => {
              state.selectedStandAloneIds.delete(id)
            }),
          ),

        deselectAllIds: () =>
          set(
            produce(state => {
              state.selectedStandAloneIds = new Set()
            }),
          ),
      })),
    ),
  )

const { Provider, useStore: useElementSelectionStore } = createContext<ElementSelectionStoreType>()

const ElementSelectionStoreProvider = ({ children }: Props): ReactElement => (
  <Provider createStore={createStore}>{children}</Provider>
)

export { ElementSelectionStoreProvider, useElementSelectionStore }

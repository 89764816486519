import { useMemo } from 'react'
import { reduce } from 'lodash-es'
import { useAllDomains } from '@editorHooks'

const useGuidToDomain = () => {
  const allDomains = useAllDomains()

  const guidToDomain = useMemo(
    () =>
      reduce(
        allDomains,
        (col, domain) => ({
          ...col,
          [domain.guid]: domain,
        }),
        {} as Record<string, Domain>,
      ),
    [allDomains],
  )

  return guidToDomain
}

export default useGuidToDomain

import React, { ReactElement } from 'react'
import { Divider, useTheme } from '@mui/material'
import { ToggleButtonDropDown, ToggleDropDownOptions } from '@ui/actions'
import { Slab } from '@ui/icons'
import { structureDrawOptions, modelDrawOptions } from './constants'

interface Props {
  onClick: (value: StructuralPlanningModes) => void
  value?: StructuralPlanningModes
}

const EditElementSelect = ({ onClick, value }: Props): ReactElement => {
  const theme = useTheme()

  return (
    <ToggleButtonDropDown
      toggleType="hover"
      style={{
        color: theme.palette.grey[800],
        backgroundColor: value ? theme.palette.grey[400] : undefined,
        borderColor: theme.palette.grey[600],
      }}
      Icon={Slab}
      onClick={() => value && onClick(value)}
      data-cy="btn-draw-select"
    >
      <ToggleDropDownOptions value={value} onClick={onClick} options={modelDrawOptions} />
      <Divider sx={{ my: 0.5 }} />
      <ToggleDropDownOptions value={value} onClick={onClick} options={structureDrawOptions} />
    </ToggleButtonDropDown>
  )
}

export default EditElementSelect

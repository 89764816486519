import React, { ReactElement, useEffect, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { filter, flatten, includes, findIndex } from 'lodash-es'
import { Typography, TextField, MenuItem, Stack, Button } from '@mui/material'
import { ThreeEvent } from '@react-three/fiber'
import { SelectedArrayItem, ErrorField } from '@ui/forms'
import { Box } from '@ui/structure'
import { useElementSelectionStore, useControlStore } from '@editorStores'
import { useTypeInteraction } from '@editorHooks'
import { SceneMouseTooltip } from 'src/components/pages/Editor/components'
import ElementSelection from '../../../ElementSelection'
import { windDirectionAssignments, windDirectionWatches } from './constants'

const OuterWallAssignment = ({ setWindAngle }: WallAssignmentParamsActions): ReactElement => {
  const selectedStandAloneIds = useElementSelectionStore(state => state.selectedStandAloneIds)
  const hasSelection = !!selectedStandAloneIds.size
  const deselectAllIds = useElementSelectionStore(state => state.deselectAllIds)
  const setHighlightedIds = useElementSelectionStore(state => state.setHighlightedIds)
  const addHiddenElementIds = useControlStore(state => state.addHiddenElementIds)
  const removeHiddenElementIds = useControlStore(state => state.removeHiddenElementIds)
  const [selectedType, setSelectedType] = useState<WindloadSelection>(
    windDirectionAssignments[0].value,
  )
  const { getValues, watch, trigger, formState } = useFormContext()
  const { append } = useFieldArray({
    name: `outerWallAssignment.${selectedType}`,
  })
  useTypeInteraction('outer_walls')
  const watches = watch(windDirectionWatches)

  const onPointerOver = (event: ThreeEvent<MouseEvent>) => {
    if (!event.object.name) return null

    const assignmentCategoryIndex = findIndex(watches, watch => includes(watch, event.object.name))

    if (assignmentCategoryIndex === -1) return null

    return windDirectionAssignments[assignmentCategoryIndex].label
  }

  const setAssignmentsVisible = (visible: boolean, index: number) => {
    if (visible) removeHiddenElementIds(watches[index])
    else addHiddenElementIds(watches[index])
    if (formState.submitCount) trigger()
  }

  const createAssignment = () => {
    const current = getValues(`outerWallAssignment.${selectedType}`)

    const values = filter([...selectedStandAloneIds], id => !current.includes(id))

    append(values)
    if (formState.submitCount) trigger()
    deselectAllIds()
  }

  useEffect(() => {
    setHighlightedIds(flatten(watches))
  }, [watches])

  useEffect(() => {
    setWindAngle('0')
  }, [])

  return (
    <>
      <ElementSelection />
      <SceneMouseTooltip onPointerOver={onPointerOver} />
      <Box p={1} border={1} borderColor="grey.200" borderRadius={1}>
        <Typography variant="h6" textAlign="center">
          Ausgewählt: {selectedStandAloneIds.size}
        </Typography>
        <Typography mb={1}>Weisen Sie jeder Windrichtung min. ein Bauteil zu:</Typography>
        <TextField
          select
          disabled={!hasSelection}
          label="Windrichtung"
          value={selectedType}
          onChange={event => setSelectedType(event.target.value as WindloadSelection)}
          size="small"
          fullWidth
          sx={{ mt: 1, bgcolor: 'grey.50' }}
          data-cy="wind-direction-select"
        >
          {windDirectionAssignments.map(({ label, value }, index) => (
            <MenuItem value={value} key={index} data-cy={`option-${value}`}>
              {label}
            </MenuItem>
          ))}
        </TextField>

        <Stack direction="row" spacing={1} mt={1}>
          <Button
            onClick={createAssignment}
            disabled={!selectedStandAloneIds.size}
            size="small"
            variant="contained"
            fullWidth
            data-cy="btn-assign-wall"
          >
            Zuweisen
          </Button>

          <Button
            onClick={deselectAllIds}
            disabled={!selectedStandAloneIds.size}
            size="small"
            variant="outlined"
            fullWidth
          >
            Auswahl aufheben
          </Button>
        </Stack>

        <Stack direction="column" spacing={1} mt={2}>
          <Typography>Bestehende Zuweisungen:</Typography>
          {windDirectionAssignments.map(({ value }) => (
            <ErrorField
              data-cy={`error-${value}`}
              key={`error-${value}`}
              name={`outerWallAssignment.${value}`}
            />
          ))}
          {windDirectionAssignments.map(({ label, value }, index) => {
            return (
              <SelectedArrayItem
                key={value}
                title={label}
                onVisible={(visible: boolean) => setAssignmentsVisible(visible, index)}
                onDelete={() => setAssignmentsVisible(true, index)}
                arrayKey={`outerWallAssignment.${value}`}
              />
            )
          })}
        </Stack>
      </Box>
    </>
  )
}

export default OuterWallAssignment

import { useMemo } from 'react'
import { some } from 'lodash-es'

/**
 * Check if any of the bundles contains a max utilization over 1
 * @param bundles
 * @returns
 */
const useMaxUtilizationOver1 = (bundles: MemberPositionBundleWithMaxUtilization[]) => {
  return useMemo(
    () =>
      some(
        bundles,
        bundle =>
          bundle.maxUtilizationCheck?.max_utilization &&
          bundle.maxUtilizationCheck?.max_utilization > 1,
      ),
    [bundles],
  )
}

export default useMaxUtilizationOver1

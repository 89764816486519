import { number, object, mixed, string, array } from 'yup'

const pointSchema = object({
  x: number().required('X coordinate is required'),
  y: number().required('Y coordinate is required'),
  z: number().required('Z coordinate is required'),
})

// Define the schema for the points array
const pointsSchema = array()
  .of(pointSchema)
  .min(3, 'Each roof element must have 3 points')
  .required('Points array is required')

// Define the shape schema with the custom validation
const shapeSchema = object({
  points: pointsSchema,
  shape_type: mixed().oneOf(['Rectangular', 'BaseAxisXYFreeform']),
}).test(
  'is-base-axis-horizontal-for-non-rectangular-shape',
  'Erste Kante ist nicht horizontal.',
  function (value) {
    const { shape_type, points } = value || {}
    if (shape_type === 'BaseAxisXYFreeform' && points && points.length > 1) {
      return points[0].z === points[1].z
    }
    return true
  },
)

export const verticalRoofSlabSchema = object({
  guid: string(),
  shape: shapeSchema,
  orientation: mixed().oneOf(['horizontal', 'vertical']),
  storey: string().test('storey-check', 'wrong storey selection', function (value, context) {
    const type = context.options.context?.element_type
    if (type === 'vertical_slabs' && value === 'Dach') {
      return this.createError({
        message:
          "Aktuell unterstützt das Backend keine Deckenpositionen, denen das Stockwerk 'Dach' zugewiesen ist.",
      })
    }

    if (type === 'vertical_roof_slabs' && value !== 'Dach') {
      return this.createError({
        message: "Dachpositionen muss das Stockwerk 'Dach' zugewiesen sein.",
      })
    }

    return true
  }),
})

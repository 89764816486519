import { last, sortBy, toNumber } from 'lodash-es'
import { object, array, string, boolean, number } from 'yup'
import { lineString, lineOverlap } from '@turf/turf'

const intervalSchema = object({
  lower: number().min(0).max(1).default(0),
  upper: number().min(0).max(1).default(1),
})

export const smoothingSettingsSchema = object({
  point_load_threshold: number().moreThan(0).default(0),
  reference_length: number().default(0),
  intervals: array().of(intervalSchema).default([]),

  isOverlapping: boolean().test({
    name: 'is-overlapping-intervals',
    test: function () {
      const intervals = this.parent.intervals as RelativeInterval[]

      if (intervals.length === 0)
        return this.createError({
          message: 'Mindestens 1 Intervall muss definiert sein',
        })

      const sorted = sortBy(intervals, 'lower')

      if (toNumber(sorted[0].lower) !== 0)
        return this.createError({
          message: 'Ein Intervall muss bei 0 starten',
        })

      if (toNumber(last(sorted)?.upper) !== 1)
        return this.createError({
          message: 'Ein Intervall muss bei 1 enden',
        })

      for (let i = 0; i < sorted.length; i++) {
        const current = sorted[i]
        const next = sorted[i + 1]
        if (next) {
          if (current.upper !== next.lower) {
            return this.createError({
              message: `Die Definition der Intervalle muss lückenlos erfolgen`,
            })
          }

          const currentLine = lineString([
            [0, toNumber(current.lower)],
            [0, toNumber(current.upper)],
          ])
          const nextLine = lineString([
            [0, toNumber(next.lower)],
            [0, toNumber(next.upper)],
          ])

          const overllapping = lineOverlap(currentLine, nextLine)

          if (overllapping.features.length) {
            return this.createError({
              message: `Intervalle ${i + 1} und ${i + 2} überschneiden sich`,
            })
          }
        }
      }

      return true
    },
  }),
})
  .nullable()
  .default(null)

export const schema = object({
  bundles: array<MemberPositionBundle>().of(
    object({
      representative_position: string(),
      representative_for: array().of(string()),
      exported: boolean(),
      comment: string(),
      extended_comment: string(),
      smoothing_settings: smoothingSettingsSchema,
    }),
  ),

  new_interval: object({
    lower: number()
      .min(0)
      .max(1)
      .default(0)
      .test({
        name: 'lower-more-than-upper',
        test: function (value: number) {
          const { upper } = this.parent

          if (value >= upper)
            return this.createError({
              message: 'Start muss kleiner als Ende sein',
            })

          return true
        },
      }),
    upper: number().min(0).max(1).default(1),
  }),
})

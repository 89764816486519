import { useHotkeys } from 'react-hotkeys-hook'
import { HotkeyCallback, OptionsOrDependencyArray } from 'react-hotkeys-hook/dist/types'

// prettier-ignore
export const shiftKeyMap: { [key: string]: string } = {
    '=': '0',
    '!': '1',
    '"': '2',
    '§': '3',
    // prettier would remove the string characters around $, which would be wrong here
    '$': '4',
    '%': '5',
    '&': '6',
    '/': '7',
    '(': '8',
    ')': '9',
}

export const useHotkeys_Shift_0to9 = (
  callback: HotkeyCallback,
  options: OptionsOrDependencyArray = {},
  dependencies: OptionsOrDependencyArray = [],
) => {
  return useHotkeys(
    [
      // standard keys
      'SHIFT+=',
      'SHIFT+!',
      'SHIFT+"',
      'SHIFT+§',
      'SHIFT+$',
      'SHIFT+%',
      'SHIFT+&',
      'SHIFT+/',
      'SHIFT+(',
      'SHIFT+)',
      // numpad keys
      'SHIFT+0',
      'SHIFT+1',
      'SHIFT+2',
      'SHIFT+3',
      'SHIFT+4',
      'SHIFT+5',
      'SHIFT+6',
      'SHIFT+7',
      'SHIFT+8',
      'SHIFT+9',
    ],
    callback,
    options,
    dependencies,
  )
}

import { Box3 } from 'three'
import { ARROW_OFFSET, ARROW_DISTANCE } from './constants'

const getOffset = (index: number) => index * ARROW_DISTANCE + ARROW_OFFSET

export const arrowPositionsByAngle = (angle: WindAngle, bbox: Box3): Float32Array[] => {
  const iterator = [...Array(3).keys()]

  switch (angle) {
    case '0': {
      const y = (bbox.max.y - bbox.min.y) / 2

      return iterator.map((_, index: number) => {
        const offset = getOffset(index)
        return new Float32Array([
          -1 - offset,
          y,
          0,
          -1.5 - offset,
          y - 0.2,
          0,
          -1.5 - offset,
          y + 0.2,
          0,
        ])
      })
    }
    case '90': {
      const x = (bbox.max.x - bbox.min.x) / 2

      return iterator.map((_, index: number) => {
        const offset = getOffset(index)
        return new Float32Array([
          x,
          -1 - offset,
          0,
          x - 0.2,
          -1.5 - offset,
          0,
          x + 0.2,
          -1.5 - offset,
          0,
        ])
      })
    }
    case '180': {
      const y = (bbox.max.y - bbox.min.y) / 2
      const x = bbox.max.x

      return iterator.map((_, index: number) => {
        const offset = getOffset(index)
        return new Float32Array([
          1 + x + offset,
          y,
          0,
          1.5 + x + offset,
          y - 0.2,
          0,
          1.5 + x + offset,
          y + 0.2,
          0,
        ])
      })
    }
    case '270': {
      const x = (bbox.max.x - bbox.min.x) / 2
      const y = bbox.max.y

      return iterator.map((_, index: number) => {
        const offset = getOffset(index)
        return new Float32Array([
          x,
          1 + y + offset,
          0,
          x - 0.2,
          1.5 + y + offset,
          0,
          x + 0.2,
          1.5 + y + offset,
          0,
        ])
      })
    }
    default: {
      return []
    }
  }
}

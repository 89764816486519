import React, { ReactElement, useCallback } from 'react'
import { ColorRepresentation } from 'three'
import { useTheme } from '@mui/material'
import { DraggableLine } from '@modugen/scene/lib/components/Lines/DraggableLine'
import { useSceneStore } from '@editorStores'

export enum PurlinElements {
  Line = 'PurlinLine',
  Handle = 'PurlinHandle',
}

interface Props {
  purlin: ShapeObjectLine
  isActive: boolean
  onClick: () => void
}

const Purlin3D = ({ purlin, isActive, onClick }: Props): ReactElement => {
  const { scenePalette } = useTheme()

  // state picking due to performance reasons
  const mappedColor = useSceneStore(
    useCallback(state => state.guidToColor[purlin.guid], [purlin.guid]),
  ) as ColorRepresentation | undefined

  return (
    <DraggableLine
      lineName={PurlinElements.Line}
      key={purlin.guid}
      line={{
        start: purlin.shape.start,
        end: purlin.shape.end,
      }}
      color={(mappedColor || scenePalette.elements3d.beams) as string}
      showLengthIndicator={!!purlin.is_local || isActive}
      selectedColor={scenePalette.elements3d.beams as string}
      isSelected={isActive}
      showIndicators={false}
      onClick={onClick}
    />
  )
}

export default Purlin3D

import { useEffect } from 'react'
import { flatten } from 'lodash-es'
import { useQueryParams, ArrayParam } from 'use-query-params'
import { useElementSelectionStore } from '../stores/elementSelectionStore'

const useHighlightFromParams = (params: string[], condition = true) => {
  const setSelectedStandaloneIds = useElementSelectionStore(state => state.setSelectedStandaloneIds)
  const [values] = useQueryParams(
    params.reduce((acc, param) => ({ ...acc, [param]: ArrayParam }), {}),
  )

  useEffect(() => {
    if (!condition) return

    const ids: string[] = flatten(Object.values(values))

    if (ids?.length) setSelectedStandaloneIds([...ids] as string[])
    else setSelectedStandaloneIds([])

    return () => setSelectedStandaloneIds([])
  }, [values, condition])
}

export default useHighlightFromParams

import { throttle } from 'lodash-es'
import { StateCreator, SetState, GetState, StoreApi } from 'zustand'
import { validateData } from './utils'

const throttledValidate = throttle(validateData, 500)

/* eslint-disable @typescript-eslint/no-explicit-any */
export const validate =
  (config: StateCreator<any>) => (set: SetState<any>, get: GetState<any>, api: StoreApi<any>) =>
    config(
      (...args) => {
        set(...args)
        const state = get()
        const { validationSchema } = state

        if (!validationSchema) return

        const validationResult = throttledValidate(state, validationSchema)

        set({ validation: validationResult })
      },
      get,
      api,
    )

import React, { ReactElement } from 'react'
import { Grid } from '@mui/material'
import { Form, TextField, UnitConvertedField } from '@ui/forms'
import { connectionSchema } from './schema'

interface Props {
  id: string
  defaultValues: Connection
  onSubmit: (values: Connection) => void
}

const ConnectionForm = ({ defaultValues, id, onSubmit }: Props): ReactElement => {
  return (
    <Form
      id={id}
      onSubmit={onSubmit}
      validationSchema={connectionSchema}
      defaultValues={{
        ...connectionSchema.getDefault(),
        ...defaultValues,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Name"
            name="name"
            placeholder="Zugverbindung 1"
            data-cy="connection-name"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Kommentar im Export"
            name="export_comment"
            data-cy="connection-comment"
          />
        </Grid>

        <Grid item xs={6}>
          <UnitConvertedField
            label={
              <>
                R<sub>d</sub> Erdbeben (sehr kurz)
              </>
            }
            name="r_d_earthquake"
            data-cy="r_d_earthquake"
          />
        </Grid>
        <Grid item xs={6}>
          <UnitConvertedField
            label={
              <>
                R<sub>d</sub> Wind (kurz/sehr kurz)
              </>
            }
            name="r_d_wind"
            data-cy="r_d_wind"
          />
        </Grid>
      </Grid>
    </Form>
  )
}

export default ConnectionForm

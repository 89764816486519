import { elementTypes } from '@scene'
import { transform, includes } from 'lodash-es'
import { roofStoreyKey } from './constants'

export const generateStoreyAssignment = (storeyAssignment: StoreyAssignment) => {
  // elements belonging to the roof will never get assigned to a storey by
  // the backend, the roofStoreyKey is therefore a frontend-only construct
  const availableStoreys = new Set([roofStoreyKey])
  const storeysByGuid: Record<string, string> = {}

  Object.values(storeyAssignment as unknown as Record<string, Record<string, string[]>>).forEach(
    storeys => {
      Object.entries(storeys).forEach(([storey, guids]) => {
        if (!availableStoreys.has(storey)) availableStoreys.add(storey)

        guids.forEach(guid => {
          storeysByGuid[guid] = storey
        })
      })
    },
  )

  return {
    storeysByGuid,
    availableStoreys,
    visibleStoreys: new Set(availableStoreys),
  }
}

export const findStoreyForElement = (
  guid: string,
  storeyAssignment: { [key: number]: string[] },
): string => {
  return transform(
    storeyAssignment,
    (result: string[], guids, storey) => {
      if (includes(guids, guid)) {
        result.push(storey)
        return false
      }
    },
    [],
  )[0]
}

export const addStoreyToElements = (
  model: PlanarModel,
  storeyAssignment: StoreyAssignment,
): PlanarModel => {
  const { roof_slabs, slabs, walls, vertical_slabs, beams, columns, purlins, vertical_roof_slabs } =
    model
  const {
    slab_storey_id_assignment,
    wall_storey_id_assignment,
    vertical_slab_storey_id_assignment,
    beam_storey_id_assignment,
    column_storey_id_assignment,
  } = storeyAssignment

  return {
    ...model,
    slabs: slabs.map(el => ({
      ...el,
      storey: findStoreyForElement(el.guid, slab_storey_id_assignment),
      type: 'slabs',
    })),
    vertical_slabs: vertical_slabs.map(el => ({
      ...el,
      storey: findStoreyForElement(el.guid, vertical_slab_storey_id_assignment),
      type: 'vertical_slabs',
    })),
    columns: columns.map(el => ({
      ...el,
      storey: findStoreyForElement(el.guid, column_storey_id_assignment),
      type: 'columns',
    })),
    beams: beams.map(el => ({
      ...el,
      storey: findStoreyForElement(el.guid, beam_storey_id_assignment),
      type: 'beams',
    })),
    walls: walls.map(el => ({
      ...el,
      storey: findStoreyForElement(el.guid, wall_storey_id_assignment),
      type: el.placement === 'External' ? 'outer_walls' : ('inner_walls' as ElementTypes),
    })),
    vertical_roof_slabs: vertical_roof_slabs.map(el => ({
      ...el,
      storey: roofStoreyKey,
      type: 'vertical_roof_slabs',
    })),
    roof_slabs: roof_slabs.map(el => ({
      ...el,
      storey: roofStoreyKey,
      type: 'roof_slabs',
    })),
    purlins: purlins.map(el => ({
      ...el,
      storey: roofStoreyKey,
      type: 'purlins',
    })),
  }
}

export const flattenDomains = (model: PlanarModel) => {
  const slabBeams = [...model.vertical_slabs.map(el => el.beam)]
  const roofSlabBeams = [...model.vertical_roof_slabs.map(el => el.beam)]
  return [
    ...model.walls,
    ...model.vertical_slabs,
    ...model.beams,
    ...model.vertical_roof_slabs,
    ...model.columns,
    ...model.purlins,
    ...slabBeams,
    ...roofSlabBeams,
  ].reduce((acc: Domain[], element) => {
    return [
      ...acc,
      ...(element.domains || []).map(domain => ({
        ...domain,
        element_guid: element.guid,
        length: domain.end.sub(domain.start).length(),
      })),
    ]
  }, [])
}

export const createInteractableByType = (types: ElementTypes[]) => {
  return elementTypes.reduce(
    (collector, type) => ({ ...collector, [type]: types.includes(type) }),
    {} as Record<ElementTypes, boolean>,
  )
}

import React, { ReactElement, useCallback, useMemo } from 'react'
import {
  createMeshStandardMaterialMemoized,
  getShapeEdges,
  getShapeMatrixTransform,
  getShapeObject,
} from '@scene'
import { ColorRepresentation, DoubleSide } from 'three'
import { useTheme } from '@mui/material'
import { BasicMesh, BasicMeshProps } from '@modugen/scene/lib/components/BasicMesh'
import { config as sceneConfig } from '@modugen/scene/lib/config'
import { MeshStandardMaterialProps } from '@react-three/fiber'
import { useSceneStore } from 'src/components/pages/Editor/stores/sceneStore'

interface ShapeMeshProps extends BasicMeshProps {
  data: ShapeObject
  shapeColor?: string | ColorRepresentation
  outlines?: boolean
  outlinesColor?: string | ColorRepresentation
  translucent?: boolean
  applyThickness?: boolean
  visible?: boolean
  meshMaterialProps?: MeshStandardMaterialProps
}

/**
 * @deprecated use simplified SimpleShape
 */
const ShapeMesh = ({
  data,
  shapeColor,
  outlines = true,
  outlinesColor,
  translucent = false,
  applyThickness,
  meshMaterialProps = {},
  ...basicMeshProps
}: ShapeMeshProps): ReactElement => {
  // state picking due to performance reasons
  const mappedColor = useSceneStore(
    useCallback(state => state.guidToColor[data.guid], [data.guid]),
  ) as string | undefined

  const {
    scenePalette,
    sceneConfig: { defaultThickness },
  } = useTheme()

  const thickness = applyThickness ? data.thickness || defaultThickness : 0

  const matrixTransform = useMemo(
    () => getShapeMatrixTransform(data.shape.points),
    [data.shape.points],
  )

  const shapeObject = useMemo(
    () =>
      getShapeObject(
        data.shape.points,
        (data.openings || []).map(opening => opening.shape.points),
        matrixTransform,
      ),
    [data.shape.points, matrixTransform, data.openings],
  )

  const edges = useMemo(
    () => getShapeEdges(shapeObject, applyThickness ? data.thickness || 0.005 : 0.01),
    [shapeObject, applyThickness, data],
  )

  const material = useMemo(
    () =>
      createMeshStandardMaterialMemoized({
        side: DoubleSide,
        // @ts-ignore
        color: mappedColor || shapeColor || scenePalette.elements3d.walls,
        opacity: translucent ? 0.33 : 1,
        transparent: translucent,
        ...meshMaterialProps,
      }),
    [shapeColor, translucent, meshMaterialProps, mappedColor],
  )

  const userData = useMemo(
    () => ({
      points: data.shape.points,
      ...(basicMeshProps.userData || {}),
    }),
    [],
  )

  return (
    <BasicMesh
      name={data.guid}
      // why disabling matrixAutoUpdate should be fine:
      // https://github.com/pmndrs/react-three-fiber/issues/635#issuecomment-682439509
      matrix={matrixTransform}
      matrixAutoUpdate={false}
      material={material}
      {...basicMeshProps}
      userData={userData}
    >
      <extrudeGeometry
        args={[
          shapeObject,
          {
            depth: thickness,
            bevelEnabled: false,
          },
        ]}
      />

      {outlines && (
        <lineSegments
          layers={sceneConfig.R3FNonSelectableObjectLayer}
          geometry={edges}
          renderOrder={100}
        >
          <lineBasicMaterial color={outlinesColor || scenePalette.outlines} />
        </lineSegments>
      )}
    </BasicMesh>
  )
}

export default ShapeMesh

import React, { ReactElement } from 'react'
import { Toolbar } from '@mui/material'
import { Navbar } from '@ui/navigation'
import { Box } from '@ui/structure'

interface Props {
  children: ReactElement
}

const Layout = ({ children }: Props): ReactElement => {
  return (
    <>
      <Navbar />
      <Toolbar />
      <Box
        component="main"
        sx={({ palette, spacing, distances }) => ({
          backgroundColor: palette.grey[200],
          padding: spacing(3),
          minHeight: `calc(100vh - ${distances.navBarHeight})`,
        })}
      >
        {children}
      </Box>
    </>
  )
}

export default Layout

import { Vector3, BufferGeometry, Line } from 'three'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { bbox, lineString } from '@turf/turf'

export const generatePointsFromLine = (
  [x1, y1]: [number, number],
  [x2, y2]: [number, number],
  z: number,
  isHorizontal = true,
): Point[] => {
  const [minX, minY, maxX, maxY] = bbox(
    lineString([
      [x1, y1],
      [x2, y1],
      [x2, y2],
      [x1, y2],
    ]),
  )

  const bottomLeft = new ImmutableVector3(minX, minY, z)
  const bottomRight = new ImmutableVector3(maxX, minY, z)
  const topRight = new ImmutableVector3(maxX, maxY, z)
  const topLeft = new ImmutableVector3(minX, maxY, z)

  return isHorizontal
    ? [topLeft, bottomLeft, bottomRight, topRight]
    : [bottomLeft, bottomRight, topRight, topLeft]
}

export const getLineFromBeam = (points: Point[]): ImmutableVector3[] =>
  points.map(point => new ImmutableVector3(point.x, point.y, 0))

export const getSnapLinesFromPolygon = (
  { guid, shape: { points } }: ShapeObject,
  xyOnly = false,
) => {
  return points.map((point, i) => {
    const nextIndex = i + 1
    const nextPointIndex = nextIndex === points.length ? 0 : nextIndex
    const nextPoint = points[nextPointIndex]

    const start = new Vector3(point.x, point.y, xyOnly ? 0 : point.z)
    const end = new Vector3(nextPoint.x, nextPoint.y, xyOnly ? 0 : nextPoint.z)

    const geometry = new BufferGeometry().setFromPoints([start, end])
    const line = new Line(geometry)

    line.userData.guid = guid

    return line
  })
}

export const getSnapLinesFromLine = (
  { guid, shape: { start, end } }: ShapeObjectLine,
  xyOnly = false,
) => {
  const startV = new Vector3(start.x, start.y, xyOnly ? 0 : start.z)
  const endV = new Vector3(end.x, end.y, xyOnly ? 0 : end.z)

  const geometry = new BufferGeometry().setFromPoints([startV, endV])
  const line = new Line(geometry)

  line.userData.guid = guid

  return line
}

import React, { ReactElement } from 'react'

interface Props {
  combination: LoadCase
}

const LoadCaseAbbreviation = ({ combination }: Props): ReactElement => {
  const map: Record<LoadCaseTypes, ReactElement> = {
    SnowFull: (
      <>
        Q<sub>k,s</sub>{' '}
      </>
    ),
    DeadLoad: <>G</>,
    Wind_Global_0: (
      <>
        Q<sub>k,w,0</sub>
      </>
    ),
    Wind_Global_90: (
      <>
        Q<sub>k,w,90</sub>
      </>
    ),
    Wind_Global_180: (
      <>
        Q<sub>k,w,180</sub>
      </>
    ),
    Wind_Global_270: (
      <>
        Q<sub>k,w,270</sub>
      </>
    ),
    LiveLoad_A1: (
      <>
        Q<sub>k,N,A1</sub>
      </>
    ),
    LiveLoad_A2: (
      <>
        Q<sub>k,N,A2</sub>
      </>
    ),
    LiveLoad_A3: (
      <>
        Q<sub>k,N,A3</sub>
      </>
    ),
    LiveLoad_B1: (
      <>
        Q<sub>k,N,B1</sub>
      </>
    ),
    LiveLoad_B2: (
      <>
        Q<sub>k,N,B2</sub>
      </>
    ),
    LiveLoad_B3: (
      <>
        Q<sub>k,N,B3</sub>
      </>
    ),
    LiveLoad_C1: (
      <>
        Q<sub>k,N,C1</sub>
      </>
    ),
    LiveLoad_C2: (
      <>
        Q<sub>k,N,C2</sub>
      </>
    ),
    LiveLoad_C3: (
      <>
        Q<sub>k,N,C3</sub>
      </>
    ),
    LiveLoad_C4: (
      <>
        Q<sub>k,N,C4</sub>
      </>
    ),
    LiveLoad_C5: (
      <>
        Q<sub>k,N,C5</sub>
      </>
    ),
    LiveLoad_C6: (
      <>
        Q<sub>k,N,C6</sub>
      </>
    ),
    LiveLoad_D1: (
      <>
        Q<sub>k,N,D1</sub>
      </>
    ),
    LiveLoad_D2: (
      <>
        Q<sub>k,N,D2</sub>
      </>
    ),
    LiveLoad_D3: (
      <>
        Q<sub>k,N,D3</sub>
      </>
    ),
    LiveLoad_E1: (
      <>
        Q<sub>k,N,E1</sub>
      </>
    ),
    LiveLoad_E2: (
      <>
        Q<sub>k,N,E2</sub>
      </>
    ),
    LiveLoad_F1: (
      <>
        Q<sub>k,N,F1</sub>
      </>
    ),
    LiveLoad_F2: (
      <>
        Q<sub>k,N,F2</sub>
      </>
    ),
    LiveLoad_H: (
      <>
        Q<sub>k,N,H</sub>
      </>
    ),
    Earthquake_0: (
      <>
        A<sub>k,E,0</sub>
      </>
    ),
    Earthquake_90: (
      <>
        A<sub>k,E,90</sub>
      </>
    ),
    Earthquake_180: (
      <>
        A<sub>k,E,180</sub>
      </>
    ),
    Earthquake_270: (
      <>
        A<sub>k,E,270</sub>
      </>
    ),
    Earthquake_Spectral_PlusXPlus30Y: (
      <>
        A<sub>k,E,+X|+0.3Y</sub>
      </>
    ),
    Earthquake_Spectral_PlusXMinus30Y: (
      <>
        A<sub>k,E,+X|-0.3Y</sub>
      </>
    ),
    Earthquake_Spectral_MinusXPlus30Y: (
      <>
        A<sub>k,E,-X|+0.3Y</sub>
      </>
    ),
    Earthquake_Spectral_MinusXMinus30Y: (
      <>
        A<sub>k,E,-X|-0.3Y</sub>
      </>
    ),
    Earthquake_Spectral_PlusYPlus30X: (
      <>
        A<sub>k,E,+Y|+0.3X</sub>
      </>
    ),
    Earthquake_Spectral_PlusYMinus30X: (
      <>
        A<sub>k,E,+Y|-0.3X</sub>
      </>
    ),
    Earthquake_Spectral_MinusYPlus30X: (
      <>
        A<sub>k,E,-Y|+0.3X</sub>
      </>
    ),
    Earthquake_Spectral_MinusYMinus30X: (
      <>
        A<sub>k,E,-Y|-0.3X</sub>
      </>
    ),
    Earthquake_X_e_max_y: (
      <>
        A<sub>k,E_X,e_max_y</sub>
      </>
    ),
    Earthquake_X_e_max_y_inv: (
      <>
        A<sub>k,E_X,e_max_y_inv</sub>
      </>
    ),
    Earthquake_X_e_min_y: (
      <>
        A<sub>k,E_X,e_min_y</sub>
      </>
    ),
    Earthquake_X_e_min_y_inv: (
      <>
        A<sub>k,E_X,e_min_y_inv</sub>
      </>
    ),
    Earthquake_Y_e_max_x: (
      <>
        A<sub>k,E_Y,e_max_x</sub>
      </>
    ),
    Earthquake_Y_e_max_x_inv: (
      <>
        A<sub>k,E_Y,e_max_x_inv</sub>
      </>
    ),
    Earthquake_Y_e_min_x: (
      <>
        A<sub>k,E_Y,e_min_x</sub>
      </>
    ),
    Earthquake_Y_e_min_x_inv: (
      <>
        A<sub>k,E_Y,e_min_x_inv</sub>
      </>
    ),
  }

  if (!combination.load_case_type) return <></>

  return map[combination.load_case_type]
}

export default LoadCaseAbbreviation

import { useMemo } from 'react'
import { useModelStore, useControlStore } from '@editorStores'

const useHiddenElements = () => {
  const hiddenElementIds = useControlStore(state => state.hiddenElementIds)
  const model = useModelStore(state => state.model)
  const visibilityByType = useModelStore(state => state.visibilityByType)
  const visibleStoreys = useModelStore(state => state.visibleStoreys)

  return useMemo(() => {
    const elements = [
      ...model.walls,
      ...model.slabs,
      ...model.roof_slabs,
      ...model.vertical_slabs,
      ...model.beams,
      ...model.columns,
      ...model.purlins,
      ...model.vertical_roof_slabs,
      ...(model.foundation ? [model.foundation] : []),
    ]

    return elements.reduce(
      (acc: Set<string>, { guid, storey, type }: ShapeObject | ShapeObjectLine) => {
        // special treatment for foundation because there is no storey for those elements
        const isVisibleExeptStorey =
          visibilityByType[type as ElementTypes] && !hiddenElementIds.has(guid)
        const isVisible = storey
          ? visibleStoreys.has(storey) && isVisibleExeptStorey
          : isVisibleExeptStorey

        if (isVisible) return acc

        return new Set([...acc, guid])
      },
      new Set(),
    )
  }, [hiddenElementIds, model, visibilityByType, visibleStoreys])
}

export default useHiddenElements

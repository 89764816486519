import React, { Fragment, ReactElement } from 'react'
import { getCenterMultiple } from '@modugen/scene/lib/utils'
import { useUIStore } from '@stores'
import { useElementLabel } from '@editorHooks'
import Label from '../Label'

interface Props {
  data: {
    representativePosition?: Position | SlabBeam
    representativePositionFor: Array<Position | SlabBeam | undefined>
  }[]
}

const PositionGroupingLabels = ({ data }: Props): ReactElement => {
  const getLabel = useElementLabel()

  const showHtmlSceneLabels = useUIStore(state => state.showHtmlSceneLabels)

  if (!showHtmlSceneLabels) return <></>

  return (
    <>
      {data.map(({ representativePosition, representativePositionFor }) => {
        if (!representativePosition) return null

        const representativeGuid =
          'slab_guid' in representativePosition
            ? representativePosition.guid
            : representativePosition.position_guid

        const { start, end } =
          'slab_guid' in representativePosition
            ? representativePosition.shape
            : representativePosition

        const representativeLabel = getLabel(representativeGuid)

        const representatives = representativePositionFor.map(representativeFor => {
          if (!representativeFor) return null

          const fGuid =
            'slab_guid' in representativeFor
              ? representativeFor.guid
              : representativeFor.position_guid

          const { start: fStart, end: fEnd } =
            'slab_guid' in representativeFor ? representativeFor.shape : representativeFor

          const label = getLabel(fGuid)

          const center = getCenterMultiple([fStart, fEnd])

          return <Label key={fGuid} label={label} position={center.v} />
        })

        const center = getCenterMultiple([start, end])

        return (
          <Fragment key={representativeGuid}>
            <Label label={representativeLabel} position={center.v} highlighted />
            {representatives}
          </Fragment>
        )
      })}
    </>
  )
}

export default PositionGroupingLabels

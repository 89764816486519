import { useMemo } from 'react'
import { useTapelineSnapTargets } from '@scene'
import { flatten, maxBy, filter } from 'lodash-es'
import useTapelineCentersSnapTargets from '@modugen/scene/lib/hooks/useTapelineCentersSnapTargets'
import { useModelStore } from '@editorStores'
import { getSnapLinesFromLine, getSnapLinesFromPolygon } from '../../../FloorplanDrawer/utils'

const useRoofDrawingTargets = () => {
  const model = useModelStore(state => state.model)
  const tapelineSnapTargets = useTapelineSnapTargets()
  const tapelineCenterTargets = useTapelineCentersSnapTargets()

  // MEMOS

  const purlinSnapTargets = useMemo(() => {
    const purlinLines = model.purlins.map(purlin => getSnapLinesFromLine(purlin))
    return flatten(purlinLines)
  }, [model])

  const wallSnapTargetsUpperMostStorey = useMemo(() => {
    const upperMostStorey = maxBy(model.walls, 'storey')?.storey
    const wallsInUpperMostStorey = filter(model.walls, { storey: upperMostStorey })

    const snapLines = wallsInUpperMostStorey.map(wall => getSnapLinesFromPolygon(wall, false))

    return flatten(snapLines)
  }, [model])

  const verticalRoofSlabSnapTargets = useMemo(() => {
    const targets = model.vertical_roof_slabs.map(roof => getSnapLinesFromPolygon(roof, false))
    return flatten(targets)
  }, [model.vertical_roof_slabs])

  const verticalSlabSnapTargets = useMemo(() => {
    const targets = model.vertical_slabs.map(roof => getSnapLinesFromPolygon(roof, false))
    return flatten(targets)
  }, [model.vertical_slabs])

  const roofSlabSnapTargets = useMemo(() => {
    const targets = model.roof_slabs.map(roof => getSnapLinesFromPolygon(roof, false))
    return flatten(targets)
  }, [model.roof_slabs])

  const slabSnapTargets = useMemo(() => {
    const targets = model.slabs.map(slab => getSnapLinesFromPolygon(slab, false))
    return flatten(targets)
  }, [model.slabs])

  const snapTargets = useMemo(
    () => [
      ...wallSnapTargetsUpperMostStorey,
      ...verticalRoofSlabSnapTargets,
      ...verticalSlabSnapTargets,
      ...purlinSnapTargets,
      ...tapelineSnapTargets,
      ...tapelineCenterTargets,
      ...roofSlabSnapTargets,
      ...slabSnapTargets,
    ],
    [
      wallSnapTargetsUpperMostStorey,
      verticalRoofSlabSnapTargets,
      verticalSlabSnapTargets,
      purlinSnapTargets,
      tapelineSnapTargets,
      tapelineCenterTargets,
      roofSlabSnapTargets,
      slabSnapTargets,
    ],
  )

  return snapTargets
}

export default useRoofDrawingTargets

import * as d3 from 'd3'
import { STROKE_WIDTH_FACTOR } from '../constants'

export const plotCLTLayerLongEdge = (
  ref: React.MutableRefObject<SVGSVGElement | null>,
  x: number,
  y: number,
  length: number,
  thickness: number,
  orientation: LayerOrientation,
): void => {
  let width: number
  let height: number
  const strokeWidth = length / STROKE_WIDTH_FACTOR

  if (orientation === 'Horizontal') {
    width = length
    height = thickness
  } else {
    width = thickness
    height = length
  }

  d3.select(ref.current)
    .append('rect')
    .attr('x', x)
    .attr('y', y)
    .attr('width', width)
    .attr('height', height)
    .attr('fill', '#b89130')
    .attr('stroke', 'black')
    .attr('stroke-width', strokeWidth)
}

export const plotCLTLayerShortEdge = (
  ref: React.MutableRefObject<SVGSVGElement | null>,
  x: number,
  y: number,
  height: number,
  width: number,
  strokeWidth: number,
): void => {
  d3.select(ref.current)
    .append('rect')
    .attr('x', x)
    .attr('y', y)
    .attr('width', width)
    .attr('height', height)
    .attr('fill', '#f9d978')
    .attr('stroke', 'black')
    .attr('stroke-width', strokeWidth)
}

export const plotCLTLayerShortEdges = (
  ref: React.MutableRefObject<SVGSVGElement | null>,
  x: number,
  y: number,
  length: number,
  thickness: number,
  edgeCount: number,
  orientation: LayerOrientation,
): void => {
  let width: number
  let height: number
  const shortEdgeLength = length / edgeCount
  const strokeWidth = length / STROKE_WIDTH_FACTOR

  if (orientation === 'Horizontal') {
    width = length / edgeCount
    height = thickness

    for (let i = 0; i < edgeCount; i++) {
      plotCLTLayerShortEdge(ref, x, y, height, width, strokeWidth)
      x = x + shortEdgeLength
    }
  } else {
    width = thickness
    height = length / edgeCount

    for (let i = 0; i < edgeCount; i++) {
      plotCLTLayerShortEdge(ref, x, y, height, width, strokeWidth)
      y = y + shortEdgeLength
    }
  }
}

export const plotCLTAssemblyLayer = (
  ref: React.MutableRefObject<SVGSVGElement | null>,
  x: number,
  y: number,
  length: number,
  thickness: number,
  layerCount: number,
  orientation: LayerOrientation,
): void => {
  const edgeCount = 6
  const layerThickness = thickness / layerCount
  let loopCount = (layerCount - 1) / 2

  if (orientation === 'Horizontal') {
    while (loopCount > 0) {
      plotCLTLayerLongEdge(ref, x, y, length, layerThickness, orientation)
      y = y + layerThickness
      plotCLTLayerShortEdges(ref, x, y, length, layerThickness, edgeCount, orientation)
      y = y + layerThickness
      loopCount--
    }
    plotCLTLayerLongEdge(ref, x, y, length, layerThickness, orientation)
  } else {
    while (loopCount > 0) {
      plotCLTLayerLongEdge(ref, x, y, length, layerThickness, orientation)
      x = x + layerThickness
      plotCLTLayerShortEdges(ref, x, y, length, layerThickness, edgeCount, orientation)
      x = x + layerThickness
      loopCount--
    }
    plotCLTLayerLongEdge(ref, x, y, length, layerThickness, orientation)
  }
}

import { postRequest } from '../utils'

export const saveHorizontalDistributionSettings: RequestTypeVariable = {
  getKey: (projectId: string) => ['saveHorizontalDistributionSettings', projectId],
  request: async (id: string, data: SlabStiffening[]): Promise<HoirzontalDistributionSettings> => {
    const { data: result } = await postRequest<HoirzontalDistributionSettings>({
      url: `/projects/${id}/horizontal-distribution-settings`,
      data,
    })

    return result as HoirzontalDistributionSettings
  },
}

import { setLocale } from 'yup'

setLocale({
  mixed: {
    required: 'Dies ist ein Pflichtfeld',
    notType: val => {
      const { type } = val
      if (type === 'number') {
        return 'Wert muss eine Zahl sein'
      }

      return 'Wert entspricht nicht dem richtigen Format'
    },
  },
  number: {
    moreThan: ({ more }) => `Wert muss größer als ${more} sein`,
    lessThan: ({ less }) => `Wert muss kleiner als ${less} sein`,
    min: ({ min }) => `Wert muss größer oder gleich ${min} sein`,
    max: ({ max }) => `Wert muss kleiner oder gleich ${max} sein`,
  },
})

import React, { ReactElement } from 'react'
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { toNumber } from 'lodash-es'
import { Stack, Grid } from '@mui/material'
import { AddButton } from '@ui/actions'
import { TextField } from '@ui/forms'
import { Box } from '@ui/structure'

interface Props {
  index: number
}

const AddIntervalFields = ({ index }: Props): ReactElement => {
  const { append } = useFieldArray({ name: `bundles[${index}].smoothing_settings.intervals` })

  const newInterval = useWatch({ name: 'new_interval' })

  const { setValue, trigger } = useFormContext()

  return (
    <Stack spacing={2} p={2} border={1} borderColor="grey.200" borderRadius={1}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField name="new_interval.lower" label="Start" type="number" />
        </Grid>
        <Grid item xs={6}>
          <TextField name="new_interval.upper" label="Ende" type="number" />
        </Grid>
      </Grid>

      <Box display="flex" justifyContent="flex-end">
        <AddButton
          size="small"
          onClick={async () => {
            const validInterval = await trigger('new_interval')

            if (!validInterval) return

            const { lower, upper } = newInterval

            append({
              lower: toNumber(lower),
              upper: toNumber(upper),
            })

            setValue('new_interval.lower', 0)
            setValue('new_interval.upper', 1)
          }}
        >
          Interval hinzufügen
        </AddButton>
      </Box>
    </Stack>
  )
}

export default AddIntervalFields

import React, { ReactElement, ReactNode } from 'react'
import { FieldErrorsImpl, useFormContext } from 'react-hook-form'
import isUndefined from 'lodash-es/isUndefined'
import { Stack } from '@mui/system'
import { ErrorFieldBase } from '..'

/**
 * Render all errors of a form component. Uses form context to get all errors of
 * the form
 */
const Errors = (): ReactElement => {
  const {
    formState: { errors },
  } = useFormContext()

  const renderErrors = (
    errors: Partial<
      FieldErrorsImpl<{
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [x: string]: any
      }>
    >,
  ): ReactNode[] =>
    Object.values(errors).map(error => {
      if (!isUndefined(error?.message)) {
        return (
          <ErrorFieldBase
            key={error?.message as string}
            message={error?.message as string}
            severity="error"
          />
        )
        // @ts-ignore
      } else return renderErrors(error)
    })

  return (
    <Stack direction="column" spacing={2}>
      {errors && renderErrors(errors)}
    </Stack>
  )
}

export default Errors

import { useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { usePostHog } from 'posthog-js/react'
import { useAppStore } from '@stores'

/**
 * This hook will write current session per project id to the local storage.
 * Once project IDs will be changed new session will be initialized, so sessions
 * in posthog will be recorded per project
 * @param projectId
 */
const usePosthogSession = (projectId: string | undefined) => {
  const posthog = usePostHog()
  const sessions = useAppStore(state => state.projectSessions)
  const addSession = useAppStore(state => state.addSession)
  const recordSession = useAppStore(state => state.recordSession)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (!recordSession) {
      enqueueSnackbar('Aufnahme der Sitzungen ist abgeschaltet', { variant: 'info' })
      return
    }

    if (!projectId || !posthog.sessionManager) return

    const projectSession = sessions[projectId]
    const activeSession = posthog.sessionManager.checkAndGetSessionAndWindowId().sessionId

    if (projectSession !== activeSession) {
      posthog.sessionManager?.resetSessionId()
      const newSession = posthog.sessionManager.checkAndGetSessionAndWindowId().sessionId
      addSession(projectId, newSession)
    }

    posthog.startSessionRecording(true)

    return () => {
      posthog.stopSessionRecording()
    }
  }, [sessions, projectId, recordSession, posthog])
}

export default usePosthogSession

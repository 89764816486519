import { object, string, number, boolean } from 'yup'

export const plateLayerSchema = object({
  producer: string().required().default(''),
  layer: object({
    kind: string().default('plate-layer'),
    double_penetration: boolean().default(true),
    plate: object({
      material: object({
        thickness: number().nullable().default(0),
      }),
    })
      .nullable()
      .default(null)
      .required(),
    fastener: object()
      .nullable()
      .default(null)
      .required()
      .test({
        name: 'validate fastener distance against thickness',
        message: 'Fastenerlänge muss größer als die Materialbreite sein',
        test: function (fastener: Fastener) {
          const plateMaterialThickness = this.parent?.plate?.material?.thickness

          if (isNaN(plateMaterialThickness) || !fastener) return true

          return fastener.length > plateMaterialThickness
        },
      }),
    fastener_distance: number().moreThan(0).default(0),
  }),
})

import { first, last, sortBy } from 'lodash-es'
import { Box3 } from 'three'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'

const box3fromPoints = (points: ImmutableVector3[]) => {
  const origin = new ImmutableVector3()
  const sorted = sortBy(points, p => p.distanceTo(origin))
  const min = first(sorted)
  const max = last(sorted)
  return new Box3(min?.v, max?.v)
}

export default box3fromPoints

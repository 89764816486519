import { object, string, mixed } from 'yup'

const addressDataSchema = object({
  street: string().default(''),
  city: string().default(''),
  zip_code: string().default(''),
})

export const baseProjectSchema = object({
  name: string().required().default('').min(5, 'Name muss mindestes 5 Zeichen haben'),
  description: string().default(''),
  owner_name: string().default(''),
  owner_surname: string().default(''),
  owner_address: string().default(''),
  responsible_engineer_name: string().default(''),
  processor_name: string().default(''),
  construction_company_name: string().default(''),
  address: addressDataSchema,
  hand_in_project_name: string()
    .default('')
    .test('maxLength', function (value) {
      const { path, createError } = this
      return (
        value.length <= 25 ||
        createError({
          path,
          message: `Maximal 25 Zeichen. ${value.length} Zeichen wurden eingegeben`,
        })
      )
    }),
})

export const createProjectSchema = baseProjectSchema.concat(
  object({
    model: mixed().required('Bitte wählen Sie eine JSON Datei für das Modell aus'),
  }),
)

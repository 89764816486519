import React, { ReactElement } from 'react'
import { UseMutationResult } from 'react-query'
import { Button } from '@mui/material'
import { SaveButton } from '@ui/actions'
import { DialogBase } from '@ui/feedback'
import ConnectionForm from '../ConnectionForm'

const FORM_ID = 'connection-create-form'

interface Props {
  open: boolean
  onClose: () => void
  mutation:
    | UseMutationResult<Connection, unknown, Connection, unknown>
    | UseMutationResult<ProjectConnection, unknown, ProjectConnection, unknown>
  connection: Connection
}

const CreateConnectionDialog = ({ open, onClose, mutation, connection }: Props): ReactElement => {
  const isDuplicate = !!connection.guid
  const title = isDuplicate ? `${connection?.name} duplizieren` : 'Neue Zugverbindung erstellen'

  const handleSubmit = async (data: Connection | ProjectConnection) => {
    await mutation.mutateAsync(data as Connection & ProjectConnection)
    onClose()
  }

  return (
    <DialogBase
      open={open}
      onClose={onClose}
      title={title}
      dialogProps={{
        fullWidth: true,
      }}
    >
      <>
        <ConnectionForm defaultValues={connection} id={FORM_ID} onSubmit={handleSubmit} />
      </>
      <>
        <Button onClick={onClose}>Abbrechen</Button>
        <SaveButton
          loading={mutation.isLoading}
          form={FORM_ID}
          type="submit"
          data-cy="create-connection-btn"
        >
          Speichern
        </SaveButton>
      </>
    </DialogBase>
  )
}

export default CreateConnectionDialog

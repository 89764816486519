import { postRequest } from 'src/state/utils'

export const saveTensileTransmissionGraph: RequestTypeVariable = {
  getKey: (projectId: string) => ['saveTensileTransmissionGraph', projectId],
  request: async (
    projectId: string,
    input: TensileTransmissionGraph,
  ): Promise<TensileTransmissionGraph> => {
    const { data } = await postRequest<TensileTransmissionGraph>({
      url: `/projects/${projectId}/tensile-transmission-graph`,
      data: input,
    })

    return data as TensileTransmissionGraph
  },
}

export const saveVerticalTransmissionGraph: RequestTypeVariable = {
  getKey: (projectId: string) => ['saveVerticalTransmissionGraph', projectId],
  request: async (
    projectId: string,
    input: VerticalTransmissionGraph,
  ): Promise<VerticalTransmissionGraph> => {
    const { data } = await postRequest<VerticalTransmissionGraph>({
      url: `/projects/${projectId}/vertical-transmission-graph`,
      data: input,
    })

    return data as VerticalTransmissionGraph
  },
}

export const recalculateTensileTargets: RequestTypeVariable = {
  getKey: (projectId: string, elementId: string) => [
    'recalculateTensileTargets',
    projectId,
    elementId,
  ],
  request: async (projectId: string, elementId: string): Promise<TensileTransmissionGraph> => {
    const { data } = await postRequest<TensileTransmissionGraph>({
      url: `/projects/${projectId}/tensile-transmission-graph/restore/${elementId}`,
    })

    return data as TensileTransmissionGraph
  },
}

export const recalculateVerticalTargets: RequestTypeVariable = {
  getKey: (projectId: string, elementId: string) => [
    'recalculateVerticalTargets',
    projectId,
    elementId,
  ],
  request: async (projectId: string, elementId: string): Promise<VerticalTransmissionGraph> => {
    const { data } = await postRequest<VerticalTransmissionGraph>({
      url: `/projects/${projectId}/vertical-transmission-graph/restore/${elementId}`,
    })

    return data as VerticalTransmissionGraph
  },
}

import React, { ReactElement } from 'react'
import { Typography } from '@mui/material'
import { Box } from '@ui/structure'
import { useElementTypes, useModelClickListeners, useTypeInteraction } from '@editorHooks'
import RoofFormBase from './components/RoofFormBase'
import SlabFormBase from './components/SlabFormBase'
import WallAlignmentForm from './components/WallAlignmentForm'

interface Props {
  selectedElement: string | null
  selectElement: (element: string) => void
}

const RoofForm = ({ selectedElement, selectElement }: Props): ReactElement => {
  const elementTypes = useElementTypes()

  useTypeInteraction(['roof_slabs'])

  useModelClickListeners(
    event => selectElement(event.object.name),
    [elementTypes],
    !selectedElement,
  )

  return (
    <>
      {!selectedElement ? (
        <Box component="div" p={1} border={1} borderColor="grey.200" borderRadius={1}>
          <Typography>Wählen Sie ein Dach an</Typography>
          <WallAlignmentForm />
        </Box>
      ) : (
        <RoofFormBase selectedElement={selectedElement} />
      )}
    </>
  )
}

interface SlabFormProps {
  selectedElement: string | null
  selectElement: (element: string) => void
  elementType: 'roof_slabs' | 'slabs'
}

const SlabForm = ({ selectedElement, selectElement, elementType }: SlabFormProps): ReactElement => {
  const elementTypes = useElementTypes()

  useTypeInteraction([elementType])

  useModelClickListeners(
    event => selectElement(event.object.name),
    [elementTypes],
    !selectedElement,
  )

  return (
    <>
      {!selectedElement ? (
        <Box component="div" p={1} border={1} borderColor="grey.200" borderRadius={1}>
          <Typography>Wählen Sie eine Decke an</Typography>
        </Box>
      ) : (
        <SlabFormBase selectedElement={selectedElement} />
      )}
    </>
  )
}

export { RoofForm, SlabForm }

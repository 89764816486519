import { useMemo } from 'react'
import { filter, find, max, toNumber } from 'lodash-es'
import { useModelStore } from '@editorStores'

const useElementsPerStorey = (
  model: PlanarModel,
  mainStorey?: string,
  otherStoreys: string[] = [],
) => {
  const rips = useModelStore(state => state.model.rips)

  const positionGuidToStorey = useMemo(() => {
    const positions = [...rips]
    return positions.reduce((collector, position) => {
      const wall = find(model.walls, { guid: position.wall_guid })
      if (!wall) return collector
      return { ...collector, [position.position_guid]: wall?.storey }
    }, {} as Record<string, string>)
  }, [rips, model])

  const maxStorey = useMemo(
    () => max(Object.keys(model.storey_boundaries).map(s => toNumber(s)))?.toString() as string,
    [model],
  )

  const elements = useMemo(
    () => ({
      slabs: filter(model.slabs, ['storey', mainStorey]),
      slabsInOtherStoreys: filter(
        model.slabs,
        slab => slab.storey !== mainStorey && otherStoreys.includes(slab.storey),
      ),
      beams: filter(model.beams, ['storey', mainStorey]),
      beamsInOtherStoreys: filter(
        model.beams,
        beam => beam.storey !== mainStorey && otherStoreys.includes(beam.storey),
      ),
      columns: filter(model.columns, ['storey', mainStorey]),
      columnsInOtherStoreys: filter(
        model.columns,
        column => column.storey !== mainStorey && otherStoreys.includes(column.storey),
      ),
      purlins: mainStorey === maxStorey ? model.purlins : [],
      purlinsInOtherStoreys: otherStoreys.includes(maxStorey) ? model.purlins : [],
      roofSlabs: mainStorey === maxStorey ? model.roof_slabs : [],
      roofSlabsInOtherStorey: otherStoreys.includes(maxStorey) ? model.roof_slabs : [],
      verticalSlabs: filter(model.vertical_slabs, ['storey', mainStorey]),
      verticalSlabsInOtherStoreys: filter(
        model.vertical_slabs,
        slab => slab.storey !== mainStorey && otherStoreys.includes(slab.storey),
      ),
      verticalRoofSlabs: mainStorey === maxStorey ? model.vertical_roof_slabs : [],
      verticalRoofSlabsInOtherStoreys: otherStoreys.includes(maxStorey)
        ? model.vertical_roof_slabs
        : [],
      rips: filter(rips, rip => mainStorey === positionGuidToStorey[rip.position_guid]),
      ripsInOtherStoreys: filter(rips, rip => {
        const storey = positionGuidToStorey[rip.position_guid]
        return storey !== mainStorey && otherStoreys.includes(storey)
      }),
    }),
    [model, mainStorey, otherStoreys, maxStorey, positionGuidToStorey],
  )

  return elements
}

export default useElementsPerStorey

const checkIfElementSupportsColumnLike = (
  elementGuid: string,
  graph: VerticalTransmissionGraph,
  guidToElementType: Record<string, ElementTypes | undefined>,
): boolean => {
  // Get all targets on the element
  const targetsOnElement = graph.element_targets.filter(
    (target: ElementSupportItem) => target.element_guid === elementGuid,
  )

  const columnLikeElementTypes = ['rips', 'columns']

  for (const target of targetsOnElement) {
    const supportToTarget = graph.support_targets.find(
      (supportTarget: ElementSupportTarget) => supportTarget.target_guid === target.guid,
    )
    const support = graph.element_supports.find(
      (elementSupport: ElementSupportItem) => elementSupport.guid === supportToTarget?.support_guid,
    )

    if (support && columnLikeElementTypes.includes(guidToElementType[support.element_guid] || '')) {
      return true
    }
  }

  return false
}

export default checkIfElementSupportsColumnLike

export const windDirectionToAxis: Record<
  WindDirection,
  {
    axis: 'x' | 'y'
    sign: 'positiv' | 'negativ'
  }
> = {
  Direction0: {
    axis: 'x',
    sign: 'positiv',
  },
  Direction90: {
    axis: 'y',
    sign: 'positiv',
  },
  Direction180: {
    axis: 'x',
    sign: 'negativ',
  },
  Direction270: {
    axis: 'y',
    sign: 'negativ',
  },
}

import { memo } from 'react'
import { useElementSelection } from '@editorHooks'

// utility component to avoid retriggering useEffect hooks in parent
// because watch of hook form always returns new value
const ElementSelection = (): null => {
  useElementSelection()
  return null
}

export default memo(ElementSelection)

import { sortPointsCounterClockwise } from '@editorUtils'
import { minBy, inRange } from 'lodash-es'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { rotateArray } from '@utils'

/**
 * The first two points need to define the lower edge (the points with lowest
 * z). Hence we need to rotate it here to achieve this
 * @param points
 * @returns
 */
export const prepareRoofShapeFromPoints = (points: ImmutableVector3[]) => {
  const sortedCCW = sortPointsCounterClockwise(points)

  const lowestZ = minBy(sortedCCW, p => p.z)?.z as number

  for (let i = 0; i <= 3; i++) {
    const rotatedPoints = rotateArray(sortedCCW, -i)
    const p1 = rotatedPoints[0]
    const p2 = rotatedPoints[1]
    if (
      inRange(p1.z, lowestZ - 0.01, lowestZ + 0.01) &&
      inRange(p2.z, lowestZ - 0.01, lowestZ + 0.01)
    ) {
      return rotatedPoints
    }
  }

  return sortedCCW
}

import { useEffect, useState } from 'react'
import { getModelBoundingBox } from '@editorUtils'
import { Vector3 } from 'three'
import { useCameraStore } from '@modugen/scene/lib/controllers/CameraController/cameraStore'
import { toImmutable } from '@modugen/scene/lib/utils'

/**
 * This hook will set the camera rotation target to the calculated center of
 * provided model exactly once. When the provided model changes this will not
 * trigger a recalculation of the model center and will not set the camera
 * rotation target
 */
const useCameraRotationAroundModel = (model: PlanarModel) => {
  const setCameraRotationTarget = useCameraStore(state => state.setRotationTarget)

  // in order to prevent jumping of the camera when adding elements (like
  // columns) in 3d camera rotation should only be applied once when the model
  // is loaded at the beginning
  const [cameraRotated, setCameraRotated] = useState(false)

  useEffect(() => {
    if (!cameraRotated) {
      const center = getModelBoundingBox(model).getCenter(new Vector3())

      setCameraRotationTarget(toImmutable(center))
      setCameraRotated(true)
    }
  }, [model])
}

export default useCameraRotationAroundModel

import { DependencyList, useEffect, useRef, useState } from 'react'
import { cloneDeep } from 'lodash-es'

const useElementHistory = <E extends { is_local?: boolean }>({
  element,
  getCurrentElement,
  removeElement,
  resetElement,
  dependencies,
}: {
  /**
   * when the provided element changes the element data will not be stale
   * anymore. This means the data is updated and will be reset when the
   * component unmounts. In order to make the new element stale you need to call
   * the returned `setStale` method
   */
  element: E
  /**
   * Callback to retrieve the current element. Important: react states (e.g.
   * from setState or zustand) should not be used within this callback. As they
   * will be outdated
   */
  getCurrentElement: () => E
  removeElement: (element: E) => void
  resetElement: (element: E) => void
  /**
   * Dependency list that decides when the check to reset the element data will
   * be performed
   */
  dependencies: DependencyList
}) => {
  const statusRef = useRef<'updated' | 'stale'>('stale')
  const originalElementRef = useRef(cloneDeep(getCurrentElement()))

  const [isStale, setIsStale] = useState(true)

  const setStale = (element: E) => {
    statusRef.current = 'stale'
    originalElementRef.current = cloneDeep(element)
  }

  const reset = () => {
    resetElement(originalElementRef.current)
    statusRef.current = 'stale'
    setIsStale(true)
  }

  useEffect(() => {
    statusRef.current = 'updated'
    setIsStale(false)
  }, [element])

  useEffect(() => {
    originalElementRef.current = cloneDeep(getCurrentElement())

    return () => {
      const currentElement = getCurrentElement()

      if (currentElement?.is_local) removeElement(currentElement)
      else if (statusRef.current !== 'stale' && originalElementRef.current) {
        reset()
      }
    }
  }, dependencies)

  return {
    setStale,
    reset,
    isStale,
  }
}

export default useElementHistory

import React, { Fragment, ReactElement } from 'react'
import { getCenterMultiple } from '@modugen/scene/lib/utils'
import { useUIStore } from '@stores'
import { useElementLabel } from '@editorHooks'
import Label from '../Label'

interface Props {
  data: {
    representativePosition?: ShapeObjectLine
    representativePositionFor: Array<ShapeObjectLine | undefined>
  }[]
}

const ModelPositionGroupingLabels = ({ data }: Props): ReactElement => {
  const getLabel = useElementLabel()

  const showHtmlSceneLabels = useUIStore(state => state.showHtmlSceneLabels)

  if (!showHtmlSceneLabels) return <></>

  return (
    <>
      {data.map(({ representativePosition, representativePositionFor }) => {
        if (!representativePosition) return null
        const {
          guid,
          shape: { start, end },
        } = representativePosition

        const representativeLabel = getLabel(guid)

        const representatives = representativePositionFor.map(representativeFor => {
          if (!representativeFor) return null

          const {
            guid: fGuid,
            shape: { start: fStart, end: fEnd },
          } = representativeFor
          const label = getLabel(fGuid)

          const center = getCenterMultiple([fStart, fEnd])

          return <Label key={fGuid} label={label} position={center.v} />
        })

        const center = getCenterMultiple([start, end])

        return (
          <Fragment key={representativePosition.guid}>
            <Label label={representativeLabel} position={center.v} highlighted />
            {representatives}
          </Fragment>
        )
      })}
    </>
  )
}

export default ModelPositionGroupingLabels

import { Vector2 } from 'three'

const get2DLineOrthoDirection = (start: Vector2, end: Vector2): Vector2 => {
  const direction = start.clone().sub(end).normalize()

  // rotate 90 degrees around origin
  const rotated = direction.rotateAround(new Vector2(), Math.PI / 2)

  return rotated
}

export default get2DLineOrthoDirection

import { useMemo } from 'react'

const usePrimaryWallDirection = (
  walls: ShapeObject[],
): Record<string, UnsignedPrimaryWallDirection> => {
  const wallGuidToDirection = useMemo(() => {
    return walls.reduce((collector, wall) => {
      const start = wall.shape.points[0]
      const end = wall.shape.points[1]
      const diff = end.sub(start)
      const direction = Math.abs(diff.x) > Math.abs(diff.y) ? 'X' : 'Y'
      collector[wall.guid] = direction
      return collector
    }, {} as Record<string, UnsignedPrimaryWallDirection>)
  }, [walls])

  return wallGuidToDirection
}

export default usePrimaryWallDirection

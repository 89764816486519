import React, { ReactElement } from 'react'
import numeral from 'numeral'
import { Delete, DragIndicator } from '@mui/icons-material'
import { IconButton, TableCell, TableRow, Typography } from '@mui/material'
import { DraggableProvided } from '@hello-pangea/dnd'
import { Box } from '@ui/structure'

interface Props {
  prio: number
  connection: Connection
  provided: DraggableProvided
  isDragging: boolean
  onDelete: () => void
}

const ListItem = ({ prio, connection, provided, isDragging, onDelete }: Props): ReactElement => {
  const { name, guid, export_comment, r_d_wind, r_d_earthquake } = connection

  return (
    <TableRow
      ref={provided.innerRef}
      {...provided.draggableProps}
      key={guid}
      sx={({ shadows }) => ({
        ...provided.draggableProps.style,
        width: '100%',
        display: isDragging ? 'table' : 'table-row',
        background: '#fff',
        boxShadow: isDragging ? shadows[1] : 'none',
      })}
    >
      <TableCell component="th" scope="row">
        <Box display="flex" alignItems="center">
          <Box mr={2} mt={0.5} {...provided.dragHandleProps}>
            <DragIndicator fontSize="small" />
          </Box>
          <Typography>{prio}</Typography>
        </Box>
      </TableCell>

      <TableCell>{name}</TableCell>
      <TableCell>{export_comment}</TableCell>
      <TableCell>{numeral(r_d_earthquake / 1000).format('0,0.00a')}</TableCell>
      <TableCell>{numeral(r_d_wind / 1000).format('0,0.00a')}</TableCell>
      <TableCell align="right">
        <Box display="flex" justifyContent="flex-end">
          <IconButton onClick={onDelete}>
            <Delete fontSize="small" />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  )
}

export default ListItem

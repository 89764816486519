import { getGluelamMaterials, getSoftWoodMaterials, getSteelMaterials } from '@queries'

export const materialQueryFromMaterialType = (materialType: string) => {
  switch (materialType) {
    case 'softwoodMaterial': {
      return getSoftWoodMaterials
    }
    case 'glulamMaterial': {
      return getGluelamMaterials
    }
    case 'steelMaterial': {
      return getSteelMaterials
    }
    default: {
      return getSoftWoodMaterials
    }
  }
}

export const materialTypeFromCS = (crossSection: CrossSection): MaterialType => {
  switch (crossSection.material.kind) {
    case 'Softwood': {
      return 'softwoodMaterial'
    }
    case 'Glulam': {
      return 'glulamMaterial'
    }
    case 'Steel': {
      return 'steelMaterial'
    }
    default: {
      return 'softwoodMaterial'
    }
  }
}

import { useEffect } from 'react'
import { useSceneStore } from '../stores/sceneStore'

const useColorMapping = (mapping: Record<string, string>) => {
  const setGuidToColorMapping = useSceneStore(state => state.setGuidToColorMapping)
  const clearGuidToColorMapping = useSceneStore(state => state.clearGuidToColorMapping)

  useEffect(() => {
    setGuidToColorMapping(mapping)

    return () => clearGuidToColorMapping()
  }, [mapping])
}

export default useColorMapping

export const tabConfig = {
  plans: {
    value: 'plans',
    label: 'Pläne',
  },
  loads: {
    value: 'loads',
    label: 'Lasten',
  },
  certificates: {
    value: 'certificates',
    label: 'Nachweise',
  },
  others: {
    value: 'others',
    label: 'Sonstige',
  },
}

import React, { ReactElement, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { get, some, isString } from 'lodash-es'
import { TabProps, Tab } from '@mui/material'

interface FormTabProps extends TabProps {
  fields: string[] | string
  'data-cy'?: string
}

const FormTab = ({ fields, sx = {}, ...tabProps }: FormTabProps): ReactElement => {
  const {
    formState: { errors, submitCount },
    watch,
  } = useFormContext()
  const isFieldString = isString(fields)
  const fieldWatch = watch(isFieldString ? [fields] : fields)

  const hasErrors = useMemo(() => {
    if (!submitCount) return false
    if (isFieldString) return !!get(errors, fields)

    return some(fields, fieldName => !!get(errors, fieldName))
  }, [errors, fields, fieldWatch, submitCount])

  return (
    <Tab
      {...tabProps}
      sx={{
        color: ({ palette }) => (hasErrors ? `${palette.error.main}` : ''),
        ...sx,
        '&.Mui-selected': {
          color: ({ palette }) => (hasErrors ? `${palette.error.main}` : ''),
        },
      }}
    />
  )
}

export default FormTab

import React, { ReactElement, ReactNode } from 'react'
import { Stack, SxProps } from '@mui/material'

interface Props {
  children: ReactNode
  sx?: SxProps
}

const Toolbar = ({ children, sx }: Props): ReactElement => {
  return (
    <Stack
      sx={{
        position: 'absolute',
        display: 'flex',
        left: '50%',
        transform: 'translateX(-50%)',
        top: ({ spacing }) => spacing(3),
        zIndex: 1,
        ...sx,
      }}
      direction="row"
      spacing={1}
    >
      {children}
    </Stack>
  )
}

export default Toolbar

import { toNumber } from 'lodash-es'
import { number, object, string, boolean } from 'yup'

export const purlinEqualnessFactor = 2

export const purlinSchema = object({
  guid: string(),
  xStart: number(),
  yStart: number(),
  xEnd: number(),
  yEnd: number(),
  isHorizontal: boolean().test({
    name: 'is-horizontal-purlin',
    message: 'Pfette muss horizontal im Raum liegen. Ist der z-Wert derselbe für Start und Ende?',
    test: function () {
      const { zStart, zEnd } = this.parent

      const zStartNum = toNumber(zStart)
      const zEndNum = toNumber(zEnd)

      return zStartNum.toFixed(purlinEqualnessFactor) === zEndNum.toFixed(purlinEqualnessFactor)
    },
  }),
})

export const rafterSchema = object({
  guid: string(),
  xStart: number(),
  yStart: number(),
  xEnd: number(),
  yEnd: number(),
})

import { filter, maxBy, reject } from 'lodash-es'

const addUtilizationToPositionBundle = (
  bundles: MemberPositionBundle[],
  positionChecks: CombinedPositionCheck[],
): MemberPositionBundleWithMaxUtilization[] => {
  return bundles.map(bundle => {
    const elementPositionChecks = filter(positionChecks, {
      element_guid: bundle.representative_position,
    })

    const elementChecksWithoutSupportCompression = reject(elementPositionChecks, check =>
      ['SupportCompression', 'SteelSupportCompression'].includes(check.check_type),
    )

    const elementChecksOnlySupportCompression = filter(elementPositionChecks, check =>
      ['SupportCompression', 'SteelSupportCompression'].includes(check.check_type),
    )

    return {
      ...bundle,
      maxUtilizationCheck: maxBy(elementPositionChecks, check => check.max_utilization),
      maxUtilOnlySupportCompression: maxBy(
        elementChecksOnlySupportCompression,
        check => check.max_utilization,
      ),
      maxUtilWithoutSupportCompression: maxBy(
        elementChecksWithoutSupportCompression,
        check => check.max_utilization,
      ),
    }
  })
}

export default addUtilizationToPositionBundle

import { useMemo } from 'react'
import { mapValueKey } from '@editorUtils'
import { useElementPositionType } from '@resultsHooks'
import { find, reduce } from 'lodash-es'
import { useModelStore, useResultsStore } from '@editorStores'
import { useGuidToElement } from '@editorHooks'

interface PositionGroupingLabel {
  representativePosition?: Position | SlabBeam
  representativePositionFor: Array<Position | SlabBeam | undefined>
}

interface ElementGroupingLabel {
  representativePosition?: ShapeObjectLine
  representativePositionFor: Array<ShapeObjectLine | undefined>
}

const useActiveBundleLabels = (selectedElement?: string) => {
  const rips = useModelStore(state => state.model.rips)
  const lintels = useModelStore(state => state.model.lintels)

  // groupings
  const wallRipsPositionGrouping = useResultsStore(state => state.wallRipsPositionGrouping)
  const wallLintelsPositionGrouping = useResultsStore(state => state.wallLintelsPositionGrouping)
  const slabBeamsPositionGrouping = useResultsStore(state => state.slabBeamsPositionGrouping)
  const columnsPositionGrouping = useResultsStore(state => state.columnsPositionGrouping)
  const purlinsPositionGrouping = useResultsStore(state => state.purlinsPositionGrouping)
  const roofSlabBeamsPositionGrouping = useResultsStore(
    state => state.roofSlabBeamsPositionGrouping,
  )
  const beamsPositionGrouping = useResultsStore(state => state.beamsPositionGrouping)

  const intermediateBundle = useResultsStore(state => state.intermediateBundle)

  const verticalSlabs = useModelStore(state => state.model.vertical_slabs)
  const verticalRoofSlabs = useModelStore(state => state.model.vertical_roof_slabs)

  const selectedPositionType = useElementPositionType(selectedElement || undefined)

  const guidToElement = useGuidToElement()
  const guidToPosition = useMemo(
    () => mapValueKey([...rips, ...lintels], 'position_guid'),
    [rips, lintels],
  )

  const slabBeamGuidToBeam = useMemo(
    () =>
      reduce(
        [...verticalSlabs, ...verticalRoofSlabs],
        (collector, item) => ({
          ...collector,
          [item.beam.guid]: item.beam,
        }),
        {} as Record<string, SlabBeam>,
      ),
    [verticalSlabs, verticalRoofSlabs],
  )

  const positionGoupingOfSelectedElement = useMemo(() => {
    const allGroupings = [
      ...(wallRipsPositionGrouping || []),
      ...(wallLintelsPositionGrouping || []),
      ...(slabBeamsPositionGrouping || []),
      ...(columnsPositionGrouping || []),
      ...(purlinsPositionGrouping || []),
      ...(roofSlabBeamsPositionGrouping || []),
      ...(beamsPositionGrouping || []),
    ]

    return find(allGroupings, bundle => bundle.representative_position === selectedElement)
  }, [
    wallRipsPositionGrouping,
    wallLintelsPositionGrouping,
    slabBeamsPositionGrouping,
    columnsPositionGrouping,
    purlinsPositionGrouping,
    roofSlabBeamsPositionGrouping,
    beamsPositionGrouping,
    selectedElement,
  ])

  const positionBundleLabels = useMemo<PositionGroupingLabel | undefined>(() => {
    const allowedPositionTypes: PositionGroupingType[] = [
      'roof-slab-beams',
      'slab-beams',
      'wall-rips',
      'wall-lintels',
    ]

    if (
      !selectedPositionType ||
      !selectedElement ||
      !allowedPositionTypes.includes(selectedPositionType)
    )
      return

    const representativesFor =
      (intermediateBundle || positionGoupingOfSelectedElement)?.representative_for || []

    return {
      representativePosition:
        guidToPosition[selectedElement] || slabBeamGuidToBeam[selectedElement],
      representativePositionFor: representativesFor.map(
        guid => guidToPosition[guid] || slabBeamGuidToBeam[guid],
      ),
    }
  }, [
    selectedPositionType,
    guidToPosition,
    positionGoupingOfSelectedElement,
    selectedElement,
    intermediateBundle,
  ])

  const elementBundleLabels = useMemo<ElementGroupingLabel | undefined>(() => {
    const allowedPositionTypes: PositionGroupingType[] = ['beams', 'columns', 'purlins']

    if (
      !selectedPositionType ||
      !selectedElement ||
      !allowedPositionTypes.includes(selectedPositionType)
    )
      return

    const representativesFor =
      (intermediateBundle || positionGoupingOfSelectedElement)?.representative_for || []

    return {
      representativePosition: guidToElement[selectedElement] as ShapeObjectLine,
      representativePositionFor: representativesFor.map(
        guid => guidToElement[guid],
      ) as ShapeObjectLine[],
    }
  }, [
    selectedPositionType,
    guidToPosition,
    positionGoupingOfSelectedElement,
    selectedElement,
    intermediateBundle,
  ])

  return {
    positionBundleLabels,
    elementBundleLabels,
  }
}

export default useActiveBundleLabels

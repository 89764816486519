import { cloneDeep, isUndefined } from 'lodash-es'
import create from 'zustand'
import { combine } from 'zustand/middleware'

interface UIStoreState {
  reducedLogo: boolean
  editorNavOpen: boolean
  portalRootLeft: HTMLDivElement | null
  portalRootCenter: HTMLDivElement | null
  portalRootRight: HTMLDivElement | null
  showHtmlSceneLabels: boolean
}

const initialState: UIStoreState = {
  reducedLogo: false,
  editorNavOpen: false,
  portalRootLeft: null,
  portalRootCenter: null,
  portalRootRight: null,
  showHtmlSceneLabels: true,
}

const useUIStore = create(
  combine(cloneDeep(initialState), set => ({
    toggleReducedLogo: (value?: boolean) =>
      set(state => ({ reducedLogo: !isUndefined(value) ? value : !state.reducedLogo })),
    toggleEditorNavOpen: (value?: boolean) =>
      set(state => ({ editorNavOpen: !isUndefined(value) ? value : !state.editorNavOpen })),
    setPortalRootLeft: (portalRootLeft: HTMLDivElement | null) => set({ portalRootLeft }),
    setPortalRootCenter: (portalRootCenter: HTMLDivElement | null) => set({ portalRootCenter }),
    setPortalRootRight: (portalRootRight: HTMLDivElement | null) => set({ portalRootRight }),
    setShowHtmlSceneLabels: (showHtmlSceneLabels: boolean) => set({ showHtmlSceneLabels }),
  })),
)

export default useUIStore

import { some, filter } from 'lodash-es'
import { object, string, array, number } from 'yup'
import { AssemblyTypeEnum } from 'src/constants/assemblies'

const vibrationSettingsSchema = object({
  requirement: string().required(),
  screed_data: object({ screed: string(), fill: string() }),
  screed_height: number().positive(),
  screed_e_modul: number().positive(),
})

export const assemblySchema = object({
  guid: string().default(''),
  name: string().required().default(''),
  usage_type: string().required(),
  short_name: string()
    .default('')
    .test(
      'required-short-name',
      'Short name is required for Inner or Outer usage types',
      function (value, context) {
        const usageType = context.parent.usage_type
        if ([AssemblyTypeEnum.Inner, AssemblyTypeEnum.Outer].includes(usageType)) {
          return value !== ''
        }
        return true
      },
    )
    .test('maxLength', function (value) {
      const { path, createError } = this
      return (
        value.length <= 25 ||
        createError({
          path,
          message: `Maximal 25 Zeichen. ${value.length} Zeichen wurden eingegeben`,
        })
      )
    }),
  description: string().default(''),
  kind: string().required().default('timber-frame-assembly'),
  vibration_check_settings: vibrationSettingsSchema.default(null).nullable(),
  layers: array()
    .min(1, 'Fügen Sie mindestens eine Schicht hinzu')
    .test({
      name: 'min-timber-frame-test',
      message: 'Holzaufbauten müssen mindestens eine Holzrahmenschicht haben',
      test: function (layers) {
        if (this.parent.kind !== 'timber-frame-assembly') return true
        return some(layers, ['kind', 'timber-frame-layer'])
      },
    })
    .test({
      name: 'min-plate-layer-test',
      message: 'Wandaufbauten müssen mindestens eine Plattenschicht haben',
      test: function (layers) {
        if (
          this.parent.kind !== 'timber-frame-assembly' ||
          this.parent.usage_type === 'Roof' ||
          this.parent.usage_type === 'Ceiling'
        )
          return true
        return some(layers, ['kind', 'timber-frame-layer'])
      },
    })
    .test({
      name: 'max-timber-frame-test',
      message: 'Holzaufbauten können maximal eine Holzrahmenschicht enthalten',
      test: function (layers) {
        if (this.parent.kind !== 'timber-frame-assembly') return true
        return filter(layers, ['kind', 'timber-frame-layer']).length <= 1
      },
    })
    .test({
      name: 'max-plate-layer-beside-timber-frame-test',
      message:
        'Nur eine statisch wirksame Platte pro Seite ist erlaubt. Nutze stattdessen eine Produktschicht.',
      test: function (layers = []) {
        let count = 0

        layers.every(layer => {
          if (count > 1) return false
          // reset count if left of timber frame passes condition
          if (layer.kind === 'timber-frame-layer') count = 0
          if (layer.kind === 'plate-layer') count++
          return true
        })

        return count <= 1
      },
    })
    .default([]),
})

import { useEffect } from 'react'
import { useModelStore } from '../stores/modelStore'

const useDomainVisibility = (domainGuids: string[] = []) => {
  const setVisibleDomains = useModelStore(state => state.setVisibleDomains)

  useEffect(() => {
    setVisibleDomains(domainGuids)

    return () => setVisibleDomains([])
  }, [domainGuids.join()])
}

export default useDomainVisibility

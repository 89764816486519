import { postRequest } from '../utils'

export const saveVerticalTransmissionTarget: RequestTypeVariable = {
  getKey: (projectId: string, hostElementId: string, targetElementId: string) => [
    'saveVerticalTransmissionTarget',
    projectId,
    hostElementId,
    targetElementId,
  ],
  request: async (
    projectId: string,
    hostElementId: string,
    targetElementId: string,
    input: VerticalTransmissionGraph,
  ): Promise<VerticalTransmissionGraph> => {
    const { data } = await postRequest<VerticalTransmissionGraph>({
      url: `/projects/${projectId}/vertical-transmission-graph/connect/${hostElementId}/${targetElementId}`,
      data: input,
    })

    return data as VerticalTransmissionGraph
  },
}

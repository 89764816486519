import { useEffect } from 'react'
import { useUIStore } from '@stores'

const useReducedNavigation = () => {
  const toggleReducedLogo = useUIStore(state => state.toggleReducedLogo)

  useEffect(() => {
    toggleReducedLogo(true)

    return () => toggleReducedLogo(false)
  }, [])
}

export default useReducedNavigation

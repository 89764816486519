import React, { ReactElement, useMemo } from 'react'
import convert from 'convert'
import { isNull, isUndefined } from 'lodash-es'
import numeral from 'numeral'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from '@mui/material'
import { nmToNmm2 } from './utils'

interface Props {
  check: CombinedPositionCheck
}

const AdditionalCheckInformation = ({ check }: Props): ReactElement | null => {
  const renderTableBody = (check: CombinedPositionCheck): ReactElement | null => {
    if (check.check_category_type === 'Tension')
      return (
        <>
          {!isNull(check.k_mod) && (
            <TableRow>
              <TableCell>
                k<sub>mod</sub>
              </TableCell>
              <TableCell>{numeral(check.k_mod).format('0,0.00a')}</TableCell>
            </TableRow>
          )}

          {!isNull(check.design_normal_force) && (
            <TableRow>
              <TableCell>
                N<sub>d</sub>
              </TableCell>
              <TableCell>
                {numeral(convert(check.design_normal_force, 'newton').to('kilonewton')).format(
                  '0,0.00a',
                )}
                kN
              </TableCell>
            </TableRow>
          )}

          {!isNull(check.sigma_t0d) && (
            <TableRow>
              <TableCell>
                σ<sub>t,0,d</sub>
              </TableCell>
              <TableCell>
                {numeral(nmToNmm2(check.sigma_t0d)).format('0,0.00a')} N/mm
                <sup>2</sup>
              </TableCell>
            </TableRow>
          )}

          {!isNull(check.f_t0d) && (
            <TableRow>
              <TableCell>
                f<sub>t,0,d</sub>
              </TableCell>
              <TableCell>
                {numeral(nmToNmm2(check.f_t0d)).format('0,0.00a')} N/mm
                <sup>2</sup>
              </TableCell>
            </TableRow>
          )}
        </>
      )

    if (check.check_category_type === 'Compression')
      return (
        <>
          {!isNull(check.k_mod) && (
            <TableRow>
              <TableCell>
                k<sub>mod</sub>
              </TableCell>
              <TableCell>{numeral(check.k_mod).format('0,0.00a')}</TableCell>
            </TableRow>
          )}

          {!isNull(check.l_ef) && (
            <TableRow>
              <TableCell>
                l<sub>ef</sub>
              </TableCell>
              <TableCell>{numeral(check.l_ef).format('0,0.00a')} m</TableCell>
            </TableRow>
          )}

          {!isNull(check.k_c) && (
            <TableRow>
              <TableCell>
                k<sub>c,y/z</sub>
              </TableCell>
              <TableCell>{numeral(check.k_c).format('0,0.00a')}</TableCell>
            </TableRow>
          )}

          {!isNull(check.design_normal_force) && (
            <TableRow>
              <TableCell>
                N<sub>d</sub>
              </TableCell>
              <TableCell>
                {numeral(convert(check.design_normal_force, 'newton').to('kilonewton')).format(
                  '0,0.00a',
                )}{' '}
                kN
              </TableCell>
            </TableRow>
          )}

          {!isNull(check.sigma_c0d) && (
            <TableRow>
              <TableCell>
                σ<sub>c,0,d</sub>
              </TableCell>
              <TableCell>
                {numeral(nmToNmm2(check.sigma_c0d)).format('0,0.00a')} N/mm
                <sup>2</sup>
              </TableCell>
            </TableRow>
          )}

          {!isNull(check.f_c0d) && (
            <TableRow>
              <TableCell>
                f<sub>c,0,d</sub>
              </TableCell>
              <TableCell>
                {numeral(nmToNmm2(check.f_c0d)).format('0,0.00a')} N/mm<sup>2</sup>
              </TableCell>
            </TableRow>
          )}
        </>
      )

    if (check.check_category_type === 'Bending')
      return (
        <>
          {!isNull(check.k_mod) && (
            <TableRow>
              <TableCell>
                k<sub>mod</sub>
              </TableCell>

              <TableCell>{numeral(check.k_mod).format('0,0.00a')}</TableCell>
            </TableRow>
          )}

          {!isNull(check.l_ef) && (
            <TableRow>
              <TableCell>
                l<sub>ef</sub>
              </TableCell>
              <TableCell>{numeral(check.l_ef).format('0,0.00a')} m</TableCell>
            </TableRow>
          )}

          {!isNull(check.k_c) && (
            <TableRow>
              <TableCell>
                k<sub>c,y/z</sub>
              </TableCell>
              <TableCell>{numeral(check.k_c).format('0,0.00a')}</TableCell>
            </TableRow>
          )}

          {!isNull(check.k_crit) && (
            <TableRow>
              <TableCell>
                k<sub>crit</sub>
              </TableCell>
              <TableCell>{numeral(check.k_crit).format('0,0.00a')}</TableCell>
            </TableRow>
          )}

          {!isNull(check.design_normal_force) && (
            <TableRow>
              <TableCell>
                N<sub>d</sub>
              </TableCell>
              <TableCell>
                {numeral(convert(check.design_normal_force, 'newton').to('kilonewton')).format(
                  '0,0.00a',
                )}{' '}
                kN
              </TableCell>
            </TableRow>
          )}

          {!isNull(check.design_bending_moment_y) && (
            <TableRow>
              <TableCell>
                M<sub>y,d</sub>
              </TableCell>
              <TableCell>
                {numeral(convert(check.design_bending_moment_y, 'newton').to('kilonewton')).format(
                  '0,0.00a',
                )}{' '}
                kNm
              </TableCell>
            </TableRow>
          )}

          {!isNull(check.design_bending_moment_z) && (
            <TableRow>
              <TableCell>
                M<sub>z,d</sub>
              </TableCell>
              <TableCell>
                {numeral(convert(check.design_bending_moment_z, 'newton').to('kilonewton')).format(
                  '0,0.00a',
                )}{' '}
                kNm
              </TableCell>
            </TableRow>
          )}

          {!isNull(check.sigma_c0d) && (
            <TableRow>
              <TableCell>
                σ<sub>c,0,d</sub>
              </TableCell>
              <TableCell>
                {numeral(nmToNmm2(check.sigma_c0d)).format('0,0.00a')} N/mm
                <sup>2</sup>
              </TableCell>
            </TableRow>
          )}

          {!isNull(check.sigma_myd) && (
            <TableRow>
              <TableCell>
                σ<sub>m,y,d</sub>
              </TableCell>
              <TableCell>
                {numeral(nmToNmm2(check.sigma_myd)).format('0,0.00a')} N/mm
                <sup>2</sup>
              </TableCell>
            </TableRow>
          )}

          {!isNull(check.sigma_mzd) && (
            <TableRow>
              <TableCell>
                σ<sub>m,z,d</sub>
              </TableCell>
              <TableCell>
                {numeral(nmToNmm2(check.sigma_mzd)).format('0,0.00a')} N/mm
                <sup>2</sup>
              </TableCell>
            </TableRow>
          )}

          {!isNull(check.f_c0d) && (
            <TableRow>
              <TableCell>
                f<sub>c,0,d</sub>
              </TableCell>
              <TableCell>
                {numeral(nmToNmm2(check.f_c0d)).format('0,0.00a')} N/mm<sup>2</sup>
              </TableCell>
            </TableRow>
          )}

          {!isNull(check.f_md) && (
            <TableRow>
              <TableCell>
                f<sub>m,d</sub>
              </TableCell>
              <TableCell>
                {numeral(nmToNmm2(check.f_md)).format('0,0.00a')} N/mm<sup>2</sup>
              </TableCell>
            </TableRow>
          )}
        </>
      )

    if (check.check_category_type === 'Shear')
      return (
        <>
          {!isNull(check.k_mod) && (
            <TableRow>
              <TableCell>
                k<sub>mod</sub>
              </TableCell>
              <TableCell>{numeral(check.k_mod).format('0,0.00a')}</TableCell>
            </TableRow>
          )}

          {!isNull(check.k_cr) && (
            <TableRow>
              <TableCell>
                k<sub>c,r</sub>
              </TableCell>
              <TableCell>{numeral(check.k_cr).format('0,0.00a')}</TableCell>
            </TableRow>
          )}

          {!isNull(check.design_shear_force_z) && (
            <TableRow>
              <TableCell>
                V<sub>z,d</sub>
              </TableCell>
              <TableCell>
                {numeral(convert(check.design_shear_force_z, 'newton').to('kilonewton')).format(
                  '0,0.00a',
                )}{' '}
                kN
              </TableCell>
            </TableRow>
          )}

          {!isNull(check.design_shear_force_y) && (
            <TableRow>
              <TableCell>
                V<sub>y,d</sub>
              </TableCell>
              <TableCell>
                {numeral(convert(check.design_shear_force_y, 'newton').to('kilonewton')).format(
                  '0,0.00a',
                )}{' '}
                kN
              </TableCell>
            </TableRow>
          )}

          {!isNull(check.tau_zd) && (
            <TableRow>
              <TableCell>
                T<sub>z,d</sub>
              </TableCell>
              <TableCell>
                {numeral(nmToNmm2(check.tau_zd)).format('0,0.00a')} N/mm<sup>2</sup>
              </TableCell>
            </TableRow>
          )}

          {!isNull(check.tau_yd) && (
            <TableRow>
              <TableCell>
                T<sub>y,d</sub>
              </TableCell>
              <TableCell>
                {numeral(nmToNmm2(check.tau_yd)).format('0,0.00a')} N/mm<sup>2</sup>
              </TableCell>
            </TableRow>
          )}

          {!isNull(check.f_vd) && (
            <TableRow>
              <TableCell>
                f<sub>v,d</sub>
              </TableCell>
              <TableCell>
                {numeral(nmToNmm2(check.f_vd)).format('0,0.00a')} N/mm<sup>2</sup>
              </TableCell>
            </TableRow>
          )}
        </>
      )

    if (check.check_category_type === 'SupportCompression')
      return (
        <>
          {!isNull(check.k_mod) && (
            <TableRow>
              <TableCell>
                k<sub>mod</sub>
              </TableCell>
              <TableCell>{numeral(check.k_mod).format('0,0.00a')}</TableCell>
            </TableRow>
          )}

          <TableRow>
            <TableCell>Lager</TableCell>
            <TableCell>{check.support_name}</TableCell>
          </TableRow>

          {!isNull(check.k_c90) && (
            <TableRow>
              <TableCell>
                k<sub>c,90</sub>
              </TableCell>
              <TableCell>{numeral(check.k_c90).format('0,0.00a')}</TableCell>
            </TableRow>
          )}

          {!isNull(check.design_support_force) && (
            <TableRow>
              <TableCell>
                F<sub>d</sub>
              </TableCell>
              <TableCell>
                {numeral(convert(check.design_support_force, 'newton').to('kilonewton')).format(
                  '0,0.00a',
                )}{' '}
                kN
              </TableCell>
            </TableRow>
          )}

          {!isNull(check.A_eff) && (
            <TableRow>
              <TableCell>
                A<sub>eff</sub>
              </TableCell>
              <TableCell>
                {numeral(convert(check.A_eff, 'square meter').to('square centimeter')).format(
                  '0,0.00a',
                )}{' '}
                cm<sup>2</sup>
              </TableCell>
            </TableRow>
          )}

          {!isNull(check.sigma_c90d) && (
            <TableRow>
              <TableCell>
                σ<sub>c,90,d</sub>
              </TableCell>
              <TableCell>
                {numeral(nmToNmm2(check.sigma_c90d)).format('0,0.00a')} N/mm
                <sup>2</sup>
              </TableCell>
            </TableRow>
          )}

          {!isNull(check.f_c90d) && (
            <TableRow>
              <TableCell>
                f<sub>c,90,d</sub>
              </TableCell>
              <TableCell>
                {numeral(nmToNmm2(check.f_c90d)).format('0,0.00a')} N/mm
                <sup>2</sup>
              </TableCell>
            </TableRow>
          )}
        </>
      )

    if (check.check_category_type === 'FireProtection')
      return (
        <>
          {!isNull(check.k_mod) && (
            <TableRow>
              <TableCell>
                k<sub>mod</sub>
              </TableCell>
              <TableCell>{numeral(check.k_mod).format('0,0.00a')}</TableCell>
            </TableRow>
          )}

          {!isNull(check.design_normal_force) && (
            <TableRow>
              <TableCell>
                N<sub>d</sub>
              </TableCell>
              <TableCell>
                {numeral(convert(check.design_normal_force, 'newton').to('kilonewton')).format(
                  '0,0.00a',
                )}{' '}
                kN
              </TableCell>
            </TableRow>
          )}

          {!isNull(check.sigma_c0d) && (
            <TableRow>
              <TableCell>
                σ<sub>c,0,d</sub>
              </TableCell>
              <TableCell>
                {numeral(nmToNmm2(check.sigma_c0d)).format('0,0.00a')} N/mm
                <sup>2</sup>
              </TableCell>
            </TableRow>
          )}

          {!isNull(check.sigma_max) && (
            <TableRow>
              <TableCell>
                σ<sub>max</sub>
              </TableCell>
              <TableCell>
                {numeral(nmToNmm2(check.sigma_max)).format('0,0.00a')} N/mm
                <sup>2</sup>
              </TableCell>
            </TableRow>
          )}
        </>
      )

    if (check.check_category_type === 'Displacement')
      return (
        <>
          {!isNull(check.l) && (
            <TableRow>
              <TableCell>l</TableCell>
              <TableCell>{numeral(check.l).format('0,0.00a')} m</TableCell>
            </TableRow>
          )}

          {!isNull(check.type) && (
            <TableRow>
              <TableCell>Art</TableCell>
              <TableCell>{check.type}</TableCell>
            </TableRow>
          )}

          {!isNull(check.k_def) && (
            <TableRow>
              <TableCell>
                k<sub>def</sub>
              </TableCell>
              <TableCell>{numeral(check.k_def).format('0,0.00a')}</TableCell>
            </TableRow>
          )}

          {!isNull(check.w) && (
            <TableRow>
              <TableCell>
                w<sub>vorh</sub>
              </TableCell>
              <TableCell>{numeral(convert(check.w, 'm').to('mm')).format('0,0.00a')} mm</TableCell>
            </TableRow>
          )}

          {!isNull(check.w_max) && (
            <TableRow>
              <TableCell>
                w<sub>zul</sub>
              </TableCell>
              <TableCell>
                {numeral(convert(check.w_max, 'm').to('mm')).format('0,0.00a')} mm
              </TableCell>
            </TableRow>
          )}

          {!isNull(check.max_utilization) && (
            <TableRow>
              <TableCell>η</TableCell>
              <TableCell>{numeral(check.max_utilization).format('0,0.00a')}</TableCell>
            </TableRow>
          )}
        </>
      )

    if (check.check_category_type === 'SteelDisplacement')
      return (
        <>
          <TableRow>
            <TableCell>l</TableCell>
            <TableCell>{numeral(check.l).format('0,0.00a')} m</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>w</TableCell>
            <TableCell>{numeral(convert(check.w, 'm').to('mm')).format('0,0.00a')} mm</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>
              w<sub>max</sub>
            </TableCell>
            <TableCell>
              {numeral(convert(check.w_max, 'm').to('mm')).format('0,0.00a')} mm
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell>η</TableCell>
            <TableCell>{numeral(check.max_utilization).format('0,0.00a')}</TableCell>
          </TableRow>
        </>
      )

    if (check.check_category_type === 'SteelBasicCheck')
      return (
        <>
          <TableRow>
            <TableCell>Kombiniert Erlaubt?</TableCell>
            <TableCell>{check.basic_check_allowed ? 'Ja' : 'Nein'}</TableCell>
          </TableRow>

          {!isUndefined(check.design_normal_force) && (
            <TableRow>
              <TableCell>
                N<sub>Ed</sub>
              </TableCell>
              <TableCell>
                {numeral(convert(check.design_normal_force, 'N').to('kN')).format('0,0.00a')} kN
              </TableCell>
            </TableRow>
          )}

          <TableRow>
            <TableCell>
              V<sub>z,Ed</sub>
            </TableCell>
            <TableCell>
              {numeral(convert(check.design_shear_force_z, 'N').to('kN')).format('0,0.00a')} kN
            </TableCell>
          </TableRow>

          {!isUndefined(check.design_bending_moment_y) && (
            <TableRow>
              <TableCell>
                M<sub>y,Ed</sub>
              </TableCell>
              <TableCell>
                {numeral(convert(check.design_bending_moment_y, 'N').to('kN')).format('0,0.00a')}{' '}
                kNm
              </TableCell>
            </TableRow>
          )}

          {!isUndefined(check.design_normal_force_resistance) && (
            <TableRow>
              <TableCell>
                N<sub>pl,Rd</sub>
              </TableCell>
              <TableCell>
                {numeral(convert(check.design_normal_force_resistance, 'N').to('kN')).format(
                  '0,0.00a',
                )}{' '}
                kN
              </TableCell>
            </TableRow>
          )}

          <TableRow>
            <TableCell>
              V<sub>z,pl,Rd</sub>
            </TableCell>
            <TableCell>
              {numeral(convert(check.design_shear_resistance_z, 'N').to('kN')).format('0,0.00a')} kN
            </TableCell>
          </TableRow>

          {!isUndefined(check.design_bending_resistance_y) && (
            <TableRow>
              <TableCell>
                M<sub>y,pl,Rd</sub>
              </TableCell>
              <TableCell>
                {numeral(convert(check.design_bending_resistance_y, 'N').to('kN')).format(
                  '0,0.00a',
                )}{' '}
                kNm
              </TableCell>
            </TableRow>
          )}
        </>
      )

    if (check.check_category_type === 'SteelCompression')
      return (
        <>
          <TableRow>
            <TableCell>
              N<sub>Ed</sub>
            </TableCell>
            <TableCell>
              {numeral(convert(check.design_normal_force, 'N').to('kN')).format('0,0.00a')} kN
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              N<sub>pl,Rd</sub>
            </TableCell>
            <TableCell>
              {numeral(convert(check.design_normal_force_resistance, 'N').to('kN')).format(
                '0,0.00a',
              )}{' '}
              kN
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              l<sub>cr</sub>
            </TableCell>
            <TableCell>{numeral(check.l_cr).format('0,0.00a')} m</TableCell>
          </TableRow>

          <TableRow>
            <TableCell>Χ</TableCell>
            <TableCell>{numeral(check.chi).format('0,0.00a')}</TableCell>
          </TableRow>
        </>
      )

    if (check.check_category_type === 'SteelSupportCompression')
      return (
        <>
          <TableRow>
            <TableCell>
              N<sub>Ed</sub>
            </TableCell>
            <TableCell>
              {numeral(convert(check.design_support_force, 'N').to('kN')).format('0,0.00a')} kN
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              f<sub>cd</sub>
            </TableCell>
            <TableCell>
              {numeral(nmToNmm2(check.design_support_material_strength)).format('0,0.00a')} N/mm
              <sup>2</sup>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Fußplatte</TableCell>
            <TableCell>
              {numeral(convert(check.plate_length, 'm').to('mm')).format('0,0a')} mm x{' '}
              {numeral(convert(check.plate_width, 'm').to('mm')).format('0,0a')} mm x{' '}
              {numeral(convert(check.plate_thickness, 'm').to('mm')).format('0,0a')} mm
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>c</TableCell>
            <TableCell>{numeral(convert(check.c, 'm').to('mm')).format('0,0.00a')} mm</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              A<sub>eff</sub>
            </TableCell>
            <TableCell>
              {numeral(convert(check.A_eff, 'square meter').to('square centimeter')).format(
                '0,0.00a',
              )}{' '}
              cm<sup>2</sup>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              N<sub>Rd</sub>
            </TableCell>
            <TableCell>
              {numeral(convert(check.design_normal_force_resistance, 'N').to('kN')).format(
                '0,0.00a',
              )}{' '}
              kN
            </TableCell>
          </TableRow>
        </>
      )

    if (check.check_category_type === 'SteelBasePlateWeld')
      return (
        <>
          <TableRow>
            <TableCell>
              N<sub>Ed</sub>
            </TableCell>
            <TableCell>
              {numeral(convert(check.design_support_force, 'N').to('kN')).format('0,0.00a')} kN
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              l<sub>w</sub>
            </TableCell>
            <TableCell>
              {numeral(convert(check.weld_length, 'm').to('mm')).format('0,0.00a')} mm
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              a<sub>w</sub>
            </TableCell>
            <TableCell>
              {numeral(convert(check.weld_thickness, 'm').to('mm')).format('0,0.00a')} mm
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              σ<sub>Ed</sub>
            </TableCell>
            <TableCell>
              {numeral(nmToNmm2(check.design_sigma_resulting)).format('0,0.00a')} N/mm
              <sup>2</sup>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              β<sub>w</sub>
            </TableCell>
            <TableCell>{numeral(convert(check.beta_w, 'm').to('m')).format('0,0.00a')}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              σ<sub>Rd</sub>
            </TableCell>
            <TableCell>
              {numeral(nmToNmm2(check.design_resistance_sigma_resulting)).format('0,0.00a')} N/mm
              <sup>2</sup>
            </TableCell>
          </TableRow>
        </>
      )

    return null
  }

  const tableBody = useMemo(() => renderTableBody(check), [check])

  if (isNull(tableBody)) return null

  return (
    <TableContainer>
      <Table
        stickyHeader
        size="small"
        sx={{
          [`& .${tableCellClasses.root}`]: {
            borderBottom: 'none',
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>Berechnung</TableCell>

            <TableCell>Wert</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>{tableBody}</TableBody>
      </Table>
    </TableContainer>
  )
}

export default AdditionalCheckInformation

import { object, boolean } from 'yup'
import { elementTypeToRequiredSupport } from '../../utils/getProblematicElements'

/* eslint-disable no-console */
export const verticalTransmitterSchema = object({
  isExactVerticalTransmitters: boolean().test({
    name: 'is-exact-transmitters',
    test: function () {
      const { verticalTransmitters } = this.parent
      const { selectedElementType } = this.options.context || {}

      const elementType: ElementTypes = selectedElementType
      console.log('Element type: %s', elementType)
      if (!(elementType && elementType in elementTypeToRequiredSupport)) {
        console.log('Element does not need validation')
        return true
      }
      const requiredSupports = elementTypeToRequiredSupport[elementType]

      console.log('Got %s supports, requires %s', verticalTransmitters.length, requiredSupports)
      if (requiredSupports && verticalTransmitters.length !== requiredSupports) {
        console.log('Wrong number of supports!')
        return this.createError({
          message: `Es müssen ${requiredSupports} Auflager definiert sein`,
        })
      }

      return true
    },
  }),
})
/* eslint-enable no-console */

import { array, number, object, string } from 'yup'

const dimensions = object({
  x: number().required().moreThan(0).default(0),
  y: number().required().moreThan(0).default(0),
})

export const seismicModelConfig = object({
  building_dimensions: array(dimensions).default([]),
  q_value: number().required().positive().default(1.5),
  ground_type_combination: string()
    .oneOf(['A-R', 'B-R', 'C-R', 'B-T', 'C-T', 'C-S', 'A', 'B', 'C', 'D', 'E'])
    .default('A-R'),
  seismic_zone: string()
    .oneOf(['Zone 0', 'Zone 1', 'Zone 2', 'Zone 3', 'Z_1_A', 'Z_1_B', 'Z_2', 'Z_3_A', 'Z_3_B'])
    .default('Zone 1'),
  additional_slab_live_load: number().min(0).default(0),
  additional_roof_snow_load: number().min(0).default(0),
  additional_separating_wall_load: number().min(0).default(0),
  phi_values: array(number().min(0)).default([]),
  psi_2_snow: number().min(0).default(0),
  overstrength_factor: number().min(0).default(0),
  standard_used: string().oneOf(['DIN_4109', 'SIA_261']).default('DIN_4109'),
})

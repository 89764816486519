import CLTLayerForm from '../CLTLayerForm'
import PlateLayerForm from '../PlateLayerForm'
import ProductLayerForm from '../ProductLayerForm'
import TimberFrameLayerForm from '../TimberFrameLayerForm'

export const layerForms: {
  [key: string]: React.FC<LayerFormProps>
} = {
  'product-layer': ProductLayerForm,
  'timber-frame-layer': TimberFrameLayerForm,
  'plate-layer': PlateLayerForm,
  'clt-assembly-layer': CLTLayerForm,
}

import { postRequest } from '../utils'

export const savePositionGrouping: RequestTypeVariable = {
  getKey: (projectId: string, type: PositionGroupingType) => [
    'savePositionGrouping',
    projectId,
    type,
  ],
  request: async (
    id: string,
    type: PositionGroupingType,
    data: MemberPositionBundle[],
  ): Promise<MemberPositionBundle[]> => {
    const { data: result } = await postRequest<MemberPositionBundle[]>({
      url: `/projects/${id}/position-grouping/${type}`,
      data: data,
    })

    return result as MemberPositionBundle[]
  },
}

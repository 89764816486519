import React, { ReactElement } from 'react'
import { Straighten, Preview, DeleteForever } from '@mui/icons-material/'
import { Stack } from '@mui/material'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'
import { ToggleButtonGroup, ToggleButton } from '@ui/actions'

// import { useModelStore } from '../../../stores/modelStore'

// TODO: consider integrating an event bus that allows components to react to
// states without importing their stores / other dependencies
const TapelineControls = (): ReactElement => {
  // no state picking as we use almost all store methods
  const tapelineStore = useTapelineStore()

  // set to xy mode as soon as a storey was selected
  // const activeStorey = useModelStore(state => state.activeStorey)
  // useEffect(() => tapelineStore.setXyOnly(true), [])

  return (
    <Stack direction="row" spacing={1}>
      {/* we need to disable this button while marquee selection
      is active as this will also lock the camera  */}
      <ToggleButtonGroup size="small">
        <ToggleButton
          hint="Maßband zeichnen"
          value="active"
          size="small"
          onClick={() => {
            tapelineStore.setIsActive(!tapelineStore.isActive)
            tapelineStore.isVisible && tapelineStore.setIsVisible(false)
          }}
          selected={tapelineStore.isActive}
          Icon={Straighten}
        />

        <ToggleButton
          hint="Maßband anzeigen"
          value="visible"
          size="small"
          onClick={() => {
            tapelineStore.setIsVisible(!tapelineStore.isVisible)
            tapelineStore.isActive && tapelineStore.setIsActive(false)
          }}
          selected={tapelineStore.isVisible}
          Icon={Preview}
        />
      </ToggleButtonGroup>

      {tapelineStore.isActive && (
        <ToggleButton
          hint="Alle Maßbänder entfernen"
          value="delete"
          size="small"
          onClick={() => tapelineStore.removeAllLines()}
          Icon={DeleteForever}
        />
      )}
    </Stack>
  )
}

export default TapelineControls

import React, { ReactElement } from 'react'
import { useTheme } from '@mui/material'
import ImmutableVector3 from '@modugen/scene/lib/utils/ImmutableVector3'
import { ThreeEvent } from '@react-three/fiber'
import SimpleShape from '../SimpleShape'

interface Props {
  points: ImmutableVector3[]
  thickness?: number
  onClick?: (event: ThreeEvent<MouseEvent>) => void
  highlighted?: boolean
}

const SimpleStiffeningMeshElement = ({
  points,
  thickness,
  onClick,
  highlighted = false,
}: Props): ReactElement => {
  const { scenePalette } = useTheme()

  return (
    <SimpleShape
      points={points}
      color={highlighted ? scenePalette.selection : scenePalette.elements3d.stiffening}
      thickness={thickness}
      meshMaterialProps={{ opacity: 0.4, transparent: true }}
      outlines
      outlinesColor={scenePalette.outlines}
      onClick={onClick}
      cursor={onClick && 'pointer'}
    />
  )
}

export default SimpleStiffeningMeshElement

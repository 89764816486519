import React, { ReactElement } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import SupportTableRow from './components/SupportTableRow'

interface Props {
  designForces: DesignSupportForce[]
}

const SupportResultsTable = ({ designForces }: Props): ReactElement => {
  return (
    <>
      <TableContainer>
        <Table stickyHeader size="small">
          <colgroup>
            <col style={{ width: '5%' }} />
            <col style={{ width: '35%' }} />
            <col style={{ width: '25%' }} />
            <col style={{ width: '25%' }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <Typography>Auflager</Typography>
              </TableCell>

              <TableCell>
                <Typography>Design Auflagerkraft</Typography>
              </TableCell>

              <TableCell>
                <Typography>Relative Position</Typography>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {designForces.length === 0 ? (
              <TableRow>
                <TableCell colSpan={6}>
                  <Typography align="center" m={1}>
                    Keine Auflagerkräfte auf dem Element vorhanden
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              designForces.map((supportForce, i) => (
                <SupportTableRow key={i} supportForce={supportForce} />
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

export default SupportResultsTable

import React, { ReactElement, useCallback, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { find, cloneDeep } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { v4 as uuid } from 'uuid'
import { useModelStore, useElementLoadStore } from '@editorStores'
import { useElementType } from '@editorHooks'
import { useStructuralPlanningQueryParams } from '../../../hooks'
import { createLoad } from '../utils'

interface Methods {
  onAdd: () => void
  onRemove: (guid: string) => void
  onCopy: (guid: string) => void
  loads: ElementLoad[]
}

interface Props {
  children: ({ onAdd, onRemove, onCopy, loads }: Methods) => ReactElement
}

const LoadFieldArray = ({ children }: Props) => {
  const { enqueueSnackbar } = useSnackbar()

  const {
    params: { loadType, selectedElements },
    actions: { selectLoad: setLoad },
  } = useStructuralPlanningQueryParams()

  const { watch } = useFormContext()

  const addLoad = useElementLoadStore(state => state.addLoadPerElement)
  const removeLoad = useElementLoadStore(state => state.removeLoad)
  const domains = useModelStore(state => state.domains)

  const rips = useModelStore(state => state.model.rips)
  const lintels = useModelStore(state => state.model.lintels)

  const allPositions = useMemo(() => [...rips, ...lintels], [rips, lintels])

  const elementType = useElementType(selectedElements?.[0])

  const loads = watch(loadType as string) as ElementLoad[]

  const pointLoadElements: ElementTypes[] = [
    'beams',
    'purlins',
    'columns',
    'inner_walls',
    'outer_walls',
    'rips',
    'lintels',
  ]
  const lineLoadElements: ElementTypes[] = [
    'beams',
    'purlins',
    'inner_walls',
    'outer_walls',
    'lintels',
  ]

  const onAdd = useCallback(() => {
    const selectedElement = selectedElements?.[0]

    if (!selectedElement || !elementType) return

    if (loadType === 'line-load' && !lineLoadElements.includes(elementType)) {
      enqueueSnackbar('Linienlasten können nicht auf diesem Element aufgeprägt werden', {
        variant: 'info',
      })
      return
    }

    if (loadType === 'point-load' && !pointLoadElements.includes(elementType)) {
      enqueueSnackbar('Punktlasten können nicht auf diesem Element aufgeprägt werden', {
        variant: 'info',
      })
      return
    }

    const load = createLoad({
      element_guid: selectedElement,
      domains,
      positions: allPositions,
      // @ts-ignore
      loadType,
    })

    addLoad(load as ElementLoad)
    setLoad(load as ElementLoad)
  }, [selectedElements, elementType, allPositions])

  const onRemove = (guid: string) => {
    removeLoad(guid)
  }

  const onCopy = (guid: string) => {
    const load = find(loads, { guid }) as ElementLoad
    const newLoad = {
      ...cloneDeep(load),
      guid: uuid(),
    }
    addLoad(newLoad)
    setLoad(newLoad)
  }

  return <>{children({ onAdd, onRemove, onCopy, loads })}</>
}

export default LoadFieldArray

import React, { Fragment, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { find, toNumber } from 'lodash-es'
import { useQueryParam } from 'use-query-params'
import InfoIcon from '@mui/icons-material/Info'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Divider, Grid, Tab, Tooltip, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { SwitchLabeled } from '@ui/actions'
import { Form, Select, TextField } from '@ui/forms'
import { Box, Drawer, LayoutDrawer as Layout } from '@ui/structure'
import { SceneControlled } from '@editorComponents'
import { checkDuplicateAssemblyNames, checkWallAssembliesHaveShortName } from 'src/utils/assemblies'
import { useModelStore } from '../../stores/modelStore'
import { useSystemManagerStore } from '../../stores/systemManagerStore'
import {
  DownloadDocumentItem,
  DownloadItemWithSettings,
  CombinedPositionPlanItem,
} from './components'
import DownloadDocumentItemWithTask from './components/DownloadDocumentItemWithTask'
import { tabConfig } from './constants'

dayjs.extend(customParseFormat)

const todayStringDE = () => {
  const dayjsToday = dayjs()
  return dayjsToday.format('DD.MM.YYYY')
}

const handInDateToRequestParam = (handInDate: string) => {
  const dayjsHandInDate = dayjs(handInDate, 'DD.MM.YYYY')
  return dayjsHandInDate.format('DD-MM-YYYY')
}

const Export = () => {
  const [tab, setTab] = useQueryParam<keyof typeof tabConfig, 'plans'>('tab')
  const availableStoreys = useModelStore(state => state.availableStoreys)

  const storeys = useMemo(
    () =>
      [...availableStoreys].sort((a, b) => {
        const aN = a === 'Dach' ? Number.MAX_SAFE_INTEGER : toNumber(a)
        const bN = b === 'Dach' ? Number.MAX_SAFE_INTEGER : toNumber(b)
        return toNumber(aN) - toNumber(bN)
      }),
    [availableStoreys],
  )
  const [paperFormat, setPaperFormat] = useState('A2')
  const [paperOrientation, setOrientation] = useState('landscape')
  const [scaleDenominator, setScaleDenominator] = useState(50)
  const [fontSize, setFontSize] = useState(8)
  const [greyOutNonBearing, setGreyOutNonBearing] = useState(false)
  const [useBluebeamLabels, setUseBluebeamLabels] = useState(true)
  const [handInDate, setHandInDate] = useState(todayStringDE())
  const [planDate, setPlanDate] = useState(todayStringDE())

  const assemblies = useSystemManagerStore(state => state.assemblies)
  const assemblyConfigIsOkForStiffening =
    checkDuplicateAssemblyNames(assemblies) && checkWallAssembliesHaveShortName(assemblies)

  const onScaleDenominatorChange = (event: { target: { value: unknown } }) => {
    const newValue = Number(event.target.value)
    if (newValue <= 0) {
      setScaleDenominator(50)
    } else {
      setScaleDenominator(newValue)
    }
  }
  const onFontSizeChange = (event: { target: { value: unknown } }) => {
    const newValue = Number(event.target.value)
    if (newValue <= 1) {
      setFontSize(10)
    } else {
      setFontSize(newValue)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onSettingsSubmit = () => {}

  const toggleGreyOutNonBearing = () => setGreyOutNonBearing(!greyOutNonBearing)
  const toggleUseBluebeamLabels = () => setUseBluebeamLabels(!useBluebeamLabels)
  const paperFormatOptions = [
    {
      value: 'A2',
      label: 'A2',
    },
    {
      value: 'A1',
      label: 'A1',
    },
  ]
  const paperOrientationOptions = [
    {
      value: 'landscape',
      label: 'Quer',
    },
    {
      value: 'portrait',
      label: 'Hochkant',
    },
  ]

  return (
    <Layout>
      <SceneControlled />
      <Drawer>
        <Box>
          <TabContext value={tab || 'plans'}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
              }}
            >
              <TabList onChange={(e, v) => setTab(v)}>
                {Object.entries(tabConfig).map(([value, { label }]) => (
                  <Tab
                    key={value}
                    value={value}
                    label={label}
                    data-cy={`tab-${value}`}
                    sx={{ margin: 0 }}
                  />
                ))}
              </TabList>
            </Box>

            <TabPanel value={tabConfig.plans.value} sx={{ margin: 0 }}>
              <Box marginX={-2} mt={-2}>
                <Stack direction="column" spacing={1}>
                  <Box>
                    {/* Wrap this in a form so that we can reuse the custom form components.
                        Raw MaterialUI components could be used here instead. */}
                    <Form id="export-plans-settings-form" onSubmit={onSettingsSubmit}>
                      <Divider>Einstellungen</Divider>
                      <Stack direction="row" spacing={1}>
                        <SwitchLabeled
                          checked={greyOutNonBearing}
                          onChange={() => toggleGreyOutNonBearing()}
                          label="Nichttragende Wände ausgrauen"
                          data-cy="export-settings-grey-out-non-bearing-switch"
                        />
                      </Stack>
                      <Stack direction="row" spacing={1}>
                        <SwitchLabeled
                          checked={useBluebeamLabels}
                          onChange={() => toggleUseBluebeamLabels()}
                          label="Bluebeam kompatible Anmerkungen verwenden"
                          data-cy="export-settings-use-bluebeam-labels-switch"
                        />
                      </Stack>
                      <Stack direction="row" spacing={1}>
                        <Select
                          label="Format"
                          name="export.settings.paper-format"
                          options={paperFormatOptions}
                          onChange={e => {
                            const option = find(
                              paperFormatOptions,
                              ({ value }) => e.target.value === value,
                            )
                            if (option) setPaperFormat(option.value)
                          }}
                          value={
                            find(paperFormatOptions, ({ value }) => paperFormat === value)?.value
                          }
                          data-cy="export-settings-format-select"
                        />
                        <Select
                          label="Quer / Hoch"
                          name="export.settings.orientation"
                          options={paperOrientationOptions}
                          onChange={e => {
                            const option = find(
                              paperOrientationOptions,
                              ({ value }) => e.target.value === value,
                            )
                            if (option) setOrientation(option.value)
                          }}
                          value={
                            find(paperOrientationOptions, ({ value }) => paperOrientation === value)
                              ?.value
                          }
                          data-cy="export-settings-orientation-selection"
                        />
                        <TextField
                          name="scale-denominator"
                          label="Maßstab 1:X"
                          type="number"
                          placeholder="Maßstab 1:X"
                          value={scaleDenominator}
                          onChange={onScaleDenominatorChange}
                        />
                        <TextField
                          name="fontsize"
                          label="Schriftgr."
                          type="number"
                          placeholder="Schriftgr."
                          value={fontSize}
                          onChange={onFontSizeChange}
                        />
                      </Stack>
                      <Box mb={1} mt={1}>
                        <Grid container spacing={1} alignItems="center">
                          <Grid item xs={6}>
                            <Box ml={2}>
                              <Typography fontWeight={'fontWeightMedium'}>
                                Datum für Pläne
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              name="plan-date-text-input"
                              rows={1}
                              data-cy="plan-date-text-input"
                              placeholder="TT.MM.YYYY"
                              onChange={e => setPlanDate(e.target.value)}
                              value={planDate}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Form>
                  </Box>
                  <Divider>Positionspläne (PP) - PDF</Divider>
                  <Box>
                    {storeys.map(storey => {
                      let formattedStorey = `${storey}. OG`
                      if (storey === 'Dach') return <></>
                      if (storey === '0') formattedStorey = 'EG'
                      const fileName = `Positionsplan-${formattedStorey}`
                      return (
                        <Fragment key={storey}>
                          <DownloadDocumentItem
                            title={`${formattedStorey}`}
                            path={`position-plan/${storey}`}
                            params={{
                              paper_format: paperFormat,
                              orientation: paperOrientation,
                              scale: scaleDenominator,
                              add_header: true,
                              f_format: 'pdf',
                              grey_out_non_bearing: greyOutNonBearing,
                              use_bluebeam_labels: useBluebeamLabels,
                              font_size: fontSize,
                              hand_in_date: handInDateToRequestParam(planDate),
                            }}
                            type="application/pdf"
                            filename={fileName}
                            extension="pdf"
                          />
                        </Fragment>
                      )
                    })}
                    <CombinedPositionPlanItem
                      path="position-plan"
                      params={{
                        paper_format: paperFormat,
                        orientation: paperOrientation,
                        scale: scaleDenominator,
                        add_header: true,
                        f_format: 'pdf',
                        grey_out_non_bearing: greyOutNonBearing,
                        use_bluebeam_labels: useBluebeamLabels,
                        font_size: fontSize,
                        hand_in_date: handInDateToRequestParam(planDate),
                      }}
                      type="application/pdf"
                      filename="Positionsplan"
                      extension="pdf"
                    />
                  </Box>
                  <Divider>Aussteifungspläne (AP) - PDF</Divider>
                  {!assemblyConfigIsOkForStiffening && (
                    <Tooltip title="Kurzbezeichnungen für Wandaufbauten müssen angegeben und duplikatfrei sein.">
                      <Typography
                        align="center"
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      >
                        <InfoIcon style={{ marginRight: 8 }} />
                        Kurzbezeichnung für Aufbauten überprüfen
                      </Typography>
                    </Tooltip>
                  )}

                  <Stack direction="column" spacing={1}>
                    <Box>
                      {storeys.map(storey => {
                        if (storey === 'Dach') return <></>

                        const formattedStorey = storey === '0' ? 'EG' : `${storey}. OG`
                        const fileName = `Aussteifungsplan-${formattedStorey}`

                        return (
                          <Fragment key={storey}>
                            <DownloadDocumentItem
                              title={`${formattedStorey}`}
                              path={`stiffening-plan/pdf/${storey}?`}
                              params={{
                                paper_format: paperFormat,
                                orientation: paperOrientation,
                                scale: scaleDenominator,
                                add_header: true,
                                f_format: 'pdf',
                                use_bluebeam_labels: useBluebeamLabels,
                                font_size: fontSize,
                                hand_in_date: handInDateToRequestParam(planDate),
                              }}
                              type="application/pdf"
                              filename={fileName}
                              extension="pdf"
                            />
                          </Fragment>
                        )
                      })}
                    </Box>
                  </Stack>

                  <Divider />

                  <DownloadDocumentItem
                    title="Lastenplan"
                    path={'foundation-loads/image'}
                    params={{
                      paper_format: paperFormat,
                      orientation: paperOrientation,
                      scale: scaleDenominator,
                      add_header: true,
                      f_format: 'pdf',
                      grey_out_non_bearing: greyOutNonBearing,
                      use_bluebeam_labels: useBluebeamLabels,
                      font_size: fontSize,
                      hand_in_date: handInDateToRequestParam(planDate),
                    }}
                    type="application/pdf"
                    filename={`Lastplan`}
                    extension="pdf"
                  />

                  <Divider />

                  <DownloadDocumentItem
                    title="Positionslisten"
                    path="all-position-lists"
                    params={{}}
                    type="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    filename="Positionslisten"
                    extension="docx"
                  />
                  <Divider />

                  <DownloadDocumentItem
                    title="Modell (JSON)"
                    path="planar-model" // export/planar-model
                    params={{}}
                    type="application/json"
                    filename="PlanarModel"
                    extension="json"
                  />
                </Stack>
              </Box>
            </TabPanel>
            <TabPanel value={tabConfig.loads.value}>
              <Box marginX={-2} mt={-2}>
                <Stack direction="column" spacing={1}>
                  <DownloadDocumentItem
                    title="Lastzusammenstellung"
                    path="load-overview/document"
                    type="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    filename="Lastzusammenstellung"
                    extension="docx"
                  />

                  <Divider />

                  <DownloadDocumentItem
                    title="Verteilung Aussteifungslasten"
                    path="horizontal-loads/document"
                    type="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    filename="Verteilung Aussteifungslasten"
                    extension="docx"
                  />

                  <Divider />

                  <DownloadItemWithSettings
                    title="Fundamentlasten (XLSX)"
                    path="foundation-loads/table"
                    type="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    filename="Fundamentlasten"
                    extension="xlsx"
                  />

                  <DownloadItemWithSettings
                    title="Fundamentlasten (RFEM)"
                    path="foundation-loads/rfem"
                    type="application/json"
                    filename="Fundamentlasten"
                    extension="json"
                  />

                  <DownloadDocumentItem
                    title="Erdbebenmodell (RFEM)"
                    path="seismic-model/rfem"
                    type="application/pdf"
                    filename="seismic-model"
                    extension="json"
                  />

                  <DownloadDocumentItem
                    title="Export Erdbebenlasten"
                    path="simplified-seismic-results/document"
                    params={{}}
                    type="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    filename="Erdbebenlasten"
                    extension="docx"
                  />

                  <Divider />

                  <DownloadDocumentItem
                    title="Aufbauten"
                    path="assemblies/docx"
                    type="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    filename="Aufbauten"
                    extension="docx"
                  />
                </Stack>
              </Box>
            </TabPanel>
            <TabPanel value={tabConfig.certificates.value}>
              <Box marginX={-2} mt={-2}>
                <Stack direction="column" spacing={1}>
                  <DownloadDocumentItem
                    title="Scheibennachweise"
                    path="stiffening-segments/document"
                    type="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    filename="Scheibennachweise"
                    extension="docx"
                  />
                  <DownloadDocumentItem
                    title="Zugankernachweise"
                    path="tensile_anchors/document"
                    type="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    filename="Zugankernachweise"
                    extension="docx"
                  />
                  <DownloadDocumentItem
                    title="Schubanker-Übersicht"
                    path="shear-anchors/document"
                    type="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    filename="Schubanker-Übersicht"
                    extension="docx"
                  />
                  <DownloadDocumentItem
                    title="Ersatz-Schubmodule (Spektralanalyse)"
                    path="shear-modulus/document"
                    type="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    filename="Ersatz-Schubmodule"
                    extension="docx"
                  />
                  <Divider />

                  <Box>
                    {storeys.map(storey => {
                      let storeyToShow = `${storey}. OG`
                      if (storey === '0') storeyToShow = 'EG'
                      if (storey === 'Dach') storeyToShow = 'Dach'

                      // Inconsistent reference to roof here, sometimes explicit path is provided,
                      //  sometimes `-1` is used
                      const storeyForPath = storey === 'Dach' ? '-1' : storey

                      const path = `member-checks/storey/${storeyForPath}/document`

                      return (
                        <Fragment key={storey}>
                          <DownloadDocumentItem
                            title={`Bauteilnachweise ${storeyToShow}`}
                            path={path}
                            type="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            filename={`Bauteilnachweise ${storeyToShow}`}
                            extension="docx"
                          />
                        </Fragment>
                      )
                    })}

                    <DownloadDocumentItem
                      title="Alle Bauteilnachweise (DOCX)"
                      path="member-checks/document"
                      type="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      filename="Bauteilnachweise"
                      extension="docx"
                    />
                  </Box>
                </Stack>
              </Box>
            </TabPanel>
            <TabPanel value={tabConfig.others.value}>
              <Stack direction="column" spacing={1}>
                <Box>
                  <Form id="export-others-settings-form" onSubmit={onSettingsSubmit}>
                    <Stack direction="column" spacing={1}>
                      <TextField
                        label="Abgabedatum DD.MM.YYYY"
                        name="handInDate-text-input"
                        rows={1}
                        data-cy="handInDate-text-input"
                        placeholder="TT.MM.YYYY"
                        onChange={e => setHandInDate(e.target.value)}
                        value={handInDate}
                      />
                    </Stack>
                  </Form>
                </Box>
                <DownloadDocumentItemWithTask
                  title="Gesamtdokument"
                  apiDocumentName="full-structural-document"
                  params={{
                    hand_in_date: handInDateToRequestParam(handInDate),
                    extension: 'docx',
                  }}
                  type="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  filename="Gesamtdokument"
                  extension="docx"
                />
                <Divider />
                <Box marginX={-2} mt={-2}>
                  <Stack direction="column" spacing={1}>
                    <DownloadDocumentItem
                      title="Deckblatt"
                      path="title-page/document"
                      params={{
                        hand_in_date: handInDateToRequestParam(handInDate),
                      }}
                      type="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      filename="Deckblatt"
                      extension="docx"
                    />
                    <DownloadDocumentItem
                      title="Vorbemerkungen"
                      path="preliminary-remarks/document"
                      type="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      filename="Vorbemerkungen"
                      extension="docx"
                    />
                  </Stack>
                </Box>
              </Stack>
            </TabPanel>
          </TabContext>
        </Box>
      </Drawer>
    </Layout>
  )
}

export default Export

import React, { ReactElement, ReactNode, useState } from 'react'
import { isUndefined } from 'lodash-es'
import { Button, Paper, Stack, SxProps, Theme, Typography } from '@mui/material'

interface Props {
  hint?: string
  children: ReactNode
  title: string | ReactNode
  style?: SxProps<Theme>
  left?: number
  onClick?: () => void
  'data-cy'?: string
  toggleType?: 'hover' | 'click'
}

const TextButtonDropDown = ({
  children,
  title,
  style,
  left,
  toggleType = 'click',
  onClick = () => null,
  'data-cy': dataCy,
}: Props): ReactElement => {
  const [open, setOpen] = useState(false)

  return (
    <div style={{ zIndex: Number.MAX_SAFE_INTEGER, display: 'table-cell' }}>
      <Typography
        sx={{
          '&:hover': {
            '.controlsContainer': {
              display: toggleType === 'hover' ? 'block' : undefined,
            },
          },
          cursor: 'pointer',
          ...style,
        }}
        onClick={() => {
          setOpen(!open)
          onClick()
        }}
        data-cy={dataCy}
      >
        {title}
      </Typography>
      <Paper
        className="controlsContainer"
        sx={{
          display: toggleType === 'hover' ? 'none' : open ? 'block' : 'none',
          position: 'absolute',
          // right: extendTo === 'left' ? 0 : undefined,
          // left: extendTo === 'right' ? 0 : undefined,
          left: isUndefined(left) ? -120 : left,
          top: theme => theme.spacing(3.5),
          p: 1.25,
          zIndex: Number.MAX_SAFE_INTEGER,
          width: 300,
        }}
        onClick={e => e.stopPropagation()}
        elevation={3}
      >
        {children}
        <Stack justifyContent="flex-end">
          <Button variant="text" onClick={() => setOpen(false)}>
            schließen
          </Button>
        </Stack>
      </Paper>
    </div>
  )
}

export default TextButtonDropDown

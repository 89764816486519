import React, { ReactElement, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { get, some } from 'lodash-es'
import { Button, Grid } from '@mui/material'
import { TextField, Select, FormControl } from '@ui/forms'
import AssemblyPlot from '../../AssemblyPlot'
import { assemblyTypes } from '../../constants'
import VibrationCheckSettingsDialog from '../VibrationCheckSettingsDialog'

const GeneralFields = (): ReactElement => {
  const {
    watch,
    setValue,
    formState: { errors, submitCount },
  } = useFormContext()
  const layers = watch('layers')
  const assemblyType = watch('usage_type')
  const typeOptions = useMemo(
    () => assemblyTypes.map(({ label, usage_type: value }) => ({ label, value })),
    [],
  )

  const vibrationCheckSettingsFields = ['vibration_check_settings']
  const fieldWatch = watch(vibrationCheckSettingsFields)

  const hasErrors = useMemo(() => {
    if (!submitCount) return false

    return some(vibrationCheckSettingsFields, fieldName => !!get(errors, fieldName))
  }, [errors, vibrationCheckSettingsFields, fieldWatch, submitCount])

  const [openVibrationCheckSettingsDialog, setOpenVibrationCheckSettingsDialog] =
    useState<boolean>(false)

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl label="Illustration">
            <AssemblyPlot assemblyType={assemblyType} layers={layers} />
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField label="Name" name="name" placeholder="120ger Wand" data-cy="assembly-name" />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Kurzbez."
            name="short_name"
            placeholder="W1"
            data-cy="assembly-short-name"
          />
        </Grid>
        <Grid item xs={12}>
          <Select name="usage_type" label="Typ" options={typeOptions} />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Beschreibung" name="description" rows={3} multiline />
        </Grid>
        <Grid item xs={12}>
          <FormControl label="Nachweiseinstellungen">
            <Button
              onClick={() => setOpenVibrationCheckSettingsDialog(true)}
              sx={{
                color: ({ palette }) => (hasErrors ? `${palette.error.main}` : ''),
              }}
            >
              Schwingungsnachweise
            </Button>
          </FormControl>
        </Grid>
      </Grid>
      {openVibrationCheckSettingsDialog && (
        <VibrationCheckSettingsDialog
          open
          onClose={() => setOpenVibrationCheckSettingsDialog(false)}
          onReset={() => {
            setOpenVibrationCheckSettingsDialog(false)
            setValue('vibration_check_settings', null)
          }}
        />
      )}
    </>
  )
}

export default GeneralFields

import { object, string, boolean, number, ref } from 'yup'

export const timberFrameLayerSchema = object({
  materialTypeStandardRip: string().default('softwoodMaterial'),
  materialTypeLintel: string().default('softwoodMaterial'),
  layer: object({
    unique_name: string().default('timber-frame-layer'),
    kind: string().default('timber-frame-layer'),
    crossSectionStandardRip: object({
      shape: object({
        height: number().default(0).moreThan(0),
        width: number().default(0).moreThan(0),
      }),
      unique_name: string().default(''),
      material: object().default(null).nullable().required(),
      processed: boolean().default(true),
      producer: string().default('').nullable(),
      description: string().default('').nullable(),
    }),
    crossSectionLintel: object({
      shape: object({
        height: number().default(0).moreThan(0),
        width: number().default(0).moreThan(0),
      }),
      unique_name: string().default(''),
      material: object().default(null).nullable().required(),
      processed: boolean().default(true),
      producer: string().default('').nullable(),
      description: string().default('').nullable(),
    }),
    rip_step_size: number().default(0.625).moreThan(0),
    hasInsulation: boolean(),
    insulation_thickness: number()
      .nullable()
      .when('hasInsulation', {
        is: true,
        then: number()
          .required('Insulation thickness is required when insulation is enabled')
          .moreThan(0)
          .max(
            ref('crossSectionStandardRip.shape.height'),
            'Muss kleiner oder gleich Querschnittshöhe sein',
          ),
        otherwise: number().nullable(),
      }),
    insulation: object()
      .nullable()
      .when('hasInsulation', {
        is: true,
        then: object({
          name: string().required('Insulation name is required when insulation is enabled'),
          producer: string().nullable(),
          density: number()
            .required('Insulation density is required when insulation is enabled')
            .moreThan(0),
          description: string().nullable(),
        }),
        otherwise: object().nullable(),
      }),
  }),
})

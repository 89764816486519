import React, { ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'
import { MenuItem, Stack } from '@mui/material'
import { TextField, UnitConvertedField } from '@ui/forms'
import { liveLoadCaseToLoadProposal, liveLoadCategoryOptions } from '../../constants'

interface Props {
  index: number
}

const AssignmentForm = ({ index }: Props): ReactElement => {
  const { setValue } = useFormContext()

  return (
    <Stack direction="column" spacing={1} mb={1} mt={1}>
      <TextField
        select
        label="Nutzlastkategorie"
        name={`configurations[${index}].load_case.load_case_type`}
        onChange={event => {
          const loadCaseType = event.target.value as LiveLoadLoadCaseType
          setValue(`configurations[${index}].load_case.load_case_type`, loadCaseType)
          setValue(
            `configurations[${index}].load_per_area`,
            liveLoadCaseToLoadProposal[loadCaseType],
          )
        }}
        size="small"
        sx={{ mt: 1, bgcolor: 'grey.50' }}
      >
        {Object.entries(liveLoadCategoryOptions).map(([key, { label }]) => (
          <MenuItem value={key} key={key}>
            {label}
          </MenuItem>
        ))}
      </TextField>

      <Stack direction="row" spacing={1}>
        <UnitConvertedField
          name={`configurations[${index}].load_per_area`}
          label="Last"
          type="number"
          unitLabel="kN/m²"
          data-cy={`live-load-load-per-area-${index}`}
        />

        <UnitConvertedField
          name={`configurations[${index}].separating_wall_load`}
          label="Trennwandzuschlag"
          type="number"
          unitLabel="kN/m²"
          data-cy={`live-load-separating-wall-load-${index}`}
        />
      </Stack>
    </Stack>
  )
}

export default AssignmentForm

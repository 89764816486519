import React, { ReactElement, useMemo } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { get } from 'lodash-es'
import { Button, Divider, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useElementLabel } from '@editorHooks'

interface Props {
  index: number
  onClickPosition: (guid: string) => void
}

const LoadsSmoothingErrorsInScene = ({ index, onClickPosition }: Props): ReactElement => {
  const getLabel = useElementLabel()

  const {
    formState: { errors },
  } = useFormContext()

  const bundles = useWatch({ name: 'bundles' }) as MemberPositionBundle[]

  const invalidPositionIds = useMemo(() => {
    const bundleErrors = get(errors, 'bundles')
    const ids = bundleErrors
      ? (bundleErrors as unknown as Array<unknown>).map(
          (e, i) => bundles[i].representative_position,
        )
      : []

    return ids
  }, [errors, bundles])

  if (invalidPositionIds.length === 0) return <></>

  return (
    <Stack spacing={2}>
      <Divider />
      <Typography variant="h6">Invalide Positionen</Typography>

      {invalidPositionIds.map(
        guid =>
          guid !== bundles[index].representative_position && (
            <Stack
              key={guid}
              direction="row"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography>{getLabel(guid)}</Typography>
              <Button
                size="small"
                variant="contained"
                color="error"
                onClick={() => onClickPosition(guid)}
              >
                zur Position
              </Button>
            </Stack>
          ),
      )}
    </Stack>
  )
}

export default LoadsSmoothingErrorsInScene

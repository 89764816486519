import React, { ReactElement, useEffect, useRef } from 'react'
import { useFormContext } from 'react-hook-form'
import { Grid, InputAdornment } from '@mui/material'
import { TextField, ErrorField, UnitConvertedField } from '@ui/forms'

interface Props {
  onChange: (value: Interval, effective_height: number) => void
}

const StiffeningElementFormField = ({ onChange }: Props): ReactElement => {
  const { watch, setValue, formState } = useFormContext()
  const upper = watch('upper')
  const lower = watch('lower')
  const effective_height = watch('effective_height')
  const initialValue = useRef({ upper, lower, effective_height })

  useEffect(() => {
    onChange({ upper, lower }, effective_height)
    setValue('length', parseFloat((upper - lower).toFixed(3)))
  }, [upper, lower, effective_height])

  useEffect(() => {
    // all stiffening elments are saved at once so invalid
    // intervals have to be reset if user exits without saving
    return () => {
      if (!formState.isValid) {
        onChange(
          { lower: initialValue.current.lower, upper: initialValue.current.upper } as Interval,
          initialValue.current.effective_height,
        )
      }
    }
  }, [])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          label="Element Länge (kalkuliert)"
          name="length"
          type="number"
          data-cy="input-element-length"
          disabled
          InputProps={{
            endAdornment: <InputAdornment position="end">m</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          inputProps={{
            step: 0.1,
          }}
          label="Start"
          data-cy="input-element-start"
          name="lower"
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="end">m</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          inputProps={{
            step: 0.1,
          }}
          label="Ende"
          name="upper"
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="end">m</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <UnitConvertedField
          label="Effektive Höhe"
          tooltip={{ text: 'Für Nachweisführung und Ermittlung der Zugkräfte' }}
          name="effective_height"
          conversionFactor={1}
          unitLabel="m"
        />
      </Grid>
      <Grid item xs={12}>
        <ErrorField data-cy="error-intersection" name="isIntersectingWithOpening" />
      </Grid>
    </Grid>
  )
}

export default StiffeningElementFormField

export const layerOptionsCLT = [
  { label: 'Produktschicht', value: 'product-layer' },
  { label: 'CLT Schicht', value: 'clt-assembly-layer' },
  { label: 'Plattenschicht', value: 'plate-layer' },
]

export const layerOptionsTimberFrame = [
  { label: 'Produktschicht', value: 'product-layer' },
  { label: 'Plattenschicht', value: 'plate-layer' },
  { label: 'Holzrahmenschicht', value: 'timber-frame-layer' },
]

export const positionToDomain = (position: Position): Domain => {
  return {
    ...position,
    guid: position.domain_guid,
    element_guid: position.position_guid,
    length: position.start.distanceTo(position.end),
  } as Domain
}

export const memberToDomain = (member: Member): Domain => {
  return {
    guid: member.guid,
    start: member.start,
    end: member.end,
    coordinate_system: member.coordinate_system,
    length: member.start.distanceTo(member.end),
  } as Domain
}

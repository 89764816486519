import { useEffect } from 'react'
import { useFormContext, FieldError } from 'react-hook-form'

const useFieldArrayErrors = (fieldArrayName: string): FieldError | undefined => {
  const {
    formState: { submitCount, errors },
    watch,
    trigger,
  } = useFormContext()
  const values = watch(fieldArrayName)

  useEffect(() => {
    if (submitCount === 0) return

    trigger(fieldArrayName)
  }, [submitCount, values, fieldArrayName])

  return submitCount ? (errors[fieldArrayName] as FieldError) : undefined
}

export default useFieldArrayErrors

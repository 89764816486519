import { filter, find, groupBy, isUndefined, round, toNumber } from 'lodash-es'

// Exact supports, so cannot have greater or fewer than the number
export const elementTypeToRequiredSupport: { [type in ElementTypes]?: number } = {
  lintels: 2,
  rips: 1,
}

const elementTypesNotAllowedWithoutLoadInducing: ElementTypes[] = ['beams', 'columns', 'purlins']

const intervalFractionDigits = 4

interface ProblemOnElement {
  element_guid: string
  message: string
}

interface ProblematicElements {
  problemsOnElements: ProblemOnElement[]
  element_guids: string[]
}

interface AdmissibleSupportConfiguration {
  minPointSupports: number
  maxPointSupports: number
  minLineSupports: number
  maxLineSupports: number
  minFullySupportedDomains: number
  maxFullySupportedDomains: number
  partiallySupportedDomainsAllowed: boolean
  note: string
}

const createAdmissibleSupportConfig = (
  config: Partial<AdmissibleSupportConfiguration>,
): AdmissibleSupportConfiguration => ({
  minPointSupports: 0,
  maxPointSupports: Number.MAX_SAFE_INTEGER,
  minLineSupports: 0,
  maxLineSupports: Number.MAX_SAFE_INTEGER,
  minFullySupportedDomains: 0,
  maxFullySupportedDomains: Number.MAX_SAFE_INTEGER,
  partiallySupportedDomainsAllowed: true,
  note: '',
  ...config,
})

const exactlyNPointSupports = (count: number): AdmissibleSupportConfiguration =>
  createAdmissibleSupportConfig({
    minPointSupports: count,
    maxPointSupports: count,
    maxLineSupports: 0,
    note: `Genau ${count} Punktauflager`,
  })

const minNFullySupportedDomains = (count: number): AdmissibleSupportConfiguration =>
  createAdmissibleSupportConfig({
    minFullySupportedDomains: count,
    minLineSupports: 1,
    maxPointSupports: 0,
    note: `Mindestens ${count} durch Linienauflager vollständig abgedeckte Domains.`,
  })

const minNPointSupports = (count: number): AdmissibleSupportConfiguration =>
  createAdmissibleSupportConfig({
    minPointSupports: count,
    note: `Mindestens ${count} Punktauflager`,
  })

const minNLineSupports = (count: number): AdmissibleSupportConfiguration =>
  createAdmissibleSupportConfig({
    minLineSupports: count,
    note: `Mindestens ${count} Linienauflager`,
  })

const minNFullySupportedDomainsAndNoPartiallySupportedDomains = (
  count: number,
): AdmissibleSupportConfiguration =>
  createAdmissibleSupportConfig({
    minFullySupportedDomains: count,
    minLineSupports: 1,
    maxPointSupports: 0,
    partiallySupportedDomainsAllowed: false,
    note: `Mindestens ${count} durch Linienauflager vollständig abgedeckte Domains und alle weiteren Domains ebenfalls vollständig abgedeckt.`,
  })

const ADMISSIBLE_SUPPORTS: { [key in ElementTypes]?: AdmissibleSupportConfiguration[] } = {
  beams: [minNPointSupports(2), minNLineSupports(1)],
  purlins: [minNPointSupports(2), minNLineSupports(1)],
  columns: [exactlyNPointSupports(1)],
  rips: [exactlyNPointSupports(1)],
  lintels: [exactlyNPointSupports(2)],
  outer_walls: [minNFullySupportedDomains(1)],
  inner_walls: [minNFullySupportedDomains(1)],
  vertical_slabs: [minNFullySupportedDomainsAndNoPartiallySupportedDomains(2)],
  vertical_roof_slabs: [minNFullySupportedDomainsAndNoPartiallySupportedDomains(2)],
}

interface IntervalCheckResult {
  isValid: boolean
  errorMessage?: string
}

const checkIntervalUnionCoversCompleteWidth = (
  intervals: Interval[],
  elementLength?: number,
): IntervalCheckResult => {
  const sortedIntervals = intervals.sort((a, b) => toNumber(a.lower) - toNumber(b.lower))

  // Check if it starts at 0
  const lowerEnd = toNumber(sortedIntervals[0].lower)
  if (lowerEnd !== 0) {
    let errorMessage = 'Auflager beginnt nicht am Anfang der Domain.'

    if (!isUndefined(elementLength)) {
      errorMessage += `Es fehlen ${round(lowerEnd * elementLength, 2)}m.`
    }

    return {
      isValid: false,
      errorMessage,
    }
  }

  // Check if it ends at 1
  const upperEnd = toNumber(sortedIntervals[sortedIntervals.length - 1].upper)
  if (upperEnd !== 1) {
    let errorMessage = 'Auflager endet nicht am Ende der Domain.'

    if (!isUndefined(elementLength)) {
      errorMessage += ` Es fehlen ${round((1 - upperEnd) * elementLength, 2)}m.`
    }

    return {
      isValid: false,
      errorMessage,
    }
  }

  // Check for gaps between intervals
  for (let i = 0; i < sortedIntervals.length - 1; i++) {
    const currentUpper = toNumber(sortedIntervals[i].upper)
    const nextLower = toNumber(sortedIntervals[i + 1].lower)

    if (
      currentUpper.toFixed(intervalFractionDigits) !== nextLower.toFixed(intervalFractionDigits)
    ) {
      let errorMessage = 'Domain enthält Bereiche ohne Auflager.'

      if (!isUndefined(elementLength)) {
        errorMessage += ` Zwischen ${currentUpper * elementLength}m und ${
          nextLower * elementLength
        }`
      }

      return { isValid: false, errorMessage }
    }
  }

  return { isValid: true }
}

const checkIfRipIsIndirectlyUnsupported = (
  rip_support: ElementSupportItem,
  graph: VerticalTransmissionGraph,
  containing_wall: string,
): boolean => {
  const target_guid = find(graph.support_targets, {
    support_guid: rip_support.guid,
  })?.target_guid
  const target = find(graph.element_targets, { guid: target_guid })

  if (target && containing_wall === target.element_guid) {
    const wall_supports = filter(graph.element_supports, {
      element_guid: target.element_guid,
    })
    const rip_position = target.relative_position

    // Check if any wall_support's relative_interval includes the rip_position
    const isRipPositionSupported = wall_supports.some(support => {
      const interval = support.relative_interval as Interval
      return (
        toNumber(interval.lower) <= toNumber(rip_position) &&
        toNumber(rip_position) <= toNumber(interval.upper)
      )
    })

    return !isRipPositionSupported
  } else {
    // Rip is directly supported by some element other than its containing wall
    return false
  }
}

const getProblematicElements = (
  graph: VerticalTransmissionGraph,
  guidToElementType: Record<string, ElementTypes | undefined>,
  positionGuidToWallGuid: Record<string, string>,
  domainGuidToDomain: Record<string, Domain>,
): ProblematicElements => {
  const problems: ProblemOnElement[] = []

  Object.entries(guidToElementType).forEach(([guid, type]) => {
    if (!type) return

    const supports = filter(graph.element_supports, { element_guid: guid })
    const admissibleConfigs = ADMISSIBLE_SUPPORTS[type]

    if (admissibleConfigs) {
      const pointSupports = supports.filter(s => s.support_type === 'point')
      const lineSupports = supports.filter(s => s.support_type === 'line')

      // Group line supports by domain
      const domainSupports = groupBy(lineSupports, 'domain_guid')

      const domainToFullySupported = Object.entries(domainSupports).map(
        ([domain_guid, lineSupports]) => {
          const intervals = lineSupports.map(s => s.relative_interval as Interval)
          const domain = domainGuidToDomain[domain_guid]
          const result = checkIntervalUnionCoversCompleteWidth(intervals, domain?.length)
          return {
            domain_guid,
            isValid: result.isValid,
            errorMessage: result.errorMessage,
          }
        },
      )
      const fullySupportedDomains = domainToFullySupported.filter(d => d.isValid).length

      const hasPartiallySupportedDomain = domainToFullySupported.some(d => !d.isValid)

      const isValid = admissibleConfigs.some(config => {
        const isPointSupportsValid =
          config.minPointSupports <= pointSupports.length &&
          pointSupports.length <= config.maxPointSupports
        const isLineSupportsValid =
          config.minLineSupports <= lineSupports.length &&
          lineSupports.length <= config.maxLineSupports
        const isFullySupportedDomainsValid =
          config.minFullySupportedDomains <= fullySupportedDomains &&
          fullySupportedDomains <= config.maxFullySupportedDomains
        const isPartiallySupportedDomainsValid =
          config.partiallySupportedDomainsAllowed || !hasPartiallySupportedDomain
        return (
          isPointSupportsValid &&
          isLineSupportsValid &&
          isFullySupportedDomainsValid &&
          isPartiallySupportedDomainsValid
        )
      })

      if (!isValid) {
        const domainErrors = domainToFullySupported.filter(d => !d.isValid)
        const validConfigs = admissibleConfigs.map(config => config.note).join(' ODER ')
        let message = `Element hat keine gültige Auflagerkonfiguration${
          domainErrors.length > 0 ? `: ${domainErrors[0].errorMessage} ` : '. '
        }Gültige Konfigurationen sind: ${validConfigs}`

        if (hasPartiallySupportedDomain) {
          message +=
            ' Ein oder mehrere Domains sind nur teilweise unterstützt, was für diese Konfiguration nicht zulässig ist.'
        }

        problems.push({
          element_guid: guid,
          message: message,
        })
      }

      if (type === 'rips' && isValid) {
        const isRipUnsupported = checkIfRipIsIndirectlyUnsupported(
          supports[0],
          graph,
          positionGuidToWallGuid[guid],
        )
        if (isRipUnsupported) {
          const message = `Rippe ist verbunden mit der Wand, aber diese hat keine Auflager an dieser Stelle.`
          problems.push({
            element_guid: guid,
            message: message,
          })
        }
      }
    }

    // get all elements that do not have any load inducing elements
    if (elementTypesNotAllowedWithoutLoadInducing.includes(type)) {
      const support = find(graph.element_targets, { element_guid: guid })
      if (isUndefined(support)) {
        const message = `Element erhält keine Last(en) aus anderen Bauteilen.`
        problems.push({
          element_guid: guid,
          message: message,
        })
      }
    }
  })

  const problematicElements = problems.reduce((collector, { element_guid }) => {
    collector.add(element_guid)
    return collector as Set<string>
  }, new Set())
  const result = {
    problemsOnElements: problems,
    element_guids: [...problematicElements],
  } as ProblematicElements
  return result
}

export default getProblematicElements

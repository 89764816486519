import { useCallback } from 'react'
import { useControlStore } from '@editorStores'

/**
 * This hook should be used for all scene elements in the floorplan view in
 * order to enhance the click event passed to the element with 'global' click
 * events (e.g. for hiding). It is not ideal to make low level elements like a
 * wall interact with the global control store, but I think this is the best way
 * to implement uniform behaviour accross the whole app.
 * @param elementGuid
 * @param onClick The onClick function to execute if no global function is
 * enabled
 */
const useEnhanceClickEvent = (elementGuid: string, onClick?: (...args: any[]) => unknown) => {
  const actionMode = useControlStore(state => state.actionMode)
  const addHiddenElementIds = useControlStore(state => state.addHiddenElementIds)

  const hideElement = useCallback(
    () => addHiddenElementIds([elementGuid]),
    [elementGuid, addHiddenElementIds],
  )

  return {
    onClickEnhanced: actionMode === 'hide' ? hideElement : onClick,
    onClickBase: hideElement,
  }
}

export default useEnhanceClickEvent

import React, { useEffect, useState, ReactElement } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { LoadingIndicator } from '@ui/feedback'

interface Props {
  element: ReactElement
}

const ProtectedRoute = ({ element: Component }: Props): ReactElement => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0()
  const [Element, setElement] = useState<ReactElement>(<LoadingIndicator />)

  useEffect(() => {
    if (isLoading) {
      setElement(<LoadingIndicator />)
      return
    }

    if (!isAuthenticated) {
      loginWithRedirect()
      setElement(<LoadingIndicator />)
      return
    }

    setElement(Component)
  }, [isLoading, isAuthenticated, Component])

  return Element
}

export default ProtectedRoute

export const layerNames: {
  [key: string]: string
} = {
  'product-layer': 'Generisches Produkt',
  'timber-frame-layer': 'Holzrahmenschicht',
  'plate-layer': 'Statisch wirksame Platte',
  'clt-assembly-layer': 'CLT Schicht',
}

export const assemblyTypes: {
  label: string
  usage_type: AssemblyTypes
}[] = [
  {
    label: 'Innenwände',
    usage_type: 'Inner',
  },
  {
    label: 'Aussenwände',
    usage_type: 'Outer',
  },
  {
    label: 'Decken',
    usage_type: 'Ceiling',
  },
  {
    label: 'Dächer',
    usage_type: 'Roof',
  },
]

export const assemblyKinds: {
  label: string
  type: AssemblyKind
}[] = [
  {
    label: 'Holzrahmen',
    type: 'timber-frame-assembly',
  },
  // {
  //   label: 'CLT Aufbau',
  //   type: 'clt-assembly',
  // },
]

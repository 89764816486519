import { styled } from '@mui/system'

export const Container = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  display: flex;
  alignitems: center;
  justifycontent: center;
  flexdirection: column;
  zindex: 1;
`

export const Overlay = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000;
  opacity: 0.2;
  zindex: 1;
`

import React, { useState } from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import SupportResultsTable from './components/SupportResultsTable'

interface Props {
  designForces: DesignSupportForce[]
}

const SupportResults = ({ designForces }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [drawerExpanded, setDrawerExpanded] = useState(false)

  return (
    <Accordion defaultExpanded={false} data-cy="checks-accordion">
      <AccordionSummary
        aria-controls="panel1-content"
        id="panel1-header"
        expandIcon={<ArrowDropDownIcon />}
      >
        <Typography variant="h5" align="center" sx={{ width: '100%' }}>
          Design Auflagerkräfte
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <SupportResultsTable designForces={designForces} />
      </AccordionDetails>
    </Accordion>
  )
}

export default SupportResults

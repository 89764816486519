import { some } from 'lodash-es'
import { object, number, boolean } from 'yup'

interface Args {
  min: number
  max: number
  restrictedIntervals: Interval[]
}

export const createStiffeningElementSchema = ({ min, max, restrictedIntervals }: Args) => {
  return object({
    lower: number()
      .min(min, `Kann nicht kleiner als ${min}m sein`)
      .max(max, `Kann nicht größer als ${max}m sein`)
      .test({
        name: 'lower-is-smaller',
        message: 'Start darf nicht größer als Ende sein',
        test: function (value = 0) {
          if (value >= this.parent.upper) return false

          return true
        },
      }),
    upper: number()
      .min(min, `Kann nicht kleiner als ${min}m sein`)
      .max(max, `Kann nicht größer als ${max}m sein`)
      .test({
        name: 'upper-is-larger',
        message: 'Ende darf nicht kleiner als Start sein',
        test: function (value = 0) {
          if (value <= this.parent.lower) return false

          return true
        },
      }),
    effective_height: number().positive('Muss größer 0 sein.'),
    isIntersectingWithOpening: boolean()
      .default(false)
      .test({
        name: 'opening-test',
        message: 'Element darf nicht über einer Öffnung liegen',
        test: function () {
          const valueLower = this.parent.lower
          const valueUpper = this.parent.upper

          const isOverlapping = some(restrictedIntervals, interval => {
            const { lower, upper } = interval

            if (
              (lower > valueLower && upper < valueUpper) ||
              (valueUpper > lower && upper > valueLower) ||
              (lower < valueLower && upper > valueLower)
            ) {
              return true
            }

            return false
          })

          return !isOverlapping
        },
      }),
  })
}

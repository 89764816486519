import { find, isNil } from 'lodash-es'
import { roofAreaTypeConfig, roofTypeConfig } from './constants'

export const configToLabel = ({
  roof_type,
  flow_area_type,
  // @ts-ignore
  luv_side_elevation,
  // @ts-ignore
  luv_is_short_side,
}: RoofConfig): string => {
  const roofType = find(roofTypeConfig, ['value', roof_type])?.label
  const area = find(roofAreaTypeConfig, ['value', flow_area_type])?.label
  const alpha = luv_side_elevation
  // short side required for backend but only displayed for HipRoof
  const isShortSide = roof_type === 'HipRoof' ? luv_is_short_side : null
  const isShortSideLabel = luv_is_short_side ? 'kurz' : 'lang'

  return `${roofType} ${!isNil(area) ? ' - ' + area : ''} ${
    !isNil(isShortSide) ? ' - ' + isShortSideLabel : ''
  } ${!isNil(alpha) ? ' - ' + alpha + '°' : ''}`
}

export const createConfigFromType = (
  element_guid: string,
  { roofType, flowArea, alpha, isShortSide }: RoofFormConfig,
) => {
  const shared = {
    element_guid,
    shape_guid: null,
    roof_type: roofType,
  }

  switch (roofType) {
    case 'HipRoof': {
      return {
        ...shared,
        flow_area_type: flowArea,
        luv_side_elevation: alpha,
        luv_is_short_side: isShortSide,
      }
    }
    case 'GableRoof': {
      return {
        ...shared,
        flow_area_type: flowArea,
      }
    }
    case 'ShedRoof': {
      return {
        ...shared,
        flow_area_type: flowArea,
      }
    }
    case 'FlatRoof': {
      return {
        ...shared,
      }
    }
  }
}

import { getPointAtPercentage } from '@scene'
import { inRange, some, toNumber } from 'lodash-es'

/**
 * Check whether the provided value (relative position on wall) is within any of
 * the provided stiffening intervals
 *
 * As we are dealing with absolute intervals on the StiffeningInterval and a
 * relative position of the target we need to apply a minimal threshold in order
 * to overcome minimal rounding errors.
 */
const isPositionOnWallWithinAnyInterval = (
  wall: ShapeObject,
  intervals: StiffeningSegment[],
  value: number,
  threshold = 0.0001,
) => {
  const wallStart = wall.shape.points[0]
  const wallEnd = wall.shape.points[1]

  const wallDirection = wallEnd.sub(wallStart)

  if (!intervals) return false

  const positionOnDomain = getPointAtPercentage(wallStart, wallEnd, value)

  const domainPositionProjected = positionOnDomain.projectOnVector(wallDirection)
  const wallStartProjected = wallStart.projectOnVector(wallDirection)

  const absoluteDomainPositionOnWall = domainPositionProjected.distanceTo(wallStartProjected)

  return some(intervals, ({ interval: { lower, upper } }) =>
    inRange(absoluteDomainPositionOnWall, toNumber(lower) - threshold, toNumber(upper) + threshold),
  )
}

export default isPositionOnWallWithinAnyInterval

import { Box3, Vector3 } from 'three'

const getModelBoundingBox = (model: PlanarModel) => {
  // bounding box calculation is buggy for extruded geometries (see
  // bit.ly/3pf2UDm), hence we need to do it manually
  const customBoundingBox = new Box3()
  model.walls.forEach(wall => {
    wall.shape.points.forEach((p: Point) =>
      customBoundingBox.expandByPoint(new Vector3(p.x, p.y, p.z)),
    )
  })

  model.slabs.forEach(slab => {
    slab.shape.points.forEach((p: Point) =>
      customBoundingBox.expandByPoint(new Vector3(p.x, p.y, p.z)),
    )
  })

  model.roof_slabs.forEach(roofSlab => {
    roofSlab.shape.points.forEach((p: Point) =>
      customBoundingBox.expandByPoint(new Vector3(p.x, p.y, p.z)),
    )
  })

  return customBoundingBox
}

export default getModelBoundingBox

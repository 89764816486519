import { useConfigStore } from '@stores'
import { getRequest } from 'src/state/utils'

export const getModel: RequestTypeVariable = {
  getKey: (id: string) => ['getModel', id],
  request: async (id: string): Promise<PlanarModel> => {
    const { apiRoutes } = useConfigStore.getState()

    const { data } = await getRequest<PlanarModel>({
      url: apiRoutes.getModel(id),
    })

    return data as PlanarModel
  },
}

import React, { ReactElement, useMemo, useState } from 'react'
import { reduce, toNumber } from 'lodash-es'
import { Accordion, AccordionSummary, Stack, Typography } from '@mui/material'
import { SaveButton } from '@ui/actions'
import { LoadingIndicator } from '@ui/feedback'
import { Form } from '@ui/forms'
import { Box } from '@ui/structure'
import { useModelStore } from '@editorStores'
import FormFields from '../AssignmentForm/FormFields'
import { storeyNumToCeilingZLevel, storeyNumToStoreyLabel } from '../utils'

const StoreyWithoutCeilingInfo = ({ storey }: { storey: number }) => {
  const storeyBoundaries = useModelStore(state => state.model.storey_boundaries)
  const storeyZLevel = storeyNumToCeilingZLevel(storey, storeyBoundaries)
  return (
    <>
      <Accordion>
        <AccordionSummary key={storey.toString()} id={storey.toString()}>
          <Typography variant="body2">
            Keine Decke im {storeyNumToStoreyLabel(storey)} bei z={storeyZLevel}m
          </Typography>
        </AccordionSummary>
      </Accordion>
    </>
  )
}

interface Props {
  isLoading: boolean
  data?: LiveLoadConfiguration[]
  isSaving: boolean
  onSave: (data: { configurations: LiveLoadConfiguration[] }) => void
}

const LiveLoads = ({ isLoading, data, isSaving, onSave }: Props): ReactElement => {
  const [selectedGuid, setSelectedGuid] = useState(0)

  const model = useModelStore(state => state.model)
  const storeys = useMemo(
    () =>
      Object.keys(model.storey_assignment.slab_storey_id_assignment).map(storey =>
        toNumber(storey),
      ),
    [model],
  )
  const storeysReverse = [...storeys].reverse()
  const storeysWithSlabs = useMemo(
    () =>
      reduce(
        model.storey_assignment.slab_storey_id_assignment,
        (accumulator, value, storey) => {
          if (value.length > 0) {
            accumulator.push(toNumber(storey))
            return accumulator
          } else {
            return accumulator
          }
        },
        [] as number[],
      ),
    [model],
  )

  const defaultValues: { configurations: LiveLoadConfiguration[] } = useMemo(
    () => ({
      configurations:
        data ||
        storeys.map(storey => ({
          storey,
          separating_wall_load: 0,
          load_case: {
            load_case_type: 'LiveLoad_A1',
            identifier: 'Live',
            category: 'Live',
          },
          load_per_area: 1,
        })),
    }),
    [storeys, data],
  )

  if (isLoading) return <LoadingIndicator />

  return (
    <Form defaultValues={defaultValues} onSubmit={onSave} data-cy="live-loads-form">
      <Stack direction="column" spacing={2}>
        <Typography mb={1}>
          Nutzlasten werden immer auf die Geschoss<strong>decke</strong> angewandt.
        </Typography>
        <Box>
          {storeysReverse.map(storey => {
            if (storeysWithSlabs.includes(storey)) {
              return (
                <FormFields
                  key={storey}
                  storey={storey}
                  onOpenAccordion={setSelectedGuid}
                  openAccordion={selectedGuid}
                />
              )
            } else {
              return (
                <>
                  <StoreyWithoutCeilingInfo storey={storey} />
                </>
              )
            }
          })}
        </Box>
        <Box>
          <SaveButton loading={isSaving} type="submit" data-cy="save-btn">
            Speichern
          </SaveButton>
        </Box>
      </Stack>
    </Form>
  )
}

export default LiveLoads

import React, { ReactElement } from 'react'
import { RectangularShapeDrawer } from '@structuralPlanningComponents'
import { v4 as uuid } from 'uuid'
import { useModelStore } from '@editorStores'
import { useGuidToElement, useTypeInteraction } from '@editorHooks'

interface Props {
  setActiveElement: (guid: string) => void
  resetMode: () => void
}

const VerticalSlabDrawer3D = ({ setActiveElement, resetMode }: Props): ReactElement => {
  const setBlockingElement = useModelStore(state => state.setBlockingElement)

  useTypeInteraction(['roof_slabs', 'vertical_roof_slabs'])

  const addVerticalSlab = useModelStore(state => state.addSlab)
  const elements = useGuidToElement()

  return (
    <RectangularShapeDrawer
      onRoofDrawStart={() => setBlockingElement('vertical_slabs', true)}
      onRoofDrawEnd={() => setBlockingElement('vertical_slabs', false)}
      resetMode={resetMode}
      onElementDrawn={(target, points) => {
        const targetElement = elements[target]

        const slab = {
          guid: uuid(),
          shape: { points },
          storey: targetElement.storey,
          type: 'vertical_slabs' as ElementTypes,
          is_local: true,
        }

        setActiveElement(slab.guid)
        addVerticalSlab(slab)
      }}
      type="vertical_slabs"
      drawElements={['slabs', 'roof_slabs']}
    />
  )
}

export default VerticalSlabDrawer3D

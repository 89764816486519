export const defaultCrossSectionFormValues = () => {
  return {
    materialType: 'softwoodMaterial',
    steelShape: '',
    shape: {
      height: 0.0,
      width: 0.0,
      kind_literal: 'RectangularCS',
    },
    material: {
      kind: '',
      identifier: '',
    },
    usage_class: 'One',
  }
}

import produce from 'immer'
import { remove, findIndex } from 'lodash-es'

/**
 * This method adds an element guid to the bundle of a given element and
 * reorganizes the state in the member position bundle. It does so by removing
 * the given positionFor from any other bundle (either as representative for or as
 * representative position) and reassigns it to the bundle with the given
 * element guid
 * @param state the current bundles
 * @param position the representative position
 * @param positionFor the position the representative position should be used for
 * @returns updated member position bundles
 */
export const reassignRepresentativePositionInBundles = (
  state: MemberPositionBundle[],
  position: string,
  positionFor: string,
) => {
  return produce(state, newState => {
    let found = false

    // first we need to remove the guid from the existing bundles...
    const removedBundle = remove(newState, ['representative_position', positionFor])
    if (removedBundle.length) {
      found = true

      const representativesOfRemoved = (removedBundle[0].representative_for as string[]).map(
        guid => ({
          representative_position: guid,
          representative_for: [],
          exported: false,
        }),
      ) as MemberPositionBundle[]

      newState.push(...representativesOfRemoved)
    } else {
      for (let i = 0; i < newState.length; i++) {
        if (newState[i].representative_position === position) {
          continue
        }
        const removedRepresentatives = remove(
          newState[i].representative_for,
          el => el === positionFor,
        )
        if (removedRepresentatives.length) {
          found = true
          break
        }
      }
    }

    if (found) {
      // ...then we can reassign it to the current bundle
      const newIndex = findIndex(newState, ['representative_position', position])
      newState[newIndex].representative_for.push(positionFor)
    }

    return newState
  })
}

import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { useResultsInvalidation } from '@editorHooks'
import { getWindLoadSettings, getWindLoadsResults } from '@queries'
import { computeWindloadResults, saveWindLoadSettings } from '@mutations'
import queryClient from 'src/state/client'

const useWindloadsMutations = () => {
  const { enqueueSnackbar } = useSnackbar()

  const invalidateResults = useResultsInvalidation()

  const { projectId }: { projectId?: string } = useParams()

  const { mutate: compute, isLoading: isComputingWindLoads } = useMutation(
    () => computeWindloadResults.request(projectId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getWindLoadsResults.getKey(projectId))
      },
      onError: () => {
        enqueueSnackbar('Fehler beim Neuberechnen der Windlastkonfiguration', { variant: 'error' })
      },
    },
  )

  const { mutate, isLoading: isSavingWindLoads } = useMutation(
    async (data: WindLoadsFormValues) => {
      await saveWindLoadSettings.request(projectId, {
        roof_assignment: data.roofAssignment,
        wall_assignment_0: data.outerWallAssignment,
        wind_load_parameters: data.baseParameters,
        building_dimensions: data.dimensions,
      } as WindLoadSettings)
    },
    {
      onSuccess: () => {
        invalidateResults(projectId as string)
        queryClient.invalidateQueries(getWindLoadSettings.getKey(projectId))
        queryClient.invalidateQueries(getWindLoadsResults.getKey(projectId))
        enqueueSnackbar('Windlasten erfolgreich gespeichert', { variant: 'success' })
        compute()
      },
      onError: () => {
        enqueueSnackbar('Fehler beim Speichern der Windlasten', { variant: 'error' })
      },
    },
  )

  return {
    mutate,
    isSaving: isSavingWindLoads,
    isComputingWindLoads,
  }
}

export default useWindloadsMutations

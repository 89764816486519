import React, { ReactElement, useMemo } from 'react'
import { useMutation, useQueryClient, useQueries } from 'react-query'
import { useParams } from 'react-router-dom'
import { SelectedElementProblemsList } from '@structuralPlanningComponents'
import { checkIfElementSupportsColumnLike } from '@structuralPlanningUtils'
import { AxiosError } from 'axios'
import { filter, find } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { TabContext, TabPanel, TabList, LoadingButton } from '@mui/lab'
import { Divider, Stack, Tab, Typography } from '@mui/material'
import { AddButton, DeleteButton } from '@ui/actions'
import { Box } from '@ui/structure'
import { useEditElementStore, useModelStore, useStructuralPlanningStore } from '@editorStores'
import { useElementLabel, useElementTypes, useResultsInvalidation } from '@editorHooks'
import {
  getAssemblyAssignment,
  getElementCrossSectionAssignment,
  getElementLabels,
  getMemberCheckSettings,
  getModel,
  getStiffeningIntervals,
  getStiffeningProposal,
  getTensileTransmissionGraph,
  getVerticalTransmissionGraph,
} from '@queries'
import {
  saveStiffeningIntervals,
  recalculateTensileTargets,
  deleteWall,
  restoreRipCSOnWalls,
  restoreLintelCSOnWalls,
} from '@mutations'
import { buildErrorMessage } from 'src/constants/errors'
import { AssemblyAssignmentForm } from '../../../AssemblySelection/components'
import { useStructuralPlanningQueryParams } from '../../hooks'
import { useSaveTensileTransmissionGraph } from '../../hooks/useSaveTensileTransmissionGraph'
import PlacementForm from '../PlacementForm'
import StiffeningElementList from '../StiffeningElementList'
import TensileTransmitterList from '../TensileTransmitterList'
import VerticalTransmitterList from '../VerticalTransmitterList'

interface Props {
  elementType: ElementTypes
  selectedElementGuid: string
  onClose: () => void
  initialTabValue?: string
}

const WallForm = ({
  elementType,
  selectedElementGuid,
  initialTabValue,
  onClose,
}: Props): ReactElement => {
  const { enqueueSnackbar } = useSnackbar()
  const { projectId }: { projectId?: string } = useParams()
  const queryClient = useQueryClient()

  const invalidateResults = useResultsInvalidation()

  const model = useModelStore(state => state.model)

  const verticalTransmissionGraph = useStructuralPlanningStore(
    state => state.verticalTransmissionGraph,
  )

  const elementTypes = useElementTypes()

  const {
    params: { activeWallTab, selectedElements, verticalTransmitter },
    actions: { setActiveWallTab, setVerticalTransmitter, toggleMode },
  } = useStructuralPlanningQueryParams()
  const graph = useStructuralPlanningStore(state => state.tensileTransmissionGraph)
  const setTensileTransmissionGraph = useStructuralPlanningStore(
    state => state.setTensileTransmissionGraph,
  )

  const mergedProposal = useStructuralPlanningStore(state => state.mergedProposal)

  const removeWall = useModelStore(state => state.removeWall)

  const setActiveElement = useEditElementStore(state => state.setActiveElement)

  const wall = useMemo(
    () => find(model.walls, { guid: selectedElementGuid }),
    [model, selectedElementGuid],
  )

  const getLabel = useElementLabel()

  const label = getLabel(selectedElementGuid)

  useQueries([
    {
      queryKey: getStiffeningIntervals.getKey(projectId),
      queryFn: () => getStiffeningIntervals.request(projectId),
      refetchOnMount: true,
    },
    {
      queryKey: getTensileTransmissionGraph.getKey(projectId),
      queryFn: () => getTensileTransmissionGraph.request(projectId),
      refetchOnMount: true,
    },
  ])

  const { mutate: saveGraph, isLoading: isSavingGraph } = useSaveTensileTransmissionGraph(
    projectId as string,
  )

  const { mutate: recalculateTargets, isLoading: isRecalculating } = useMutation(
    () => recalculateTensileTargets.request(projectId, selectedElements?.[0] as string),
    {
      onSuccess: async data => {
        setTensileTransmissionGraph(data)
        invalidateResults(projectId as string)
        enqueueSnackbar('Ziele erfolgreich neu berechnet', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim der Berechnung der Ziele'), {
          variant: 'error',
        })
      },
    },
  )

  const { mutate: saveIntervals, isLoading: isSavingIntervals } = useMutation(
    (mergedProposal: StiffeningProposal) =>
      saveStiffeningIntervals.request(projectId, mergedProposal),
    {
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries(getStiffeningIntervals.getKey(projectId)),
          queryClient.invalidateQueries(getTensileTransmissionGraph.getKey(projectId)),
        ])
        invalidateResults(projectId as string)
        enqueueSnackbar('Aussteifende Elemente erfolgreich gespeichert', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(
          buildErrorMessage(error, 'Fehler beim speichern der Aussteifenden Elemente'),
          { variant: 'error' },
        )
      },
    },
  )

  const { mutateAsync: handleDelete, isLoading: isDeleting } = useMutation(
    (wallGuid: string) => {
      const wallSupportsColumnLike = checkIfElementSupportsColumnLike(
        wallGuid,
        verticalTransmissionGraph,
        elementTypes,
      )
      if (wallSupportsColumnLike) {
        throw new Error(
          'Wand erhält Lasten aus einer Rippe oder einer Stütze. ' +
            'Entfernen Sie zunächst die Verbindung der Rippe bzw. Stütze zu dieser Wand und löschen Sie dann die Wand',
        )
      }
      return deleteWall.request(projectId, wallGuid)
    },
    {
      onSuccess: async () => {
        setActiveElement(null)
        removeWall(selectedElementGuid)
        await Promise.all([
          queryClient.invalidateQueries(getModel.getKey(projectId)),
          queryClient.invalidateQueries(getVerticalTransmissionGraph.getKey(projectId)),
          queryClient.invalidateQueries(getAssemblyAssignment.getKey(projectId)),
          queryClient.invalidateQueries(getStiffeningProposal.getKey(projectId)),
          queryClient.invalidateQueries(getStiffeningIntervals.getKey(projectId)),
          queryClient.invalidateQueries(getElementLabels.getKey(projectId)),
          queryClient.invalidateQueries(getTensileTransmissionGraph.getKey(projectId)),
        ])

        invalidateResults(projectId as string)
        enqueueSnackbar('Wand erfolgreich gelöscht', { variant: 'success' })
      },
      onError: (error: Error | AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Löschen der Wand'), {
          variant: 'error',
        })
      },
    },
  )

  const { mutateAsync: restoreRipCS, isLoading: isRestoringRipCS } = useMutation(
    (element_guids: string[]) => {
      return restoreRipCSOnWalls.request(projectId as string, element_guids)
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getElementCrossSectionAssignment.getKey(projectId))
        await queryClient.invalidateQueries(getMemberCheckSettings.getKey(projectId))
        enqueueSnackbar('Querschnitte von Rippen erfolgreich aus Aufbau übernommen', {
          variant: 'success',
        })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(
          buildErrorMessage(error, 'Fehler beim Speichern der Querschnitts-Zuweisung'),
          { variant: 'error' },
        )
      },
    },
  )
  const { mutateAsync: restoreLintelCS, isLoading: isRestoringLintelCS } = useMutation(
    (element_guids: string[]) => {
      return restoreLintelCSOnWalls.request(projectId as string, element_guids)
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(getElementCrossSectionAssignment.getKey(projectId))
        await queryClient.invalidateQueries(getMemberCheckSettings.getKey(projectId))
        enqueueSnackbar('Querschnitte von Stürzen erfolgreich aus Aufbau übernommen', {
          variant: 'success',
        })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(
          buildErrorMessage(error, 'Fehler beim Speichern der Querschnitts-Zuweisung'),
          { variant: 'error' },
        )
      },
    },
  )

  const intervals = useMemo(() => {
    return filter(mergedProposal, ['element_guid', selectedElementGuid])
  }, [mergedProposal, selectedElementGuid])

  return (
    <Box>
      <TabContext value={activeWallTab || initialTabValue || 'vertical-transmission'}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            '& .MuiTab-root': {
              fontSize: 12,
              paddingX: ({ spacing }) => spacing(1.5),
            },
          }}
        >
          <TabList
            onChange={(_, value) => setActiveWallTab(value)}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab value="geometry" label="Bauteil" data-cy="tab-geometry" />
            <Tab
              value="vertical-transmission"
              label="Vertikale Verbindungen"
              data-cy="tab-wall-vertical-transmission"
            />
            <Tab value="connector" label="Zugverbindungen" data-cy="tab-connector" />
          </TabList>
        </Box>
        <TabPanel value="connector">
          <TensileTransmitterList
            key={`connector-list-${isRecalculating}`}
            selectedElement={selectedElementGuid}
            onSave={() => saveGraph(graph)}
            onRecalculate={() => recalculateTargets()}
            isRecalculating={isRecalculating}
            isLoading={isSavingGraph}
          />
        </TabPanel>
        <TabPanel value="vertical-transmission">
          <VerticalTransmitterList
            selectedElement={selectedElementGuid}
            verticalTransmitter={verticalTransmitter}
            setVerticalTransmitter={setVerticalTransmitter}
          />
          <SelectedElementProblemsList selectedElement={selectedElementGuid} />
        </TabPanel>
        <TabPanel value="geometry">
          <Stack direction="column" spacing={2}>
            <Typography variant="h6">{label}</Typography>

            <AssemblyAssignmentForm
              elementType={elementType}
              selectedElements={[selectedElementGuid]}
              onClose={onClose}
              showSelectedElementsNumber={false}
            />
            <Divider orientation="horizontal" flexItem textAlign="center">
              Querschnitte aus Aufbau übernehmen
            </Divider>
            <Stack spacing={2} direction="row">
              <LoadingButton
                loading={isRestoringRipCS}
                type="submit"
                variant="contained"
                size="small"
                data-cy="restore-rip-cs-btn"
                onClick={() => restoreRipCS([selectedElementGuid] as string[])}
              >
                Rippen-QS übernehmen
              </LoadingButton>
              <LoadingButton
                loading={isRestoringLintelCS}
                type="submit"
                variant="contained"
                size="small"
                data-cy="restore-lintel-cs-btn"
                onClick={() => restoreLintelCS([selectedElementGuid] as string[])}
              >
                Stürze-QS übernehmen
              </LoadingButton>
            </Stack>
            <Divider></Divider>

            <StiffeningElementList
              intervals={intervals}
              onSave={() => saveIntervals(mergedProposal)}
              isLoading={isSavingIntervals}
            />

            <Divider />

            <PlacementForm wallGuid={selectedElementGuid} />

            <Divider />

            <Stack justifyContent="flex-end" direction="row" mb={2}>
              <AddButton
                onClick={() => {
                  if (wall) {
                    setActiveElement(selectedElementGuid)
                    toggleMode('draw-openings')
                  }
                }}
                color="primary"
                variant="contained"
              >
                Öffnung einzeichnen
              </AddButton>
            </Stack>
            <Stack justifyContent="flex-end" direction="row">
              <DeleteButton
                onClick={() => handleDelete(selectedElementGuid)}
                loading={isDeleting}
                data-cy="delete-wall-btn"
              >
                Wand Löschen
              </DeleteButton>
            </Stack>
          </Stack>
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export default WallForm

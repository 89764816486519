export const tablesConfig: Record<ElementCSType, { value: ElementCSType; label: string }> = {
  Purlin: {
    value: 'Purlin',
    label: 'Pfetten',
  },
  Beam: {
    value: 'Beam',
    label: 'Unterzüge',
  },
  Column: {
    value: 'Column',
    label: 'Stützen',
  },
  Rip: {
    value: 'Rip',
    label: 'Wandrippen',
  },
  Lintel: {
    value: 'Lintel',
    label: 'Stürze',
  },
}

export const elementCSTypeToLabel: Record<keyof typeof tablesConfig, string> = {
  Beam: 'Unterzüge',
  Column: 'Stützen',
  Purlin: 'Pfetten',
  Rip: 'Wandrippen',
  Lintel: 'Stürze',
}

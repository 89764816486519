import React, { ReactElement } from 'react'
import { Grid, InputAdornment, Stack } from '@mui/material'
import { TextField } from '@ui/forms'

const VibrationCheckSettingsFormFields = (): ReactElement | null => {
  return (
    <Stack direction="column" spacing={2}>
      <Grid container spacing={2} xs={6}>
        <Grid item xs={12}>
          <TextField
            name="vibration.slab_width"
            label="Deckenbreite"
            type="number"
            InputProps={{
              endAdornment: <InputAdornment position="end">m</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>
    </Stack>
  )
}

export default VibrationCheckSettingsFormFields

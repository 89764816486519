import { find } from 'lodash-es'
import { object, string } from 'yup'
import { assemblyTypes } from 'src/components/manager/assemblies/constants'
import { checkDuplicateAssemblyNames, checkWallAssembliesHaveShortName } from 'src/utils/assemblies'

const categorySchema = (category: AssemblyTypes) =>
  object({
    standard: string()
      .default(undefined)
      .test({
        name: 'standard-is-set',
        test: function (value) {
          if (!value) {
            const label = find(assemblyTypes, ['usage_type', category])?.label
            return this.createError({
              message: `Bitte wählen Sie für ${label} einen Standardaufbau aus`,
              path: category,
            })
          }

          return true
        },
      }),
  })
    .test(
      'required-short-name',
      'Kurzbezeichnung für Aufbauten vom Typ Innenwand und Außenwand muss angegeben werden.',
      function (_, context) {
        const { assemblies } = context.options.context as {
          assemblies: Assembly[]
        }
        return checkWallAssembliesHaveShortName(assemblies)
      },
    )
    .test('duplicate-short-name', 'Kurzbezeichnung muss einzigartig sein.', function (_, context) {
      const { assemblies } = context.options.context as {
        assemblies: Assembly[]
      }
      return checkDuplicateAssemblyNames(assemblies)
    })
    .test('short-name-max-length', function (_, context) {
      const { assemblies } = context.options.context as {
        assemblies: Assembly[]
      }
      const { path, createError } = this
      for (const assembly of assemblies) {
        if (assembly.short_name.length > 25) {
          return createError({
            path,
            message: `Kurzbezeichnung darf maximal 25 Zeichen lang sein. ${assembly.short_name} sind ${assembly.short_name.length} Zeichen.`,
          })
        }
      }
      return true
    })

export const schema = object({
  Inner: categorySchema('Inner'),
  Outer: categorySchema('Outer'),
  Roof: categorySchema('Roof'),
  Ceiling: categorySchema('Ceiling'),
})

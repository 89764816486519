import React, { ReactElement } from 'react'
import { Grid, Link } from '@mui/material'
import { Form, TextField, UploadField, SelectLoadable } from '@ui/forms'
import { getResponsibleEngineers, getProcessorNames, getConstructionCompanies } from '@queries'
import { createProjectSchema, baseProjectSchema } from '../schema'

interface Props {
  id: string
  onSubmit: (project: ProjectData) => void
  project: ProjectData | null
}

const ProjectDataForm = ({ id, onSubmit, project }: Props): ReactElement => {
  return (
    <Form
      id={id}
      validationSchema={project ? baseProjectSchema : createProjectSchema}
      defaultValues={
        project
          ? {
              ...baseProjectSchema.getDefault(),
              ...project,
            }
          : createProjectSchema.getDefault()
      }
      onSubmit={values => onSubmit(values)}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextField label="Projekt Name" name="name" data-cy="input-project-name" />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Projektname für Abgabe"
            name="hand_in_project_name"
            data-cy="input-hand-in-project-name"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Beschreibung" name="description" data-cy="input-description" />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Projektadresse - Straße"
            name="address.street"
            data-cy="input-address-street"
            placeholder="Straße"
          />
          <TextField
            label="Projektadresse - Stadt"
            name="address.city"
            data-cy="input-address-city"
            placeholder="Stadt"
          />
          <TextField
            label="Projektadresse - PLZ"
            name="address.zip_code"
            data-cy="input-address-zip"
            placeholder="PLZ"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField label="Bauverantwortliche" name="owner_name" data-cy="input-owner-name" />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Adresse des Bauverantwortlichen"
            name="owner_address"
            data-cy="input-owner-address"
            multiline={true}
            rows={3}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectLoadable
            label="Planungsverantwortung"
            name="responsible_engineer_name"
            placeholder="Planungsverantwortung auswählen"
            query={getResponsibleEngineers}
            renderOptions={(data: string[] = []) =>
              data.map(responsible_engineer => ({
                value: responsible_engineer,
                label: responsible_engineer,
              }))
            }
            data-cy="input-responsible-engineer"
          />
          <Link href="https://airtable.com/appFrSIpLBzsNkVZk/pagNkXTCpLnJqB2tf/form">
            Neue Planungsverantwortung hinzufügen
          </Link>
        </Grid>
        <Grid item xs={12}>
          <SelectLoadable
            label="Bearbeitung"
            name="processor_name"
            placeholder="Bearbeitung auswählen"
            query={getProcessorNames}
            renderOptions={(data: string[] = []) =>
              data.map(processor_name => ({ value: processor_name, label: processor_name }))
            }
            data-cy="input-processor-name"
          />
          <Link href="https://airtable.com/appFrSIpLBzsNkVZk/pagNkXTCpLnJqB2tf/form">
            Neue Bearbeitung hinzufügen
          </Link>
        </Grid>
        <Grid item xs={12}>
          <SelectLoadable
            label="Auftraggeberin"
            name="construction_company_name"
            placeholder="Bearbeitung auswählen"
            query={getConstructionCompanies}
            renderOptions={(data: string[] = []) =>
              data.map(construction_company_name => ({
                value: construction_company_name,
                label: construction_company_name,
              }))
            }
            data-cy="input-construction-company-name"
          />
          <Link href="https://airtable.com/appFrSIpLBzsNkVZk/pagizIOgYlVbOeOqe/form">
            Neue Auftraggeberin hinzufügen
          </Link>
        </Grid>
        <Grid item xs={12}>
          {!project && <UploadField name="model" label="Modell JSON" />}
        </Grid>
      </Grid>
    </Form>
  )
}

export default ProjectDataForm

import React, { ReactElement, useEffect } from 'react'
import { TabContext, TabPanel, TabList } from '@mui/lab'
import { FormTab } from '@ui/forms'
import { Box } from '@ui/structure'
import { useTypeInteraction } from '@editorHooks'
import { RoofFormFields } from './components'
import { tabConfig, windAngleToDirection } from './constants'

const RoofAssignment = ({
  setWindAngle,
  activeWindAngle,
}: WallAssignmentParamsActions): ReactElement => {
  const defaultValue = tabConfig[0]

  const navigateTab = (value: string) => {
    // set to null to remove query param
    setWindAngle(value === defaultValue ? null : (value as WindAngle))
  }

  useEffect(() => {
    setWindAngle(activeWindAngle || defaultValue)
  }, [activeWindAngle])

  useTypeInteraction('roof_slabs')

  return (
    <Box marginX={-2} mt={-2}>
      <TabContext value={activeWindAngle || defaultValue}>
        <Box
          sx={{
            '& .MuiTabs-root': {
              minHeight: ({ spacing }) => spacing(4),
            },
            '& .MuiTab-root': {
              fontSize: 12,
              paddingX: ({ spacing }) => spacing(1.5),
              height: ({ spacing }) => spacing(3),
              minHeight: ({ spacing }) => spacing(3),
            },
          }}
        >
          <TabList onChange={(_, value) => navigateTab(value)}>
            {tabConfig.map(angle => (
              <FormTab
                fields={`roofAssignment[${windAngleToDirection[angle]}].configurations`}
                value={angle}
                label={`${angle}°`}
                key={angle}
                data-cy={`tab-angle-${angle}`}
              />
            ))}
          </TabList>
          {tabConfig.map(angle => (
            <TabPanel value={angle} key={angle}>
              <RoofFormFields direction={windAngleToDirection[angle]} />
            </TabPanel>
          ))}
        </Box>
      </TabContext>
    </Box>
  )
}

export default RoofAssignment

import React, { ReactElement, useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { uniqBy, filter, sortBy } from 'lodash-es'
import { Grid, Divider } from '@mui/material'
import { SelectLoadable, FormControl, MillimeterField } from '@ui/forms'
import { getStructuralPlates, getFasteners } from '@queries'
import { meterToMillimeter } from '@utils'

const PlateLayerForm = (): ReactElement => {
  const { watch, setValue } = useFormContext()
  const producerWatch = watch('producer')
  const plateWatch = watch('layer.plate')
  const fastenerWatch = watch('layer.fastener')

  const renderProducerOptions = useCallback((data: PlateCrossSection[]) => {
    const values = data.map(({ producer }) => ({
      value: producer.trim(),
      label: producer,
    }))

    return uniqBy(values, 'value')
  }, [])

  const renderPlateOptions = useCallback(
    (data: PlateCrossSection[]) => {
      const dataByProducer = filter(data, ['producer', producerWatch])

      const values = dataByProducer.map(value => ({
        value: value,
        label:
          value.material.identifier + ' - lxh: ' + value.shape_x.width + 'x' + value.shape_y.width,
      }))

      return sortBy(uniqBy(values, 'value'), ['label'])
    },
    [producerWatch],
  )

  const renderFastenerOptions = useCallback(
    (data: Fastener[]) => {
      return data.map((fastener: Fastener) => ({
        value: fastener,
        label: `${fastener.producer} ${fastener.unique_name} ${meterToMillimeter(
          fastener.length,
        )}mm`,
      }))
    },
    [producerWatch],
  )

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <SelectLoadable
          label="Produzent"
          name="producer"
          query={getStructuralPlates}
          onChangeFinished={() => setValue('layer.plate', null)}
          renderOptions={renderProducerOptions}
          data-cy="producer"
        />
      </Grid>
      <Grid item xs={12}>
        <SelectLoadable
          label="Produkt"
          name="layer.plate"
          disabled={!producerWatch}
          query={getStructuralPlates}
          renderOptions={renderPlateOptions}
          data-cy="product"
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl label="Material Breite">
          <MillimeterField
            name=""
            staticValue={plateWatch ? plateWatch?.material.thickness : ''}
            size="small"
            disabled
            numeralFormatter="0.[0]" // 1 decimal
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <SelectLoadable
          label="Klammer"
          name="layer.fastener"
          query={getFasteners}
          renderOptions={renderFastenerOptions}
          data-cy="fastener"
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl label="Klammer Durchmesser">
          <MillimeterField
            name=""
            staticValue={fastenerWatch ? fastenerWatch.diameter : ''}
            size="small"
            disabled
            numeralFormatter="0.[00]" // 2 decimals
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl label="Klammer Länge">
          <MillimeterField
            name=""
            staticValue={fastenerWatch ? fastenerWatch.length : ''}
            size="small"
            disabled
            numeralFormatter="0" // 0 decimals
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <MillimeterField
          label="Klammer Abstand"
          name="layer.fastener_distance"
          data-cy="fastener_distance"
          numeralFormatter="0" // 0 decimals
        />
      </Grid>
    </Grid>
  )
}

export default PlateLayerForm

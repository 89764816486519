import React, { ReactElement } from 'react'
import { isUndefined } from 'lodash-es'
import numeral from 'numeral'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { TableCell, TableRow, IconButton, Select, MenuItem } from '@mui/material'
import { AlertDialog } from '@ui/feedback'
import { Box } from '@ui/structure'

interface Props {
  connection: Connection
  onDelete: () => void
  onEdit: () => void
  onChangeOrder?: (order: number | undefined) => void
  order?: number | undefined
  showOrderInput?: boolean
}

const ConnectionListItem = ({
  connection,
  onDelete,
  onEdit,
  onChangeOrder,
  order,
  showOrderInput = false,
}: Props): ReactElement => {
  const { name, guid, export_comment, r_d_wind, r_d_earthquake } = connection

  return (
    <>
      <TableRow
        key={guid}
        sx={({ palette }) => ({
          '&:hover': {
            background: palette.grey[100],
          },
          cursor: 'pointer',
        })}
      >
        {showOrderInput && (
          <TableCell component="th" scope="row">
            <Select
              value={isUndefined(order) ? 'not-relevant' : order}
              onChange={e =>
                onChangeOrder?.(
                  e.target.value === 'not-relevant' ? undefined : (e.target.value as number),
                )
              }
              size="small"
            >
              {[...Array(9)].map((_, num) => {
                const order = num + 1
                return (
                  <MenuItem key={order} value={order}>
                    {order}
                  </MenuItem>
                )
              })}
              <MenuItem value="not-relevant">-</MenuItem>
            </Select>
          </TableCell>
        )}
        <TableCell>{name}</TableCell>
        <TableCell>{export_comment}</TableCell>
        <TableCell>{numeral(r_d_earthquake / 1000).format('0,0.00a')}</TableCell>
        <TableCell>{numeral(r_d_wind / 1000).format('0,0.00a')}</TableCell>
        <TableCell align="right">
          <Box display="flex" justifyContent="flex-end">
            <AlertDialog
              title="Zugverbindung verändern?"
              text={
                showOrderInput
                  ? 'Änderungen an dieser Zugverbindung werden nur innerhalb des Projektes angewandt und wirken sich nicht auf andere Projekte aus.'
                  : 'Die Änderung an der Zugverbindung wird nicht in bestehende Projekte übernommen. Die Zugverbindungen müssen <b>jeweils</b> in den entsprechenden Projekten im Zugverbindungs-Manager geändert werden.'
              }
              onConfirm={onEdit}
            >
              <IconButton>
                <EditIcon fontSize="small" />
              </IconButton>
            </AlertDialog>
            <AlertDialog
              title="Zugverbindung löschen?"
              text="Sind Sie sicher, dass Sie diese Zugverbindung löschen wollen?"
              onConfirm={async () => onDelete()}
            >
              <IconButton>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </AlertDialog>
          </Box>
        </TableCell>
      </TableRow>
    </>
  )
}

export default ConnectionListItem

import {
  ToggleButtonGroup as MuiToggleButtonGroup,
  ToggleButton as MuiToggleButton,
  styled,
} from '@mui/material'
import { alpha } from '@mui/material/styles'

export const ToggleButtonGroup = styled(MuiToggleButtonGroup)({
  backgroundColor: 'transparent',
})

export const ToggleButton = styled(MuiToggleButton, {
  shouldForwardProp: prop => prop !== 'inverted',
})<{
  inverted?: boolean
}>(({ theme, inverted }) => ({
  color: alpha(theme.palette.common.white, 0.75),
  borderColor: inverted
    ? alpha(theme.palette.common.black, 0.5)
    : alpha(theme.palette.common.white, 0.5),
  padding: theme.spacing(1),

  '&.Mui-selected': {
    color: theme.palette.common.white,
    backgroundColor: inverted ? theme.palette.grey[400] : theme.palette.primary.light,
  },

  '&.Mui-disabled': {
    color: alpha(theme.palette.common.white, 0.25),
    borderColor: alpha(theme.palette.common.white, 0.25),
  },
}))

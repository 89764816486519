import numeral from 'numeral'
import { formatLocalForce } from '@ui/forms/LoadForceField/utils'

export const getLineLoadTitle = (load: LineLoadWithDomain, index: number): string => {
  const { start, end, relative_end, relative_start, domain, load_case } = load

  if (!domain) return `L${index + 1}`

  const loadType = loadCategoryToShort[load_case.category] || '-'
  const domainLength = domain?.length || 1

  const startFormatted = numeral(formatLocalForce(start, domain as Domain)).format('0[.][00]a')
  const endFormatted = numeral(formatLocalForce(end, domain as Domain)).format('0[.][00]a')
  const relativeStart = numeral(relative_start * domainLength).format('0[.][00]a')
  const relativeEnd = numeral(relative_end * domainLength).format('0[.][00]a')

  return `L${
    index + 1
  }: ${startFormatted}kN | ${endFormatted}kN | ${relativeStart}m - ${relativeEnd}m | ${loadType}`
}

export const getPointLoadTitle = (load: PointLoadWithDomain, index: number): string => {
  const { force, relative_position, domain, load_case } = load

  if (!domain) return `P${index + 1}`

  const loadType = loadCategoryToShort[load_case.category] || '-'
  const domainLength = domain?.length || 1

  const forceFormatted = numeral(formatLocalForce(force, domain as Domain)).format('0[.][00]a')
  const relativePosition = numeral(relative_position * domainLength).format('0[.][00]a')

  return `P${index + 1}: ${forceFormatted}kN | ${relativePosition}m | ${loadType}`
}

export const getAreaLoadTitle = (load: AreaLoad, index: number): string => {
  const { load_case } = load

  const loadType = loadCategoryToShort[load_case.category] || '-'

  const forceFormatted = numeral(load.force.z).format('0[.][00]a')

  return `A${index + 1}: ${forceFormatted}N | ${loadType}`
}

export const loadCategoryToShort: Record<LoadCategory, string> = {
  Live: 'L',
  Dead: 'G',
  Snow: 'S',
  SnowPlus: 'S',
  Wind: 'W',
  EarthQuake: 'E',
}

export const loadCaseLabels: Record<LoadCategory, { [K in LoadCaseTypes]?: string }> = {
  Live: {
    LiveLoad_A1: 'Nutzlast A1',
    LiveLoad_A2: 'Nutzlast A2',
    LiveLoad_A3: 'Nutzlast A3',
    LiveLoad_B1: 'Nutzlast B1',
    LiveLoad_B2: 'Nutzlast B2',
    LiveLoad_B3: 'Nutzlast B3',
    LiveLoad_C1: 'LiveLoad C1',
    LiveLoad_C2: 'LiveLoad C2',
    LiveLoad_C3: 'LiveLoad C3',
    LiveLoad_C4: 'LiveLoad C4',
    LiveLoad_C5: 'LiveLoad C5',
    LiveLoad_C6: 'LiveLoad C6',
    LiveLoad_D1: 'LiveLoad D1',
    LiveLoad_D2: 'LiveLoad D2',
    LiveLoad_D3: 'LiveLoad D3',
    LiveLoad_E1: 'LiveLoad E1',
    LiveLoad_E2: 'LiveLoad E2',
    LiveLoad_F1: 'LiveLoad F1',
    LiveLoad_F2: 'LiveLoad F2',
    LiveLoad_H: 'LiveLoad H',
  },
  Dead: {
    DeadLoad: 'Eigengewicht',
  },
  Snow: {
    SnowFull: 'Schnee',
  },
  SnowPlus: {
    SnowFull: 'Schnee >1000m Höhe ü. NN',
  },
  Wind: {
    Wind_Global_0: 'Wind 0',
    Wind_Global_90: 'Wind 90',
    Wind_Global_180: 'Wind 180',
    Wind_Global_270: 'Wind 270',
  },
  EarthQuake: {
    Earthquake_0: 'Erdbeben 0',
    Earthquake_90: 'Erdbeben 90',
    Earthquake_180: 'Erdbeben 180',
    Earthquake_270: 'Erdbeben 270',
    Earthquake_Spectral_PlusXPlus30Y: 'E +X|+0.3Y',
    Earthquake_Spectral_PlusXMinus30Y: 'E +X|-0.3Y',
    Earthquake_Spectral_MinusXPlus30Y: 'E -X|+0.3Y',
    Earthquake_Spectral_MinusXMinus30Y: 'E -X|-0.3Y',
    Earthquake_Spectral_PlusYPlus30X: 'E +Y|+0.3X',
    Earthquake_Spectral_PlusYMinus30X: 'E +Y|-0.3X',
    Earthquake_Spectral_MinusYPlus30X: 'E -Y|+0.3X',
    Earthquake_Spectral_MinusYMinus30X: 'E -Y|-0.3X',
    Earthquake_X_e_max_y: 'E X e_max_y',
    Earthquake_X_e_max_y_inv: 'E X e_max_y_inv',
    Earthquake_X_e_min_y: 'E X e_min_y',
    Earthquake_X_e_min_y_inv: 'E X e_min_y_inv',
    Earthquake_Y_e_max_x: 'E Y e_max_x',
    Earthquake_Y_e_max_x_inv: 'E Y e_max_x_inv',
    Earthquake_Y_e_min_x: 'E Y e_min_x',
    Earthquake_Y_e_min_x_inv: 'E Y e_min_x_inv',
  },
}
